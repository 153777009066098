.check-adult-frame:not([frame_index="0"])
  width 100%
  height 100%
  background-color $dim-60
  >.don_wrapper
    text-align center
    width 90%
    height auto
    position absolute
    top 50%
    left 50%
    bottom auto
    transform translate(-50%, -50%)
    border-radius 8px
    overflow hidden
    background-color $white
    >.header
      position relative
      padding 36px 24px 12px
      height auto !important
      .title
        width 100%
.check-adult-foreigner
  padding 0 24px 24px
  &__description
    margin-bottom 24px
    color $gray-80
  &__age
    margin-bottom 24px
    input
      padding 12px 0
      text-align center
      font-size 16px
      width 160px
      border none
      border-bottom 1px solid #ddd
      outline none
      &::placeholder
        color #ddd
      &:focus-visible
        border-bottom 1px solid $blue
  &__agrees
    width 100%
    position relative
    display flex
    flex-direction column
    align-items flex-start
    margin-bottom 32px
    gap 8px
  &__agree
    display flex
    align-items flex-start
    .go_terms
      text-decoration-line underline
      color $blue
  &__button-wrapper
    width 100%
    display flex
    gap 12px
    align-items center
    justify-content center
  &__iam18
    flex 1 1 0
    button
      width 100%
      height 52px
      display flex
      justify-content center
      align-items center
      color $gray-50
      border-radius 8px
      background-color $gray-5
      border none
      &.input-checked
        background $blue
        color $white
  &__iam-under18
    flex 1 1 0
    button
      width 100%
      height 52px
      display flex
      justify-content center
      align-items center
      color $gray-80
      border-radius 8px
      border 1px solid $gray-20
      background-color transparent
