.app-today
  min-height 80vh
  &__container
    padding 0 24px

  &__infinite__scroll
    margin-bottom 20px

    &__divider
      font-size 16px
      font-weight 600
      line-height 1.25
      letter-spacing -0.3px
      color #4e4e4e
      margin-bottom 8px
