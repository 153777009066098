$mshop-default-header = '.mshop-default-header'
$transition_common = 0.3s ease

.mshop-default-header
  position relative
  height 60px
  &__wrapper
    background-color rgba(255,255,255,1)
    color #000000
    padding 0 16px 0 24px
    transition transform $transition_common
  &__wrapper--pinned
    transform translateY(0%)
  &__wrapper--unpinned
    transform translateY(-100%)
  &__inner
    display flex
    align-items center
    height 60px
  &__left
    {$font-heading-h5-sb}
    align-items center
    display flex
  &__logo-box
    width 180px
    height 32px
    display flex
    align-items center
    position relative
  &__logo
    margin auto 0
    position absolute
    top 0
    bottom 0
    right 0
    left 0
    max-width 100%
    max-height 100%
  &__arrow-icon
    display inline-flex
    width 24px
    height 24px
    margin-right 12px
  &__center
    position absolute
    left 0
    top 0
    width 100%
    height 100%
    display flex
    justify-content center
    align-items center
    z-index -1
  &__title
    {$font-heading-h5-sb}
  &__right
    margin-left auto
    display flex
    align-items center
    column-gap 16px
  &__shopping-bag
    width 28px
    height 28px
    cursor pointer
  &__menu-icon
    width 28px
    height 28px
    cursor pointer
  &[data-position="fixed"]
    {$mshop-default-header}__wrapper
      position fixed
      top 0
      right 0
      left 0
      z-index 3
  &[data-layering="true"]
    position absolute
  &[data-theme="light"]
    {$mshop-default-header}__wrapper
      background-color rgba(255,255,255,0)
      color #ffffff
    // svg attribute style 이라 important 써야 함
    {$mshop-default-header}__shopping-bag *
      stroke #ffffff !important
    {$mshop-default-header}__menu-icon *
      stroke #ffffff !important

  &[data-theme="transparent"]
    {$mshop-default-header}__wrapper
      background-color rgba(255,255,255,0)
      color #ffffff
    // svg attribute style 이라 important 써야 함
    {$mshop-default-header}__shopping-bag *
      stroke #ffffff !important
    {$mshop-default-header}__menu-icon *
      stroke #ffffff !important

  // item color 는 transparent 일 때만 적용되어야 함, 그래서 위치가 transparent 랑 white 사이
  &[data-item_color="black"]
    {$mshop-default-header}__logo--white
      opacity 0
    {$mshop-default-header}__wrapper
      color #111111
    // svg attribute style 이라 important 써야 함
    {$mshop-default-header}__shopping-bag *
      stroke #111111 !important
    {$mshop-default-header}__menu-icon *
      stroke #111111 !important

  &[data-theme="white"]
    {$mshop-default-header}__logo--white
      opacity 0
    {$mshop-default-header}__wrapper
      background-color rgba(255,255,255,1)
      color #111111
    // svg attribute style 이라 important 써야 함
    {$mshop-default-header}__shopping-bag *
      stroke #111111 !important
    {$mshop-default-header}__menu-icon *
      stroke #111111 !important

  &--transition-ready
    {$mshop-default-header}__logo
      transition opacity $transition_common
    {$mshop-default-header}__wrapper
      transition background-color $transition_common, color $transition_common, transform $transition_common
    {$mshop-default-header}__shopping-bag *
      transition stroke $transition_common
    {$mshop-default-header}__menu-icon *
      transition stroke $transition_common

  [data-show="false"]
    display none
