/*
 * -----------------메인-----------------------
 * 이미지, 뱃지, (상품이름 || 제품이름), 가격, 스토어이름, 좋아요 버튼
 *  - 홈에서 상품 더보기, 상품탭 상품
*/
.{a-p-i}--v2
  width 50%
  margin-bottom pxToRem(24px)
  box-sizing border-box
  .{a-p-i}
    &__like
      display block
    &__store-name
      display block
    &__bottom-info
      margin 16px 0 0
    &__img
      border-radius 16px
      overflow hidden
  &.grid-stack-container__item
    width 100%
  &.swiper-slide
    .{a-p-i}
      &__like
        display none
