$header-menu = '.header-menu'
//$creator-header = '.creator-header'

//.creator-header
//  position relative
//  height 60px
//  outline none
//  z-index 200
//  &--is-menu
//    height 114px
//  &--is-app
//    height 0
//    {$creator-header}__inner
//      height 0
//  &--is-menu{$creator-header}--is-app
//    height 60px
//  &__inner
//    position fixed
//    top 0
//    left 0
//    width 100%
//    box-sizing border-box
//  &__image-box
//    position relative
//    display flex
//    align-items center
//    width 168px
//    height 40px
//  &__image
//    height 21px
//    &--plus
//      height 25px
//  &__row
//    position relative
//    padding 0 15px 0 24px
//    height 60px
//    background-color #fff
//    z-index 2
//    &:nth-child(1)
//      overflow hidden
//    &--flex
//      display flex
//      flex-direction row
//      justify-content space-between
//      align-items center
//    &--is-app {$creator-header}--flex
//      display none
//      height 0
//    &--menu
//      display flex
//      transition transform .3s
//      z-index 1
//      height 54px
//    &--scroll
//      padding 0
//      overflow hidden
//    &--dark
//      background-color #000
//      color #fff
//  &__column
//    font-size 0
//    &--flex
//      display flex
//      align-items center
//    &--scroll
//      overflow-x auto
//      overflow-y hidden
//      padding 0 24px 80px
//      margin-bottom -80px
//  &__logo-text
//    font-size 18px
//    font-weight 600
//    line-height 1.2
//    letter-spacing -0.2px
//  &__button
//    reset-button()
//    flexCenter()
//    position relative
//    width 40px
//    height 40px
//    &::before
//      stripe-image-before 22px 22px '//image1.marpple.co/files/u_1396787/2021/4/original/50680d8497b99dcb9e459945e4237b05f2435c3c1.png?w=156' 78px
//    &--cart
//      &::before
//        background-position -2px -2px
//    &--menu
//      &::before
//        background-position -54px -2px
//      .blind-text
//        blind()
//  &__text-box
//    flexCenter()
//    display none
//    position absolute
//    top pxToRem(3px)
//    right 0
//    padding 4px 0 1px
//    width 19px
//    border-radius 100%
//    background-color #0e2ee2
//    color #fff
//    &--show
//      display flex
//  &__button--cart {$creator-header}__text-box--wide
//      right pxToRem(-5px)
//      width 25px
//      border-radius 8px
//  &__text--cart
//    font 12px 12px -.2px
//  &--unpinned
//    {$creator-header}__inner
//      height 60px
//    {$creator-header}__row--menu
//      transform translateY(-100%)
//  &--pinned {$creator-header}__row--menu
//    transform translateY(0)

{$header-menu}
  reset-list()
  display flex
  align-items center
  margin-top 10px
  width 100%
  &--mo
    white-space nowrap
  &__anchor
    font pxToRem(19px) 1.26 -0.2px 600
    color #a2a2a2 !important
    ^[{$root-index}]__list ^[{$root-index}..-{$root-index}]
      margin-right 16px
    &--active
      color #1C75FF !important
    @media screen and (max-width 350px)
      font pxToRem(20px) 30px -0.3px 600
.mshop-main-seller-frame
  {$header-menu}
    &__anchor
      &--active
        color #111111 !important

@keyframes snow-animation {
  from {
    transform: translateY(-11px);
    opacity: 1;
  }
  to {
    transform: translateY(60px);
    opacity: 0;
  }
}
