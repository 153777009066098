$asp=app-store-product
.{$asp}
  &__kv
    position relative
    height 0
    margin-bottom 24px
    img
      width 100%
  &__cate_lists
    margin 0 0 28px
    .slide-selector__item
      margin 0 6px 0 0
      padding 6px 14px 4px
      border-radius 14px
      border solid 1px #dddddd
      background-color #fff
      line-height 1.38
      letter-spacing -0.2px
      color #4e4e4e
      font-size 13px
      height auto
      &[data-selected="true"]
        border solid 1px #1C75FF
        color #1C75FF
  &__list
    min-height 500px
    margin 0 24px 30px
  &__list-head
    margin-bottom 12px
    display flex
    align-items center
    font-size 18px
    line-height 1.56
    letter-spacing -0.2px
    color #111111
  &__title
    margin-right 4px
    font-weight 600
.app-store-suspended
  display flex
  height 30vh
  flex-direction column
  justify-content center
  align-items center
  padding 140px 0
  text-align center
  img
    width 113px
  p
    width 100%
    margin 8px 0 0
    font-size 16px
    font-weight 600
    line-height 1.63
