// modules/MShop/Youtube/Bridge/F/Style/Mo/index.styl
@media screen and (max-width 375px)
  html.youtube-x-marpple
    font-size: calc(1vw * (14 / 3.75)) !important;

.randing-page-header
  position fixed
  top 0
  left 0
  right 0
  display flex
  justify-content center
  align-items center
  padding pxToRem(16) 0
  gap pxToRem(16)
  width 100%
  height pxToRem(60)
  box-sizing border-box
  z-index 100
  background: rgba(20, 20, 20, 0.01);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  color #fff
  transition color .25s ease-in-out, transform .25s ease-in-out;
  will-change transform
  &--unpinned
    transform translateY(-100%)
  &--black
    color: var(--Black, #141414);
    background: var(--Bright-70, rgba(255, 255, 255, 0.70));
  img
    flex-shrink 0

.youtube-error-wrap
  text-align center
  h3
    font-size pxToRem(20)
    line-height pxToRem(26)
    font-weight 800
    margin 0
    margin-bottom pxToRem(12)
    letter-spacing pxToRem(-0.3)
    word-break keep-all
  div.message
    letter-spacing pxToRem(-0.2)
    font-size pxToRem(15)
    line-height pxToRem(26)
    font-weight 400
    display flex
    justify-content center
    align-items center
    flex-wrap wrap
    gap pxToRem(4)
    max-width pxToRem(320)
    margin 0 auto
    word-break keep-all
  a.link
    color rgba(60, 137, 249, 1)
    letter-spacing pxToRem(-0.3)
    font-size pxToRem(16)
    line-height pxToRem(24)
    font-weight 600
    margin-top pxToRem(20)
    display flex
    justify-content center
    align-items center
    flex-wrap wrap
    gap pxToRem(4)
    word-break keep-all


.youtube-bridge
  &__title
    color: var(--Black, #141414)
    font-size pxToRem(24)
    font-weight 700
    line-height: pxToRem(34)
    letter-spacing: -0.2px
    text-align: center
    strong
      color #1F5EFF
  &__title2
    color: var(--Black, #141414);
    text-align: center;
    font-size: pxToRem(28);
    font-weight: 700;
    line-height: pxToRem(39);
    letter-spacing: -0.2px;
    strong
      color #1F5EFF
  &__sub-txt1
    margin-top pxToRem(12)
    color: var(--Black, #141414);
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.2px;
    font-size: pxToRem(14)
    line-height: pxToRem(22)
    a
      color: var(--Blue, #1C75FF)
      text-decoration-line: underline
  &__content
    max-width pxToRem(1920)
    margin 0 auto
    width 100%

.youtube-bridge-help01-swiper
  width 100vw
  overflow hidden
  max-width none
  position relative
  padding-bottom pxToRem(20)

.youtube-bridge-help01
  margin 0
  padding 0
  list-style none
  display flex
  align-items center
  box-sizing border-box
  position relative
  &__li
    text-align center
    width 100vw
  &__img
    margin pxToRem(30) 0
    text-align center
    width 100%
    img
      width 57.3%
      object-fit cover
  &__txt1
    color: var(--Black, #141414);
    text-align: center;
    font-weight: 500;
    letter-spacing: -0.2px;
    font-size: pxToRem(14);
    line-height: pxToRem(22)
  &__pagination
    position absolute
    top auto !important
    left pxToRem(32) !important
    right pxToRem(32) !important
    width auto !important
    bottom 0 !important
    height pxToRem(2) !important
    background: var(--Dim-10, rgba(20, 20, 20, 0.10));
    .swiper-pagination-progressbar-fill
      background: var(--Blue-100, #1C75FF) !important;


.youtube-bridge-why
  display flex
  align-items center
  flex-direction column
  justify-content center
  gap pxToRem(40)
  &__ol
    order 2
    width 100%
    margin 0
    padding 0 pxToRem(10)
    list-style none
    display flex
    flex-direction column
    gap pxToRem(32)
  &__li
    display flex
    align-items flex-start
    justify-content flex-start
    gap pxToRem(32)
  &__icon
    display: none
  &__txt-area
    display flex
    width 100%
    flex-direction column
    gap pxToRem(12)
    text-align center
  &__txt1
    margin 0
    padding 0
    color: #141414
    font-size: pxToRem(20)
    font-weight: 700
    line-height: pxToRem(28)
    letter-spacing: -0.2px
    strong
      color: var(--Blue-100, #1C75FF);
  &__txt2
    margin 0
    padding 0
    color: #6B6B6B
    font-size: pxToRem(14)
    font-weight: 500;
    line-height: pxToRem(22)
    letter-spacing: -0.2px
  &__thumb
    order 1
    width 100%
    box-sizing border-box
    margin-top pxToRem(32)
    padding 0 pxToRem(20)
    img
      width 100%


.youtube-bridge-how
  width 100%
  &--swiper
    width 100vw
    overflow hidden
    max-width none
    position relative
    padding-bottom pxToRem(20)
  &__ol
    margin pxToRem(32) 0 0
    padding 0
    list-style none
  &__li
    width 100vw
    padding 0 pxToRem(20)
    box-sizing border-box
  &__img
    margin-bottom pxToRem(16)
    img
      width 100%
  &__step
    margin-bottom pxToRem(12)
    color: var(--Black, #141414);
    text-align: center;
    font-family 'Bebas Neue Pro';
    font-size: pxToRem(24)
    font-weight: 700
    line-height: pxToRem(24)
    letter-spacing: -0.2px
    text-transform: uppercase
  &__txt1
    margin-bottom pxToRem(12)
    font-size: pxToRem(14)
    line-height: pxToRem(22)
    color: var(--Black, #141414);
    text-align: center;
    font-weight: 500;
    letter-spacing: -0.2px;
  &__txt2
    text-align: center
  &__a
    color: #1C75FF !important
    font-size: pxToRem(14)
    font-weight: 700
    line-height: pxToRem(22)
    letter-spacing: -0.2px
    padding-bottom 4px
    border-bottom 1px solid #1C75FF
  &__pagination
    position absolute
    top auto !important
    left pxToRem(32) !important
    right pxToRem(32) !important
    width auto !important
    bottom 0 !important
    height pxToRem(2) !important
    display flex
    align-items center
    justify-content space-between
    background: var(--Dim-10, rgba(20, 20, 20, 0.10));
    .swiper-pagination-progressbar-fill
      background: var(--Blue-100, #1C75FF) !important;

.youtube-bridge-case
  color #fff
  .youtube-bridge__title
  .youtube-bridge__sub-txt1
    color #fff
  &__title
    position absolute
    top pxToRem(95)
    left 0
    right 0
    z-index 2

  &__swiper
    width 100vw
    overflow hidden
    max-width none
  &__ul
    margin 0
    padding 0
    list-style none
  &__li
    width 100vw
    padding pxToRem(256) pxToRem(40) pxToRem(162)
    box-sizing border-box
  &__bg
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    z-index -1
  &__youtube-txt1
    margin-bottom pxToRem(5)
    display flex
    align-items center
    justify-content flex-start
    gap pxToRem(5)
    font-size: pxToRem(14);
    font-weight: 700;
    line-height: pxToRem(22);
    letter-spacing: -0.2px;
  &__txt1
    margin-bottom pxToRem(8)
    font-size: pxToRem(24)
    font-weight: 700;
    line-height: pxToRem(34px)
    letter-spacing: -0.2px;
    text-align left
  &__thumb
    margin-bottom pxToRem(8)
    width 100%
  &__thumb-img
    width 100%

  &__info-box
    width 100%
    display: flex;
    padding: pxToRem(16) pxToRem(32)
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: pxToRem(8);
    box-sizing border-box
    border-radius: pxToRem(16);
    background: var(--White, #FFF);
    backdrop-filter: blur(14px);
    -webkit-backdrop-filter: blur(14px)
  &__info-txts
    width 100%
    display flex
    align-items center
    justify-content space-between
    gap pxToRem(20)
  &__info-txt1
    color: #1C75FF
    font-size: pxToRem(16)
    font-weight: 700
    line-height: pxToRem(24)
    letter-spacing: -0.2px
  &__info-txt2
    color: var(--Blue-100, #1C75FF);
    font-family: 'Bebas Neue Pro'
    font-size: pxToRem(24)
    font-weight: 700
    line-height: pxToRem(24)
    letter-spacing: -0.2px;
    span
      display inline-block
      margin-left pxToRem(2)
      font-family: Pretendard;
      font-size: pxToRem(16)
  &__pagination
    position absolute
    z-index 1
    width pxToRem(102) !important
    top auto !important
    left pxToRem(32) !important
    right pxToRem(32) !important
    bottom pxToRem(128) !important
    margin 0 auto
    height pxToRem(2) !important
    display flex
    align-items center
    justify-content space-between
    background: rgba(255, 255, 255, 0.2) !important;
    .swiper-pagination-progressbar-fill
      background: #fff !important;
  &__page-current
    position absolute
    left 50%
    z-index 1
    transform translateX(-50%)
    margin-left pxToRem(-66)
    bottom pxToRem(117)
    font-family 'Bebas Neue Pro'
    font-size: pxToRem(16)
    font-weight: 700;
    line-height: pxToRem(24)
  &__page-length
    position absolute
    right 50%
    z-index 1
    transform translateX(-50%)
    margin-right pxToRem(-83)
    bottom pxToRem(117)
    font-family 'Bebas Neue Pro'
    font-size: pxToRem(16)
    font-weight: 700;
    line-height: pxToRem(24)

.youtube-bridge-guide1
  position relative
  margin-top pxToRem(32)
  padding-bottom pxToRem(64)
  display flex
  align-items center
  justify-content center
  &__ul
    display flex
    align-items flex-start
    width 100vw
    margin 0
    padding 0
    list-style none
  &__li
    width 100vw
    text-align center
  &__img
    margin-bottom pxToRem(24)
    img
      width 100%
  &__txt1
    margin 0 0 pxToRem(8)
    padding 0
    color: var(--Black, #141414);
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.2px;
    font-size: pxToRem(20)
    line-height: pxToRem(28)
  &__txt2
    margin 0
    padding 0
    color: var(--Gray-80, #6B6B6B)
    font-size: pxToRem(14)
    font-weight: 500
    line-height: pxToRem(22)
    letter-spacing: -0.2px
  &__prev
    display flex
    align-items center
    justify-content center
    top auto
    left 50%
    bottom 0
    transform translateX(-50%)
    width pxToRem(33)
    height pxToRem(32)
    margin-left pxToRem(-25)
    border-radius: 999px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px)
    background: var(--Blue-100, #1C75FF);
    &.swiper-button-disabled
      opacity 1
      background: var(--Gray-10, #E7E7E7);
  &__next
    display flex
    align-items center
    justify-content center
    top auto
    right 50%
    bottom 0
    transform translateX(-50%)
    width pxToRem(33)
    height pxToRem(32)
    margin-right pxToRem(-55)
    border-radius: 999px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px)
    background: var(--Blue-100, #1C75FF);
    &.swiper-button-disabled
      opacity 1
      background: var(--Gray-10, #E7E7E7);

.youtube-bridge-main-09
  background: var(--Light-Blue, #EBF3FE)

.youtube-bridge-select-login-apply
  width 100%
  &__h3
    margin 0 0 pxToRem(12)
    padding 0
    color: var(--Black, #141414)
    text-align: center
    font-size: pxToRem(16)
    line-height: pxToRem(24)
    letter-spacing: -0.2px;
  &__p
    margin 0
    padding 0
    color: var(--Black, #141414);
    text-align: center;
    font-size: pxToRem(14);
    line-height: pxToRem(22);
    letter-spacing: -0.4px;
  &__btns
    margin-top pxToRem(58)
    display grid
    align-items center
    justify-content space-between
    gap pxToRem(24)
    grid-template-columns 1fr 1fr

.youtube-bridge-marquee
  display flex
  overflow-x hidden
  width 100%
  position relative
  z-index 0
  &__background
    --gap pxToRem(40)
    flex-shrink 0
    display flex
    gap var(--gap)
    justify-content space-around
    align-items center
    will-change transform
    animation marquee-left 50s linear infinite
    min-width 100%
    height pxToRem(200)
    max-height pxToRem(400)
    padding-bottom pxToRem(20)
    &[aria-hidden="true"]
      margin-left var(--gap)
    & img
      height 100%

.main-09-card
  margin pxToRem(40) 0 0
  padding 0
  list-style none
  &__li
    width 80vw
    min-height pxToRem(452)
    padding pxToRem(60) pxToRem(20)
    display flex
    flex-direction column
    align-items center
    gap pxToRem(56)
    box-sizing border-box
    border-radius: pxToRem(20)
    border: 1px solid var(--Gray-10, #E7E7E7);
    background: var(--White, #FFF);
  &__under
    text-decoration underline
  &__txt1
    margin 0
    padding 0
    color: var(--Black, #141414)
    font-size: pxToRem(20)
    font-weight: 700
    line-height: pxToRem(28)
    letter-spacing: -0.2px
  &__ul
    margin 0
    padding 0
    list-style none
    display flex
    flex-direction column
    gap pxToRem(14)
    align-items center
    justify-content center
  &__ul-li
    color: var(--Black, #141414)
    font-size: pxToRem(16)
    font-weight: 500
    line-height: pxToRem(24)
    letter-spacing: -0.2px
    strong
      color: var(--Blue, #1C75FF)
      font-weight: 700
      line-height: pxToRem(34)
      background-color #EBF3FE

.youtube-channel-item2
  width 100%
  display: flex;
  height: pxToRem(100);
  padding: pxToRem(20);
  align-items: center;
  box-sizing border-box;
  gap: pxToRem(16);
  border-radius: pxToRem(20);
  background: var(--Light-Blue, #EBF3FE);
  &__logo
    width: pxToRem(60);
    height: pxToRem(60);
    border-radius 9999px;
  &__info
    flex 1
    display flex
    flex-direction column
    gap pxToRem(2)
    text-align left
  &__title
    color: var(--Black, #111);
    font-size: pxToRem(16);
    font-weight: 700;
    line-height: pxToRem(24);
    letter-spacing: -0.2px;
    limitLine(1)
  &__url
    color: var(--Gray-80, #6B6B6B);
    font-family: "Neue Haas Unica Pro";
    font-size: pxToRem(14);
    font-weight: 400;
    line-height: pxToRem(20);
    letter-spacing: -0.2px;
    limitLine(1)

.youtube-bridge-faq
  margin pxToRem(40) auto 0
  text-align left
  &__ul
    margin 0
    padding 0
    list-style none
    display flex
    flex-direction column
    gap pxToRem(8)
  &__li
    position relative;
    border-radius: pxToRem(12);
    background: var(--Light-Blue, #EBF3FE);
    &[is_open="true"]
      color #fff
      background: var(--Blue, #1C75FF);
    &[is_open="false"]
      color: var(--Blue, #1C75FF);
  &__wrap
    padding pxToRem(16) pxToRem(20);
  &__plus
  &__minus
    position absolute
    top pxToRem(16)
    right pxToRem(20)
    svg
      width pxToRem(20)
      height pxToRem(20)
  &__plus
    color #1C75FF
  &__minus
    color #fff
  &__question
    padding-right pxToRem(30)
    font-size: pxToRem(16);
    font-weight: 700;
    line-height: pxToRem(24);
    letter-spacing: -0.2px;
  &__answer
    display none
    padding-top pxToRem(16)
    color: var(--White, #FFF);
    font-size: pxToRem(14);
    font-weight: 400;
    line-height: pxToRem(22)
    letter-spacing: -0.2px;
    &--show
      display block
  &__p
    margin 0 0 pxToRem(12)
    padding 0
    &--mb0
      margin-bottom 0
  &__answer-ul
    margin 0 0 pxToRem(12)
    padding 0
    list-style none
  &__answer-li
    position relative
    padding-left pxToRem(22)
    &:before
      content ''
      position absolute
      top pxToRem(9)
      left pxToRem(10)
      display block
      width pxToRem(4px)
      height pxToRem(4px)
      background-color #fff
      border-radius 99px
  &__answer-ol
    margin 0 0 pxToRem(16) pxToRem(24)
    padding 0
    list-style auto
  &__a
    margin-right pxToRem(2)
    display inline-flex
    align-items center
    gap pxToRem(2)
    color: var(--White, #FFF) !important;
    border-bottom 1px solid #fff;

.youtube-bridge-page
  scroll-snap-type y mandatory
  width 100%
  position relative
  color: var(--Black, #141414);
  text-align center
  font-family "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  font-style normal
  overflow-x hidden
  font-size: pxToRem(15)
  font-weight: 500;
  line-height: pxToRem(22)
  letter-spacing: -0.3px;

  /* cta button */
  .cta-button
    //transition .3s ease-in-out
    position: fixed;
    bottom: calc(16px + env(safe-area-inset-bottom));
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;

  pxToVw(value)
    unit(value/375*100, 'vw')
  pxToVMin(value)
    unit(value/375*100, 'vmin')
  font_18_m_150 =
    font-size pxToRem(18)
    line-height 150%
    font-weight 600
  .h3-53
    font-weight 700
    font-size pxToRem(40)
    line-height pxToRem(53)
  .h3-56
    font-weight 700
    font-size pxToRem(40)
    line-height pxToRem(56)
  .h3-60
    letter-spacing: -0.2px;
    font-weight 700
    font-size pxToRem(28)
    line-height pxToRem(39)
  input
    font-family "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    font-style normal
  button
    display flex
    justify-content center
    align-items center
    border none
    background-color transparent
    font-family "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    font-style normal
    &:disabled
      background: var(--Gray-5, #F5F5F5);
      color: var(--Gray-50, #A2A2A2);
    &.primary
      background-color #1C75FF
      color #FFFFFF
      &:disabled
        background: var(--Gray-5, #F5F5F5);
        color: var(--Gray-50, #A2A2A2);
    &.m-button
      display flex
      height pxToRem(52)
      min-width pxToRem(80)
      padding pxToRem(7) pxToRem(8) pxToRem(8)
      justify-content center
      align-items center
      gap pxToRem(4)
      border-radius pxToRem(8)
    &.default
      height pxToRem(68)
      width 100%
      display flex
      justify-content center
      align-items center
      border-radius pxToRem(8)
      {font_18_m_150}
    &.gray
      background-color #4E4E4E
      color #FFFFFF
      &:hover
        background-color #2f2f2f
    &.full-width
      width 100%


  .youtube-bridge-page__header2
    position absolute
    top 0
    left 0
    right 0
    display flex
    justify-content center
    align-items center
    padding pxToRem(16) 0
    gap pxToRem(24)
    width 100%
    height pxToRem(64)
    box-sizing border-box
    z-index 10
    background #1C75FF
    color #fff
    transition color 0.5s
    will-change transform
    &--black
      color: var(--Black, #141414);
    img
      flex-shrink 0

  .underline
    text-decoration underline
  .chip
    color #FFFFFF
    text-align center
    font-family Campton
    font-size pxToRem(14)
    font-style normal
    font-weight 500
    line-height 150%
    height pxToRem(28)
    display inline-flex
    align-items center
    justify-content center
    padding 0 pxToRem(8)
    border-radius: 999px;
    background: var(--MS-Blue, #1F5EFF);
    box-shadow: 0 0 pxToRem(20) 0 rgba(0, 0, 0, 0.20);
    backdrop-filter blur(pxToRem(5))
    -webkit-backdrop-filter blur(pxToRem(5))
    &.revers
      color #1F5EFF
      border-radius: 999px;
      background: var(--Blue-10, #EBF3FE);

  .padding-r-l
    padding 0 pxToRem(20)
  .youtube-bridge-page__header
    display flex
    justify-content center
    align-items center
    padding pxToRem(14) 0
    gap pxToRem(8)
    .youtube
      svg
        width pxToRem(112)
        height pxToRem(26)
    .cross
      svg
        width pxToRem(22)
        height pxToRem(22)
    .marpple
      svg
        width pxToRem(42)
        height pxToRem(22)
    //img
    //  flex-shrink 0
  .youtube-bridge-page__full-page
    width 100%
    height 100vh
    & > .swiper-wrapper
      display block
      & > .swiper-slide
        display flex
        flex-direction column
        position relative
        //height 100vh
        & .youtube-bridge-page__section
          width 100%

  .youtube-bridge-page__section
    display flex
    align-items center
    justify-content center
    flex-direction column
    min-height 100vh
    padding-top pxToRem(95)
    padding-bottom pxToRem(95)
    position relative
    box-sizing border-box
    width 100%
    &--01
      padding-top 0
      background-color #1C75FF
      min-height: 100svh;
      height 100svh
      position relative
    &--02
      background: var(--Blue-10, #EBF3FE);
    &--how
      background: var(--Blue-10, #EBF3FE);
    &--case
      padding-top 0
      padding-bottom 0
      background: #1c75ff;
    &--color-white
      color #fff
    &.main-transition
      transition height 0.5s
      will-change transform
    &.gradation-color
      background linear-gradient(287deg, #1F5EFF 21.26%, #0E43CD 91.53%)
      justify-content flex-start
    &.content-height
      height fit-content
    &:nth-child(3)
    &:nth-child(4)
      min-height auto
    .title
      font-size pxToRem(20)
      line-height 150%
      font-weight 600
      & > div:last-child
        font-size pxToRem(32)
        line-height 125%
        font-weight bold

    .content
      max-width pxToRem(1920)
      margin 0 auto
      width 100%
    /* main-01 */
    .phone-mockup-content
      position relative
      display flex
      justify-content flex-start
      align-items flex-start
      flex-direction column
      width 100%
      min-height pxToRem(540)
      .main-video-container
        width 100%
        display flex
        justify-content center
        position relative
        .mockup-container
          display flex
          justify-content center
          align-items center
        .mockup-frame
          width pxToVw(214)
          max-width pxToRem(214)
          z-index 2
          position absolute
          aspect-ratio 214 / 440
        .main-video
          width pxToVw(194)
          max-width pxToRem(194)
          position relative
          z-index 1
          aspect-ratio 194 / 420
          video
            position relative
            z-index 1
            margin-top: pxToRem(14);
            width 100%
            height 100%
            border-radius pxToRem(26)
          .back-drop-black
            position absolute
            z-index 0
            background-color #111
            width calc(100% - 1px)
            height calc(100% - 1rem)
            margin-top pxToRem(14)
            border-radius pxToRem(26)
            box-shadow pxToRem(-20) pxToRem(20) pxToRem(40) 0 rgba(0, 0, 0, 0.40)
    .description
      z-index 3
      display flex
      justify-content flex-end
      align-items center
      flex-direction column
      color: var(--White, #FFF);
      gap pxToRem(8)
      font-size pxToRem(16)
      font-weight normal
      line-height pxToRem(22)
      width 100%
      position absolute
      height 360px
      bottom 0
      padding-bottom calc(88px + env(safe-area-inset-bottom))
      background: linear-gradient(0deg, #1C75FF 69.3%, rgba(29, 117, 255, 0.00) 100%);

      .description-header
        color: var(--White, #FFF);
        text-align: center;
        font-weight: 700;
        line-height: pxToRem(36);
        letter-spacing: -0.2px;
        font-size pxToRem(24)
    .marquee-wrapper
      display flex
      overflow-x hidden
      width 100%
      position absolute
      z-index 0
      bottom pxToRem(130)
    .marquee-background
      --gap pxToRem(80)
      flex-shrink 0
      display flex
      gap var(--gap)
      justify-content space-around
      align-items center
      will-change transform
      animation marquee-left 50s linear infinite
      min-width 100%
      height min(pxToVw(150), pxToRem(150))
      &[aria-hidden="true"]
        margin-left var(--gap)
      & img
        height 100%

    /* main-02 */
    .video-container
      width 100%
      margin pxToRem(50) auto
      overflow hidden

      .marpple-video
        border-radius pxToRem(12)
        width 100%
        height 100%

    /* main-03 */
    &.main-03
      margin-bottom pxToRem(8)
    .youtube-bridge-page__m-shop-wrapper
      display flex
      justify-content center
      align-items center
      flex-direction column
    .m-shop-container
      margin-top pxToRem(24)
      width 100%
      overflow hidden
      padding-bottom pxToRem(38)
      .swiper-pagination-bullet
        background-color #828282
        &.swiper-pagination-bullet-active
          background-color #F2F2F2
    .m-shop-item-list
      display flex
      align-items center
      .m-shop-item
        display flex
        flex-direction column
        gap pxToRem(20)
        width 100%
        .swiper-slide-shadow-left,
        .swiper-slide-shadow-right
          background-color rgba(17, 17, 17, 0.80)

        video
          width 100%
          height 100%
          border-radius pxToRem(12)
          aspect-ratio 327 / 200
          object-fit cover
        .m-shop-profile
          display flex
          gap pxToRem(12)
          align-items center
          color #FFFFFF
          img
            width pxToRem(60)
            height pxToRem(60)
            border-radius pxToRem(12)
          .item
            display flex
            flex-direction column
            text-align left
            font-size pxToRem(14)
            line-height 150%
            font-weight 400
            .item-title
              font-size pxToRem(18)
              line-height 150%
              font-weight 700

  /* main-04 */
  .main-04
    height 150vh
    .title
      margin-top pxToRem(40)
    .goods-container
      margin-top pxToRem(28)
      display flex
      box-sizing border-box
      width 100%
      height 80%
      overflow hidden
      position relative
      justify-content center
    &.content-height
      .youtube-bridge-page__section-more-btn
        svg
          transform rotateX(180deg)

    .youtube-bridge-page__section-more-btn
      position absolute
      z-index 2
      bottom pxToRem(0)
      font-size pxToRem(16)
      line-height pxToRem(24)
      color: #141414
      font-weight: 500;
      letter-spacing: -0.2px;
      display: flex;
      height: pxToRem(52)
      padding pxToRem(16) pxToRem(24)
      justify-content: center
      align-items: center
      gap: pxToRem(4)
      transition all 0.3s ease-in-out
      border-radius: pxToRem(12)
      background: rgba(20, 20, 20, 0.05)
      backdrop-filter: blur(20px)
      -webkit-backdrop-filter: blur(20px)
      svg
        transition all 0.3s ease-in-out
        width pxToRem(20)
        height pxToRem(20)

  .youtube-bridge-page__section-wrap
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width 100%
  .goods-mask-gradient
    position absolute
    z-index 1
    top 0
    left 0
    width 100%
    height 100%
    box-sizing border-box
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 90%, #FFF 99.98%);
  .goods-list
    display grid
    grid-column-gap pxToRem(16)
    grid-row-gap pxToRem(24)
    grid-template-columns 1fr 1fr
    .goods
      border-radius pxToRem(16)
      position relative
      background-color #F6F6F6
      width calc(100vw / 5 - (pxToRem(25) * 4))
      max-width: pxToRem(292);
      aspect-ratio 1 / 1
      &.hidden
        display none
      .goods-thumbnail
        width 100%
        aspect-ratio 1 / 1
      .goods-profile
        border-radius pxToRem(60)
        border pxToRem(0.5) solid #F2F2F2
        position absolute
        width pxToVMin(24)
        max-width pxToRem(24)
        left pxToRem(8)
        bottom pxToRem(8)
        aspect-ratio 1 / 1

  .main-05
    .title
      margin-top pxToRem(60)
    .sample-mockup-mo-swiper
      margin-top pxToRem(40)
      position relative
      overflow hidden
      padding 0 pxToRem(24)
      box-sizing border-box
      --content-width pxToVMin(215)
      --padding-btn pxToRem(24)
      .sample-mockup-mo-swiper-btn
        position absolute
        top 50%
        transform translateY(-1rem)
        width pxToRem(28)
        height pxToRem(28)
        z-index 2
        padding 0
        svg
          width pxToRem(28)
          height pxToRem(28)
        &.swiper-button-disabled
          svg
            path
              stroke #4E4E4E
        &:not(.swiper-button-disabled)
          svg
            path
              stroke #CCCCCC
        &.sample-mockup-mo-swiper-prev-btn
          left calc(50% - (var(--content-width) / 2) - 2rem - var(--padding-btn))
        &.sample-mockup-mo-swiper-next-btn
          right calc(50% - (var(--content-width)/ 2) - 2rem - var(--padding-btn))

  .sample-mockup-list
    display flex
    align-items center
    .sample-mockup
      display flex
      flex-direction column
      gap pxToRem(20)
      font-size pxToRem(15)
      line-height pxToRem(22)
      font-weight 500
      //&:nth-child(2)
      //  width  pxToVm(235)
      & > img
        height pxToVMin(437)
        max-height  pxToRem(437)
        aspect-ratio auto var(--content-width) / 437
        object-fit contain
      & > div
        display flex
        justify-content center
        align-items center
        flex-direction column

  .main-06
    overflow hidden
    .main-06-content-list
      display flex
      align-items center
      align-self flex-start
      flex-direction column
      width 100%
      .main-06-title
        padding pxToRem(60) 0
        font-size pxToRem(30)
        line-height pxToRem(38)
        font-weight 700
        letter-spacing pxToRem(-1)
        display flex
        justify-content center
        align-items center
        gap pxToRem(12)
        flex-direction column
        .main-06-title-desc
          font-size pxToRem(15)
          line-height pxToRem(22)
          font-weight 400
          letter-spacing pxToRem(-0.3)
      .main-06-content
        width 100%
        height 100%
        padding 0 pxToRem(28)
        box-sizing border-box
        flex-direction column
        .card-detail
          width 100%
          .detail
            .text-wrap
              flex 1
              display flex
              position relative
              justify-content center
              align-items center
              a
                color #DDDDDD
                flex 1
                text-align center
                white-space nowrap
                text-overflow ellipsis
                overflow hidden
                position absolute
                z-index 1
                opacity 0
                width 100%
                transition all 0.6s ease-in-out
                &.view
                  opacity 1


        .main-06-content-prev-btn
        .main-06-content-next-btn
          padding 0
          width pxToRem(28)
          height pxToRem(28)
          svg
            width pxToRem(28)
            height pxToRem(28)
          &.swiper-button-disabled
            cursor auto
            svg
              path
                stroke #4E4E4E
          &:hover:not(.swiper-button-disabled)
            svg
              path
                stroke #FFFFFF
        .swiper-wrapper
          flex-direction initial
          justify-content initial
          align-items center
          gap 0
          .card-detail.swiper-slide
            &:last-child
              .main-06-content-next-btn
                svg
                  path
                    stroke #4E4E4E
            &:not(:first-child)
              .main-06-content-prev-btn
                svg
                  path
                    stroke #CCCCCC

  .card
    z-index 1
    position relative
    flex-direction column-reverse
    display flex
    gap pxToRem(40)
    align-items center
    .card-detail
      color #ddd
      font-size pxToRem(18)
      line-height 150%
      font-weight 600
      display flex
      flex-direction column
      justify-content center
      align-items center
      gap pxToRem(40)
      img.logo
        border-radius pxToRem(12)
        width 100%
        aspect-ratio 1
      .detail
        display flex
        align-items center
        width 100%
        .text
          flex 1
          text-align center
          white-space nowrap
          text-overflow ellipsis
          overflow hidden
        button
          background-color transparent
          padding: pxToRem(8p)
          &:first-child
            margin-left pxToRem(-8)
          &:last-child
            margin-right pxToRem(-8)
    .card-desc
      width 100%
      font-size pxToRem(15)
      line-height pxToRem(22)
      font-weight 400
      display flex
      flex-direction column
      gap pxToRem(12)
      justify-content center
      color #dddddd
      .card-desc-highlight
        font-size pxToRem(30)
        line-height pxToRem(38)
        font-weight 700
        letter-spacing pxToRem(-1)
        color #fff
    .flip-list
      display grid
      grid-template-columns 1fr 1fr
      grid-gap pxToRem(12)
      width pxToVMin(326)
      max-width pxToRem(326)
      align-items flex-start
      align-content flex-start
      gap pxToRem(12)
      flex-wrap wrap
    .flip-card
      background-color transparent
      width 100%
      height 100%
      aspect-ratio 1
      perspective: 1000px
      position relative
      .chip
        position absolute
        z-index: 1;
        top pxToRem(-20)
        right pxToRem(17)
      &:hover
        .flip-card-inner
          transform rotateY(180deg)
          transition-duration 0.6s
    .flip-card-inner
      position: relative
      width 100%
      height 100%
      text-align center
      transition transform 1s
      transform-style preserve-3d
    .flip-card-front
    .flip-card-back
      width 100%
      height 100%
      display flex
      flex-direction column
      justify-content center
      align-items center
      gap pxToRem(8)
      flex-shrink 0
      border-radius pxToRem(20)
      position absolute
      -webkit-backface-visibility hidden
      backface-visibility hidden
    .flip-card-front
      background-color #fff
      color #000000
      font-size pxToRem(18)
      line-height 150%
      font-weight 700
      background rgba(255, 255, 255, 0.70)
      backdrop-filter: blur(pxToRem(8))
      -webkit-backdrop-filter: blur(pxToRem(8))
      svg
        width pxToRem(52)
        height pxToRem(52)
    .flip-card-back
      background-color #1F5EFF
      color #FFFFFF
      font-size pxToRem(14)
      line-height 150%
      font-weight 400
      transform rotateY(180deg)
      .header
        font-size pxToRem(16)
        line-height 150%
        font-weight 600


  .card-02
    z-index 1
    position relative
    display flex
    flex-direction column
    align-items center
    justify-content center
    width 100%
    &__help-txt1
      display flex
      align-items center
      justify-content flex-start
      gap pxToRem(8)
      color: var(--Blue, #1C75FF);
      font-size: pxToRem(12)
      font-weight: 500;
      text-align left
      line-height: pxToRem(17)
      letter-spacing: -0.2px
    &__img
      display flex
      width 100%
      margin-bottom pxToRem(8)
      justify-content center
    .card-desc
      margin-bottom pxToRem(44)
      font-size pxToRem(14)
      line-height pxToRem(22)
      font-weight 500
      display flex
      flex-direction column
      gap pxToRem(16)
      color: var(--Black, #141414);
      text-align: center;
      font-style: normal;
      letter-spacing: -0.2px;
      .card-desc-highlight
        color: var(--Black, #141414);
        text-align: center;
        font-weight: 700;
        font-size: pxToRem(24)
        line-height: pxToRem(34)
        letter-spacing: -0.2px
    .card-detail
      width 100%
    .flip-list
      display grid
      width 100%
      grid-template-columns repeat(2, 1fr)
      grid-template-rows repeat(2, 1fr)
      grid-gap pxToRem(13)
      flex-wrap wrap
      padding 0
      box-sizing border-box
    .flip-card
      background-color transparent
      width 100%
      aspect-ratio 1 / 1.04
      perspective: 1000px
      .chip
        position absolute
        z-index: 1;
        top pxToRem(-20)
        right pxToRem(17)
      &:hover
        .flip-card-inner
          transform rotateY(180deg)
          transition-duration 0.6s
    .flip-card-inner
      position: relative
      width 100%
      height 100%
      text-align center
      transition transform 1s
      transform-style preserve-3d
    .flip-card-front
    .flip-card-back
      width 100%
      height 100%
      box-sizing border-box
      display flex
      padding pxToRem(54) 0 pxToRem(50) 0
      flex-direction column
      justify-content center
      align-items center
      gap pxToRem(8)
      flex-shrink 0
      border-radius pxToRem(24)
      position absolute
      -webkit-backface-visibility hidden
      backface-visibility hidden
    .flip-card-front
      font-size: pxToRem(14)
      font-weight: 700
      line-height: pxToRem(22)
      letter-spacing: -0.2px
      border-radius: pxToRem(24)
      background: var(--Light-Blue, #EBF3FE);
      color: var(--Blue-100, #1C75FF);
      img
        width pxToRem(80)
        height pxToRem(80)
    .flip-card-back
      padding pxToRem(46) 0 pxToRem(42) 0
      border-radius: pxToRem(24)
      background: var(--Blue, #1C75FF);
      font-size pxToRem(14)
      line-height pxToRem(22)
      font-weight 500
      color: var(--White, #FFF);
      text-align: center;
      letter-spacing: -0.2px;
      transform rotateY(180deg)
      .header
        font-weight 700

  .main-07
    .card
      margin-top pxToRem(60)
    .background-logo
      width pxToRem(120)

  /* main-08 */
  .main-08-wrap
    //height 100vh
    width 100%
    display flex
    justify-content center
    align-items center
    //overflow hidden
    & .youtube-bridge-page__section:nth-child(2)
      height 100vh
    #step1
      background: var(--Blue-100, #1C75FF);
      .h3-60
      .desc
        color #fff
    #step2
    #step3
    #step4
      color: var(--Black, #141414);
    .main-08
      padding-top pxToRem(30)
      gap pxToRem(28) !important
      will-change transform
      transition display ease-in-out 0.2s
      &.hidden
        display none
      .step1_bg
        display none
  .youtube-bridge-page__section.integration-processing
    height auto
    justify-content center
    &.none-channel
      & > .desc
        padding-bottom 0
      justify-content center
      .hand-image
        top pxToRem(50)
        right pxToRem(-30)
    &.none-possible-channel
      & > .desc
        padding-bottom 0
      justify-content center

    .desc
      display flex
      justify-content center
      align-items center
      position relative
    .hand-image
      position absolute
      right pxToRem(-30)
      top pxToRem(56)
      width pxToRem(60)
      aspect-ratio 1 / 1
    & .button-area-vertical
      display flex
      flex-direction column
      width 100%
      gap pxToRem(20)
      margin-top pxToRem(60)
      padding 0 pxToRem(24)
      box-sizing border-box
      & > a
        width 100%
    & .button-area
      display flex
      flex-direction column
      gap pxToRem(20)
      width 100%
      padding 0 pxToRem(24)
      box-sizing border-box
      & > a
        width 100%
      button
        padding pxToRem(16) pxToRem(8) pxToRem(17)
        border-radius pxToRem(8)
        width 100%
        display flex
        flex-direction column
        align-items center
        font-size pxToRem(16)
        box-sizing border-box
        line-height  pxToRem(24)
        font-weight 700
        flex 1 0 0
        min-height pxToRem(56)
        &:disabled
          color: var(--Gray-50, #A2A2A2);
        &:not(.primary):not(:disabled)
          color #1C75FF
          border-radius: pxToRem(12);
          background: var(--Light-Blue, #EBF3FE);
    & > .desc
      padding pxToRem(60) 0 pxToRem(40)
      gap pxToRem(12)
      display flex
      align-items center
      flex-direction column
      font-size pxToRem(16)
      font-weight 400
      line-height 150%
      color: var(--Black, #141414);
  #step3
    .button-area
      margin-bottom pxToRem(60)
  .youtube-bridge-page__section.integration
    gap pxToRem(100)
    display flex
    justify-content center
    align-items center
    .desc
      position relative
      display flex
      align-items center
      flex-direction column
      gap pxToRem(12)
      font-size pxToRem(16)
      font-weight 400
      line-height 150%
      color: var(--Black, #141414);
      .hand-image
        position absolute
        right pxToRem(-36)
        top calc(50% - 16rem / 14)
        width pxToRem(60)
        aspect-ratio 1
  .youtube-bridge-page__integration-btn
    padding pxToRem(9) pxToRem(24)
    font-size pxToRem(14)
    font-weight 700
    line-height pxToRem(22)
    border-radius pxToRem(8)
    color: var(--Blue-100, #1C75FF);
    text-align: center;
    letter-spacing: -0.2px;
    background: var(--Blue-10, #EBF3FE);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px)



  /* footer */
  .youtube-bridge-page__footer
    display flex
    justify-content center
    align-items center
    flex-direction column
    width 100%
    .more
      display flex
      justify-content center
      align-items center
      position relative
      & > img
        position relative
        z-index: 0;
        width 100%
        aspect-ratio 375 / 320
      & > .dim
        position absolute
        z-index 1
        background-color #111111B2
        width 100%
        height 100%
      .footer-desc
        position absolute
        z-index 2
        display flex
        flex-direction column
        justify-content center
        align-items center
        gap pxToRem(20)
        font-size pxToRem(14)
        color: var(--White, #FFF);
        text-align: center;
        font-weight: 500;
        line-height: pxToRem(22)
        letter-spacing: -0.2px;

      .youtube-bridge-page__footer-more-btn
        padding pxToRem(18) pxToRem(24)
        font-size pxToRem(16)
        color: var(--Bright-70, rgba(255, 255, 255, 0.70));
        font-weight: 500;
        line-height: pxToRem(24)
        letter-spacing: -0.2px;
        border-radius pxToRem(12)
        background: var(--Bright-20, rgba(255, 255, 255, 0.20));
        backdrop-filter: blur(20px);
        -webkit-backdrop-filter: blur(20px)
    .corporation
      display flex
      gap pxToRem(30)
      width 100%
      padding pxToRem(40) pxToRem(24) pxToRem(80)
      flex-direction column-reverse
      text-align left
      box-sizing border-box
      .info
        color #141414
        font-size pxToRem(11)
        line-height pxToRem(16)
        font-weight 400
        display flex
        gap pxToRem(8)
        flex-direction column
        justify-content flex-start
        & > div > a:last-child
          display block
        & a
          color #141414
          text-underline none
      .contact
        .tell
          font-size pxToRem(32)
          line-height 150%
          font-weight 700
          margin-bottom pxToRem(6)
          color #141414
          text-decoration none
        .time
          font-size pxToRem(14)
          line-height 150%
          font-weight 400
          color #141414
        .email
          font-size pxToRem(16)
          line-height pxToRem(26)
          font-weight 400
          & a
            color #141414

  .form-field-list
    width 100%
    box-sizing border-box
    display flex
    flex-direction column
    gap pxToRem(28)
    font-size pxToRem(14)
    line-height 150%
    font-weight normal
    .form-field
      display flex
      flex-direction column
      align-items flex-start
      width 100%
      &.error
        & input.form-input:focus-visible
          outline pxToRem(1) solid #D92A0F
        & p.helper-text
          color #D92A0F
      label
        font-weight 600
        margin-bottom pxToRem(2)
      &__label
        display flex
        align-items center
        gap pxToRem(4)
      &__required-icon
        display flex
        align-items flex-start
        padding-bottom pxToRem(4)
        color #1C75FF
      input.form-input
        width 100%
        font-weight 500
        display flex
        font-size pxToRem(14)
        line-height pxToRem(22)
        letter-spacing: -0.2px;
        padding pxToRem(12) 0
        box-sizing border-box
        background-color transparent
        border none
        border-bottom: 1px solid var(--Gray-10, #E7E7E7);
        &:disabled
          background: var(--Gray-5, #F5F5F5);
        &:read-only
          background: var(--Gray-5, #F5F5F5);
        &::placeholder
          color #A2A2A2
          overflow hidden
          text-overflow ellipsis
          white-space nowrap
          font-size pxToRem(11)
          line-height 150%
          font-weight 400
        &:focus-visible
          outline pxToRem(2) solid #1F5EFF
      p.helper-text
        font-size pxToRem(11)
        line-height 150%
        font-weight 400
        margin pxToRem(4) 0 0
      .input-with-button
        display flex
        gap pxToRem(8)
        width 100%
        & > input
          flex 1
        & > button
          display flex
          height pxToRem(52)
          min-width pxToRem(100)
          padding pxToRem(7) pxToRem(8) pxToRem(8)
          justify-content center
          align-items center
          gap pxToRem(4)
          border-radius pxToRem(8)
          &:not(:disabled):not(.primary)
            color #1C75FF
            background: var(--Light-Blue, #EBF3FE);
            backdrop-filter: blur(20px);
            -webkit-backdrop-filter: blur(20px)
          &:disabled
            color: var(--Gray-50, #A2A2A2);
            background: var(--Gray-5, #F5F5F5);
            backdrop-filter: blur(20px);
            -webkit-backdrop-filter: blur(20px)
          &:disabled.complete
            color: var(--Gray-50, #A2A2A2);
            background: var(--Gray-5, #F5F5F5);
            backdrop-filter: blur(20px);
            -webkit-backdrop-filter: blur(20px)
          &:disabled.primary
            color: var(--Gray-50, #A2A2A2);
            background: var(--Gray-5, #F5F5F5);
      .form-field-item-warp
        width 100%
        display flex
        flex-direction column
        gap pxToRem(8)
      .verify-email
        display none
        transition display ease-in-out 0.2s
        &.show
          display flex
        .wait-verify-code
          display flex
          align-items center
          position relative
          .countdown
            position absolute
            right pxToRem(20)
            width fit-content
            color #1F5EFF
            text-align right
            font-size pxToRem(15)
            font-style normal
            font-weight 400
            line-height pxToRem(22)

    .checkbox-wrap
      position relative
      display flex
      align-items center
      justify-content space-between
      width 100%
      label
        flex 1
      & .term
        margin-left pxToRem(10)
        & a
          font-weight 600
          color #A2A2A2
          text-decoration-line underline
    input[type="checkbox"]
      visibility hidden
      width 0
      height 0
      margin 0
      &:checked + label > span.checkbox
        background-color #1F5EFF
        border none
        & > svg
          display block

      & + label
        display inline-flex
        align-items center
        cursor pointer
        gap pxToRem(8)
        & > span.checkbox
          display flex
          align-items center
          justify-content center
          border-radius pxToRem(4)
          width pxToRem(18)
          height pxToRem(18)
          box-sizing border-box
          border pxToRem(2) solid #A2A2A2
          & > svg
            display none
            margin-bottom pxToRem(2)
    .agree-box
      display flex
      flex-direction column
      width 100%
      align-items flex-start
      gap pxToRem(14)
      & > div
        width 100%
        display flex
        flex-direction column
        align-items flex-start
        gap pxToRem(8)
        font-size pxToRem(16)
        line-height pxToRem(24)
        color: var(--Black, #141414);
        font-style: normal;
        font-weight: 500;
        letter-spacing: -0.2px;
        box-sizing border-box
        & label
          display inline-flex
          align-items center
          gap pxToRem(8)
          position relative
          cursor pointer
          & input[type="checkbox"]
            visibility hidden
            position absolute
            z-index 0
            &:checked + .check_box
              background-color #1F5EFF
          & .check_box
            border-radius pxToRem(4)
            width pxToRem(18)
            height pxToRem(18)
            background-color #CCCCCC
            background-image url(//s3.marpple.co/files/u_2947328/2023/11/original/cf8c350b7b7ee7a8d8b9df25baac9dff3cdf82c31.png)
            background-size contain
          & a
            margin-left pxToRem(2)
            font-weight 400
            color #A2A2A2
            text-decoration-line underline

  .youtube-bridge-page__integration-signup
    width 100%
    box-sizing border-box
    & + .button-area
      margin-top pxToRem(40)

  .youtube-bridge-page__integration-channel
    width 100%
    box-sizing border-box
    .youtube-channel
      margin-bottom pxToRem(20)
    .youtube-bridge-page__select-channel-list
      margin-top pxToRem(20)
      display flex
      flex-direction column
      gap pxToRem(8)
      width 100%
      box-sizing border-box

      .youtube-channel-item
        box-sizing border-box
        width 100%
        display flex
        align-items center
        gap pxToRem(12)
        padding pxToRem(12) 0
        color #A2A2A2
        font-size pxToRem(14)
        font-weight 500
        line-height pxToRem(22)
        text-align left
        margin 0 0 pxToRem(20)
        border-bottom: 1px solid var(--Gray-20, #D9D9D9);
        background: var(--White, #FFF);
        &[checked="true"]
          border-bottom: 1px solid var(--Blue, #1C75FF);
        &.disabled
          background-color #CCCCCC
          color #A2A2A2
          .youtube-channel-info
            img
              opacity 0.6
            .channel-name
              color #A2A2A2
        &:not(.disabled)
        input[type="radio"]:not(:disabled)
          cursor pointer
        input[type="radio"]
          margin 0
          width pxToRem(16)
          height pxToRem(16)
          appearance none
          border pxToRem(1) solid #DDD
          border-radius 50%
          background-color #FFF
          flex 0 0 auto
          &:checked
            border pxToRem(4) solid #1C75FF
            & + div
              color #111111
          &:disabled
            background-color #F2F2F2
        .youtube-channel-info
          flex 1 1 auto
          display flex
          gap pxToRem(8)
          align-items center
          min-width 0
          box-sizing border-box
          img
            width pxToRem(32)
            aspect-ratio 1 / 1
            flex 0 0 auto
            border-radius pxToRem(100)
          .channel-name
            box-sizing border-box
            flex 1 1 auto
            font-weight 600
            display flex
            flex-direction column
            align-items flex-start
            min-width 0
            & > span
              text-overflow ellipsis
              white-space nowrap
              overflow hidden
              width 100%
              & .sub-text
                font-size pxToRem(14)
                font-weight 400
                line-height 150%
  .youtube-bridge-page__section.integration-complete
    justify-content center
    .desc
      gap pxToRem(12)
      display flex
      align-items center
      flex-direction column
      font-size pxToRem(16)
      font-weight 400
      line-height 150%
      color #fff
    .youtube-bridge-page__integration-complete-view
      position relative
      .youtube-bridge-page__select-channel-list
        gap pxToRem(12)
        align-items center
        .party-img
          display none
          position absolute
          right 0
          top pxToRem(-54)
          width pxToRem(80)
          height pxToRem(80)
          filter drop-shadow(0x 0 pxToRem(20) rgba(0, 0, 0, 0.80))
          &.hi-there
            animation hi-there 2s ease forwards
          svg
            width 100%
            height 100%
            aspect-ratio 1
            flex-shrink 0
        .relation-icon
          flex-shrink 0
          width pxToRem(40)
          height pxToRem(40)
          aspect-ratio 1
          svg
            width 100%
            height 100%
        .youtube-channel-item
          padding pxToRem(20) pxToRem(16)
          border-radius pxToRem(20)
          border pxToRem(5) solid #1F5EFF
          color #111111
          background-color #F8F8F8
          cursor auto
          .youtube-channel-info
            img
              width pxToRem(50)
              border-radius pxToRem(40)
            svg
              width pxToRem(50)
              height pxToRem(50)
            .channel-name
              text-align left
              font-weight 700
              .sub-text
                font-size pxToRem(13)
                font-weight 400
                line-height pxToRem(20)
                color #4E4E4E
      .button-area
        position relative
        width 100%
        margin-top pxToRem(52)
        .chip
          position absolute
          right pxToRem(16)
          bottom pxToRem(50)
        button
          font-size pxToRem(18)
          font-weight 600
          line-height 150%
          &.primary
            height pxToRem(60)
            width 100%
            border-radius pxToRem(8)
  .move-image
    &.bottom-origin
      transform-origin 20% 100%
      &.hi-there
        animation hi-there-bottom 2s ease forwards
    &.hi-there
      animation hi-there 2s ease forwards

@keyframes marquee-left {
  from { transform: translateX(0); }
  to { transform: translateX(calc(-100% - var(--gap))); }
}

@keyframes hi-there {
  30% { transform: scale(1.1); }
  40%, 60% { transform: rotate(-20deg) scale(1.1); }
  50% { transform: rotate(20deg) scale(1.1); }
  70% { transform: rotate(0deg) scale(1.1); }
  100% { transform: scale(1); }
}

@keyframes hi-there-bottom {
  30% { transform: scale(1.1); }
  40%, 60% { transform: rotate(-10deg) scale(1.1); }
  50% { transform: rotate(10deg) scale(1.1); }
  70% { transform: rotate(0deg) scale(1.1); }
  100% { transform: scale(1); }
}

