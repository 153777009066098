$mshop-plus-product-list = '.mshop-plus-product-list'

.mshop-plus-product-list
  margin-bottom 60px

  [data-show="false"]
    display none !important

  &__sort-box
    display flex
    padding 16px 24px 16px 0
    {$mshop-plus-product-list}__dropdown
      margin-left auto
  &__dropdown .mshop-comp-dropdown__button
    display flex
    align-items center
    {$font-body-b3-sb}
  &__filter-all
    &[data-active="true"]
      border-color $nbox-color
      color $nbox-color
  &__cate-lists
    display flex
    column-gap 24px
    padding 0 24px
    width 100%
    overflow scroll
    box-sizing border-box
    hideScrollBar()
    border-bottom 1px solid $gray-100
  &__cate-list
    reset-button()
    {$font-body-b1-sb}
    color $gray-500
    flex-shrink 0
    &[data-selected="true"]
      padding 11px 0
      border-bottom 2px solid $black
      color $gray-800
  &__cate-items
    display flex
    column-gap 8px
    padding 0 24px
    width 100%
    box-sizing border-box
    overflow scroll
    hideScrollBar()
  &__cate-item
    reset-button()
    flex-shrink 0
    border-radius 26px
    border 1px solid $gray-200
    {$font-body-b3-r}
    color $gray-800
    margin 16px 0
    padding 7.5px 16px
  &__categories[data-variant="nbox"] &__cate-item[data-selected="true"]
    {$font-body-b3-sb}
    color $nbox-color
    border-color $nbox-color

  &__products
    padding 0 24px


