.app-home-today
  [data-show="false"]
    display none

.today-tag-slider
  width 100%
  margin-bottom 32px
  overflow hidden
  box-sizing border-box

  &__wrapper
    width 100%

  &__row
    list-style none
    padding 0
    margin 8px 0
    &:first-child
      margin-top 0

  &__item
    margin 0 6px
    align-items center
    padding: 7px 20px 8px;
    border-radius: 17px;
    background-color: #f8f8f8;
    cursor pointer
    color #4e4e4e
    font-size 15px
    line-height 1.27

    &:first-child
      margin-left 1px

    &[data-selected="true"]
      background-color #1C75FF
      color white


  &__item__icon
    height 100%
    margin-right 10px

  &__item__content
    word-break keep-all !important

.home-today-container
  margin-bottom 8px
  padding 0 0 0 24px

.home-today-list
  padding-left pxToRem(24px)
  html[is_plus="true"] &
    padding-left 0
    padding-right pxToRem(24px)

.home-today-list-items
  margin-top pxToRem(56px)

.home-today-features
  margin-top 24px
  box-sizing border-box
  padding-right pxToRem(24px)

.home-today-see-more
  width 100%
  display block
  padding 8px 0 6px
  font-size pxToRem(13px)
  line-height 1.38
  letter-spacing -0.2px
  text-align center
  color #a2a2a2
  border-radius 16px
  border solid 1px #ddd
  background none
