@import "../share.styl"
#creator_settings_body.setting-digital-item-orders
  .header-section
    margin 30px 0
    &__title
      color black
      margin-bottom 10px
      font-size 20px
      font-weight 600
    &__description
      color $gray-2
      font-size 14px

  .main-section
    padding 10px
    height 100%
    background-color $gray-6
    .table__wrapper
      position relative
      height calc(100vh - 20rem)
      min-height 16.3rem
      overflow scroll

  .pagination-item
    a
      padding 7px 8px 6px !important
      &.current
        background-color $blue-1
        color: #fff !important
        border-radius 8px

  .search-section
    margin-bottom 20px
    border none
    align-items center
    padding 0
    display flex
    flex-direction column
    gap 12px
    &__buttons
      display flex
      justify-content center
      margin-bottom 40px
      gap 10px
      > button
        width 120px
        height 40px
        display flex
        justify-content center
        align-items center
        font-weight 500
        font-size 14px
        border-radius 2px
    &__reset
      border 1px solid $gray-s1
      color $gray-s3
      background #fff
    &__search
      border 1px solid $blue-1
      color #fff !important
      background $blue-1

  .search
    display flex
    align-items center
    width 100%

  .search-date
    display flex
    align-items center
    justify-content center
    width 100%
    &__from
    &__to
      cursor pointer
      flex 1
    > div
      position relative
    &__hidden
      position absolute
      top 0
      left 0
      visibility hidden
    &__divider
      color $gray-s4
      margin 0 9px
    &__input
      display flex
      align-items center
      border 1px solid $gray-s1
      border-radius 2px
      font-size 14px
      font-weight 500
      text-indent 10px
      padding-right 50px
      height 40px
      width 100%
    &__icon--back-ground
      position absolute
      top 50%
      right 4px
      transform translate(0, -50%)
      width 32px
      height 32px
      display flex
      justify-content center
      align-items center
      background-color $gray-5
      border-radius 2px
      &__calendar-icon
        width 18px
        height 18px
        background-image url("//s3.marpple.co/files/u_2308668/2023/6/original/8748b11a34e7ac1dc95c2325498c11e235be79e61.png")
        background-size 18px 18px
        background-repeat no-repeat

  .search-select
    display flex
    align-items center
    height 40px
    gap 10px
    justify-content center
    width 100%
    select
      -webkit-appearance none
      -moz-appearance none
      border 1px solid $gray-s1
      flex 1
      border-radius 2px
      background $image-down-arrow no-repeat 95% center
      background-size 18px 18px
      font-size 14px
      text-indent 10px
      font-weight 500
      height 100%
    input
      height 100%
      font-size 14px
      text-indent 10px
      font-weight 500
      flex 2


.main-section__table
  margin-bottom 15px
  .table
    background #fff
    border 1px solid $gray-s2
    &__head
      display flex
      position sticky
      top 0
      background #fff
      width 100%
      z-index 100
    &__body
      z-index 1
      display flex
    &__row
      display flex
      min-height 48px
    &__th
    &__td
      font-size 14px
      padding 0 4px
      display flex
      justify-content center
      align-items center
    &__right
      width 100%
    &__head
      .table__row
        height 48px
    .table__th
      font-weight 600
      background-color white
      border-bottom 1px solid #000
    .table__td
      border-bottom 1px solid $gray-s1
    [data-key="상품명"]
    [data-key="상품명 별칭"]
    [data-key="주문자 이름"]
    [data-key="주문자 이메일"]
      text-overflow:ellipsis;
      overflow:hidden;
      word-break: break-all; // 모든 문자를 자른다. (단어 구분 없는 줄바꿈)
      white-space: nowrap;
      *
        text-overflow:ellipsis;
        overflow:hidden;
        word-break: break-all; // 모든 문자를 자른다. (단어 구분 없는 줄바꿈)
        white-space: nowrap;
    [data-key="주문일시"]
    [data-key="주문번호"]
    [data-key="상품번호"]
      text-align center
      min-width 100px
    [data-key="상품금액"]
    [data-key="마플샵 수수료"]
      text-align center
      min-width 100px
    [data-key="상품명"]
    [data-key="상품명 별칭"]
    [data-key="주문자 이메일"]
      text-align center
      min-width 200px
    [data-key="주문자 이름"]
    [data-key="연락처1"]
    [data-key="연락처2"]
      text-align center
      min-width 100px
