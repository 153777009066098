.app-home-top-seller
  &__list
    margin 0 24px
  &__another
    display flex
    margin 0 -8px 8px
  &__item-group
    width 50%
    padding 0 8px
    min-width 0
  &__top3
    display flex
    align-items flex-end
    margin 0 0 24px
    justify-content space-between
  .ts__name
    font-size 14px
    line-height 1.36
    letter-spacing -0.2px
    color #111

.top-seller-item
  width 24.5%
  flex-shrink 0
  &--1
    width 41.5%
  &__wrapper
    position relative
  &__img
    position relative
    margin-bottom 8px
    border-radius 24px
    overflow hidden
    font-size 0
    height 0
    padding-bottom 100%
    background-position center
    background-size cover


  &__medal
    position absolute
    font-size 10px
    top 0
    left 5px
    width 16px
    height 24px
    display flex
    align-items flex-end
    justify-content center
    &--2
      background-color silver
    &--3
      background-color saddlebrown
    &--1
      width 24px
      background-color gold
      height 36px
      left 10px
  &__name
    line-height 1.36
    letter-spacing -0.2px
    color #111
    text-align center
    width 100%
    overflow hidden
    text-overflow ellipsis
    white-space nowrap
  &__bg1
    position absolute
    top 50px
    left -15px
    z-index 1
    width 7px
    height 7px
    border-radius 50%
    background-color #24d8b3
  &__bg2
    position absolute
    top 24px
    left -30px
    z-index 1
    width 17px
    height 17px
    background-color #ffd802
    transform rotate(-315deg)
  &__bg3
    position absolute
    top 62px
    left -12px
    z-index 1
    width 5px
    height 10px
    background-color #1C75FF
    transform rotate(213deg)
  &__bg4
    position absolute
    top 19px
    right -8px
    z-index 1
    width 3px
    height 7px
    background-color #1C75FF
    transform rotate(49deg)
  &__bg5
    position absolute
    top 32px
    right -24px
    z-index 1
    width 14px
    height 14px
    border-radius 50%
    background-color #ffd802
  &__bg6
    position absolute
    top 58px
    right -10px
    z-index 1
    width 5px
    height 5px
    transform rotate(130deg)
    background-color #24d8b3
