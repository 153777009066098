.youtube-bridge-page
  pxToVw(value)
    unit(value/375*100, 'vw')
  pxToVMin(value)
    unit(value/375*100, 'vmin')
  &.youtube-shopping
    word-break keep-all
    .youtube-bridge-page__section
      &:nth-child(3):not(.main-08)
      &:nth-child(4):not(.main-08)
      &:nth-child(5):not(.main-08)
      &:nth-child(6):not(.main-08)
        min-height pxToRem(714)
        height auto
        padding pxToRem(60) 0
        .section-content-wrapper
          margin-bottom pxToRem(43)
      &:nth-child(7):not(.main-08)
      &:nth-child(8):not(.main-08)
        padding-top pxToRem(60)
        height auto
        min-height auto
        .video-container
          margin 0
      &.main-05.layout-split
        min-height pxToRem(714)
        height 100vh
      .title
        margin-top 0
    .main-07
      .card
        margin-top 0
  .layout-split
    display flex
    align-items center
    flex-direction column-reverse
    justify-content center
    box-sizing border-box
    gap pxToRem(60)
    height 100vh
    .left
      display flex
      justify-content center
      align-items center
      box-sizing border-box
      width 100%
      position relative
      z-index 0
      .left-content
        position relative
    .right
      //margin-top pxToVMin(60)
      display flex
      justify-content center
      align-items center
      box-sizing border-box
      width 100%
      z-index 1
      position relative

  .youtube-bridge-page__section
    .section-desc
      box-sizing border-box
      font-size pxToRem(15)
      line-height pxToRem(22)
      font-weight 400
      display flex
      flex-direction column
      gap pxToRem(12)
      text-align center
      color #DDD
      .highlight
        font-size pxToRem(30)
        line-height pxToRem(38)
        font-weight 700
        color #fff
        letter-spacing pxToRem(-1)

  .main-03
    .marpple-mo-mock-wrap
      position relative
      display flex
      justify-content center
      margin-bottom pxToRem(80)
      .youtube-logo-wrap
        position absolute
        z-index 2
        top pxToRem(-32)
        left pxToVw(-35)
        width pxToVw(100)
        max-width pxToRem(100)
        max-height pxToRem(100)
        border-radius pxToRem(4)
        aspect-ratio 1 / 1
        overflow hidden
        svg
          width 100%
          height 100%
      .marpple-mo-mock
        position relative
        z-index 1
        width pxToVw(186)
        max-width pxToRem(186)
        display flex
        justify-content center
        align-items center
        aspect-ratio 186 / 381
        .marpple-mo-mock-frame
          flex 1 0 pxToRem(186)
          position absolute
          z-index 2
          width 100%
          height 100%
          left 0
          top 0
        .marpple-mo-mock-frame-background
          flex 1 0 pxToRem(268)
          width 100%
          height 100%
          left 0
          top 0
          //border-radius pxToRem(0)
          position absolute
          z-index 0
          box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.20);
        video
          aspect-ratio 186 / 367
          object-fit contain
          width 100%
          position relative
          z-index 1
          border-radius pxToRem(24)

      .marquee-background-wrap
        display flex
        align-items center
        overflow-x hidden
        position absolute
        z-index 0
        bottom pxToRem(60)
        //width pxToVw(1126)
        //max-width pxToRem(1126)
        //max-height pxToRem(80)
        //aspect-ratio 1126 / 200
        width 100vw
        gap pxToRem(8)
        box-shadow pxToRem(-3) pxToRem(4) pxToRem(10) 0 rgba(0, 0, 0, 0.10)
        .gradient-marquee
          width 100%
          background linear-gradient(90deg, rgba(217, 217, 217, 0.00) 0.03%, #D9D9D9 21.37%, #D9D9D9 78.63%, rgba(217, 217, 217, 0.00) 99.97%)
          opacity 0.8
          position absolute
          z-index 1
        .marquee-background
        .marquee-background[aria-hidden="true"]
          --gap pxToRem(8)
          max-height pxToRem(80)
          padding-bottom 0
          margin 0
          img
            width pxToVw(80)
            max-width pxToRem(80)
            max-height pxToRem(80)
            aspect-ratio 1 / 1
            object-fit contain

  .main-05.layout-split
    gap pxToRem(40)
    .right
      z-index 1
    img
      aspect-ratio 1
      border-radius pxToRem(24)
    .center
      width pxToVMin(272)
      max-width pxToRem(272)
      aspect-ratio 272 / 380
      z-index 1
      position relative
    .left-top
      width pxToVMin(170)
      max-width pxToRem(170)
      position absolute
      top pxToVMin(-72)
      left pxToVMin(-71)
      z-index 2
    .left-bottom
      width pxToVMin(240)
      max-width pxToRem(240)
      position absolute
      right pxToVMin(-82)
      bottom pxToVMin(-82)
      z-index 0

  .main-07
    .parallel-marquee-wrapper
      margin pxToRem(40) 0 pxToRem(49)
      display flex
      flex-direction column
      gap pxToRem(20)
      width 100%
      .marquee-left-wrap
      .marquee-right-wrap
        display flex
        align-items center
        overflow-x hidden
        //width 100%
        gap pxToRem(12)
        .marquee-background
        .marquee-background[aria-hidden="true"]
          --gap pxToRem(12)
          max-height pxToRem(120)
          padding-bottom 0
          margin 0
          min-width auto
          height auto
          justify-content inherit
          &.revers
            animation marquee-right 50s linear infinite
          img
            width pxToVw(120)
            max-width pxToRem(120)
            max-height pxToRem(120)
            aspect-ratio 1 / 1
            object-fit contain
            border-radius pxToRem(12)

  .youtube-bridge-page__footer.youtube-shopping
    .more
      height 100vh
      justify-content inherit
      .gradation-over
        width 100%
        height 100%
        background rgba(17, 17, 17, 0.70)
        position absolute
        z-index 1
      img
        width 100%
        height 100%
      .footer-desc
        gap pxToRem(20)
        font-size pxToRem(16)
        line-height 150%
        font-weight 400
        .youtube-bridge-page__footer-more-btn
          font-size pxToRem(16)
          line-height 150%
          font-weight 600
          padding pxToRem(15) pxToRem(52) pxToRem(16)
          border-radius pxToRem(80)
          box-shadow 0 0 pxToRem(40) 0 rgba(0, 0, 0, 0.40);
@keyframes marquee-right {
  from { transform: translateX(-100%); }
  to { transform: translateX(0); }
}
