.app-home-quick-menu
  overflow hidden
  &__scroll-container
    overflow-x scroll
    overflow-y hidden
    margin-bottom -80px
    padding-bottom 80px
    -webkit-overflow-scrolling touch
  &__container
    margin-left 26px
    displayFlex()
    flexDirection row
    &::after
      display block
      padding-right 26px
      height 26px
      content: ''
  &__anchor
    & ~ ^[1..-1]
      margin-left 8px
      displayFlex flex-start center
      flexDirection column
  &__image-box
    width 72px
    height 72px
    border-radius 100%
    overflow hidden
  &__image
    vertical-align top
    width 100%
  &__text
    display block
    margin-top 8px
    font 12px 16px -0.2px
    text-align center
    white-space pre-wrap

