.pb-guide-popup
  &__frame.don_frame[is_modal="false"]
    z-index 10000001 !important
    >.don_wrapper
      >.header
        z-index 10
        .don_hide_frame
          background-image url(//s3.marpple.co/files/u_2283830/2024/10/original/ac998dae9c58a3705a07832d3592450a6cf60c341.webp) !important
          background-size 24px !important
          top 22px !important
          right 22px !important
  &__visuals
    position relative
    height 100dvh
    width 100%
  &__visual
    height 100%
    background #fff
    .pb-guide-popup__img
      padding-bottom 117.3333333%
      background-repeat no-repeat
      background-size 100%
    &[data-idx="0"]
      .pb-guide-popup__img
        background-image url(//s3.marpple.co/files/u_4078146/2025/2/original/fd3ef2a377f0624dc4a4e8264a5892691acce4661.webp)
    &[data-idx="1"]
      .pb-guide-popup__img
        background-image url(//s3.marpple.co/files/u_2283830/2024/10/original/efb34b38e63f409c89b3582f43f78fac5405de281.webp)
    &[data-idx="2"]
      .pb-guide-popup__img
        background-image url(//s3.marpple.co/files/u_2283830/2024/10/original/73b8900c5f3cfb7011bc5c03cb4e22085f05e2df3.webp)
    &[data-idx="3"]
      .pb-guide-popup__img
        background-image url(//s3.marpple.co/files/u_2283830/2024/10/original/fb0b9abffba90e5d3f0ce2d44f646bc7f721870e6.webp)
  &__info
    padding ptr(32) ptr(24)
    display flex
    flex-direction column
    gap ptr(12)
    color $black !important
    div
      span
        display block
  &__next-button
    display inline-flex
    align-items center
    justify-content center
    width ptr(48)
    height  ptr(48)
    border-radius 50%
    background-color $blue-100
    font-size 0
    border none
    z-index 10
    img
      width 24px
  &__swiper-controller
    width 100%
    display flex
    align-items center
    justify-content space-between
    padding ptr(24)
    position absolute
    bottom 0

  .swiper-pagination
    position relative
    .swiper-pagination-bullet
      width 10px
      height 10px
      margin 0 10px 0 0
      background-color $gray-10
      opacity 1
      &.swiper-pagination-bullet-active
        background-color $blue-100
