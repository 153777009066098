// modules/MShop/Youtube/SelectChannel/F/Style/Mo/index.styl

.select-channel-list-frame.don_frame
  .don_page
    > .don_wrapper > .header
      display flex
      align-items center
      justify-content center
      height 60px
      .title
        font-size 22px



.youtube-channel-list
  padding 0 30px
  ul
    margin 0
    padding 0
    list-style none
    border-top 1px solid #ccc
  li
    padding 10px 10px
    font-size 16px
    min-height 50px
    cursor pointer
    display flex
    align-items center
    justify-content space-between
    line-height 150%
    border-bottom 1px solid #ccc
    box-sizing border-box
    &:hover
      background-color #eee
  button
    color #1c4ba8 !important
    border 0 none
    background-color transparent
