.token-gate-frame
  background #fff
  bottom 68px
  padding-bottom env(safe-area-inset-bottom)
  padding-bottom constant(safe-area-inset-bottom)
  .token-gate__btn
    display none
.token-gate
  padding 10px 16px 44px
  margin-top -2px
  .pd-field__label
    display none
  &__btn
    width 100%
    height 52px
    margin 0
    font-size 16px
    font-weight 600
    text-align center
    color #ffffff
    border none
    outline none
    background-color #111111
    padding 0
    display flex
    align-items center
    justify-content center
    border-radius 8px
    box-sizing content-box
    letter-spacing -0.2px
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    margin-bottom env(safe-area-inset-bottom)
    margin-bottom constant(safe-area-inset-bottom)
    html[not-safe-area="true"] &
      padding-bottom 0
    html.safe_area_inset_bottom &
      margin-bottom 34px
  &__btn-colorful
    background-image linear-gradient(278deg, #4b6be0, #b32dfd) !important
    border-style solid
    border-width 1px
    border-image-slice 1
    border-color initial
    background-origin border-box
    svg
      margin-right 8px
    &:disabled
      background-image linear-gradient(278deg, #4b6be0, #b32dfd) !important
      border-style solid
      border-width 1px
      border-image-slice 1
      background-origin border-box
  &__btn-border-colorful
    border 1px solid transparent
    background-image linear-gradient(to bottom, #fff, #fff), linear-gradient(285deg, #4b6be0, #b82dfd)
    background-origin border-box
    background-clip content-box, border-box
    padding 0

.token-gate-list
  padding 0 0 16px
.token-gate-item
  display flex
  align-items center
  justify-content space-between
  margin-bottom 10px
  &:last-child
    margin-bottom 0
  &__chain-icon
    display flex
    align-items center
    margin-right 6px
    margin-top -3px

  &__chain
    display flex
    align-items center
    width 24%
    color #424242
    overflow hidden
    text-overflow ellipsis
    white-space nowrap
    flex-shrink 0
  &__name
    width 66%
    display flex
    align-items center
    flex-shrink 0
    a
      width 100%
      overflow hidden
      text-overflow ellipsis
      white-space nowrap
      color #828282
    a:hover
      text-decoration underline
  &__icon
    display flex
    align-items center
    justify-content center
    padding 2px
    margin-top -4px
    width 20px
    flex-shrink 0
    &[data-status="pass2"]
      visibility hidden
      width 20px
    img
      width 100%
