body.creator.cart.mshop
  position fixed
  height 100svh
  width 100%
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overscroll-behavior-y none
  overflow hidden

.don_tab[tab_name="/modules/MShop/App/Cart/S/Mui/tab.js"]
  & > .don_wrapper
    height 100svh
    overflow auto
    & > .LayoutView
      padding-bottom calc(constant(safe-area-inset-bottom) + 100px)
      padding-bottom calc(env(safe-area-inset-bottom) + 100px)
  @import "../share.styl"
  .empty_page
    min-height ptr(910)
  .pc
    display none !important
  .delivery-type-grouped-item
    &__1
      display flex
      align-items center
      padding-top ptr(12)
    &__2
      &__item
        &:last-child
          border-bottom 0
  .pre-shipping-order-item
    &__download-period
      color $gray-80
      text-align center
      display none
      padding ptr(12) 0
    &__price-set
      border-top 1px solid $gray-20
      &__price
        min-width 55px
        text-align right
        limitLine(1)
        color $black
        &:after
          {$kr-14-m}
      &__shipping-label
        min-width 50px
        display flex
        align-items center
        column-gap ptr(4)
      &__basic
        padding ptr(12) 0
      &__additional
        display none
      &__basic
        width 100%
        display flex
        justify-content space-between
      .mps-price-style
        color $gray-80
        margin-right pxToRem(3.6px)
        margin-left pxToRem(8px)
        limitLine(1)
        & + span
          limitLine(1)
          color $gray-80
      &__free-shipping
      &__condition
        display none
      &__label
        color $black
        font-size pxToRem(13px)
        display flex
        align-items center
      &__content
        color $black
        font-size pxToRem(13px)
    &__1
      flex-grow 1
      &__item
        padding ptr(16) 0
        &:last-child
          border-bottom 0

  .pre-shipping-order-item
    &[data-is_separate_shipping="true"]
      .cart-style-up-item__option__modify-btn
        display none
    &[data-delivery_type_id="DIGITAL"]
      .cart-style-up-item__option__modify-btn
        display none
      .pre-shipping-order-item__price-set__basic
        display none
      .pre-shipping-order-item__download-period
        display block
    &[data-delivery_type_id="SELLER"]
      .pre-shipping-order-item__price-set__label .mps-tooltip-icon
        display inline-block
        width ptr(16)
        height ptr(16)
    &[data-has_condition="true"]
      .pre-shipping-order-item__price-set__condition
        display flex
        align-items center
    &[data-has_condition="false"]
      .pre-shipping-order-item__price-set__condition
        display none
    &[data-free_shipping="true"]
      .pre-shipping-order-item__price-set__price
        display none
      .pre-shipping-order-item__price-set__condition
        display none
      .pre-shipping-order-item__price-set__shipping-label
        .mps-tooltip-icon
          display none
      .pre-shipping-order-item__price-set__free-shipping
        display flex
  .cart-store-item
    &__1
      display flex
      align-items center
      border-bottom 1px solid $gray-700
      padding pxToRem(16px) 0 pxToRem(12px)
    &__3
      margin-top pxToRem(16px)
      padding ptr(16)
      background-color $gray-5
      border-radius ptr(16)
    &__price-set
      display flex
      flex-direction column
      row-gap ptr(8)
      &__item
        display flex
        justify-content space-between
        width 100%
        &__text
          color $black
        &__price
          color $black
        &__total-price-text
          color $black
        &__total-price
          color $black

  .mshop-cart
    margin-top $default-header-mo_height
    background $white
    &__body
      display block
      &__1
        &__item
          margin-bottom pxToRem(24px)
          background #fff
          padding ptr(24) ptr(16) 0
          &:last-child
            margin-bottom 0
      &__2
        margin-bottom 0
    &__recommend-products
      min-height ptr(197)
      visibility hidden
      opacity 0
      -webkit-transition visibility 0.5s, opacity 0.5s ease-out
      -moz-transition visibility 0.5s, opacity 0.5s ease-out
      -ms-transition visibility 0.5s, opacity 0.5s ease-out
      -o-transition visibility 0.5s, opacity 0.5s ease-out
      transition visibility 0.5s, opacity 0.5s ease-out
      &.show
        visibility visible
        opacity 1
    &__recommend-products-header
      margin-bottom ptr(16)
      margin-left ptr(16)
    &__recommend-products-title
      color $gray-80
    &__recommend-products-wrap
      & > [data-rune="ProductCardList"]
        width: 100%;
        display: flex;
        padding: 0 ptr(16);
        gap: ptr(16);
        overflow-x: auto;
        overflow-y: hidden;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
        scroll-padding-left: ptr(16);
        scroll-snap-type: x mandatory;
        & > [data-rune="ProductCard"] {
          position: relative;
          width: ptr(128);
          flex: none;
          scroll-snap-align: start;
        }

  .mshop-cart-empty
    padding ptr(160) 0
    display flex
    flex-direction column
    justify-content center
    align-items center
    &__icon
      margin-bottom ptr(24)
      img
        width ptr(120)
        height ptr(120)
    &__title
      margin-bottom ptr(8)
      color $gray-80
    &__desc
      margin-bottom ptr(24)
      color $gray-50
      text-align center

  .cart-up-item-list-price
    &__up-item-list-price-info-wrapper
      margin ptr(24) ptr(16)
      padding pxToRem(16px)
      border 1px solid $gray-200
      border-radius pxToRem(16)
      background #fff
      &__title
        font-weight bold
        font-size pxToRem(16px)
        padding-bottom pxToRem(18px)
        border-bottom 1px solid $gray-700
    &__shipping-info
      display none
      order 2
      background #fff
      color $gray-700
      font-size 13px
      line-height 1.46
      border-radius 8px
      padding pxToRem(40px) 0 pxToRem(80px)
      justify-content center
      align-items center
      text-align center
    &__to-checkout-btn-safe-area
      padding-bottom constant(safe-area-inset-bottom)
      position fixed
      bottom 0
      width 100%
      border-top 1px solid $gray-5
      z-index 5
    &__to-checkout-btn-wrap
      padding pxToRem(16px)
      background-color $white
      z-index 5
      html.safe_area_inset_bottom &
        padding-bottom pxToRem(34px)
    &__to-checkout-btn
      width 100%
      padding pxToRem(14) 0
      display flex
      justify-content center
      align-items center
      background $gray-5
      border none
      color #fff
      border-radius 8px
      &__quantity
        display inline-flex
        justify-content center
        align-items center
        margin-left ptr(8)
        background-color $blue-10
        color $blue-100
        border-radius 50%
        width ptr(16)
        height ptr(16)

  .mshop-cart
    display flex
    flex-direction column
    &[data-has_pb="true"]
      .cart-up-item-list-price__shipping-info
        display flex
    &[data-has_sth="true"]
      .mshop-cart__body
        display block
      .mshop-cart-empty
        display none
    &[data-has_sth="false"]
      .mshop-cart__body
        display none
      .mshop-cart-empty
        display flex
    &[data-is_inhouse="true"]
      .up-item-list-price-info__2__item-shipping
        display none
      .pre-shipping-order-item__price-set__label .mps-tooltip-icon
        display inline-block

.don_frame.has_up_modal_style
  background-color $white
  border-radius 16px 16px 0 0
  overflow hidden
  max-height calc(90dvh - 6rem)
  & >.don_wrapper
    height auto
    & >.header
      z-index 99 !important
      padding 32px 16px 24px !important
      & > .title
        font-family Pretendard
        font-size 16px !important
        font-style normal !important
        font-weight 700 !important
        line-height 24px !important
        letter-spacing -0.2px !important
        color $black !important
  .modal
    background-color $ness-white
    .list_body
      position relative
      overflow-y auto
      padding 0 16px
      .modal-product-info-wrap
        display flex
        align-items center
        column-gap 16px
        padding 16px 0
        margin-bottom 4px
        height 64px
        border-bottom 1px solid $gray-10
        >.product_preview
          display block !important
          width 64px
          height 64px
          z-index 10
          border-radius 8px
          overflow hidden
          flex-shrink 0
          img
            width 64px
            height 64px
            object-fit contain
          .product_face_list.top
            display none !important
          .product_face_container
            img
              background #f6f6f6
          .product_face_list.bottom
            .product_face_container
              width 64px
              height 64px
              z-index 10
            .product_face
              width 64px
              height 64px
              background #f8f8f8
              &:not(:first-child)
                display none
              .canvas_60
                width 100%
                height 100%
                > *
                  width 100% !important
                  height 100% !important
                  transform scale(1)
      .product_title
        display flex
        flex-direction column
        .pc_name
          color $black
          limitLine(1)
          margin-bottom 4px
        .bp_name
          color $gray-50
          limitLine(1)
          margin-bottom 8px
        .price
          color $black
        .creator_price
          html[is_seller="true"] &
            display none
        .seller_price
          html[is_seller="false"] &
            display none
      .item
        position relative
        padding 12px 0
        .size_info
          color $black
          &__price
            color $gray-50
            margin-left 8px
            &:after
              display none
        &.is_not_stock
          .size_info span
            color $gray-20
        .info_wrap
          display flex
          align-items center
          justify-content space-between
          overflow hidden
          .info
            display flex
            overflow hidden
            &.size_info
              flex 1
            &.qty_info
              display flex
              flex-direction column
          span.size, span.qty
            margin-right 8px
          .input_wrap
            display flex
            align-items center
            justify-content space-between
            padding ptr(8) ptr(10)
            box-sizing border-box
            background-color $gray-5
            border-radius ptr(8)
            input.quantity
              background-color $gray-5
              text-align center
              color $black
              outline none
              border none
              margin 0
              padding 0
              position relative
              &::-webkit-inner-spin-button,
              &::-webkit-outer-spin-button
                -webkit-appearance none
                margin 0
            .plus, .minus
              display inline-block
              width 24px
              height 24px
              cursor pointer
              background-size 12px 12px
            .plus
              background url(//s3.marpple.co/files/u_2308668/2024/5/original/d6d0dbda7a0420a4797b135819091b7a507c79e21.svg) no-repeat center
            .minus
              background url(//s3.marpple.co/files/u_2308668/2024/5/original/3fb1ba642668d03ee3587eb8a38e00707ecf93981.svg) no-repeat center
        &.is_not_stock
          .qty_info
            .input_wrap
              display none !important
            &:after
              content "Sold Out"
              color $gray-20
              width 134px
              text-align center
              height 28px
        .product_price_wrap
          display none
        .product_price
          display flex
          bottom 14px
          font-size 14px
          &[is_discounted="true"]
            .discounted
              display block
            .original
              text-decoration line-through
              color color_gray3
          &.hide
            .discounted, .original
              display none
          .discounted
            display none
            margin-top 12px
            color color_black
          .original
            font-size 13px
            margin-right 8px
            margin-top 13px
            color color_black
    .possible-max-quantity
      padding 0 24px 20px
      color #828282
    .modal_footer
      position fixed
      bottom 0
      right 0
      left 0
      background-color $white
      &:after
        display block
        content ''
        padding-bottom env(safe-area-inset-bottom)
        padding-bottom constant(safe-area-inset-bottom)
      html[safe-area="true"] &
        padding-bottom 34px
      .summary
        border-top 1px solid $gray-20
        margin-top 4px
        padding 16px 0
        margin 0 24px
        display flex
        align-items center
        justify-content space-between
        .title
          color $black
        .total_price
          color $black
          .percent
          .sign
          .origin
            display none
          .discounted
            color $black
            &:after
              {$kr-24-b}

      .msg
        margin-top 8px
        font-size 9px
        color color_gray3
        html[lang="kr"] &
        html[lang="jp"] &
          display none
      .buttons
        text-align center
        display flex
        align-items center
        justify-content space-between
        column-gap 8px
        padding 16px
        html[safe-area="true"] &
          padding 0 16px
        .buttons-okay
          pointer-events none
        .buttons-okay
        .buttons-cancel
          flex 1
          width 100%
          padding 14px 0
          border-radius 8px
          border none
          background-color $gray-5
          color $gray-50
          &.active
            background-color $blue-100
            color $white
            pointer-events auto
        .buttons-cancel
          border 1px solid $gray-20
          background-color $white
          color $gray-80
