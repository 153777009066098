.app-home-community-list
  position relative
  &__container
    display flex
    flex-wrap nowrap
    justify-content flex-start
    align-items flex-start
    padding 0 pxToRem(24)
    column-gap pxToRem(16)
    scroll-snap-type x mandatory
    scroll-padding-left pxToRem(24)
    overflow-x auto
    &::-webkit-scrollbar
      display: none;
    > div
      scroll-snap-align start
  .community-review-item
    width 100%
    margin-bottom 0
    box-sizing border-box
    &__photo_img
      position relative
      width 100%
      padding-top 100%
      overflow hidden
      img
        position absolute
        top 0
        left 0
        right 0
        bottom 0
        margin auto
        width 100%
        max-height none
