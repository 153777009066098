.mall-sellers
  padding-bottom 10px
  &__title
    margin 0 25px 16px
    padding 0
    font pxToRem(23px) 30px -0.3px 600
    color #111
  &__cont
    min-height 500px
    margin-bottom 20px
  .custom-slide-selector-wrapper
    .slide-selector
      &__item
        &[data-selected="true"]
          color #fff
          background-color #1C75FF
        img
          width 14px
          vertical-align middle
          margin-bottom 2px


.mall-seller-list
  margin-top 32px
  html[is_plus="true"] &
    margin-top 0
  &__header
    margin 0 24px
    display flex
    justify-content space-between
    html[is_plus="true"] &
      display none
  &__title
    font-size 20px
    font-weight normal
    line-height 1.3
    letter-spacing -0.3px
    color #111
    margin 0
    padding 0
  &__body
    margin-top 16px
  &__btn_align
    display none


.mall-seller-list-all
  &__abc
    html[is_plus="true"] &
      display none
  &__abc_wrap
    margin 16px 24px
    padding 0 0 10px
    list-style none
    overflow auto
    overflow-y hidden
    display flex
  &__abc_item
    margin-right 20px
    font-size 18px
    font-weight 600
    line-height 1.11
    letter-spacing -0.3px
    color #ddd
    cursor pointer
    flex none
    &--on
      color #111
      text-decoration underline
  &__item
    margin-left 24px
    margin-right 24px
    margin-bottom 13px
  &__name_link
    font-size 16px
    line-height 1.38
    letter-spacing -0.2px
    color #111


.mall-seller-list-seller
  &__item
    position relative
    height 72px
    margin 0 24px 16px
    display flex
    align-items center
    &--hide
      display none
  &__thumb
    position absolute
    top 0
    left 0
    bottom 0
    width 72px
  &__item_img
    position relative
    width 100%
    padding-top 100%
    img
      position absolute
      top 0
      left 0
      right 0
      bottom 0
      width 100%
      height 100%
      max-width 100%
      max-height 100%
      object-fit cover
      border-radius 24px
  &__sns_icon
    position absolute
    bottom -7px
    right -3px
    width pxToRem(24px)
    height pxToRem(24px)
    padding pxToRem(3px)
    overflow hidden
    border-radius 50%
    background-color #fff
    img
      width 100%
      border-radius 50%
      vertical-align top
  &__info
    flex 1
    padding-left 75px
    box-sizing border-box
    height 100%
    width 100%
    display flex
    justify-content space-between
    a
      min-width 0
      padding-left 13px
      color #111
      height 100%
      width 100%
      padding-right 4px
      box-sizing border-box
      display flex
      flex-direction column
      justify-content center
    .app-store-like
      display flex
      align-items center
      &__btn
        reset-button()
        margin-right -6px
        position relative
        &:before
          content ''
          display block
          width 32px
          height 32px
          border-radius 100%
          margin auto
          background-color rgba(255, 255, 255, 0.8)
          background-image url(//s3.marpple.co/files/u_29089/2021/4/original/e3906e0ac1fe7396b7f4f37dba60467c71afb9cb1.png)
          background-size 20px 20px
          background-position center
          background-repeat no-repeat
      &__heart
        display none
        &--on
          display block
          background-image url(//s3.marpple.co/files/u_29089/2021/4/original/04f209f889e653e24cd3954e32f73bbdfb3f6d991.png)
          background-size 20px 20px
          background-position center
          background-repeat no-repeat
          position absolute
          top 0
          left 0
          right 0
          bottom 0

  &__name
    text-overflow ellipsis
    white-space nowrap
    overflow hidden
    font-weight 600
    line-height 1.29
    letter-spacing -0.2px
    color #111111
  &__desc
    margin-top 2px
    text-overflow ellipsis
    white-space nowrap
    overflow hidden
    font-size 13px
    line-height 1.38
    letter-spacing -0.2px
    color #111


.mall-seller-list-brand
  &__item
    width 100%
    margin-bottom 24%
    overflow hidden
    &--hide
      display none
  &__body
    margin-left 24px
    margin-right 24px
    &--official
      display grid
      grid-gap 0 20px
      grid-template-columns repeat(3, minmax(0, 1fr))
  &__img
    position relative
    padding-top 100%
    img
      position absolute
      top 0
      left 0
      right 0
      bottom 0
      margin auto
      width 100%
      height 100%
      vertical-align top
      border-radius 50%
  &__name
    margin-top 12px
    height 24px
    text-overflow ellipsis
    white-space nowrap
    overflow hidden
    font-size 18px
    font-weight 600
    line-height 1.33
    letter-spacing -0.2px
    color #111
    text-align center




.mall-main-seller-list
  margin-bottom 24px
  padding 0 24px
  display grid
  grid-template-columns repeat(auto-fill, 47.7%)
  grid-gap 32px 0
  justify-content space-between
  &__item
    width 100%
  &__seller
    position relative
    width 100%
    height 34px
    margin-bottom 5%
    display flex
    flex-direction column
    justify-content center
  &__thumb
    position absolute
    top 0
    left 0
    width 34px
    height 34px
  &__item_img
    position relative
    width 100%
    height 100%
    background-size cover
    background-repeat no-repeat
    background-position center
    border-radius 10px
    a
      position absolute
      top 0
      left 0
      right 0
      bottom 0
      display block
  &__sns_icon
    position absolute
    bottom -7px
    right -3px
    img
      width 11px
      border 1px solid #fff
      border-radius 50%
  &__name
    padding 4px 34px 0 42px
    text-overflow ellipsis
    white-space nowrap
    overflow hidden
    font-size 13px
    font-weight bold
    line-height 1.38
    letter-spacing -0.19px
    color #111
    a
      color #111
  &__desc
    padding 0 34px 0 42px
    text-overflow ellipsis
    white-space nowrap
    overflow hidden
    font-size 12px
    line-height 1.5
    letter-spacing -0.18px
    a
      color #111
  &__like
    position absolute
    top 0
    right 0
    width 34px
    height 34px
    .app-store-like
      display flex
      align-items center
      &__btn
        reset-button()
        margin-right -6px
        position relative
        &:before
          content ''
          display block
          width 32px
          height 32px
          border-radius 100%
          margin auto
          background-color rgba(255, 255, 255, 0.6)
          background-image url(//s3.marpple.co/files/u_29089/2021/4/original/e3906e0ac1fe7396b7f4f37dba60467c71afb9cb1.png)
          background-size 20px 20px
          background-position center
          background-repeat no-repeat
      &__heart
        display none
        &--on
          display block
          background-image url(//s3.marpple.co/files/u_29089/2021/4/original/04f209f889e653e24cd3954e32f73bbdfb3f6d991.png)
          background-size 20px 20px
          background-position center
          background-repeat no-repeat
          position absolute
          top 0
          left 0
          right 0
          bottom 0
  &__products
    display flex
    width 100%
    justify-content space-between
    .seller-product-item
      width 48%


.mall-sellers-sub
  margin 0 0 50px

.seller-item2
  padding 0 0 20px
  &__item
    width 100%
  &__seller
    position relative
    height 34px
    margin-left 24px
    margin-right 24px
    box-sizing border-box
    margin-bottom 2.5%
    display flex
    flex-direction column
    justify-content center
  &__thumb
    position absolute
    top 0
    left 0
    width 34px
    height 34px
  &__item_img
    position relative
    width 100%
    height 100%
    background-size cover
    background-repeat no-repeat
    background-position center
    border-radius 10px
    a
      position absolute
      top 0
      left 0
      right 0
      bottom 0
      display block
  &__sns_icon
    position absolute
    bottom -5px
    right -1px
    width pxToRem(11px)
    height pxToRem(11px)
    padding pxToRem(1px)
    overflow hidden
    border-radius 50%
    background-color #fff
    img
      width 100%
      border-radius 50%
      vertical-align top
  &__name
    padding 3px 44px 0 40px
    text-overflow ellipsis
    white-space nowrap
    overflow hidden
    font-size 13px
    font-weight 600
    font-stretch normal
    font-style normal
    line-height 1.22
    letter-spacing -0.2px
    a
      color #111
  &__desc
    padding 3px 44px 0 40px
    text-overflow ellipsis
    white-space nowrap
    overflow hidden
    font-size 12px
    line-height 1.25
    letter-spacing -0.2px
    color #111
    a
      color #111
  &__like
    position absolute
    top 50%
    right 0
    width 32px
    height 32px
    transform translateY(-58%)
    .app-store-like
      display flex
      align-items center
      &__btn
        reset-button()
        margin-right -6px
        position relative
        &:before
          content ''
          display block
          width 32px
          height 32px
          border-radius 100%
          margin auto
          background-color rgba(255, 255, 255, 0.6)
          background-image url(//s3.marpple.co/files/u_29089/2021/4/original/e3906e0ac1fe7396b7f4f37dba60467c71afb9cb1.png)
          background-size 20px 20px
          background-position center
          background-repeat no-repeat
      &__heart
        display none
        &--on
          display block
          background-image url(//s3.marpple.co/files/u_29089/2021/4/original/04f209f889e653e24cd3954e32f73bbdfb3f6d991.png)
          background-size 20px 20px
          background-position center
          background-repeat no-repeat
          position absolute
          top 0
          left 0
          right 0
          bottom 0
  &__products
    height 104px
    .seller-product-item
      width 28%
      height 100%
      margin-left 8px
      &:first-child
        margin-left 24px
      &:last-child
        margin-right 24px
    .swiper-wrapper
      overflow auto

.seller-product-item-empty
  width 100%
  font-size 13px
  font-weight 600
  line-height 1.43
  letter-spacing -0.18px
  text-align center
  color #4e4e4e
  height pxToRem(104px)
  display flex
  flex-direction column
  align-items center
  justify-content center
  .img img
    width 40px
  p
    margin 0
    padding 0

.mshop-official
  margin 70px 0
  &__more
    margin-top 16px
    display flex
    align-items center
    justify-content center
  &__more-btn
    height 30px
    font-size 13px
    line-height 1.38
    letter-spacing -0.2px
    color #a2a2a2
    width -webkit-fill-available
    margin 16px 24px 0
    border-radius 22px
    border solid 1px #dddddd
    background-color #ffffff
    box-sizing border-box
    &--hide
      display none
