body
  background-color #fff
  font-size 1rem
body.mshop > #body > .don_frame > .don_wrapper
  display flex
  flex-direction column

body.mshop .don_frame >.don_wrapper >.body
  order 2
body.mshop .don_frame[frame_index="0"] >.don_wrapper >.header, body .don_frame[frame_index="0"] >.don_wrapper >.header
  display block
  position static
  order 1
  z-index 11

^[-1]
html[is_app=true]
  body.mshop
    {$creator}-spinner
      width 48px
      height 48px
      background url("//s3.marpple.co/files/u_2283830/2024/2/original/69948301f4d417f6e021e3d4b423414925d38b381.gif") no-repeat
      background-size 48px 48px

.creator-layout
  display flex
  flex-direction column
  justify-content space-between
  min-height calc(100vh - 60px)
  background-color #fff
  &--is-margin
    margin-top 10px
  &__inner
    box-sizing border-box
  &__column
    &--pd-left
      padding 0 0 0 24px
    &--pd-side
      padding 0 24px
  &__body
    box-sizing border-box
  &__blind
    blind()
  &__up
    display none
