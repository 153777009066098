.HelloCreatorPage
  h3
  h4
  p
    padding 0
    margin 0
  ul
  li
    margin 0
    padding 0
  *
    box-sizing border-box
    /* KR-48/Bold */
    font-family: Pretendard;
    font-style: normal;
    letter-spacing: -0.2px;
    word-break keep-all

  /* cta button */
  .request
    //transition .3s ease-in-out
    position: fixed;
    bottom: calc(16px + env(safe-area-inset-bottom));
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;

  .section
    overflow hidden

  .section1
    position relative
    width: 100%;
    height 100svh
    .bg
      position absolute
      top 0
      left 0
      width 100%
      height 100%
      z-index 1
      display flex
      justify-content center
      img
      video
        width 100%;
        object-position center
        object-fit cover
      &:after
        content ''
        position absolute
        top 0
        left 0
        width: 100%
        height: 100%
        background: linear-gradient(180deg, rgba(28, 117, 255, 0) 42%, rgba(28, 117, 255, 0.9) 70%);

    .texts
      z-index 2
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;
      position absolute
      bottom 88px
      margin 0 auto
      width 100%
      left 0
      right 0
      text-align center
      padding-bottom env(safe-area-inset-bottom);

      *
        color: var(--White, #FFF);
      h3
        font-weight: 700;
        font-size: 28px;
        line-height: 36px;
      p
        font-size: 14px;
        font-weight 500
        line-height: 22px; /* 140% */
    .buttons
      z-index 2
      position absolute
      bottom 50px
      margin 0 auto
      left 0
      right 0
      text-align center
      a
        display: inline-flex;
        min-width: 120px;
        padding: 9px 24px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 8px;
        background: var(--Black, #141414);
        color: var(--White, #FFF);
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        line-height: 22px; /* 150% */

  .section2
    position relative
    height 812px
    .imgs
      position absolute
      top 0
      left 0
      width 100%
      img
        position absolute
      img:nth-child(1)
        width: 188px;
        height: 188px;
        top 100px;
        left 50%;
        transform translateX(-50%)
      img:nth-child(2)
        width: 188px;
        height: 188px;
        top 547px;
        left 44px;
      img:nth-child(3)
        width: 188px;
        height: 188px;
        top 466px;
        left 224px;
    .texts
      position absolute
      left 50%
      top 299px
      transform translateX(-50%)
      width 100%
      margin auto auto
      text-align center
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      *
        color: var(--Blue-100, #1C75FF);
        font-size: 28px;
        font-weight: 700;
        line-height: 36px; /* 125% */
      p
        font-size: 14px;
        font-weight: 500;
        line-height: 22px; /* 160% */

  .section3
    position relative
    height 812px
    background: var(--Blue-10, #EBF3FE);
    padding: 80px 32px 0px 32px;
    text-align center
    .texts
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;
      text-align center
      *
        color: var(--Blue-100, #1C75FF);
        font-size: 28px;
        font-weight: 700;
        line-height: 36px; /* 125% */
      p
        font-size: 14px;
        font-weight: 500;
        line-height: 22px; /* 160% */
    img
      z-index 2
      position absolute
      top 250px
      left 50%
      transform translateX(-50%)
      width 353px
      height 470px

  .section3-2
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-shrink: 0;
    > .texts
      display: flex;
      padding: 80px 32px 0px 32px;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      text-align center
      h3
        font-size: 28px;
        font-weight: 700;
        line-height: 36px; /* 128.571% */
      p
        font-size: 14px;
        font-weight: 500;
        line-height: 22px; /* 157.143% */

    .details-container
      width 100%
      overflow-x scroll
      overflow-y hidden
      -ms-overflow-style: none;
      scrollbar-width: none;
      .tabs-container::-webkit-scrollbar {
        display none;
      }
    .details
      display: flex;
      align-items: flex-start;
      gap: 24px;
      align-self: stretch;
      width 1192px
      margin 98px 32px 60px
      .detail
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        img
          width: 280px;
          align-self: stretch;
        .texts
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 8px;
          align-self: stretch;
          text-align center
          h4
            font-size: 20px;
            font-weight: 700;
            line-height: 28px; /* 140% */
          p
            color #6B6B6B
            font-size: 14px;
            font-weight: 500;
            line-height: 22px; /* 160% */
            word-break: keep-all

  .section4
    display: flex;
    padding-top: 80px;
    padding-bottom 68px
    flex-direction: column;
    align-items: center;
    background: var(--Black, #141414);

    >.texts
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      text-align center
      h3
      p
        color: var(--White, #FFF);
      h3
        font-size: 28px;
        font-weight: 700;
        line-height: 36px;
      p
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;

    .cards
      margin 68px auto 0
      padding 0 32px
    .card
      display: inline-flex;
      width: 100%
      height: auto;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      gap: 20px;
      flex-shrink: 0;
      border-radius: 16px;
      padding 32px
      .texts
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        text-align center
        word-break keep-all
      h4
        font-size: 20px;
        font-weight: 700;
        line-height: 28px; /* 141.667% */
      p
        font-size: 14px;
        font-weight: 500;
        line-height: 22px; /* 160% */
      img
        display: flex;
        width: 200px;
        height: 200px;
        justify-content: center;
        align-items: center;

    .card:nth-child(1)
      background #FFF9E2
      color #704C00
    .card:nth-child(2)
      background #EBFEF3;
      color #009444;
      transform: rotate(3.833deg);
      margin-top -2px
    .card:nth-child(3)
      background #EBF3FE
      color #1C75FF
      transform: rotate(-3.197deg);
      margin-top 10px
    .card:nth-child(4)
      background #FFE4FF
      color #990000
      margin-top 30px


  .section5
    background: #1C75FF;
    display flex
    flex-direction: column;
    position relative
    padding 80px 0
    overflow hidden
    .texts
      position relative
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      margin 0 32px 32px
      gap: 16px;
      text-align center
      color #fff
      z-index 3
      word-break keep-all
      h3
        font-size: 28px;
        font-weight: 700;
        line-height: 36px; /* 125% */
      p
        font-size: 14px;
        font-weight: 500;
        line-height: 22px; /* 160% */
        padding 0 13px
    .coins
      position absolute
      top 0
      left 0
      right 0
      bottom 0
      margin 0 auto
      z-index 1
      width 100%
    .product
      z-index 1
      display: flex;
      padding 0 32px
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 16px;
      .img
        position relative
        width 100%
        .t
          width 100%
          top 0
          left 0
          text-align center
          background #F6F6F6
          border-radius: 16px;
          img
            height 202px
        img.coin1
          position absolute
          top 40px
          left 0
          transform translateX(-50%)
          width 145px
          height 145px
      .prices
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        align-self: stretch;
        color: var(--White, #FFF);
        .price
        .profit
          display: flex;
          padding: 24px 0px;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          border-top: 1px solid var(--White, #FFF);
          h4
            font-size: 14px;
            font-weight: 700;
            line-height: 22px; /* 140% */
          .value
            font-family: "Bebas Neue Pro";
            letter-spacing normal;
            font-size: 48px;
            font-weight: 700;
            line-height: 90%; /* 64px */
            position relative
            text-align justify
            width 120px
            &:before
              position absolute
              content 'KRW'
              left -91px
        .price
          .value
            width 119px
        .profit
          .value
            width 113px
        .profit
          border-bottom: 1px solid var(--White, #FFF);
        p
          display: flex;
          padding: 16px 0 0;
          align-items: center;
          gap: 4px;
          align-self: stretch;
          font-size: 12px;
          font-weight: 500;
          line-height: 17px; /* 157.143% */

  .section6
    display: flex;
    padding: 80px 0;
    overflow hidden
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    flex: 1 0 0;
    width 100%
    .texts
      padding 0 32px
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      color #1C75FF
      margin-bottom 40px
      width 100%
      h3
        font-size: 28px;
        font-weight: 700;
        line-height: 36px; /* 125% */
      p
        font-size: 14px;
        font-weight: 500;
        line-height: 22px; /* 160% */
    .left
      width 100%
    .tabs-container
      width 100%
      overflow scroll
      overflow-y hidden
      -ms-overflow-style: none;
      scrollbar-width: none;
    .tabs-container::-webkit-scrollbar {
      display none;
    }
    .tabs
      display: flex;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      color: #D9D9D9;
      font-size: 32px;
      font-weight: 700;
      line-height: 32px; /* 93.75% */
      transition transform 0.3s ease-out
      .tab-button
        display: flex;
        align-items: flex-start;
        gap: 12px;
        &:first-child
          margin-left 32px
        &:last-child
          padding-right 32px
        span
          font-family: "Bebas Neue Pro";
          letter-spacing normal;
        svg
          margin-top 2px
      svg:nth-child(1)
        display none
      svg:nth-child(2)
        display block
      .selected
        color #1C75FF
        svg:nth-child(1)
          display block
        svg:nth-child(2)
          display none

    .right
      width calc(100% - 64px)
      margin 0 auto
      flex-shrink: 0;
      border-radius: 32px;
      background: var(--Blue-10, #EBF3FE);
      overflow hidden
      .swiper-slide
        img
          width 100%

  .section7
    background #7A44A0
    padding 27px 0 0
    .cards
      color: var(--Pink-20, #FFE4FF);
      text-align: center;
      font-size: 28px;
      font-weight: 700;
      line-height: 36px; /* 128.571% */
      margin-bottom 70px
      p
        padding 70px 0 40px
      img
        width 272px
    .texts
      display: flex;
      padding: 0px 32px 80px 32px;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      text-align center
      color #FFE4FF
      h3
        font-size: 28px;
        font-weight: 700;
        line-height: 36px; /* 125% */
      p
        font-size: 14px;
        font-weight: 500;
        line-height: 22px; /* 160% */

  .section8
    display: flex;
    flex-direction: column;
    background: var(--Blue-10, #EBF3FE);
    overflow hidden
    padding: 80px 32px 0px 32px;
    align-items: center;
    gap: 40px;
    flex-shrink: 0;
    .info
      .texts
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        color #1C75FF
        text-align center
        margin-bottom 40px
        h3
          font-size: 28px;
          font-weight: 700;
          line-height: 36px; /* 125% */
        p
          font-size: 14px;
          font-weight: 500;
          line-height: 22px; /* 160% */
      .counts
        text-align center
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        *
          color: var(--Blue-100, #1C75FF);
        h4
          font-size: 14px;
          font-weight: 700;
          line-height: 22px;
          margin-bottom 8px
        .value
          font-size: 48px;
          font-weight: 700;
          line-height: 90%; /* 120px */
          font-family: "Bebas Neue Pro";
          letter-spacing normal;
      > p
        display: flex;
        margin 0
        padding: 0px 188px;
        justify-content: flex-end;
        align-items: center;
        align-self: stretch;
        color: var(--Blue-100, #1C75FF);
        text-align: right;
        font-size: 14px;
        font-weight: 700;
        line-height: 22px; /* 157.143% */
    .creators
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      height 367px
      overflow hidden
      ul
        margin 0
        padding 0
        list-style none
        display: flex;
        align-items: center;
        gap: 16px;
        img
          width 120px
        &:nth-child(2)
          margin-left -120px

  .section9
    display: flex;
    padding: 80px 0 47px;
    align-items: center;
    gap: 63px;
    overflow hidden
    flex-direction: column;
    justify-content: flex-end;
    .texts
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      color #1C75FF
      text-align center
      h3
        font-size: 28px;
        font-weight: 700;
        line-height: 36px; /* 125% */
      p
        font-size: 14px;
        font-weight: 500;
        line-height: 22px; /* 160% */
    .img
      width: 100%
      flex-shrink: 0;
      img
        width 100%

  .section10
    display: flex;
    width 100%
    overflow: hidden;
    .sign
      display: flex;
      padding: 22px;
      justify-content: center;
      align-items: flex-start;
      gap: 66px;
      background: var(--Blue-10, #EBF3FE);
      img
        height 56px


  .section11
    position relative
    overflow hidden
    display: flex;
    width: 100%;
    padding: 80px 0 40px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    flex: 1 0 0;
    background: var(--Black, #141414);
    .top
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width 100%
      gap: 40px;
      color: var(--White, #FFF);
      h3
        font-size: 28px;
        font-weight: 700;
        line-height: 36px; /* 125% */
      .texts
        position relative
        width 90%
        height 74px
        .text
          position absolute
          top 0
          left 0
          display flex
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          opacity 0
          transition opacity 0.2s ease-out
          &.selected
            opacity 1
          h4
            font-size 14px
            line-height 22px
            font-weight 700
          p
            font-size 14px
            line-height 22px
            font-weight 500

    .swiper
      position relative
      width calc(100% - 32px)
      flex-shrink: 0;
      border-radius: 32px;
      opacity 0
      padding-bottom 48px
      &.swiper-container-horizontal
        opacity: 1;
      .swiper-pagination
        text-align left
        left -4px
        bottom 0
      .swiper-pagination-bullet
      .swiper-pagination-bullet-active
        background #fff
      .swiper-slide
        position relative
        img
          width 100%
          border-radius 16px

    .buttons
      position absolute
      bottom 0
      right -192px
      width 88px
      display: flex;
      align-items: flex-start;
      gap: 8px;
      button
        display: flex;
        width: 40px;
        height: 40px;
        padding: 8px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 999px;
        background: var(--Bright-20, rgba(255, 255, 255, 0.20));

  .section12
    display: flex;
    padding: 203px 0px 203px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--Blue-100, #1C75FF);
    text-align center
    img
      width 100%
    h3
      color: var(--White, #FFF);
      font-size: 28px;
      font-weight: 700;
      line-height: 36px; /* 125% */
      margin 24px 0
    a
      border-radius: 8px;
      background: var(--Blue-10, #EBF3FE);
      display: inline-flex;
      min-width: 120px;
      padding: 9px 24px;
      align-items: center;
      text-align center
      gap: 4px;
      color: var(--Blue-100, #1C75FF);
      font-size: 14px;
      font-weight: 700;
      line-height: 22px; /* 150% */
