.pd-modal
  z-index 5
  > .don_wrapper > .body
    common()
    padding 10px 24px 24px
    word-break keep-all
    .don_wrapper, .don_page, .body, .don_tab
      height 100%

  &__content
    height 100%

  &-guide
    d-flex(space-between, flex-start)
    height 100%
    font-size 14px
    line-height 1.43

    &__content
      p, ul
        margin 0
      p + p, ul + p
        margin-top 20px
      p + ul
        margin-top 12px
      ul
        unordered-list()
    &__contact
      width 100%
      padding-top 12px
      border-top 1px solid #f8f8f8
    &__contact-item
      margin 4px 0 0
      font-size 13px
      span
        min-width 70px
        margin-right 15px
        font-size 13px
        font-weight 600
        line-height 1.54
        letter-spacing -0.2px
        color #828282

  &.size
    .pd-bp__section
        margin 0
