.don_tab[tab_name="/modules/MShop/App/Checkout/S/Mui/tab.js"]
  @import "../share.styl"
  @import "../order_info.styl"
  .Modal
    position fixed !important
    z-index 1000 !important
  .delivery-type-grouped-item
    &__1
      display none
      html[lang="kr"] &
        display flex
        align-items center
        padding-top pxToRem(12)
  .cart-store-item
    padding 0
    margin-bottom pxToRem(24px)
    &__1
      display flex
      align-items center
      border-bottom 1px solid $gray-700
      padding pxToRem(16) 0 pxToRem(12)
    &__2
      &__item
        border-bottom 1px solid $gray-200
        &:last-child
          border-bottom 0
    &__3
      padding-top pxToRem(24px)

  .pc
    display none !important
  .checkout-order-info
    &__1
      padding 0
    &__2
      padding pxToRem(18) pxToRem(24) pxToRem(20)
  .up-item-list-price-info
    &__3.delivery-fee-info
      display none
  .section-show
    width ptr(16)
    height ptr(16)
    &__arrow
      transform rotate(180deg)
      position relative
  .checkout__section-open
    .section-show__arrow
      transform rotate(0)
    .mps-checkout__section-body
    .right_bar__content
      display block
  .mps-checkout
    margin-top $default-header-mo_height
    &[data-has_inhouse_shippings="true"]
      .pre-shipping-order-item__price-set__label .mps-tooltip-icon
        display inline-block
    .pre-shipping-order-item__price-set__shipping-label .mps-tooltip-icon
      width ptr(16)
      height ptr(16)
    .pre-shipping-order-item
      &__1__item
        padding ptr(16) 0
      &__2
        color $black
        border-top 1px solid $gray-20
        padding ptr(12) 0
    &__left
      padding-bottom calc(env(safe-area-inset-bottom) + 116px)
      padding-bottom calc(constant(safe-area-inset-bottom) + 116px)
      html[safe-area="true"] &
        padding-bottom calc(env(safe-area-inset-bottom) + 150px)
        padding-bottom calc(constant(safe-area-inset-bottom) + 150px)
    &__temp-user-infobox
      margin 0 ptr(16) ptr(24)
      padding ptr(16)
      display flex
      align-items center
      word-break break-all
      column-gap ptr(6)
      background-color $blue-10
      border-radius 8px
      &__info-icon
        color $blue-100
        width ptr(16)
        height ptr(16)
        align-items flex-start
      &__text
        color $blue-100
    &__section
      position relative
      margin-bottom 0 !important
      border-bottom pxToRem(8) solid $gray-5
    .agreement
      border-bottom 0
    &__section-header
      margin 0 !important
      padding pxToRem(24) pxToRem(16)
      display flex
      justify-content space-between
      align-items center
      color $black
    &__section-body
      display none
      margin 0 pxToRem(16) pxToRem(24) !important
    &__input-wrap
    &__input-wrap2
      display flex
      flex-direction column
      html[lang="kr"] &
        position relative
        margin-bottom pxToRem(24)
      &:last-child
        margin-bottom 0
      label
        color $black
        margin-bottom ptr(8)
      input
        &::placeholder
          color $gray-50
        &:disabled
          background $white
      input
      select
      span.value
        border none
        border-radius 0
        border-bottom 1px solid $gray-10
        padding ptr(9) 0
        width 100%
        display flex
        box-sizing border-box
        outline transparent
        -webkit-appearance none
        -moz-appearance none
        appearance none
        color $black
      select
        -webkit-appearance none
        -moz-appearance none
        background url("//s3.marpple.co/files/u_2308668/2024/5/original/9a4f0ff62fb8f886bf53270255bf03d2e15479f51.svg") no-repeat
        background-position right pxToRem(8) top pxToRem(12)
        background-size pxToRem(16) pxToRem(16)
        padding ptr(9) ptr(8) ptr(9) 0
        color $gray-50
        &.active
          color $black
      .email_text
        .value
          background-color $white
          html[lang="kr"] &
            background-color $white
        .btn
          position absolute
          top ptr(24)
          right ptr(44)
          border 0 none
          background transparent
          color $gray-50
          html[lang="kr"] &
            top 0
            right 0
      .my_point:not(:empty)
        display block
        color $blue-100
        margin-top pxToRem(8)
      .promotion_description:not(:empty)
        display block
        color $gray-50
        margin-top pxToRem(8)
    &__msg
      color $gray-50
      margin-top pxToRem(8)
    &__optional
      margin-left ptr(8)
      color $gray-50
    &__input-wrapper
      display flex
      button
        flex-shrink 0
        min-width ptr(120)
        margin-left ptr(8)
        &.active
          background-color $blue-100
          color $white
          cursor: pointer
          &:hover
            background-color $blue-10
            color $blue
    &__check-box
      color $gray-700
      border-radius 0
      display flex
      align-items center
      gap pxToRem(10)
      margin-bottom pxToRem(24)
      .mshop-core__checkbox__title
        color $black
      .mshop-core__checkbox__box-wrapper
        align-self flex-start
        margin-top ptr(3)
      .mshop-core__checkbox__sub-title
        color $gray-80
        margin-top pxToRem(4)
      &--agree
      &--en-jp
        {$font-body-b3-m}
        color $gray-700
        align-items flex-start
        margin-bottom 0
      &--required
        color $black
        flex-shrink 0
        margin-right pxToRem(4)
        font-weight bold
      .mp_input
        display flex
      label.check
        display flex
        align-items center
        input[type="checkbox"] + span
          margin 0 pxToRem(8) 0 0
          width pxToRem(18)
          height pxToRem(18)
          border 1px solid $gray-300
          border-radius 0
          &:after
            width pxToRem(16)
            height pxToRem(16)
            top 0
            left 0
            background $gray-800 url(//s3.marpple.co/files/u_14345/2021/5/original/21cb1a210300cd68d9d95a6d5bac1b369856afc51.svg) no-repeat 0 0
            background-size pxToRem(16) pxToRem(16)
    &__check-all
      align-items flex-start !important
      .mp_input
        padding-top pxToRem(3)
    &__payment-grid
      display grid
      grid-template-columns 1fr 1fr
      grid-gap pxToRem(10) pxToRem(7)
    &__payment-type
      border-radius pxToRem(8)
      height ptr(52)
      border 1px solid $gray-20
      color $gray-80
      background transparent
      position relative
      display flex
      align-items center
      justify-content center
      &:hover
        border 1px solid $blue
        color $blue
      &:active
        background-color $blue-100
        border-color $blue-100
        color $white
        .mps-checkout__payment-icon
          display none
          &--active
            display flex
    &__payment-icon
      width pxToRem(20)
      height pxToRem(20)
      margin-right pxToRem(8)
      &--active
        display none
    &__payment-info
      {$kr-12-b}
      position absolute
      top 0
      left 50%
      white-space nowrap
      transform translate(-50%,-50%)
      padding pxToRem(3.5) pxToRem(8)
      border-radius pxToRem(24)
    .payment_types
      .payment_type
        display none
      &[type="card"] > .card
      &[type="without_bank"] > .without_bank
      &[type="trans"] > .trans
      &[type="paypal"] > .paypal
      &[type="alipay"] > .alipay
        display block
      &[type="card"] button[payment_type="card"]
      &[type="without_bank"] button[payment_type="without_bank"]
      &[type="trans"] button[payment_type="trans"]
      &[type="offline_card"] button[payment_type="offline_card"]
      &[type="offline_cash"] button[payment_type="offline_cash"]
      &[type="samsung"] button[payment_type="samsung"]
      &[type="payco"] button[payment_type="payco"]
      &[type="naverpay"] button[payment_type="naverpay"]
      &[type="escrow"] button[payment_type="escrow"]
      &[type="ssgpay"] button[payment_type="ssgpay"]
      &[type="lpay"] button[payment_type="lpay"]
      &[type="paypal"] button[payment_type="paypal"]
      &[type="alipay"] button[payment_type="alipay"]
      &[type="vbank"] button[payment_type="vbank"]
      &[type="tosspay"] button[payment_type="tosspay"]
      &[type="kakaopay"] button[payment_type="kakaopay"]
      &[type="PAYMENTWALL_CREDIT_CARD"] button[payment_type="PAYMENTWALL_CREDIT_CARD"]
      &[type="PAYMENTWALL_KONBINI"] button[payment_type="PAYMENTWALL_KONBINI"]
      &[type="KOMOJU_PAY_EASY"] button[payment_type="KOMOJU_PAY_EASY"]
      &[type="KOMOJU_KONBINI_DAILY_YAMAZAKI"] button[payment_type="KOMOJU_KONBINI_DAILY_YAMAZAKI"]
      &[type="KOMOJU_KONBINI_LAWSON"] button[payment_type="KOMOJU_KONBINI_LAWSON"]
      &[type="KOMOJU_KONBINI_FAMILY_MART"] button[payment_type="KOMOJU_KONBINI_FAMILY_MART"]
      &[type="KOMOJU_KONBINI_MINISTOP"] button[payment_type="KOMOJU_KONBINI_MINISTOP"]
      &[type="KOMOJU_KONBINI_SEICOMART"] button[payment_type="KOMOJU_KONBINI_SEICOMART"]
        background-color $blue-100
        border-color $blue-100
        color $white
        .mps-checkout__payment-icon
          display none
          &--active
            display flex
      &[type="naverpay"] .naverpay-info
        display flex
    &__input-mb-8
      margin-bottom pxToRem(8)
    &__input-mb-24
      margin-bottom pxToRem(24)
    &__input-memo2
      margin-top pxToRem(8)

    &__inhouse
      &--hide
        display none

    &__promotion-code-apply
      &:after
        content 'APPLY'
        html[lang="kr"] &
          content '쿠폰 적용'
        html[lang="jp"] &
          content '適用'
      &[applied="true"]
        background $gray-800
        color #fff
        &:after
          content 'CANCEL'
          html[lang="kr"] &
            content '쿠폰 취소'
    &__agree-list
      display flex
      flex-direction column
      gap 12px
      margin-bottom 24px
      {$font-body-b3-sb}
      color $gray-600
    &__check-agree-list
      display flex
      flex-direction column
      gap pxToRem(16px)
    &__agree-description
      display none
    &__agree-flex
      display flex
      justify-content space-between
      align-items center
      color $black
      &--al-s
        align-items flex-start
    &__agree-arrow
      background url("//s3.marpple.co/files/u_29089/2023/9/original/1d1b7ee16c40a0cd35d1a4945286c7c6598189ad1.svg") no-repeat
      background-size pxToRem(15) pxToRem(15)
      width pxToRem(15)
      height pxToRem(15)
    &__agree-view
      {$font-body-b3-r}
      color $gray-600
      text-decoration underline
    &__agree-toggle
      &--open
        .mps-checkout__agree-description
          display block
          margin-top pxToRem(8)
          word-break keep-all
          {$font-body-b5-r}
          color $gray-600
        .mps-checkout__agree-arrow
          transform rotate(180deg)
    &__submit-wrap
      position fixed
      bottom 0
      left 0
      right 0
      background-color $white
      padding pxToRem(16px)
      border-top 1px solid $gray-5
      z-index 10
      display flex
      &:after
        display block
        content ''
        padding-bottom env(safe-area-inset-bottom)
        padding-bottom constant(safe-area-inset-bottom)
      html[safe-area="true"] &
        padding-bottom 34px
    &__submit-btn
      width 100%
      height pxToRem(52px)
      border-radius pxToRem(8px)
      padding 0
      &--disabled
        color $gray-50 !important
        background-color $gray-5 !important
    .naverpay-info
      display none
      {$kr-12-m}
      margin-top pxToRem(16)
      padding pxToRem(16)
      background-color $blue-10
      border-radius pxToRem(8)
      &__title
        flex-shrink 0
        color $blue-100
        margin-right pxToRem(20)
      &__description
        color $gray-80
    /*--------------- EN JP ---------------------*/
    select.dialing_code,
    select.country
      color $black
    select.state
      color $black
    &__shipping-company-item
      display flex
      align-items center
      &:first-child
        margin-right pxToRem(16)
    &__shipping-company-img
      position relative
      height pxToRem(24)
      margin-right pxToRem(6)
    &__shipping-company-infos
      table
        border-spacing 0 ptr(24)
      th
        width pxToRem(60)
        padding-right ptr(24)
        vertical-align top
        text-align left
    &__payment-type-info
      {$font-body-b1-r}
      ul
        padding-left pxToRem(18)
        margin-bottom 0
    &__agree-en
      {$font-body-b2-sb}
    &__agree-underline
      text-decoration underline
    .bar_wrapper
      html[lang="en"] &
      html[lang="jp"] &
        .shipping_company_info
          display none
          &.oversea
            display block
          select.express,
          select.express_service
            color $black
          select.express.disabled,
          select.express_service.disabled
            cursor default
            color $gray-50
            background-color #F5F5F5
            pointer-events none
            background-image url("//s3.marpple.co/files/u_2308668/2024/5/original/995524efc0c078c813058ba64e263d767cca12e81.svg")

    /*-------------------------------------*/

    .inhouse-checkout-shipping
      &__select-type
        overflow hidden
        display flex
        border 1px solid #ddd
        border-radius pxToRem(8)
        margin-bottom pxToRem(16)
      &__type-btn
        border none
        padding pxToRem(16) pxToRem(12)
        margin 0
        background #fff
        flex 1
        text-align center
        &--selected
          background #000
          *
            color #fff !important
      &__type-btn-title
        display flex
        align-items center
        justify-content center
        {$font-body-b3-sb}
        color $gray-600
        img
          width pxToRem(16)
          margin-right pxToRem(4)
      &__type-btn-description
        display none
      &__method
        padding pxToRem(20)
        background #f8f8f8
        border-radius pxToRem(8)
        color #4e4e4e
        margin-bottom pxToRem(24)
      &__method-title
        {$font-body-b3-sb}
        margin-bottom pxToRem(6)
      &__method-ul
        list-style none
        margin 0
        padding 0
        li
          {$font-body-b3-r}
          display flex
          gap pxToRem(4)
          div:first-child
            flex-shrink 0
    &__check-agree
      html[lang="en"] &,
      html[lang="jp"] &
        padding ptr(24) ptr(16)
    .accordion
      display flex
      flex-direction column
      align-self stretch
      background-color $blue-10
      border-radius 8px
      margin-top 16px
      padding 16px
      &.expand
        .accordion-content
          margin-top 8px
          max-height 1000px
        .accordion-header
          button.toggle-btn
            &::after
              content 'Close'
              html[lang='jp'] &
                content '簡潔'
      &[is_show="false"]
        display none
      .accordion-header
        display flex
        justify-content space-between
        color $blue-100
        .accordion-title
          display flex
          gap 8px
          font-size 14px
          font-weight 700
          line-height 140%
          svg
            min-width 16px
        button.toggle-btn
          color $blue-100
          width 52px
          text-align right
          background none
          border none
          text-decoration underline
          cursor pointer
          font-weight 500
          &::after
            content 'View'
            html[lang='jp'] &
              content '詳細'
      .accordion-content
        max-height 0
        overflow hidden
        transition all 0.1s ease-in-out
        .accordion-description
          margin-top 0
          color $blue-100
          font-size 12px
          font-weight 500
          line-height 140%
  .right_bar
    border-bottom pxToRem(8) solid $gray-5
    &__header
      padding ptr(24) ptr(16)
      display flex
      justify-content space-between
      align-items center
    &__title
      color $black
    &__content
      display none
      margin 0 pxToRem(16)
    &__btns
      display flex
      align-items center
      gap pxToRem(12)
    &__product-count
      color $gray-50
.don_page[page_name="mp.checkout.change_email"]
  background-color #fff
  .don_wrapper >.page_body
    padding 0 pxToRem(24)
    .header_title
      display none

    .email_text
      padding pxToRem(14) pxToRem(24) pxToRem(16)
      border-radius pxToRem(4)
      border solid pxToRem(1) #dddddd
      width calc(100% - pxToRem(34))
      font-size pxToRem(13)
      line-height 1.38
      letter-spacing pxToRem(-0.2)
      margin-bottom pxToRem(6)
      {$font-body-b1-b}
    .title
      margin-top pxToRem(24)
      font-size pxToRem(16)
      margin-bottom pxToRem(10)
      {$font-body-b1-sb}
    .current_data
      .title
        margin-top 0

    .notice
      {$font-body-b4-r}
      color $gray-500
    .new_email
      margin-bottom ptr(24)

    .inputs
      margin-bottom pxToRem(24)
      input
      .input
        border none
        border-radius 0
        border-bottom 1px solid $gray-10
        padding ptr(9) 0
        width 100%
        display flex
        box-sizing border-box
        outline transparent
        -webkit-appearance none
        -moz-appearance none
        appearance none
        color $black
    .submit_btn_wrap
      position fixed
      bottom 0
      left 0
      right 0
      background-color $white
      padding pxToRem(16px)
      border-top 1px solid $gray-5
      z-index 10
      display flex
      &:after
        display block
        content ''
        padding-bottom env(safe-area-inset-bottom)
        padding-bottom constant(safe-area-inset-bottom)
      .submit_btn
        width 100%
        height pxToRem(52px)
        border-radius pxToRem(8px)
        background-color $blue-100
        color $white
        display flex
        align-items center
        justify-content center
        {$kr-16-b}
.mps-checkout__input-wrap.has_not_email
  .fx-auto-complete-list
    right 0
    top pxToRem(70)
    ul.fx-auto-complete-list-wrap
      background #fff
      border 1px solid #e7e7e7
    li.auto-domain
      padding pxToRem(9)
      &[selected="true"]
        background-color #ebf3fe !important
        color #1c75ff !important
  &.has_not_email--en-jp
    .fx-auto-complete-list
      top pxToRem(48)
