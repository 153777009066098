.{a-p-i}
  &__tags
    display flex
    margin-bottom 4px
  &__rank
    display inline-block
    margin-bottom pxToRem(8px)
    padding pxToRem(1px) pxToRem(4px) 0 pxToRem(6px)
    width pxToRem(32px)
    text-align center
    border solid 1px #4e4e4e
    border-radius 16px
    box-sizing border-box
    span
      font-size pxToRem(13px)
      font-weight bold
      line-height 1
  &__top-info
    position relative
  &__bottom-info
    position relative
    height 108px
    margin-top pxToRem(14px)
  &__badge
    position absolute
    top pxToRem(8px)
    left pxToRem(8px)
    display flex
    padding 3px 8px
    {$font-body-b5-r}
    color $white
    border-radius 4px
    &--coming
      background-color $red-700
    &--sell_close
      background-color $sky-800
    &--sell_at
      background-color $sky-800
    &--real
      background-color $orange-800
    &--limit
      background-color $sky-800
    &--nft
      background-image $purple-800
    &--product-detail
      z-index 1
  &__p-name
    line-height 1.27
    letter-spacing -0.2px
    color #4e4e4e
    margin-bottom 2px
    font-size 15px
    text-overflow ellipsis
    white-space nowrap
    overflow hidden
  /* none / block 경계 */
  &__store-name
    display none
    height 18px
    font-weight 600
    line-height 1.38
    letter-spacing -0.2px
    color #111111
    margin-bottom 2px
    font-size 13px
    text-overflow ellipsis
    white-space nowrap
    overflow hidden
  &__category-name
    display none
    line-height 1.29
    letter-spacing -0.2px
    color #a2a2a2
    text-overflow ellipsis
    white-space nowrap
    overflow hidden
  &__like
    reset-button()
    display none
    border 0
    width 24px
    height 24px
    position absolute
    top 8px
    right 8px
  &__price
    {$font-body-b2-m}
    color $gray-800
  &__review-info
    margin-top 4px
    {$font-body-b4-m}
    color $gray-500
    column-gap 8px
    display flex
    &--hidden
      display none
  &__review-number
    {$font-body-en-b3-r}
    color $gray-500
  &__bottom-badge
    margin-top 12px
    padding pxToRem(4px) pxToRem(16px)
    height pxToRem(26px)
    border-radius 999px
    display inline-flex
    align-items center
    box-sizing border-box
    {$font-body-b5-r}
    &--hidden
      display none
    &--black
      background-color $gray-200
      color $gray-700
    &--blue
      background-color $sky-200
      color $sky-800

