.today-feed
  position relative
  margin-bottom 40px
  [data-show="false"]
    display none

  &__thumb_container
    width 100%
    font-size 0
    position relative
    display flex

  &__thumbnail
    width 100%

  &__category
    position absolute
    left 0
    writing-mode vertical-rl
    text-orientation sideways
    transform rotate(180deg) translateX(100%) translateX(pxToRem(10px))
    font-size pxToRem(11px)
    font-weight 500
    line-height 1.27
    letter-spacing normal
    text-align center
    color #a2a2a2
    height 100%

  &__content
    width 100%
    height 100%
    box-sizing border-box
    padding pxToRem(16px) 0 0 // 22 / 2 * 1.3 (폰트 절반)
    color black

  &__content_title
    font-size pxToRem(22px)
    font-weight 600
    line-height 1.3
    letter-spacing -0.3px
    position relative

  &__content_title_txt
    font-size pxToRem(20px)
    font-weight 700
    line-height 1.3
    letter-spac ing -0.2px
    color #111
    margin 0
    limitLine(1)

  &__content_title_first
    position absolute
    transform translateY(-100%)
    top 0
    left 0
  &__content_title_second
    position relative

  &__content_description
    font-size pxToRem(14px)
    font-weight 400
    line-height 1.29
    letter-spacing -0.2px
    color #111
    margin 0
    margin-top pxToRem(8px)
    limitLine(1)

  &__content_products
    display flex
    margin-top 24px
    gap 8px

  &__content_product
    background-color #f6f6f6
    display flex
    flex 1 1 0
    color #4e4e4e
    justify-content center
    align-items center
    font-size pxToRem(16px)
    font-weight 600
    &:last-child
      margin-right 0

.today-list-item
  display flex
  justify-content flex-start
  align-items flex-start
  margin-bottom 8px

  &__thumb
    width 34.8%

  &__content
    padding pxToRem(5px) pxToRem(24px) 0 pxToRem(12px)
    overflow hidden

  &__content_category
    margin 0
    font-size pxToRem(11px)
    font-weight 500
    line-height 1.64
    letter-spacing -0.2px
    color #a2a2a2

  &__content_title
    margin 0
    margin-top 2px
    font-size pxToRem(16px)
    font-weight 600
    line-height 1.31
    letter-spacing -0.2px
    color #111
    overflow hidden
    text-overflow ellipsis
    white-space nowrap

  &__content_description
    margin 0
    margin-top 3px
    font-size pxToRem(12px)
    font-style normal
    line-height 1.29
    letter-spacing -0.2px
    color #111
    overflow hidden
    text-overflow ellipsis
    white-space nowrap
