
.don_frame[frame_name="/modules/NewMaker/ConditionTooltipMo/S/Mui/frame.js"]
  >.don_wrapper >.header
    border-radius 13px 13px 0 0
    padding 28px
    display flex
    justify-content  space-between
    align-items center
    box-sizing border-box
    background #fff
    .don_hide_frame
      top 15px !important
    .title
      font-size 22px
      font-weight 700
.condition_tooltip_wrapper
  position relative
  padding 20px 16px 30px 16px
  border-radius 20px 20px 0px 0px
  z-index 1;
  background-color #fff

  & .condition_tooltip_header
    margin-bottom 24px

    &_title
      color var(--BK, var(--kakao-logo, #000))
      font-family Pretendard
      font-size 20px
      font-style normal
      font-weight 500
      line-height 140%

  & .condition_tooltip_body
    width 100%
    height 292px
    border-radius 12px
    background var(--BG-PRODUCT, #F6F6F6)
    position relative
    margin-bottom 24px

    &_text
      color var(--GY, #858585)
      font-family Pretendard
      font-size 14px
      font-style normal
      font-weight 500
      line-height 140%
      position absolute
      top 16px
      left 16px

    & img
      width 100%
      height 100%
      object-fit contain
      background #fff

    & svg
      position absolute
      top 76px
      left 48px

  & .condition_tooltip_footer
    display flex
    justify-content center
    align-items center
    gap 8px


    & .condition_tooltip_btn
      flex 1 0 0
      height 42px
      padding 12px 16px
      border-radius 999px
      border 1px solid var(--BK, #000)
      background var(--WHT, #FFF)
      font-size 14px
      font-style normal
      font-weight 500
      font-family Pretendard
      line-height 100%
      text-align center

      &.complete
        background var(--BK, #000)
        color var(--WHT, #FFF)

      &.disabled
        border 1px solid var(--GY-50, #D8D8D8)
        border-radius 999px
        background var(--GY-50, #D8D8D8)
        color var(--WHT, #FFF)


  & .condition_agree_text_checkbox_wrapper
    margin-bottom 32px

  & .condition_agree_text_wrapper
    border-bottom 1px solid #E8E8E8
    margin-bottom 16px

    & p
      margin 8px 0

      &:last-child
        margin-bottom 16px

    & .text_wrapper
      display flex
      justify-content space-between

    & .warning_text
      color var(--BK, var(--kakao-logo, #000))
      font-family Pretendard
      font-size 14px
      font-style normal
      font-weight 700
      line-height 100%

    & .view_more
      color var(--OG, #FF6B00)
      font-family Pretendard
      font-size 14px
      font-style normal
      font-weight 500
      line-height 100%
      cursor pointer


.condition_tooltip
  display none
  padding 10px 12px
  justify-content center
  align-items center
  gap 4px
  flex-shrink 0
  border-radius 999px
  background var(--OG, #FF6B00)
  position absolute
  bottom 100px
  left 50%
  transform translateX(-50%)
  width 200px
  html[lang="en"] &
    width 215px
  html[lang="jp"] &
    width 230px
  cursor pointer

  & .arrow
    position absolute
    top -8px
    left 50%
    transform translateX(-50%)


  &_text
    color var(--WHT, #FFF)
    text-align right
    font-family Pretendard
    font-size 12px
    font-style normal
    font-weight 500
    line-height 100%
