.app-home-popular-product
  box-sizing border-box
  &__list
    position relative
  &__list-wrapper
    display flex
    flex-wrap nowrap
    justify-content flex-start
    align-items flex-start
    padding 0 pxToRem(24)
    column-gap pxToRem(9)
    scroll-snap-type x mandatory
    scroll-padding-left pxToRem(24)
    overflow-x auto
    &::-webkit-scrollbar
      display: none;
    > div
      scroll-snap-align start
  &__items
    display flex
    flex-direction column
    gap pxToRem(9)
    width pxToRem(152)
    flex-shrink 0
  &__item
    width pxToRem(152)
    height 100%
    border-radius pxToRem(16px)
    overflow hidden
    flex-shrink 0
    img
      width 100%
