.BrandCommercePage
  &.editing
    display none
  h3
  h4
  p
    padding 0
    margin 0
  ul
  li
    margin 0
    padding 0
  *
    box-sizing border-box
    /* KR-48/Bold */
    font-family: Pretendard;
    font-style: normal;
    letter-spacing: pxToRem(-0.2px);
    word-break keep-all

  /* cta button */
  .request
    //transition .3s ease-in-out
    position: fixed;
    bottom: calc(16px + env(safe-area-inset-bottom));
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;

  .section
    position relative
    width 100%
    overflow hidden


  .section1
    position relative
    width: 100%;
    height 100svh
    .bg
      position absolute
      top 0
      left 0
      width 100%
      height 100%
      z-index 1
      display flex
      justify-content center
      img
      video
        width 100%;
        object-position center
        object-fit cover
      &:after
        content ''
        position absolute
        top 0
        left 0
        width: 100%
        height: 100%
        background: linear-gradient(180deg, rgba(28, 117, 255, 0) 42%, rgba(28, 117, 255, 0.9) 70%);

    .texts
      z-index 2
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;
      position absolute
      bottom 88px
      margin 0 auto
      width 100%
      left 0
      right 0
      text-align center
      padding-bottom env(safe-area-inset-bottom);

      *
        color: var(--White, #FFF);
      h3
        font-weight: 700;
        font-size: 28px;
        line-height: 36px;
      p
        font-size: 14px;
        font-weight 500
        line-height: 22px; /* 140% */
    .buttons
      z-index 2
      position absolute
      bottom 50px
      margin 0 auto
      left 0
      right 0
      text-align center
      button
        display: inline-flex;
        min-width: 120px;
        padding: 9px 24px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 8px;
        background: var(--Blue-10, #EBF3FE);
        color: #1C75FF
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        line-height: 22px; /* 150% */
  .section2
    display: flex;
    padding: 60px 0px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: var(--Blue-10, #EBF3FE);
    .texts
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: pxToRem(24px);
      align-self: stretch;
      color #1C75FF
      text-align center
      h3
        font-size: 28px;
        font-weight: 700;
        line-height: 36px; /* 128.571% */
    .cases
      position relative
      display: flex;
      padding: 0px 32px;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      padding-bottom 59px;
      margin-top 60px
      opacity 0
      &.swiper-container-horizontal
        opacity: 1;
      &.swiper-container-horizontal>.swiper-pagination-progressbar {
        position: absolute;
        left: 32px;
        right: 32px;
        width: auto;
        top auto;
        bottom: 0;
        height: 2px;
        background: var(--White, #FFF);
      }
      .swiper-pagination-progressbar-fill {
        background: var(--Blue-100, #1C75FF);
      }
      .case
        width 100%
        border-radius: pxToRem(24px);
        background: var(--White, #FFF);
        img
          border-radius: pxToRem(24px) pxToRem(24px) 0 0;
          width: 100%
        .info
          display: flex;
          padding: 16px;
          flex-direction: column;
          align-items: flex-start;
          gap: 24px;
          align-self: stretch;
          .head
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
            align-self: stretch;
            color #1C75FF
            h4
              font-size: 20px;
              font-weight: 700;
              line-height: 28px; /* 140% */
            p
              font-size: 12px;
              font-weight: 500;
              line-height: 17px; /* 141.667% */
              display: flex;
              align-items: flex-start;
              gap: 8px;
          ul
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
            align-self: stretch;
            margin 0
            padding 0
            list-style none
            li
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              align-self: stretch;
              color #1C75FF
              h5
                font-size: 14px;
                font-weight: 500;
                line-height: 22px; /* 157.143% */
                margin 0
                padding 0
              .num
                display flex
                font-family: "Bebas Neue Pro";
                font-size: 24px;
                font-weight: 700;
                line-height: 24px; /* 100% */
                text-transform: uppercase;
                span.unit
                  margin-left 4px
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 22px; /* 157.143% */
  .section3
    display: flex;
    height: 812px;
    padding: 60px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    .texts
      display: flex;
      padding: 0px 32px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 16px;
      div
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 4px;
        align-self: stretch;
      h3
        font-size: 28px;
        font-weight: 700;
        line-height: 36px; /* 125% */
      em
        display block
        font-size: 14px;
        font-weight: 500;
        line-height: 22px; /* 125% */
        color #A2A2A2
      p
        font-size: 14px;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
    .imgs
      display: flex;
      width: 100%;
      padding 0 32px
      box-sizing border-box;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom 32px
      position relative
      .swiper-slide
        width 100%
        max-width 375px
        text-align center
      img
        width calc(100% - 64px)
        &:first-child
          margin-right 32px
      .swiper-pagination
        bottom 0
      .swiper-pagination-bullet
        background: var(--Dim-10, rgba(20, 20, 20, 0.10));
        opacity 1
      .swiper-pagination-bullet.swiper-pagination-bullet-active
        background #1C75FF

  .section4
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding 60px 0
    gap: 60px;
    flex-shrink: 0;
    .bg
      position absolute
      top 0
      left 0
      width 100%
      height 100%
      z-index 1
      display flex
      justify-content center
      img
      video
        height 100%;
        object-position center
        object-fit cover
      &:after
        content ''
        position absolute
        top 0
        left 0
        width 100%
        height 100%
        background: rgba(20, 20, 20, 0.70);
        /* Blur 60 */
        backdrop-filter: blur(30px);
        -webkit-backdrop-filter: blur(30px)

    .texts
      position relative
      z-index 2
      display: flex;
      padding: 0px 32px;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      text-align center
      color: var(--White, #FFF);
      h3
        font-size: 28px;
        font-weight: 700;
        line-height: 36px; /* 128.571% */
      p
        font-size: 14px;
        font-weight: 500;
        line-height: 22px; /* 157.143% */

    .details
      position relative
      padding-bottom 57px
      display: flex;
      align-items: flex-start;
      align-self: stretch;
      &.swiper-container-horizontal
        opacity: 1;
      &.swiper-container-horizontal>.swiper-pagination-progressbar {
        position: absolute;
        left: 32px;
        right: 32px;
        width: auto;
        top auto;
        bottom: 0;
        height: 2px;
        background: rgba(255, 255, 255, 0.2);
      }
      .swiper-pagination-progressbar-fill {
        background: var(--White, #FFF);
      }
      .detail
        display: flex;
        width 260px
        flex-direction: column;
        align-items: center;
        gap: 16px;
        color: var(--White, #FFF);
        text-align center
        margin-left 32px
        &:last-child
          margin-right 32px
        img
          height: 200px;
          flex-shrink: 0;
        h4
          font-size: 20px;
          font-weight: 700;
          line-height: 28px; /* 140% */
        p
          font-size: 14px;
          font-weight: 500;
          line-height: 22px; /* 157.143% */
  .section5
    .left
      display: flex;
      height: 660px;
      padding: 60px 32px 40px 32px;
      flex-direction: column;
      align-items: flex-start;
      justify-content space-between
      flex-shrink: 0;
      align-self: stretch;
      background: var(--Blue-10, #EBF3FE);
      .texts
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;
        color: #1C75FF;
        p
          font-size: 16px;
          font-weight: 700;
          line-height: 24px; /* 150% */
        h3
          font-size: 28px;
          font-weight: 700;
          line-height: 36px; /* 128.571% */
      .details
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        .detail
          display: flex;
          padding-bottom: 12px;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          align-self: stretch;
          color: var(--Blue-100, #1C75FF);
          border-bottom: pxToRem(1px) solid var(--Blue-100, #1C75FF);
          h4
            font-size: 14px;
            font-weight: 700;
            line-height: 22px; /* 157.143% */
          p
            font-size: 14px;
            font-weight: 500;
            line-height: 22px; /* 157.143% */
    .right
      width 100%
      video
        width 100%

  .section6
    display: flex;
    height: 812px;
    padding: 60px 0px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .texts
      display: flex;
      padding: 0px 32px;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      color: var(--Blue-100, #1C75FF);
      text-align center
      .head
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        p
          font-size: 16px;
          font-weight: 700;
          line-height: 24px; /* 150% */
          text-align center
        h3
          font-size: 28px;
          font-weight: 700;
          line-height: 36px; /* 128.571% */
      > p
        font-size: 14px;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
    .texts2
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      align-self: stretch;
      h4
        padding: 0px 32px;
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px; /* 150% */
        color: var(--Blue-100, #1C75FF);
      > div
        width 100%
        overflow hidden
        overflow-x scroll
        padding-left 32px
        -ms-overflow-style: none;
        scrollbar-width: none;
      > div::-webkit-scrollbar {
        display none;
      }
      ol
        margin 0
        list-style none
        display: flex;
        padding: 0px
        align-items: flex-start;
        gap: 32px;
        width: (228px + 32px) * 3;
        li
          color: var(--Blue-100, #1C75FF);
          display: flex;
          width: 228px;
          align-items: flex-start;
          font-size: 14px;
          font-weight: 700;
          line-height: 22px;
          gap 8px
          b
            font-weight: 700;
    .img
      margin 40px 0
      position relative
      width calc(100% - 64px)
      max-width 311px
      img:first-child
        border-radius 16px
        width 100%
        height 100%
      img.bg
        position absolute
        top -22px
        right -16px
        width: 117px;
        height: 117px;


  .section7
    display: flex;
    height: pxToRem(812px);
    flex-direction: column;
    align-items: center;
    background: var(--Blue-100, #1C75FF);
    padding-top pxToRem(60px)
    h3
      color: var(--Blue-10, #EBF3FE);
      text-align: center;
      font-family: Pretendard;
      font-size: pxToRem(40px);
      font-style: normal;
      font-weight: 700;
      line-height: 116%;
      letter-spacing: -0.2px;
      margin-bottom pxToRem(20);
      a
        color: var(--Blue-10, #EBF3FE);
        text-decoration underline
        text-underline-offset 2px
        text-decoration-thickness 4px

    h4
      color: var(--Blue-10, #EBF3FE);
      text-align: center;

      /* KR-24/Bold */
      font-family: Pretendard;
      font-size: pxToRem(24px);
      font-weight: 700;
      line-height: pxToRem(34px); /* 141.667% */
      letter-spacing: -0.2px;
      margin-bottom: pxToRem(8px); /* 141.667% */

    h5
      color: var(--White, #FFF);
      text-align: center;

      /* KR-16/Regular */
      font-family: Pretendard;
      font-size: pxToRem(16px);
      font-weight: 400;
      line-height: pxToRem(24px); /* 150% */
      letter-spacing: -0.2px;
      margin-bottom: pxToRem(80px); /* 141.667% */

    .details
      position relative
      width 100%;
      height: pxToRem(200px);
      padding: 0 pxToRem(32px);

      .detail,
      .swiper-slide
        padding-top pxToRem(16px)
        display flex;
        width: pxToRem(160px);
        height: pxToRem(200px);
        justify-content flex-start;
        align-items flex-start;
        flex-direction column;
        border-top: pxToRem(1px) solid var(--White, #FFF);
        border-bottom: pxToRem(1px) solid var(--White, #FFF);

        .step
          color: var(--Blue-10, #EBF3FE);
          text-align: center;

          /* EN-32/Bebas-Bold */
          font-family: "Bebas Neue Pro";
          font-size: pxToRem(24px);
          font-style: normal;
          font-weight: 700;
          line-height: pxToRem(24px); /* 100% */
          text-transform: uppercase;
          margin-bottom pxToRem(8px);

        .title
          color: var(--Blue-10, #EBF3FE);

          /* KR-28/Landing Bold 36 */
          font-family: Pretendard;
          font-size: pxToRem(20px);
          font-style: normal;
          font-weight: 700;
          line-height: pxToRem(28px); /* 128.571% */
          letter-spacing: pxToRem(-0.2px);
          margin-bottom pxToRem(16px);

        .content
          color: var(--Blue-10, #EBF3FE);

          /* KR-20/Medium */
          font-family: Pretendard;
          font-size: pxToRem(16px);
          font-style: normal;
          font-weight: 500;
          line-height: pxToRem(24px); /* 140% */
          letter-spacing: pxToRem(-0.2px);

      &.swiper-container-horizontal
        opacity: 1;
      &.swiper-container-horizontal>.swiper-pagination-progressbar
        position: absolute;
        left: pxToRem(32px);
        width: calc(100% - 64px);
        top: pxToRem(232px);
        height: pxToRem(2px);
        background: var(--Bright-20, rgba(255, 255, 255, 0.20));

      .swiper-pagination-progressbar-fill
        background: var(--White, #FFF);

  .section8
    display: flex;
    height: 812px;
    padding-top: 60px;
    flex-direction: column;
    align-items: center;
    gap: 60px;
    background: var(--Purple-100, #7A44A0);
    .texts
      display: flex;
      padding: 0px 32px;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      color: var(--Pink-10, #FDF0FE);
      text-align center
      p.txt1
        font-size: 28px;
        font-weight: 700;
        line-height: 36px; /* 150% */
      p.txt2
        display: flex;
        padding: 0px 0px 8px 16px;
        align-items: center;
        gap: 12px;
        span
          font-size: 100px;
          font-weight: 700;
          line-height: 90%; /* 90px */
          letter-spacing: -1px;
          text-align: center;
          font-family: "Bebas Neue Pro";
      p.txt3
        font-size: 14px;
        font-weight: 500;
        line-height: 22px; /* 157.143% */

    .creators
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      flex: 1 0 0;
      align-self: stretch;
      ul
        margin 0
        padding 0
        list-style none
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        img
          width 128px


  .section9
    display: flex;
    height: 812px;
    padding: 60px 0;
    flex-direction: column;
    align-items: center;
    background: var(--Brown-100, #704C00);
    .texts
      display: flex;
      padding: 0 20px;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      color: var(--Brown-10, #FFF9E2)
      text-align center
      margin-bottom 40px
      p.txt1
        font-size: 28px;
        font-weight: 700;
        line-height: 36px; /* 150% */
      p.txt2
        display: flex;
        padding: 0px 0px 8px 16px;
        align-items: center;
        justify-content center;
        gap: 12px;
        strong
          color: var(--Brown-10, #FFF9E2);
          text-align: center;
          font-family: "Bebas Neue Pro";
          font-size: 100px;
          font-style: normal;
          font-weight: 700;
          line-height: 90%; /* 90px */
          letter-spacing: -1px;
        span
          color: var(--Brown-10, #FFF9E2);
          text-align: center;
          font-family: "Bebas Neue Pro";
          font-size: 72px;
          font-style: normal;
          font-weight: 700;
          line-height: 90%; /* 64.8px */
          letter-spacing: -0.72px;
      p.txt3
        font-size: 14px;
        font-weight: 500;
        line-height: 22px; /* 157.143% */

    .brands
      margin-top 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      flex: 1 0 0;
      align-self: stretch;
      ul
        margin 0
        padding 0
        list-style none
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        img
          width 128px
          border-radius: 16px;


  .section10
    display: flex;
    height: 812px;
    padding: 60px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
    background: var(--Green-100, #009444);
    > .texts
      display: flex;
      padding: 0px 32px;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      color #FFF9E2
      p.txt1
        font-size: 16px;
        font-weight: 700;
        line-height: 24px; /* 150% */
      h3
        font-size: 28px;
        font-weight: 700;
        line-height: 36px; /* 128.571% */
      p.txt2
        font-size: 14px;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
        text-align center

    .details
      position relative
      display: flex;
      padding: 0px 32px;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      width 100%;
      .detail,
      .swiper-slide
        display: flex;
        width: 288px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 16px;
        img
          width 100%
        .texts
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 8px;
          align-self: stretch;
          color: var(--Brown-10, #FFF9E2);
          text-align center
          h4
            font-size: 16px;
            font-weight: 700;
            line-height: 24px; /* 150% */
          p
            font-size: 14px;
            font-weight: 500;
            line-height: 22px; /* 157.143% */
      &.swiper-container-horizontal
        opacity: 1;
      &.swiper-container-horizontal>.swiper-pagination-progressbar
        position: absolute;
        left: pxToRem(32px);
        width: calc(100% - 64px);
        top: pxToRem(358px);
        height: pxToRem(2px);
        background: var(--Bright-20, rgba(255, 255, 255, 0.20));

      .swiper-pagination-progressbar-fill
        background: var(--White, #FFF);

  .section11
    display: flex;
    padding: 60px 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
    h3
      color: var(--Black, #141414);
      font-size: 28px;
      font-weight: 700;
      line-height: 36px; /* 128.571% */
      text-align center

    .faqs
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      .faq
        display: flex;
        width 100%
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        background: var(--Blue-10, #EBF3FE);
        border-radius: 12px;
        color #1C75FF
        transition background-color 0.3s ease-out
        .heading
          display: flex;
          padding: 16px;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px; /* 150% */
          cursor pointer
          .status
            width: 20px;
            height: 20px;
          .opened
            display none
          .closed
            display block
        .body
          display flex
          box-sizing content-box
          padding: 0px 16px;
          flex-direction: column;
          justify-content: start;
          align-items: flex-start;
          gap: 16px;
          align-self: stretch;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px; /* 157.143% */
          height 0px
          overflow hidden
          transition height 0.3s ease-out, padding-bottom 0.3s ease-out
          ul
            padding-left 16px
          ol
            margin 0
            padding 0
            padding-left 16px
          ol.abc
            list-style lower-alpha;
        &.is_open
          background: var(--Blue-100, #1C75FF);
          color #fff
          .heading
            .opened
              display block
            .closed
              display none
          .body
            padding-bottom 16px

  .section12
    display: flex;
    padding: 203.014px 0.001px 203.014px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--Blue-100, #1C75FF);
    text-align center
    img
      width 100%
    h3
      color: var(--White, #FFF);
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px; /* 128.571% */
      margin-bottom 24px;
    h4
      color: var(--White, #FFF);
      text-align: center;

      /* KR-20/Bold */
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px; /* 140% */
      letter-spacing: -0.2px;
      margin-bottom 12px;

    button
      display: flex;
      min-width: 120px;
      padding: 9px 24px;
      margin 0 auto
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 8px;
      color #1C75FF
      background: var(--Blue-10, #EBF3FE);
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px; /* 157.143% */

.BrandCommerceRequestEditor
  position relative
  z-index 4
  background url(//s3.marpple.co/files/u_14345/2024/5/original/cc4bc01d1a4f8efb683bd6b92893eee1a0f9379e1.png) no-repeat;
  background-size cover;
  background-position center;
  height 100vh
  min-height 874px

  input
    appearance auto
  .bg
    background: rgba(20, 20, 20, 0.60);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px); /* Add this line first, it fixes blur for Safari*/
    height 100%
  *
    box-sizing border-box
    /* KR-16/Medium */
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 150% */
    letter-spacing: -0.2px;
  .close
    display: flex;
    height: 48px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    padding-top 8px
    margin 0 0 24px
    button
      border 0 none
      display: flex;
      width: 40px;
      height: 40px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 999px;
      background: var(--Bright-5, rgba(255, 255, 255, 0.05));
      svg
        flex-shrink: 0;
  .body
    flex-shrink: 0;
    height calc(100% - 72px)
    display: flex;
    flex-direction: column;
    justify-content: space-between;

  .form
    margin 0 auto
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    padding 0 32px
    gap: 24px;
    align-self: stretch;
    > .header
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      .text1
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        span
          color: var(--Blue-100, #1C75FF);
          /* KR-16/Medium */
          font-size: 14px;
          font-weight: 500;
          line-height: 22px; /* 150% */
        h2
          margin 0
          padding 0
          color: var(--White, #FFF);
          font-size: 28px;
          font-weight: 700;
          line-height: 36px; /* 125% */
      .text2
        color: #FFF;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px; /* 160% */

    > .inputs
      width: 100%; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 12px; display: flex;
      *
        color: #A2A2A2;
      .input-wrap2
        width: 100%; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 12px; display: flex;
      .marppleshop-url-display.hide
        display none !important
      .input-wrap
        padding-top: 12px;
        padding-bottom: 12px;
        border-bottom: 1px #A2A2A2 solid;
        justify-content: flex-start;
        align-items: flex-start;
        display: flex;
        width 100%
        position relative
        input[type="text"],
        input[type="email"]
          width 100%
          word-wrap: break-word
          background transparent
          border 0 none
          color #fff

        label
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          pointer-events: none;
          transition: 0.2s ease all;
          display none

          .asterisk
            content: ' *';
            color: var(--Red-80, #D92A0F);

            /* KR-14/Medium */
            font-family: Pretendard;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; /* 157.143% */
            letter-spacing: -0.2px;

        input:placeholder-shown + label
          display block

        textarea
          width 100%
          height 80px
          background transparent
          border 0 none
        textarea::placeholder {
          color: #A2A2A2;
        }
        input
        textarea
          outline none
        button
          border-radius 999px
          border 0 none
          background: var(--Gray-5, #F5F5F5);
          display: flex;
          width: 24px;
          height: 24px;
          padding: 6.4px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          flex-shrink: 0;
      > .input-wrap:nth-child(2)
        gap: 16px;
        input[type="text"]
          width 186px
          margin-right -16px;

      .info
        height 24px;
        color: var(--Gray-50, #A2A2A2);
        /* KR-12/Medium */
        font-size: 12px;
        font-weight: 500;
        line-height: 17px;
    .footer
      display flex
      justify-content space-between
      flex-direction column

      .agree
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;

        .agree_checkbox
          display flex
          align-items center

        .agree_checkbox input[type="checkbox"]
          display: none;

        .agree_checkbox svg
          width: pxToRem(16);
          height: pxToRem(16);
          margin-right: pxToRem(4);
          cursor pointer;

        .agree_checkbox .checked
          display: none;

        .agree_checkbox input[type="checkbox"]:checked ~ .unchecked
          display: none;

        .agree_checkbox input[type="checkbox"]:checked ~ .checked
          display: inline;

        label
          color: var(--White, #FFF);
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        p
          margin 0
          padding 0
          color: var(--White, #FFF);
          /* KR-12/Medium */
          font-size: 12px;
          font-weight: 500;
          line-height: 17px;
          b
            font-weight 700




  button.submit
    z-index 5
    left 16px
    bottom 50px
    position fixed;
    width calc(100% - 32px)
    height 50px;
    border 0 none
    display: flex;
    padding: pxToRem(16px) pxToRem(24px);
    align-items: center;
    justify-content center
    align-self flex-end
    gap: pxToRem(8px);
    border-radius: pxToRem(12px);
    background: var(--Gray-5, #F5F5F5);
    color: var(--Gray-50, #A2A2A2);
    text-align: center;
    /* KR-16/Bold */
    font-size: pxToRem(16px);
    font-weight: 700;
    line-height: pxToRem(24px); /* 150% */
    transition color 0.2s ease-in-out, background-color 0.2s ease-in-out
    pointer-events none
    span
      display block
      width 100%
      height 100%
      padding-bottom env(safe-area-inset-bottom)
      padding-bottom constant(safe-area-inset-bottom)
    html[not-safe-area="true"] &
      padding-bottom 0
    html.safe_area_inset_bottom &
      padding-bottom 34px
    &.active
      color: var(--White, #FFF);
      background: var(--Blue-100, #1C75FF);
      pointer-events auto

//.BrandCommerceRequestEditorButton
//  z-index 5
//  position fixed
//  bottom 0
//  left 0
//  right 0
//  border 0 none
//  display: flex;
//  padding: 16px 24px;
//  align-items: center;
//  justify-content: center;
//  gap: 8px;
//  background: var(--Blue-10, #EBF3FE);
//  color: var(--Blue-100, #1C75FF);
//  text-align: center;
//  /* KR-16/Bold */
//  font-size: 16px;
//  font-weight: 700;
//  line-height: 24px;
//  span
//    display block
//    width 100%
//    height 100%
//    padding-bottom env(safe-area-inset-bottom)
//    padding-bottom constant(safe-area-inset-bottom)
//    html[not-safe-area="true"] &
//      padding-bottom 0
//    html.safe_area_inset_bottom &
//      padding-bottom 34px

html.brand-commerce .don_small_loader_wrap, .don_loader_wrap
  background: rgba(255, 255, 255, 0.5);
