.cch-delivery-type-logo
  > *
    display none
    align-items center
  img
    width pxToRem(20px)
    height pxToRem(20px)
    margin-right pxToRem(4px)
  &__text
    order -1
    margin-right ptr(4)
  &[data-delivery_type_id="MARPPLE"]
    .cch-delivery-type-logo__marpple
      display flex
      align-items center
  &[data-delivery_type_id="SELLER"]
    .cch-delivery-type-logo__seller
      display flex
      align-items center
  &[data-delivery_type_id="DIGITAL"]
    .cch-delivery-type-logo__digital
      display flex
      align-items center
  &[data-delivery_type_id="FAST_MARPPLE"]
    .cch-delivery-type-logo__fast-marpple
      display flex
      align-items center
.cch-store-logo
  display flex
  align-items center
  padding-bottom ptr(8)
  border-bottom 1px solid $black
  img
    width pxToRem(20px)
    height pxToRem(20px)
  span
    order -1
    margin-right pxToRem(4px)
    color $black
