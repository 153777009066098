.don_frame[frame_name="/modules/MShop/App/CustomerOrder/S/Mui/frame.js"]
  .pc
    display none
  @import "../share.styl"
  .up-item__thumbnail-wrapper
    width pxToRem(100px) !important
    height pxToRem(100px) !important
    border-radius pxToRem(16px) !important
    overflow hidden
    img
      width pxToRem(100px) !important
      height pxToRem(100px) !important
  .customer-order-to-orders
    button
      font-size pxToRem(16px)
  .customer-order-footer-info
    &.hide
      .customer-order-footer-info
        &__body
          display none
        &__header__close
          transform rotate(0)
    &__header
      padding-bottom ptr(12)
      border-bottom 1px solid $black
      &__right
        display flex
        align-items center
      &__close
        display none
      &__title
        color $black
      &__revise-button,
      &__receipt-info-button
        background-color $blue-10
        color $blue-100
        padding ptr(4) ptr(8)
        border-radius ptr(4)
    &__body
      &__row
        padding-top pxToRem(12px)
        padding-bottom pxToRem(12px)
        align-items center
        display grid
        grid-template-columns ptr(80) auto
        grid-column-gap ptr(16)
      &__label
        color $gray-50
      &__content
        color $black
        &.mp_currency
          color $black
          &:after
            {$kr-14-m}
        &.mp_currency.bold
          &:after
            {$kr-14-b}
        &.mp_currency.total-order-price
          &:after
            {$kr-20-b}
        &--total-qty
          color $black
          &[data-lang="kr"]
            &:after
              content '개'
              {$kr-14-m}

  .up-item
    margin-bottom pxToRem(20px)
    border-bottom 1px solid $gray-200
    padding-bottom pxToRem(6px)
    &:last-child
      border none
      padding-bottom 0
    &-product-info
      display flex
      flex-direction column
      row-gap ptr(4)
      > div:first-child
        align-items start
      &__up_c_id
        marginPaddingZero()
        color $gray-80
      &__name
        marginPaddingZero()
        color $black
        display flex
        &-value
          marginPaddingZero()
          flex 1
          white-space inherit
          word-break break-all
      &__second-name
        marginPaddingZero()
        color $gray-50
        white-space unset
        text-overflow unset
      &__price
        marginPaddingZero()
        color $black
        &:after
          marginPaddingZero()
          {$kr-14-b}
    .up-item-buttons
      &__button
        box-sizing border-box
        height pxToRem(40px)
        border 1px solid $gray-20
        color $black
      &__review
        background-color $blue-10
        border none
        color $blue-100


    &__order-process-step-wrapper
      margin-top pxToRem(12px)
      margin-bottom pxToRem(12px)
      display flex
      align-items center
      &__order-process-step
        color $blue-100
        background-color $blue-10
        overflow hidden
        white-space nowrap
        text-overflow ellipsis
        padding ptr(1.5) ptr(4)
        border-radius ptr(4)
        backdrop-filter blur(5px)
        -webkit-backdrop-filter blur(5px)
      &__delivery-date
        color $gray-2
        font-weight 500
        > span:first-child
          color $blue-1
        &:before
          content: '|'
          padding-left pxToRem(8px)
          padding-right pxToRem(6px)
          color $gray-s2
      &__pickup
        margin-left pxToRem(8px)
      &__tracking
        display none
    &__product-wrapper
      box-sizing border-box
      width 100%
      display grid
      grid-gap pxToRem(16px)
      grid-template-columns pxToRem(100px) auto
      margin-top ptr(16)
      margin-bottom ptr(12)
    &__buttons
      box-sizing border-box
      width 100%
      >*:last-child
        margin-bottom pxToRem(15px)
    &__thumbnail-wrapper
      display unset
  .customer-order
    margin-top $default-header-mo_height
    &__order-at-wrapper
      display flex
      align-items center
      padding pxToRem(16px)
      width 100%
      background-color $gray-5
    &-created-at
      &__title
        color $gray-80
      &__at
        color $black
    &__main-body
      padding 0 ptr(16)
      padding-top ptr(24)
      background #fff
      display flex
      flex-direction column
      &__header
        display none
      &__store-orders-wrapper
        display flex
        flex-direction column
        row-gap ptr(32)
    &__footer-wrapper
      &:last-child
        margin-bottom ptr(60)
      padding 0 ptr(16)
      margin-top ptr(48)
      background-color $white
  .store-order
    &__name
      color $black
      .store-logo
        padding-right pxToRem(24px)
        background url(//s3.marpple.co/files/u_2308668/2024/3/original/663bbe879648233b6f88e88e33fd983b827fb7f81.png)
        background-repeat no-repeat
        background-position right
        background-size pxToRem(20px) pxToRem(20px)
    &__header
      box-sizing border-box
      padding-bottom ptr(8)
      display flex
      align-items center
      border-bottom 1px solid $black
  .shipping-order
    &__sid
      color $gray-3
      display flex
      align-items center
      justify-content space-between
    &__name
      color $black
    &__header
      box-sizing border-box
      display flex
      justify-content space-between
      width 100%
      margin-top ptr(12)
      margin-bottom ptr(8)
    &__amount-btn
      padding ptr(4) ptr(8)
      background-color $blue-10
      color $blue-100
      border none
      border-radius ptr(4)
      box-sizing border-box
  .shipping-order-price
    display flex
    justify-content space-between
    width 100%
    &__left
      color $black
    &__right
      color $black

  /*
필정 새로운 class
*/
  .up-item-options
    margin-bottom pxToRem(12px)

