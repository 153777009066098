.mshop-additional-pay
  padding pxToRem(24)
  &__payment-types
    display grid
    grid-template-columns 1fr 1fr 1fr
    grid-gap pxToRem(8)
    margin-bottom pxToRem(24)
  &__payment-type
    background #ffffff
    box-sizing border-box
    outline none
    border-radius pxToRem(14)
    padding pxToRem(12) pxToRem(4)
    border solid 1px #dddddd
    font-size pxToRem(12)
    line-height 1.38
    letter-spacing -0.2px
    text-align center
    color #111111
    &--selected
      color #ffffff
      background #111111
  &__submit
    width 100%
    border none
    background-color #111
    color #ffffff
    padding pxToRem(18) 0
    border-radius pxToRem(8)
    font-size pxToRem(16)
    font-weight 600
    display flex
    justify-content center
    align-items center
