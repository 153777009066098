// modules/MShop/App/CustomerOrderReceipt/F/Style/Mo/index.styl
.receipt-frame
  >.don_wrapper
    >.header
      padding 0 !important
    >.body
      >.don_page
        >.don_wrapper >.header
          padding pxToRem(18)
          .title
            height pxToRem(24)
            display flex
            align-items center
            justify-content center
      .don_hide_frame
        background-color: transparent;
        background-image: url(//s3.marpple.co/files/u_2308668/2024/5/original/b0e1825623dc11e768f7a7417b38ebfcf6f208201.png);
        background-size: ptr(24) ptr(24)
        position: absolute
        top: ptr(18)
        right: ptr(16)
        width: ptr(24)
        height: ptr(24)


.receipt-customer-order
  padding ptr(16)
  &__header
    padding ptr(16) ptr(24)
    background-color $gray-5
    display flex
    align-items center
    justify-content space-between
    margin-bottom pxToRem(24)
    border-radius ptr(8)
  &__paid-at
    &__title
      color $gray-80
    &__date
      color $black
      margin-left ptr(12)
      margin-right auto
  &__id
    {$font-en-b-14--100}
    display flex
    column-gap pxToRem(8)
    span
      {$font-kr-m-14--100}
      position relative
      top pxToRem(-1)
  &__purchase
    padding pxToRem(4) pxToRem(8)
    margin 0
    border 1px solid $gray-20
    background-color $white
    color $gray-80

.receipt-shipping-order-group__shipping-orders
  border-top 1px solid $ness-gray-5
  margin-top pxToRem(12)
  &:first-child
    border-top-color transparent
    margin-top 0
.receipt-store-order
  &__header
    border-bottom 1px solid $black
    padding-bottom pxToRem(12)
    margin-bottom ptr(16)
    display flex
    column-gap pxToRem(4)
  &__logo
    order 2
    {$image-store-logo}
    width pxToRem(20)
    height pxToRem(20)
    margin-top ptr(2)
    flex-shrink 0
  &__name
    order 1
    color $black
.receipt-shipping-order
  display flex
  flex-direction column
  &__content
    display flex
    align-items center
    justify-content space-between
  &__item
    margin-bottom ptr(24)
  &__content
    display flex
    align-items center
    justify-content space-between
  &__name
    color $black
  .seller-delivery-type-logo
    display flex
    align-items center
    &:after
      content ""
      {$image-seller-delivery-logo}
      margin-left pxToRem(4px)
    &:before
      display none
  .marpple-delivery-type-logo
  .fast_marpple-delivery-type-logo
    display flex
    align-items center
    &:after
      content ""
      {$image-marpple-delivery-logo}
      margin-left pxToRem(4px)
    &:before
      display none
  .digital-delivery-type-logo
    display flex
    align-items center
    &:after
      content ""
      {$image-digital-delivery-logo}
      margin-left pxToRem(4px)
    &:before
      display none
  &__shipping-price-info
    display flex
    align-items center
    column-gap pxToRem(4)
  &__price
    color $black
    &:after
      {$kr-14-m}

.receipt-up-c
  &__content
    display flex
    align-items center
    column-gap pxToRem(16)
  &__thumbnail
    background-color $gray-5
    display flex
    overflow hidden
    border-radius ptr(4)
    img
      width pxToRem(72px) !important
      height pxToRem(72px) !important
      border-radius pxToRem(4px)
      object-fit contain
  &__canvas-wrapper
    display flex
    canvas
      width pxToRem(72px) !important
      height pxToRem(72px) !important
      border-radius pxToRem(4px)
  &__right
    display flex
    flex-direction column
    row-gap pxToRem(4)
  &__id
    color $gray-80
  &__product-name
    color $black
  &__product-price
    color $black
    &:after
      {$kr-20-b}
.receipt-page-button
  width 100%
  margin-top pxToRem(16)
  padding pxToRem(8) pxToRem(24)
  border 1px solid $gray-20
  border-radius pxToRem(8)
  background-color $white
  height ptr(40)
  color $gray-80

.purchase-receipt-customer-order
  padding pxToRem(16)
  padding-bottom calc(env(safe-area-inset-bottom) + 112px)
  padding-bottom calc(constant(safe-area-inset-bottom) + 112px)
  &__title
    display none
  &__info
    display flex
    flex-direction column
    row-gap pxToRem(16)
    margin-bottom pxToRem(24)
    border-bottom 1px solid $gray-5
    padding-bottom pxToRem(24)
    &:last-child
      border-bottom 0
      margin-bottom 0
  &__info-title
    color $black
  &__info-left
    color $gray-50
    flex-shrink 0
    min-width ptr(120)
  &__info-right
    color $black
    max-width 65%
  &__row
    display flex
    justify-content space-between
    color $black
    column-gap ptr(16)
    .mp_currency
      &:after
        {$kr-16-m}
    &--red
      color $red-80
  &__result-price
    color $gray-80
    &.mp_currency
      color $black
      &:after
        {$kr-20-b}

  &__buttons
    position fixed
    bottom 0
    left 0
    right 0
    background-color $white
    padding pxToRem(16px)
    border-top 1px solid $gray-5
    z-index 10
    display flex
    column-gap pxToRem(16px)
    button
      width 100%
      height pxToRem(56px)
      border-radius pxToRem(8px)
  &__pdf
    background-color $gray-5
    border none
    color $gray-50
    pointer-events none
  &__print
    background-color $blue-100
    border none
    color $white
  &__cancel
    box-sizing border-box
    display flex
    align-items center
    column-gap pxToRem(6)
    width 100%
    background-color $red-10
    color $red-80
    padding pxToRem(16)
    margin-bottom pxToRem(24)
    border-radius ptr(8)
/*
 영수증 프린트
*/
@media print
  body
    overflow-y hidden !important
  .don_frame
    display none !important
    &.receipt-frame
      display block !important
      >.don_wrapper
        max-width 100% !important
        max-height 100% !important
        width 100% !important
        height 100% !important
        >.body
          overflow hidden !important
      .header
      .purchase-receipt-customer-order__buttons
        display none !important
      .purchase-receipt-customer-order
        padding 0 !important
        transform scale(0.8) !important
        &__cancel
          display none

