.{a-p-i}--today
  position relative
  text-align left
  margin-right 7px
  width calc((100% - 7px) / 2)
  margin-bottom 30px
  &:nth-child(2n)
    margin-right 0

  .{a-p-i}
    &__badge
      display none

.{a-p-i}--today--one
  width 100%
  margin-right 0