$apfs=app-product-filters;
$apf=app-product-filter;

.{$apfs}
  padding-bottom env(safe-area-inset-bottom)
  padding-bottom constant(safe-area-inset-bottom)
  html[not-safe-area="true"] &
    padding-bottom 0
  html.safe_area_inset_bottom &
    padding-bottom 34px
  &__frame
    z-index 5

.{$apf}
  margin-bottom 18px
  &__wrapper
    padding 0 24px 60px
  &__name
    font-size 16px
    line-height 1.25
    letter-spacing -0.2px
    color #111111
    margin-bottom 10px
  &__list
    display flex
    flex-wrap wrap
    margin 0 -2px
  &__item-btn
    padding 5px 12px
    border-radius 17px
    border solid 1px #dddddd
    background #fff
    line-height 1.29
    letter-spacing -0.2px
    color #4e4e4e
    margin 0 2px 6px
    &--selected
      color #1C75FF
      border solid 1px #1C75FF
  &__ok-btn
    background-color #f8f8f8
    font-size 18px
    font-weight 600
    line-height 1.33
    letter-spacing -0.2px
    color #111111
    width 100%
    box-sizing content-box
    border 0
    padding 0
    margin 0
    height 59px
    position fixed
    bottom 0
    left 0
    padding-bottom env(safe-area-inset-bottom)
    padding-bottom constant(safe-area-inset-bottom)
    html[not-safe-area="true"] &
      padding-bottom 0
    html.safe_area_inset_bottom &
      padding-bottom 34px
    &--selected
      background-color #111111
      color #fff !important
