.token-gate-connect-sign
  padding 24px 16px
  font-size 18px
  &__notice
    font-size 18px
    font-weight bold
    margin-bottom 32px
    word-break keep-all
  &__info
    display flex
    align-items center
    margin-bottom 12px
    .title
      width 100px
    .value
      a
        text-decoration underline
        color #002aff
        display none
  &__product
    margin-bottom 24px
  &__last
    word-break keep-all
