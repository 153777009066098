.app-mypage-recent
  margin-top $default-header-mo_height
  &__lists-seller
    padding-top ptr(24)
    padding-bottom ptr(60)
    .CreatorCard
      margin-bottom ptr(16)
.no-recent-result
  display none
  &[data-len="0"]
    width 100%
    flexCenter(column)
    padding ptr(160) 0
    margin-bottom ptr(120)
  &__heart
    margin-bottom ptr(24px)
  &__heart-img
    width ptr(120)
  &__title
    color $gray-80
    align-items center
    margin-bottom ptr(8)
  &__desc
    text-align center
    color $gray-50
    margin-bottom ptr(24)
  &__button
    width ptr(196)