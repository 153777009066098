@import '../share.styl';

.tracking-item-order.don_frame[is_modal="false"]
  & > .don_wrapper
    & > .header
      z-index 10
      & > .title
        position absolute
        top 18px
        left 50%
        transform translateX(-50%)
        font-size pxToRem(16px) !important
        font-weight 700 !important
        line-height pxToRem(24px) !important

  .shipping-info-modal__summary
    padding pxToRem(24px) 0
  .shipping-info-modal__up-item
    padding pxToRem(16px)

  .timeline
    padding 0 pxToRem(16px)
    &.open
      padding-bottom pxToRem(16px)
    &__expander
      height ptr(40)
    &__card
      margin 0 0 pxToRem(16px) pxToRem(24px)

  .other
    padding pxToRem(16px)

  @media screen and (max-width 300px)
    .other__buttons
      &__company-number
      &__worker-number
        font-size pxToRem(10px)

    .shipping-info-modal__summary
      &__title
        font-size pxToRem(18px)
      &__desc
        font-size pxToRem(12px)

