.{a-p-i}--v5
  width 42%
  margin-bottom 62px
  margin-right 8px
  box-sizing border-box
  .{a-p-i}
    &__store-name
      display block
      color #111
      font-size 14px
      font-weight 600
      margin-bottom 6px
      letter-spacing -0.3px
    &__p-name
      font-size 14px
      color #4e4e4e
      letter-spacing -0.3px
      margin-bottom 9px
    &__price
      font-size 16px
      letter-spacing -0.5px
      font-weight 600
      color #111
    &__bottom-info
      margin-top 17px
    &__like
      display initial
  .mp-product-img-bg-wrap
    border-radius 8px
