.arrow-left-header
  display flex
  align-items center
  &__back-btn
    background-color transparent
    border 0
    padding 0
    font-size 0
    background-image url(//s3.marpple.co/files/u_29089/2021/4/original/bcce647f518324867afae2da33d8ee5f36a57e281.png)
    background-repeat no-repeat
    background-size 100%
    width 20px
    height 20px
    margin-right 4px
  &__title
    font-size 18px
    font-weight 600
    line-height 1.2
    letter-spacing -0.2px
    color #111111
    margin 0
    height 20px
.mshop-main-seller-header
  &__logo-text
    display block
    max-width 210px
  &__logo-img
    margin-top -4px
    display block
    height 28px
    max-width 210px
