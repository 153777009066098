html[lang="jp"]
  body.creator_home
    .ad_res
      display none


html[lang="en"]
  body.creator_home
    .ad_res
      display none

html.creator_home
  .ad_res
    width auto
    padding 0
    margin 0
    .w
      display none
    position fixed
    bottom 24px
    left 24px
    right 24px
    .back
      display none
    .cont
      width 100%
      max-width 1240px
      margin 0 auto
      z-index 1
      background-color #fff
      border-radius 21px
      > .body
        display none
      .head
        position relative
        height 42px
        overflow hidden
        box-sizing border-box
        cursor pointer
        .title
          font-size 16px
          font-weight 600
          line-height 1.25
          color #111
          text-align center
          letter-spacing -0.5px
          position absolute
          top 50%
          left 30px
          right 30px
          transform translateY(-50%)
          height 21px
        .btn
          display none


html.advance_reservation div.ad_res
  width 100%
  margin-top 64px
  padding 0 16px
  box-sizing border-box
  &:after
    content ''
    display block
    padding-bottom env(safe-area-inset-bottom)
    padding-bottom constant(safe-area-inset-bottom)
    html[not-safe-area="true"] &
      padding-bottom 0
    html.safe_area_inset_bottom &
      padding-bottom 34px
  input
    appearance auto;
  .form
    *
      box-sizing border-box
    .fieldset + .fieldset
      margin-top 50px
    legend
      display flex
      justify-content space-between
      align-items flex-end
      width 100%
      margin-bottom 20px
      p
        margin 0
        margin-right 15px
        font-size 20px
        font-weight 600
        line-height 1.3
        letter-spacing -0.3px
        color #111111
        word-break keep-all
      small
        font-size 12px
        font-weight normal
        line-height 1.33
        letter-spacing -0.2px
        text-align right
        color #111111
        white-space nowrap
        i
          color #ec1131
    .divider
      height 1px
      margin 10px 0
      background-color #f8f8f8
    &__item
      position relative
      + .form__item
        margin-top 20px
    &__label
      display block
      margin-bottom 8px
      font-size 14px
      font-weight 600
      line-height 1.29
      letter-spacing -0.2px
      color #111111
      &.required:after
        content ' *'
        color #ec1131
    &__control
      width 100%
      padding 13px 16px 11px
      font-size 13px
      color #111111
      line-height 1.38
      letter-spacing -0.2px
      border solid 1px #dddddd
      border-radius 4px
      &::placeholder
        color #a2a2a2
      &[type=textarea]
        min-height 140px
        padding-bottom 33px
    &__count
      position absolute
      bottom 12px
      right 16px
      font-size 12px
      line-height 1.33
      letter-spacing -0.2px
      text-align right
      color #a2a2a2
    &__desc
      margin 6px 0 0
      font-size 12px
      line-height 1.33
      letter-spacing -0.2px
      color #a2a2a2
    &__group
      width 100%
      display flex
      justify-content space-between
      align-items center
      select.form__control
        max-width 122px
        padding-right 40px
        margin-right 8px
        background #fff
        background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15'%3E%3Cg fill='none' fill-rule='evenodd' stroke-linecap='round'%3E%3Cg stroke='%23A2A2A2'%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cpath d='M9.657 2.657L9.657 10.657 1.657 10.657' transform='translate(-1325 -246) translate(932 88) translate(0 112) translate(0 30) rotate(90 196 212) rotate(-45 5.657 6.657)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A")
        background-repeat no-repeat
        background-position calc(100% - 16px) 50%
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
    &__file
      position relative
      display block
      margin-bottom 8px
      padding 12px 12px 10px
      font-size 13px
      line-height 1.38
      letter-spacing -0.2px
      text-align center
      color #4e4e4e
      border-radius 20px
      border solid 1px #dddddd
      overflow hidden
      input[type=file]
        position absolute
        top -10000px
        left -10000px
        width 0
        height 0
        opacity 0
    &__checks
      > div:not(.divider)
        display flex
        justify-content space-between
        align-items center
        + div:not(.divider)
          margin-top 10px
      label
        display flex
        align-items center
        font-size 14px
        line-height 1.29
        letter-spacing -0.2px
        color #111111
      a
        text-decoration underline
        cursor pointer
      input[type=checkbox]
        margin -3px 12px 0 0
      #all_check + span
        font-weight 600
    &__submit
      &-btn
        box-sizing content-box
        width 100%
        margin 0
        padding 0
        height 56px
        font-size 16px
        font-weight 600
        line-height 56px
        letter-spacing -0.2px
        text-align center
        color #fff
        background-color #111111
        border none
        padding-bottom env(safe-area-inset-bottom)
        padding-bottom constant(safe-area-inset-bottom)
        html[not-safe-area="true"] &
          padding-bottom 0
        html.safe_area_inset_bottom &
          padding-bottom 34px
        &.disabled
          color #111111
          background-color #f8f8f8
  .btn_privacy_pop
    cursor pointer
    text-decoration underline

  .noti
    margin 24px 0 40px
    padding 18px 20px
    border-radius 6px
    background-color #f8f8f8
    &__title
      margin 0 0 12px 0
      font-size 13px
      font-weight 600
      line-height 1.38
      letter-spacing -0.2px
      color #4e4e4e
      i
        margin-right 3px
    ul
      padding 0 0 0 24px
      margin 0
    li
      list-style-type '-    '
      font-size 13px
      line-height 1.38
      letter-spacing -0.2px
      color #4e4e4e
    li + li
      margin-top 8px

  .file_list
    margin 15px 0 30px
    padding 0 0 0 24px
    li
      list-style-type '-    '
      font-size 14px
      line-height 1.29
      letter-spacing -0.2px
      color #4e4e4e
    li + li
      margin-top 12px
    .btn_delete_file
      margin-left 10px
      padding 5px 10px
      color #fff
      font-size 12px
      font-weight 600
      border 0 none
      cursor pointer
      border-radius 5px
      background-color #000


.privacy_pop
  text-align left
  h2
    margin 0 0 10px
    padding 0
    font-size 16px !important
    font-weight 600
  p.txt1
    margin 0 0 10px !important
    padding 0
    font-size 13px !important
    color #000 !important
    font-weight 600
  ul
    margin 0
    padding 0
    list-style none
  li
    font-size 13px
  p.txt2
    font-size 13px !important


.hello-apply-seller
  position fixed
  bottom 24px
  left 24px
  right 24px
  .btn
    font-size 16px
    font-weight 600
    line-height 42px
    color #111
    text-align center
    letter-spacing -0.5px
    box-sizing border-box
    border-radius 21px
    height 42px
    background-color #fff
    a
      display block
      width 100%
      height 100%
