.app-mypage-like
  padding-top ptr(24)
  padding-bottom ptr(60)
  &__mui-page
    .tab_buttons
      margin-top $default-header-mo_height
      display flex
      align-items center
      justify-content center
      .tab_button
        reset-button()
        flexCenter()
        width 100%
        height ptr(40)
        text-align center
        box-sizing border-box
        column-gap ptr(4)
        {$kr-14-m}
        &[selected="false"]
          color $gray-50
          border-bottom 1px solid $gray-5
        &[selected="true"]
          color $black
          border-bottom 1px solid $black
        & > .count
          {$en-14-unica-m}

  &__lists-product
    margin 0 -3.5px
    & > .infi_wrapper > .infi_items > div
      display grid
      flex-wrap wrap
      grid-template-columns repeat(2, minmax(0, 1fr))
      gap pxToRem(16)
    .ProductCard
      min-width unset
    & > .infi_wrapper > .infi_items
      margin-bottom ptr(16)
  &__lists-seller
    & .CreatorCard
      margin-bottom ptr(16)


.no-like-result
  text-align center
  margin-top 56px
  &__heart
    margin-bottom 24px
  &__heart-img
    width 120px
  &__title
    margin-bottom ptr(8)
    color $gray-80
  &__title-desc
    margin-bottom ptr(24)
    color $gray-50
  &__more
    a
      display inline-flex
      justify-content center
      align-items center
      border-radius ptr(12)
      border none
      color $white
      background-color $blue-100
      height ptr(56)
      padding 0 ptr(24)
      min-width ptr(196)
