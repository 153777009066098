{$creator}-live
  padding 0 24px
  &__row ~ ^[1..-1]__row
    margin-top 40px
  &__link
    display block
  &__title
    margin 16px 0 12px
    font 23px 30px -.3px 600
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  &__description
    margin 0
    word-break break-word
    font 14px 18px -.2px
    color #4e4e4e
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  &__image
    width 100%
    vertical-align top
  &__iframe-box
    position relative
    width 100%
    padding-bottom 56.21%
    border-radius pxToRem(16px)
    overflow hidden
    -webkit-mask-image: -webkit-radial-gradient(white, black)
  &__iframe
    position absolute
    top 50%
    left 50%
    transform translateY(-50%) translateX(-50%)
    width calc(100% + 6px)
    height calc(100% + 6px)
    border-radius pxToRem(16px)
  &__products
    padding-top 0
  &__anchor
    flexCenter()
    box-sizing border-box
    margin-top 16px
    padding 10px
    width 100%
    min-height 40px
    border 1px solid #4e4e4e
    border-radius 20px
    font pxToRem(14px) pxToRem(18px) pxToRem(-0.2px)
    color #4e4e4e
  & .app-product-item--live
    & .app-product-item__bottom-info
      height unset

{$creator}-product
  display flex
  &__title
    margin 0
    font 13px 18px -.2px 600
    color #111
  &__description
    margin 0
    word-break break-word
    font 15px 19px -.2px
    color #4e4e4e
  &__price
    display block
    padding-top 12px
    font 15px 21px -.2px
    color #4e4e4e
  &__image
    width 100%
    vertical-align top
  &__column
    width 25.6%
    box-sizing border-box
    &:last-child
      padding-left 14px
      width 75.4%

{$app}-live
  margin 10px 0 100px
  &__section
    padding 0 24px
    & + .app-live__section
      margin-top 65px
    &.resent_sold.expanded
    &.top_sellers.expanded
      .app-live__more
        display none
  &__videos
    margin-top 65px
  &__title
    position relative
    &--left
      p
        margin 0 0 6px 0
        font-size pxToRem(16px)
        font-weight 600
        line-height 1.25
        color #a2a2a2
      h2
        margin 0 0 24px
        font-size pxToRem(23px)
        font-weight 600
        line-height 1.3
        letter-spacing -0.3px
    &--right
      position absolute
      top 0
      right 0
      font-size 13px
      line-height 1.38
      text-align right
      color #4e4e4e
      strong
        font-weight 600
        color #111
  &__period
    display block
    margin-bottom 11px
    span
      padding-right 40px
      background-image: url("//s3.marpple.co/files/u_29089/2021/5/original/99e339848ab8a6a98e4abd8b859a600a4963c5c81.png");
      background-repeat no-repeat
      background-size 20px 20px
      background-position 91% center
      border-bottom solid 2px #111
  &__more
    margin-top 16px
    display flex
    align-items center
    justify-content center
  &__more-btn
    height 30px
    font-size 13px
    line-height 1.38
    letter-spacing -0.2px
    color #a2a2a2
    width -webkit-fill-available
    border-radius 22px
    border solid 1px #dddddd
    background-color #ffffff
    box-sizing border-box
  &__tp-rest
    display flex
    flex-direction row
    flex-wrap wrap
    margin-left -7px
    > div
      width calc(50% - 7px)
      margin 24px 0 0 7px
    > div:nth-child(n+3)
      align-self flex-end
  &__ts
    > div:first-child
      flex 1
      display flex
      margin-bottom 25px
      .ts
        display flex
        align-items center
      .ts__thumb
        display block
        margin-right 20px
      .ts__name
        margin 7px 0
        word-break keep-all
        white-space normal
    > div:not(:first-child)
      flex 1
      display flex
      flex-direction column
      justify-content space-between
      .ts
        margin-bottom 15px
      .ts__info
      .ts__info > div
        display flex
        flex-direction row
        justify-content space-between
        align-items center
      .ts__info > div:first-child
        width 80%
  &__trs
    display flex
    flex-direction column
    > div
      flex 1
      margin-bottom 14px
  &__rsp
    display flex
    flex-direction row
    justify-content space-between
    align-items flex-start
    margin-left -9px
    .app-product-item--v2
      margin-bottom 0
    > div
      flex 1
      margin-left 9px

.swiper
  &__wrapper
    justify-content flex-start
    align-items center
  &__more
    text-align center
  &__more-btn
    display flex
    flex-direction column
    justify-content center
    align-items center
    word-break keep-all
    margin 0 auto
    font-size 13px
    width 100px
    height 100px
    background transparent
    border none
    outline none
    border-radius 50%
  &__icon
    display block
    margin-bottom 8px
    transform rotate(180deg)


.ts
  &__rank
    margin 0 12px 0 0
    padding-left 5px
    padding-right 5px
    min-width 28px
    text-align center
  &__thumb
    display none
    position relative
    width pxToRem(120px)
    min-width pxToRem(120px)
    margin-right 18px
  &__thumb-img
    position relative
    width 100%
    padding-top 100%
    background-size cover
    background-repeat no-repeat
    background-position center
    border-radius pxToRem(48px)
  &__sns
    position absolute
    bottom -3px
    right -3px
    width pxToRem(40px)
    height pxToRem(40px)
    padding pxToRem(3px)
    overflow hidden
    border-radius 50%
    background-color #fff
    img
      width 100%
      border-radius 50%
      vertical-align top
  &__name
    display block
    width 100%
    font-size 15px
    line-height 1.27
    white-space nowrap
    text-overflow ellipsis
    overflow hidden
  &__grown
    font-size 13px
    font-weight 600
    line-height 1.38
    &.new
    &.up
      color #ed2643
    &.up:before
      content "▲"
    &.down
      color #1C75FF
      &:before
        content "▼"


.trs
  display flex
  align-items center
  &__thumb
    position relative
    width pxToRem(120px)
    max-width 100%
    margin-right pxToRem(20px)
  &__thumb-img
    position relative
    width 100%
    padding-top 100%
    background-size cover
    background-repeat no-repeat
    background-position center
    border-radius pxToRem(40px)
  &__sns
    position absolute
    bottom -3px
    right -3px
    width pxToRem(40px)
    height pxToRem(40px)
    border pxToRem(3px) solid #fff
    border-radius 50%
    overflow hidden
    img
      width 100%
  &__rank
    margin-bottom pxToRem(7px) !important
  &__name
    margin-bottom pxToRem(10px)
    font-size pxToRem(15px)
    line-height 1.27
    color #111
  &__info
    display flex
    justify-content flex-start
    align-items center
    font-size 14px
    font-weight 600
    line-height 1.4
  &__star
    margin-right 3px
    img
      display inline-block
      margin-bottom -1px
      height 14px
      width 14px
  &__avg
    margin-right 8px

.app-live-dp
  padding 0
  .flatpickr-calendar
    margin-bottom 40px
    box-shadow none
    .flatpickr-day.week.selected
      box-shadow -5px 0 0 #1C75FF, 5px 0 0 #1C75FF
    .flatpickr-day.selected,
    .flatpickr-day.selected.inRange
      color #fff
      font-weight 600
      background #1C75FF
      border-color #1C75FF
    .flatpickr-day.inRange.flatpickr-disabled,
    .flatpickr-day.inRange.flatpickr-disabled:hover
      box-shadow none
      border none
      background transparent
    .flatpickr-current-month .flatpickr-monthDropdown-months
      -webkit-appearance unset
      appearance unset
      margin-right 10px
      line-height 1.33
    .flatpickr-day.today
      border none
    &.arrowTop:before
    &.arrowTop:after
      display none
  &__btn-ok
    background-color #111
    font-size 18px
    font-weight 600
    line-height 1.33
    color #fff
    padding 15px 0
    margin 0
    width 100%
    border 0

> #body .don_frame.date-picker
  position fixed
  display flex
  justify-content center
  align-items center
  background rgba(0,0,0,0.3)
  > .don_wrapper
    display inline-flex
    height auto
    > .header
      position static
      height auto
      padding 18px 15px
    > .body > .don_page
      min-height 0
      background #fff




