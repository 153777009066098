@keyframes file-upload {
  from {
    transform: translateY(50%);
    opacity: 0;
  }
  30% {
    transform: translateY(-10%)
    opacity: 0.3;
  }
  to {
    transform: translateY(0)
    opacity: 1;
  }
}
@keyframes file-upload-image {
  from {
    transform: translateY(50%);
    opacity: 0;
  }
  30% {
    transform: translateY(-4%)
    opacity: 0.3;
  }
  to {
    transform: translateY(0)
    opacity: 1;
  }
}

@keyframes rotate_image {
  100% {
    transform: rotate(360deg)
  }
}

.digital-product-creator-frame
  .w_br
  .m_br
  .mobile_br
    display block
  &__btn-close-frame
    position absolute
    top 9px
    right 9px
    width 36px
    height 36px
    border 0 none
    padding 0
    margin 0
    background-color transparent
    background-repeat no-repeat
    background-position center center
    background-size 18px
    background-image url("//s3.marpple.co/files/u_14345/2021/5/original/e6fc7c6c93a1091aa268db9f6fa0730c8da9793d1.svg")
  .don_back_page
    top 9px
  &__btn-back-page.hide
    visibility hidden
  &__title
    font-size 16px
    font-weight 500
    line-height 1.38
  >.don_wrapper
    >.header
      order -1
      display flex
      justify-content center
      align-items center
      border-bottom 1px solid $gray-5
      background-color white
      height 60px
      >.title
        font-size 16px
        font-weight 500
        line-height: 1.38
  .digital-product-category-selection
    display flex
    flex-direction column
    align-items center
    padding 30px 20px
    &__title
      display flex
      flex-direction column
      align-items center
      gap 8px
      margin-bottom 29px
      &__main
        font-size 20px
        font-weight 600
        letter-spacing -0.5px
      &__sub
        text-align center
        width 238px
        font-size 14px
        color $gray-s3
        line-height 1.57
        letter-spacing -0.5px
    &__categories
      display grid
      grid-row-gap 20px
      grid-column-gap 9px
      grid-template-columns repeat(2, 1fr)
      >.category
        display flex
        flex-direction column
        align-items center
        gap 12px
        >img
          width 163px
          height 163px
        >.category-name
          font-size 14px
          font-weight 500
          letter-spacing: -0.5px;

.digital-product-creator
  &__wrapper-for-background
    background-color #f8f9fa
    display flex
    flex-direction column
    padding-top 38px
    padding-bottom 40px
  &__head
    display block
    text-align center
  &__desc
    color #4c4c4c
    font-size 14px
    letter-spacing -0.5px
    font-weight 500
    line-height 1.38
    margin 0
  &__thumbnails-area
    margin-top 20px
    padding 0 26px
  &__form-buttons-container
    position fixed
    bottom 0
    left 0
    right 0
    padding 8px
    display grid
    grid-template-columns 1fr 1fr
    grid-gap 7px
    align-items center
    justify-content center
    background-color #fff
    border-top 1px solid #f2f2f2
  &__form-button-container
    position fixed
    bottom 0
    left 0
    right 0
    padding 8px
    display grid
    grid-template-columns 1fr
    grid-gap 7px
    align-items center
    justify-content center
    background-color #fff
    border-top 1px solid #f2f2f2
  &__btn-preview
    min-width 130px
    height 48px
    border-radius 2px
    border 1px solid $gray-s2
    background-color #fff
    box-sizing border-box
    font-size 15px
    font-weight 500
    letter-spacing -0.5px
    text-align center
    color $gray-2
    &--disabled
      color $gray-s4 !important
  &__btn-submit
    min-width 130px
    height 48px
    border-radius 2px
    background-color #3c89f9
    box-sizing border-box
    border 1px solid #3c89f9
    font-size 15px
    font-weight 600
    letter-spacing -0.5px
    text-align center
    color #fff !important
    &--disabled
      background-color #828282
      border-color #828282
  &__btn-cancel
    border 1px solid #ddd
    height 48px
    border-radius 2px
    background-color #fff
    box-sizing border-box
    font-size 14px
    font-weight 500
    letter-spacing -0.5px
    text-align center
    color #4c4c4c !important
  &__error-msg
    margin pxToRem(7px) 0 0
    padding 0
    font-size pxToRem(13px)
    font-weight 500
    line-height 1.38
    letter-spacing -0.5px
    color #f66565
  &__form-area
    padding 40px 24px 106px


.digital-product-thumbnail-editor
  position relative
  width 100%
  max-width 500px
  &__list
    padding 0
    list-style none
    display grid
    grid-gap 5px
    grid-template-columns repeat(6, 1fr)
  &__item
    position relative
    border 1px dashed #ccc
    padding-top calc(100% - 2px)
    background url("//s3.marpple.co/files/u_218933/2022/8/original/2b31b03ac278bce848da14702fbc4b25fe7769061.png") no-repeat center
    background-size 15px 15px
    cursor pointer
    &--on
      border-style solid
      background none
      &:hover
        border solid 1px #3c89f9
        .digital-product-thumbnail-editor__item-thumb-del
          display none
      img
        position absolute
        top 2px
        left 2px
        right 2px
        bottom 2px
        max-width calc(100% - 4px)
        max-height calc(100% - 4px)
        margin auto
        vertical-align top
    &--selected
      border solid 1px #3c89f9
      &:hover
        border-width 1px
  &__item-thumb-del
    display none
    position absolute
    top -10px
    right -10px
    width 20px
    height 20px
    img
      width 100%
      height 100%
  &__view-area
    position relative
    width 100%
    max-width 100%
    border 1px dashed transparent
    padding-top 100%
    &[data-length="0"]
      border-color #ccc
    &[data-length="1"]
      .digital-product-thumbnail-editor__arrow
        display none
    &[is-show-upload="true"]
      .digital-product-thumbnail-editor__upload
        display block
      .digital-product-thumbnail-editor__photos
        display none
    &[is-show-upload="false"]
      .digital-product-thumbnail-editor__upload
        display none
      .digital-product-thumbnail-editor__photos
        display block
  &__upload
    position absolute
    top 50%
    left 0
    right 0
    transform translateY(-50%)
    margin auto
    width 280px
    text-align center
    &--hide
      display none
    &--show
      display block
  &__upload-icon
    img
      width 36px
      vertical-align top
  &__update-text1
    margin-top 26px
    font-size 15px
    font-weight 800
    line-height 1.27
    letter-spacing -0.2px
    color #aeaeae
  &__update-text2
    margin-top 10px
    font-size 13px
    font-weight 500
    line-height 1.46
    letter-spacing -0.2px
    color #aeaeae
  &__upload-btn-area
    margin-top 36px
    label
      position relative
      display inline-block
  &__input-file
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    opacity 0
    display none
    width 100%
    height 100%
  &__upload-btn
    padding 10px 25px 9px 25px
    border 0 none
    background-color #3c89f9
    color #fff !important
    font-size 14px
    font-weight 600
    letter-spacing -0.5px
    border-radius 2px
    span
      display block
      padding 2px 0 2px 27px
      background url("//s3.marpple.co/files/u_218933/2022/8/original/5730e4209e5552643156f0fa44916a5eec8010671.png") no-repeat left center
      background-size 20px 20px
  &__photos
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    &--show
      display block
    &--hide
      display none
  &__photo
    display none
    position relative
    width 100%
    height 100%
    &--show
      display block
    img
      position absolute
      top 0
      left 0
      right 0
      bottom 0
      margin auto
      max-width 100%
      max-height 100%
    &:hover
    &:active
      .digital-product-thumbnail-editor__photo-delete
        display block
  &__photo-delete
    position absolute
    top 12px
    right 12px
    background-color rgba(17, 17, 17, 0.45)
    width 36px
    height 36px
    border-radius 50%
    cursor pointer
    & img
      width 18px
      height 18px

  &__photo-set-first
    position absolute
    left 0
    right 0
    bottom -40px
    text-align center
    font-size 12px
    line-height 1.57
    letter-spacing -0.5px
    color #000
  &__radio-label
    font-size 14px
    letter-spacing -0.5px
    margin-right 27px
    >input[type='radio']
      margin-top -1px
      margin-left 0
      vertical-align middle
      appearance none
      border 1px solid $gray-s1
      border-radius 50%
      width 18px
      height 18px
    >input[type="radio"]:checked
      border 6px solid #3c89f9

  &__text
    margin 72px -12px 0
    padding 8px 16px
    border-radius 4px
    background-color rgba(76, 76, 76, 0.05)
    font-size 13px
    line-height 1.46
    letter-spacing -0.2px
    text-align center
    color #4c4c4c
  &__arrow-prev
  &__arrow-next
    display none

.digital-product-creator-form
  >hr
    margin 40px 0
    border 0
    border-top 1px solid $gray-5
  &__control
    margin-top 40px
  &__input-wrapper
    position relative
    border-radius 3px
    border solid 1px $gray-s1
    &--error
      border-color #f66565
  &__text-input
    color $gray-1
    font-weight 500
    border-radius 3px
    padding: 11px 40px 11px 11px
    height 40px
    font-size: 14px
    border 0 none !important
    background-color #fff
    outline none
    flex 1
    width 100%
    &::placeholder
      color $gray-4
  &__select-input
    color $gray-4
    font-weight 500
    padding: 11px 40px 11px 11px
    height 40px
    font-size: 14px
    border 0 none !important
    background-color #fff
    outline none
    flex 1
    width 100%
    -webkit-appearance none
    -moz-appearance none
    background $select-down-arrow no-repeat calc(100% - 12px) center
    background-size 20px 20px
    &.active
      color $gray-1
    & > option[value=""]
      display none
  &__required
    color $blue-100
  &__input-title
    margin-bottom 12px
    font-size 14px
    font-weight 600
    letter-spacing -0.5px
    color #000
    &__sub
      font-size 13px
      font-weight 500
      line-height 1.38
      letter-spacing -0.5px
      color $gray-s4
      margin-left 6px
  &__radio-label
    font-size 14px
    letter-spacing -0.5px
    margin-right 27px
    >input[type='radio']
      margin-top -1px
      margin-left 0
      vertical-align middle
      appearance none
      border 1px solid $gray-s1
      border-radius 50%
      width 18px
      height 18px
    >input[type="radio"]:checked
      border 6px solid #3c89f9
  &__product_names
    display flex
    align-items center
    justify-content space-between
  &__letter-number
    width 100px
    height 40px
    font-size 14px
    background-color $gray-s1
    color $gray-3
    display flex
    justify-content center
    align-items center
    flex-shrink 0
  &__flag-icon
    width 18px
    height 18px
    object-fit contain
  &__number-input-wrap
    position relative
    display flex
  &__won
    position absolute
    top 0
    right 0
    bottom 0
    padding 0 11px
    display flex
    align-items center
  &__desc
    font-size 13px
    font-weight 500
    color $gray-3
  &__select-area
    display flex
    width 100%
    >select
      width 100%
      -webkit-appearance none
      -moz-appearance none
      font-size 14px
      padding: 11px 40px 11px 11px
      border solid 1px $gray-s1 !important
      border-radius 3px
      background $image-down-arrow no-repeat calc(100% - 10px) center
      background-size 18px 18px
      color $gray-4
  &__upload-box
  &__preview-upload-box
    display flex
    cursor pointer
    padding 20px
    border solid 1px $gray-s1
    border-radius 3px
    gap 12px
    &__icon-background
      flex-shrink 0
      width 48px
      height 48px
      background-color #3c89f9
      border-radius 3px
      display flex
      justify-content center
      align-items center
    &__icon
      width 32px
      height 32px
    &__guides
      padding 2px 0
      display flex
      justify-content center
      flex-direction column
      gap 5px
    &__guide-main
      font-size 14px
      font-weight 500
      color #3c89f9
    &__guide-sub
      font-size 13px
      font-weight 500
      color $gray-3
      line-height 1.38
  &__preview-upload-box.disabled
    cursor default
  &__upload-results-dashboard
    display flex
    align-items center
    border-top-left-radius 3px
    border-top-right-radius 3px
    height 50px
    background-color #f5f9fe
    gap 14px
    border-top solid 1px $gray-s1
    border-left solid 1px $gray-s1
    border-right solid 1px $gray-s1
    border-bottom solid 1px rgba(0,0,0,0)
    cursor pointer
    &.close
      border-bottom-left-radius 3px
      border-bottom-right-radius 3px
      border-bottom solid 1px $gray-s1
    &__toggle-button
      width 18px
      height 18px
      transform rotate(-180deg)
      transition transform 0.4s
      margin-right 20px
      &.close
        transform rotate(0)
    &__upload-message
      opacity 0
      transition opacity 225ms cubic-bezier(0.25, 0.46, 0.45, 0.94)
      &.text-fade
        opacity 1
        transition opacity 300ms cubic-bezier(0.55, 0.085, 0.68, 0.53)
    &__files-total-count
      opacity 0
      transition opacity 225ms cubic-bezier(0.25, 0.46, 0.45, 0.94)
      &.text-fade
        opacity 1
        transition opacity 300ms cubic-bezier(0.55, 0.085, 0.68, 0.53)
    &__fail
      color #f66565
      &.none
        display none
  &__upload-results
    display flex
    flex-direction column
    padding 20px
    border solid 1px $gray-s1
    border-top none
    border-bottom-left-radius 3px
    border-bottom-right-radius 3px
    gap 12px
    max-height 500px
    transform-origin top
    background-color white
    overflow hidden
    opacity 1
    transition max-height 0.4s, padding-top 0.6s, padding-bottom 0.6s, margin-top 0.6s, margin-bottom 0.6s, opacity 0.6s
    &.close
      max-height 0
      padding-top 0
      padding-bottom 0
      margin-top 0
      margin-bottom 0
      opacity 0
  &__decorate-image-background
    width 32px
    height 32px
    background-color #ebf3fe
    border-radius 50%
    display flex
    justify-content center
    align-items center
    flex-shrink 0
    animation file-upload 1s
    &.fail
      background-color #fde7ea
  &__decorate-image-icon
    width 20px
    height 20px
    animation file-upload-image 1s
  &__upload-info
    color $gray-2
    letter-spacing -0.5px
  &__spinner-icon
    animation rotate_image 0.7s linear infinite
    transform-origin 50% 50%
    width 20px
    height 20px
    opacity 1
    transition opacity 0.2s
    &.hide
      opacity 0
    &.none
      display none
  &__complete-circle
    position relative
    border 1.5px solid #3c89f9
    border-radius 50%
    width 20px
    height 20px
    transition opacity 0.2s
    opacity 1
    &.hide
      opacity 0
    &.none
      display none
  &__complete-checkmark
    position absolute
    transform rotate(45deg) translate(-50%, -50%)
    left 28%
    top 46%
    height 8px
    width 5px
    border-bottom 1.5px solid #3c89f9
    border-right 1.5px solid #3c89f9
  &__complete-checkmark-screen
    position absolute
    transform-origin right
    transform translate(-50%, -50%) scaleX(1)
    left 50%
    top 50%
    width 10px
    height 10px
    background-color white
    transition transform 0.9s
    &.hide
      transform translate(-50%, -50%) scaleX(0)
  &__upload-result
    display flex
    align-items center
    &__file-name
      font-size 14px
      font-weight 500
      line-height 1.29
      letter-spacing -0.5px
      color $gray-2
      overflow hidden
      max-width 130px
      white-space nowrap
      text-overflow ellipsis
      animation file-upload 1s
    &__file-size
      font-size 14px
      font-weight 500
      line-height 1.29
      letter-spacing -0.5px
      color $gray-s4
      animation file-upload 1s
      white-space nowrap
    &__fail-message
      font-size 12px
      font-weight 500
      letter-spacing -0.5px
      color #f66565
      margin-left 4px
    &__file-name.active
    &__file-size.active
      display none
    &__image
      width 32px
      height 32px
    &__icon
      height 18px
      width 18px
      display none
      animation file-upload 1s
      cursor pointer
    &__icon.active
      display block
    &__progress
      margin-left 12px
      appearance none
      margin-right auto
      transition 1s ease-out
      opacity 0
      height fit-content
      &::-webkit-progress-bar
        transition 0.2s ease-out
        opacity 0
        display block
        border-radius 10px
        background-color #f2f2f2
        margin-right 10px
        width 0
        height 8px
      &::-webkit-progress-value
        display block
        border-radius 10px
        background-color #3c89f9
        transition 0.4s ease-out
    &__progress-view
      text-align right
      font-weight 500
      font-size 14px
      color #3c89f9
      transition 1s ease-out
    &__progress.active
      opacity 1
      transition 1s ease-out
      &::-webkit-progress-bar
        opacity 1
        width 200px
    &__progress-view.active
      opacity 1
    &__progress.hide
    &__progress-view.hide
      opacity 0
    &__progress.none
    &__progress-view.none
      display none

  &__tooltip-icon
    display inline-block
    background-image url("//s3.marpple.co/files/u_1093672/2020/9/original/7b88b4654d6545661c60af72c10d3804749854221.png")
    background-size 16px 16px
    width 16px
    height 16px
    margin-left 5px
    background-repeat no-repeat
    &:hover
      background-image url("//s3.marpple.co/files/u_1093672/2020/9/original/8ab18cdaad2bce5011f094ece4787e8c7107c8ac2.png")
  &__tooltip-container
    background-color #ffffff
    border 1px solid #3c89f9
    border-radius 2px
    box-shadow 0 2px 8px 0 rgba(0, 0, 0, 0.2)
    padding 16px
    position relative
    text-align left
    .digital-product-creator-form__tooltip-icon + & {
      position absolute
      width 0
      height 0
      opacity 0
    }
  &__setting-token-gate
    width 100%
    .setting-token-gate-wrapper--empty
      display none
  &__story
    border-top 1px solid #f2f2f2
    .lang-selector
      display none
    > div
      padding 0 !important
  &__information-list
    display flex
    flex-direction column
    gap 24px
  &__information-contents
    background-color $gray-6
    padding 20px 26px
    display flex
    flex-direction column
    gap 6px
    border-radius 3px
  &__information-content
    color $gray-3
    font-size 13px
    line-height 1.38
    position relative
    &::before
      content '·'
      position absolute
      left -10px
  &__check-box-label
    font-size 14px
    letter-spacing -0.5px
    margin-right 8px
    font-weight 500
    white-space nowrap
    >input[type='checkbox']
      margin-top 1px
      margin-left 0
      vertical-align middle
      cursor pointer
      border 1px solid #d9d9d9
      border-radius 2px
      width 18px
      height 18px
  &__required-message
    color #3c89f9
  &__input-file
  &__preview_input-file
    position absolute
    top 0
    left 0
    width 0
    height 0
    opacity 0
    display none
  &__preview-progress-wrapper
    display flex
    align-items center
    margin-left -10px
    width 100%
  &__qna-type-mp
    &--hide
      display none
  &__qna-type-sns
    &--hide
      display none
  &__qna-info
    margin 16px 0 0
    padding 0
    font-size 12px
    line-height 1.5
    letter-spacing -0.5px
    color #757575
  &__inquiry-precautions
    margin-top 12px
    padding 20px
    color #757575
    border-radius 3px
    background-color #f8f8f8
    &__txt1
      margin 0 0 6px
      padding 0
      font-weight 600
      line-height 1.29
      letter-spacing -0.2px
      color #111
    ul
      margin 0
      padding 0
      list-style none
      font-size 13px
      line-height 1.69
  &__icon-important
    margin-left 2px
    display inline-block
    vertical-align top
    width 15px
    height 15px
    background url(//s3.marpple.co/files/u_218933/2022/8/original/06b2fc36647431e3e4b333a11156a1eaa285ab351.png) no-repeat
    background-size 15px 15px
    &--ml0
      margin-left 0
      margin-right 2px

.dpc-styl
  &__mt-0px
    margin-top 0
  &__mt-10px
    margin-top 10px
  &__mt-12px
    margin-top 12px
  &__mt-16px
    margin-top 16px
  &__mt-18px
    margin-top 12px
  &__mt-24px
    margin-top 24px
  &__mt-40px
    margin-top 40px
  &__ml-4px
    margin-left 4px
  &__ml-8px
    margin-left 8px
  &__ml-10px
    margin-left 10px
  &__ml-12px
    margin-left 12px
  &__ml-16px
    margin-left 16px
  &__ml-20px
    margin-left 20px
  &__mr-auto
    margin-right auto
  &__flex
    display flex
  &__items-center
    align-items center
