.app-home-modal
  position fixed
  top 0
  left 0
  width 100%
  height 100%
  opacity 0
  visibility hidden
  z-index 201
  &--active
    visibility visible
  &__mask
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    background-color #111
    opacity 0.95
    z-index 1
  &__container
    position: relative;
    display flex
    width 100%
    height 100%
    z-index 2

.app-home-apply-seller
  position relative
  displayFlex space-between center
  flex-direction column
  box-sizing border-box
  width 100%
  height 100%
  padding 0 24px
  &__image-box
    display flex
    justify-content center
    align-items center
    padding 0 19px
    width 100%
    height calc(100% - 108px)
    box-sizing border-box
  &__image
    max-width 100%
    max-height 700px;
  &__button-box
    displayFlex space-between flex-start
    width 100%
    height 108px
  &__button
    reset-button()
    width calc(50% - 4px)
    height 42px
    border-radius 21px
    border 1px solid #a2a2a2
    font 15px, 1.27, -0.2, 600
    color #fff
    &--detail
      border none
      background-color #002aff
