$drawer = '.drawer'
$avatar = '.avatar'

$keyframe-drawer-in = drawer-in
$keyframe-drawer-out = drawer-out

$root-index = 1

{$drawer}
  position fixed
  top 0
  left 0
  width 100%
  height 100%
  visibility hidden
  z-index 201
  &__inner
    position absolute
    top 0
    width 100%
    height 100%
    background-color #fff
    opacity 0
    transform translateX(70%)
    &--slide-in
      animation .25s ease-out $keyframe-drawer-in forwards
    &--slide-out
      animation .25s ease-out $keyframe-drawer-out forwards
    &--keep-open
      transform translateX(0)
      opacity 1
      visibility visible
  &__header
    displayFlex space-between center
    padding 0 15px 0 24px
    height 60px
  &__close
    reset-button()
    flexCenter()
    margin-left auto
    width 24px
    height 24px
  &__logo-anchor
    display block
    font-size 20px
    font-weight 600
    line-height 1.56
    letter-spacing -0.2px
    html[is_plus="true"] &
      display none
  &__logo-image
    width 48px
    vertical-align middle
  &__title-image
    max-width 210px
    height 28px
  &__button
    reset-button()
    flexCenter()
    position relative
    width 40px
    height 40px
    &::before
      stripe-image-before 22px 22px '//image1.marpple.co/files/u_1396787/2021/4/original/50680d8497b99dcb9e459945e4237b05f2435c3c1.png?w=156' 78px
    &--cart
      &::before
        background-position -2px -2px
        //background-image url(//image1.marpple.co/files/u_29089/2022/12/original/d8f3e50e566a5656dda05aec8782512bb22b4b561.png?h=44)
  &__blind-text
    blind()
  &__cart-count-box
    flexCenter()
    display none
    position absolute
    top pxToRem(3px)
    right 0
    //right pxToRem(-3px)
    padding 4px 0 1px
    width 19px
    border-radius 100%
    background-color #1C75FF
    color #fff
    &--show
      display flex
    &--wide
      right -5px
      width 25px
      border-radius 8px
  &__cart-count
    font 12px 12px -.2px
    color #fff
  &__body
    box-sizing border-box
    padding 0 24px
    height calc(100% - 107px)
  &__iscroll
    height 100%
    overflow hidden
  &__iscroll-list
    padding-top 10px
    padding-bottom 32px
  &__text
    font 20px, 26px, -.3px
    &--bold
      font-weight 600
{$drawer}-login
  display flex
  align-items center
  .avatar
    flex-basis pxToRem(72px)
    flex-shrink 0
  &__button
    display inline-block
  &__link
    margin-top 6px
    {$font-body-b3-r}
    flexCenter()
    display inline-flex
    width auto
    height 32px
    padding 6px 15.8px
    border 1px solid $gray-200
    border-radius 17px
    box-sizing border-box
  &__link-text
    margin-bottom -2px
    font 14px 18px -.2px
  &__text
    font 20px, 26px, -.3px
    &--bold
      font-weight 600
{$drawer}-category
  margin-top 8px
  &__group-anchor
    display inline-flex
    margin-top 24px
    {$font-heading-h4-b}
  &__list
    reset-list()
    display flex
    flex-wrap wrap
  &__item
    width 50%
  &__anchor
    display block
    margin-top 14px
    font 16px, 20px, -.2px
    color #4e4e4e

{$drawer}-banner
  height 47px
  &__button
    reset-button()
    flexCenter()
    position absolute
    left 0
    bottom 0
    width 100%
    height 47px
    font 16px, 20px, -.2px, 600
    color #fff
    background-color #000
    padding-bottom env(safe-area-inset-bottom)
    padding-bottom constant(safe-area-inset-bottom)
    html[not-safe-area="true"] &
      padding-bottom 0
    html.safe_area_inset_bottom &
      padding-bottom 34px
    &--apply
      background-color #1C75FF
      html[is_plus="true"] &
        display none
{$avatar}
  position relative
  width 72px
  height 72px
  background #f8f8f8 url("//s3.marpple.co/files/u_1396787/2021/4/original/c0c2545a3890e2512a4f499947ce007963f188fc2.png") no-repeat center
  background-size 34px
  border-radius 50%
  overflow hidden
  &__image
    width 100%
    height 100%
    object-fit cover
  &--login
    margin-right 16px
    displayFlex flex-end flex-end
  &__edit
    reset-button()
    flexCenter()
    display none
    position absolute
    bottom 0
    right 0
    width 24px
    height 24px
    border solid 2px #ffffff
    border-radius 24px
    background #f8f8f8 url('//s3.marpple.co/files/u_1396787/2021/4/original/ac9c39ba9bf5c3347f251d65fcecda023bddfb881.png') no-repeat center
    background-size 16px
    ^[{$root-index}]__text
      blind()
{$creator}-mask
  position fixed
  top 0
  left 0
  width 100%
  height 100%
  background-color #000
  opacity 0
  transition opacity .3s ease-in-out
  visibility hidden
  z-index 9
  &--is-open
    opacity 0.5
    visibility visible

@keyframes {$keyframe-drawer-in} {
  from {
    transform translateX(70%)
    opacity .3
  }
  to {
    transform translateX(0)
    opacity 1
    visibility visible
  }
}
@keyframes {$keyframe-drawer-out} {
  from {
    transform translateX(0)
    opacity 1
    visibility visible
  }
  to {
    transform translateX(70%)
    opacity 0
    visibility hidden
  }
}
