.don_frame:not([frame_index="0"]).pd-zoom-frame
  >.don_wrapper
    >.header
      padding 0
      align-items flex-start
      z-index 1
      background-color transparent
      .don_hide_frame
        margin 10px
  .pd-zoom
    position fixed
    background-color #f6f6f6
    overflow auto
    width 100%
    height 100%
    &__img
      position absolute
      user-drag none
      user-select none
      height 100%
      max-width none !important
      top 50%
      transform translate3d(0, -50%, 0)
