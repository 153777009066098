.studio-table-style-v1
  @import "../Basic.styl"
  .settings-content
    padding-top pxToRem(40px)
  .main-section
    width 100%
    border-radius 0
    padding pxToRem(20px) pxToRem(10px) pxToRem(35px)
    &__table
      overflow-x scroll
      table
        min-width pxToRem(1150px)
    &__buttons
      width 100%
  .search
    &__vacant
      display none
    &.long
      .search__name
        display none
    &__name
      width pxToRem(100px)
      text-indent 10px
  .search-date
    .__input
      display none
    &__hidden
      visibility visible
  .flatpickr-mobile
    position absolute
    width 100%
    height 100%
    top 0
    left 0
  .search-section
    &__selects
      padding 0
      margin-bottom 0
      border 0
