html.login_mode
  #header
    display none
html.login_page
  #footer
    margin-top 70px
    display none
  #header
    display block
  .creator_login_frame
    padding-top 20px !important
    > .don_wrapper > .header .don_hide_frame
      display none
    .don_page
      min-height auto !important

.creator_login_frame
  background-color #fff
  padding 0 24px
  > .don_wrapper > .header
    .title
      display none

  .don_page
    > .don_wrapper > .header
      width 100%
      text-align center
      z-index 1
    .custom_title
      .custom_title_btn
        margin-bottom 10px
        height 36px
        display flex
        justify-content flex-end
      .btn_mo_close
        background-image url("//s3.marpple.co/files/u_29089/2021/5/original/f05b717d9a56b9d20020119abb82aad91b130c6f1.png")
        width 36px
        height 36px
        margin-right -8px
        background-color transparent
        background-position 50% 50%
        background-repeat no-repeat
        background-size 18px
        border none
        outline none
    h1
      margin 0 0 24px
      padding 0
      img
        height 28px
    .tab_buttons
      height 100%
      display flex
      margin-bottom 35px
      button.tab_button
        flex 1
        position relative
        z-index 1
        margin 0
        padding 0
        height 40px
        box-sizing border-box
        border-bottom solid 1px #dddddd
        background-color #fff
        outline none
        font-size 14px
        font-weight normal
        text-align center
        color #111111
        &:first-child
          margin-right -1px
        &:last-child
        html[lang="en"] &
          font-family CircularStd
        &:active
          color #111111
        &[selected="true"]
          color #111111
          border-color #111
          font-weight 600
    .don_tab
      background-color #fff
  .don_page.creator_login_page .don_tab
  .don_page.creator_login_page .don_tab
    > .don_wrapper > .header
      display none
    > .don_wrapper > .wrap
      padding-bottom 40px
    p.txt1
      margin 0
      padding 4% 0 5% 4%
      font-size 24px
      font-weight 600
      color #212529
    .login_input
      .control
        margin 0 0 16px
        label
          display block
          font-size 14px
          font-weight 600
          line-height 1.29
          letter-spacing -0.2px
          color #111111
          margin-bottom 8px
        input
          width 100%
          padding 14px 16px 12px
          margin 0
          color #111
          font-size 14px
          box-sizing border-box
          border solid 1px #dddddd
          background-color #fff
          border-radius 4px
          &::-webkit-input-placeholder
          &:-ms-input-placeholder
          &::placeholder
            color #a2a2a2
    .gender
      margin 20px 6% 0
      label
        position relative
        margin-right 24px
        > *
          vertical-align middle
        span.radio_box
          display inline-block
          width 16px
          height 16px
          margin-right 8px
          background url(//s3.marpple.co/files/u_18053/2018/10/original/f_6008_1539249097835_AtG6XVyrVUB8ILV6xJ.png) no-repeat
          background-size cover
        input[type="radio"]
          position absolute
          top 0
          left 0
          visibility hidden
        &.on
          span.radio_box
            background-image url(//s3.marpple.co/files/u_18053/2018/10/original/f_6007_1539249097835_J9HXd3f3Hd4pXt1X9XJBR.png)
    .agree_email
      margin 24px 0 16px
      label
        display block
        position relative
        padding-left 24px
        padding-bottom 12px
        color #111111
        .check_box
          display block
          position absolute
          top 0
          left 0
          width 16px
          height 16px
          background url(//s3.marpple.co/files/u_14355/2018/10/original/f_12369_1540968528808_wa9m8Im4uHIc9u0ORxMn.png) no-repeat
          background-size cover
        input[type="checkbox"]
          position absolute
          top 0
          left 0
          visibility hidden
        a
          text-decoration underline
          html[lang="kr"] &
            position absolute
            top 0
            right 0
        &.on
          .check_box
            background-image url(//s3.marpple.co/files/u_14355/2018/10/original/f_12370_1540968742928_EEZcT5AfHu3P9SBPR2w.png)

    button[type="button"]
      width 100%
      color #fff
      border 0 none
      box-sizing border-box
      text-align center
      padding 15px 0 14px
      border-radius 4px
      background-color #111111
      font-size 15px
      font-weight normal
      line-height 1.27
      letter-spacing -0.2px
    .submit
      margin 5% 0 5%
    .fb_submit
      margin 5% 6% 5%
      button[type="button"]
        color #4167b2
        background-color #f8f9fa
        &:before
          content ''
          background-image url("//s3.marpple.co/files/u_29089/2019/1/original/f_23324_1548301193608_UQk6c1C0lREqzRyt5FJ.svg")
          width 8px
          height 12px
          display inline-block
          vertical-align top
          margin 4px 8px 0 0
    .or
      margin 16px 0
      text-align center
      font-size 12px
      line-height 1.25
      color #a2a2a2
      position relative
      html[lang="en"] &
        font-family CircularStd
      &:before
        content ''
        width 24px
        height 1px
        background-color #e9ecef
        display inline-block
        vertical-align top
        margin 7px 8px 0 0
      &:after
        content ''
        width 24px
        height 1px
        background-color #e9ecef
        display inline-block
        vertical-align top
        margin 7px 0 0 8px
    .forgot
      margin 32px 0 0
      display flex
      align-items center
      justify-content center
      font-size 12px
      padding 0 1% 40px 1%
      color #dddddd
      gap 8px
      a
        font-size 14px
        font-weight 600
        line-height 1.71
        letter-spacing -0.2px
        text-align center
        color #111
        text-decoration underline
    .forgot_password
      margin 32px 0 0
      padding 0
      text-align center
      font-size 13px
      line-height 1.38
      letter-spacing -0.2px
      color #111111
      font-weight 600
      span
      a
        cursor pointer
        color #111111
        text-decoration underline
    p.txt3
      margin 0
      padding 0 0 10%
      font-size 12px
      text-align center
      color #111111
      span
      a
        cursor pointer
        color #111111
        text-decoration underline


.mp_email_certification
  .certification
    width 400px
    margin 200px auto 0
    padding 20px
    background-color #fff
    border-radius 10px


html:not([lang="kr"])
  .mp_id_pw_find_frame
    .id_pw_area
      display none
.mp_id_pw_find_frame
  background-color #fff
  > .don_wrapper > .header
    height 48px
  .don_page
    > .don_wrapper > .header
      display none
    .don_tab
      padding-top 40px
      background-color #fff
  .don_page[page_name="@PAGE/Creator/Login/id_pw_find"] .don_tab
    > .don_wrapper > .header
      display none
    .id_pw_area
      margin-top 50px
      h2
        margin-bottom 16px !important
    .id_pw_area
    .reset_pw_area
      padding 0 6%
      h2
        margin 0
        padding 0
        margin-bottom 16px !important
        font-size 20px
        font-weight 600
        line-height 1.3
        letter-spacing -0.3px
        color #111111
      p.txt1
        margin 0
        padding 24px 0 8px
        font-size 14px
        font-weight 600
        line-height 1.29
        letter-spacing -0.2px
        color #111111
    .controls
      .control
        margin 0 0 12px
        input
          width 100%
          padding 14px 16px 12px
          font-size 14px
          margin 0
          color #111
          box-sizing border-box
          border solid 1px #dddddd
          background-color #fff
          border-radius 4px
          &::-webkit-input-placeholder
          &:-ms-input-placeholder
          &::placeholder
            color #a2a2a2
    .submit
      button[type="button"]
        width 100%
        color #fff
        border 0 none
        box-sizing border-box
        text-align center
        padding 15px 0 14px
        border-radius 4px
        background-color #111111
        font-size 15px
        font-weight normal
        line-height 1.27
        letter-spacing -0.2px

  .don_page[page_name="mp.phone_cert_page"] .don_tab
    > .don_wrapper > .header
      display none
    .phone_cert_area
      padding 0 6%
      h2
        margin 0
        padding 0
        font-size 20px
        font-weight normal
        color #111111
      p.txt1
        margin 0
        padding 24px 0 24px
        font-size 14px
        line-height 1.71
        color #111111
    .controls
      .control
        margin 0 0 24px
        input
          width 100%
          padding 14px 16px 12px
          font-size 14px
          margin 0
          color #111
          box-sizing border-box
          border solid 1px #dddddd
          background-color #fff
          border-radius 4px
          &::-webkit-input-placeholder
          &:-ms-input-placeholder
          &::placeholder
            color #a2a2a2
    .submit
      button[type="button"]
        margin-bottom 5px
        width 100%
        border 0 none
        box-sizing border-box
        text-align center
        padding 15px 0 14px
        border-radius 4px
        background-color #111111
        font-size 15px
        font-weight normal
        line-height 1.27
        letter-spacing -0.2px
        color #ffffff
  .don_page[page_name="mp.phone_cert_complete_page"] .don_tab
    > .don_wrapper > .header
      display none
    .phone_cert_complete_area
      padding 0 6%
      h2
        margin 0
        padding 0
        font-size 20px
        font-weight normal
        color #111111
      p.txt1
        margin 0
        padding 24px 0 24px
        font-size 14px
        line-height 1.71
        color #111111
    .users
      margin-bottom 30px
      ul
        margin 0
        padding 0
        list-style none
        li
          margin-bottom 10px
          input[type="radio"]
            margin-right 10px
            -webkit-appearance auto
    .buttons
      button[type="button"]
        margin-bottom 5px
        width 100%
        border 0 none
        box-sizing border-box
        text-align center
        padding 15px 0 14px
        border-radius 4px
        background-color #111111
        font-size 15px
        font-weight normal
        line-height 1.27
        letter-spacing -0.2px
        color #ffffff
  .don_page[page_name="mp.cert_reset_password_page"] .don_tab
    > .don_wrapper > .header
      display none
    .phone_cert_complete_area
      padding 0 6%
      h2
        margin 0
        padding 0
        font-size 20px
        font-weight normal
        color #111111
      p.txt1
        margin 0
        padding 24px 0 24px
        font-size 14px
        line-height 1.71
        color #111111
    .controls
      .control.email
        margin-top 24px
      .control
        margin 0 0 24px
        input
          width 100%
          padding 14px 16px 12px
          font-size 14px
          margin 0
          color #111
          box-sizing border-box
          border solid 1px #dddddd
          background-color #fff
          border-radius 4px
          &::-webkit-input-placeholder
          &:-ms-input-placeholder
          &::placeholder
            color #a2a2a2
    .buttons
      button[type="button"]
        width 100%
        color #fff
        border 0 none
        box-sizing border-box
        text-align center
        padding 15px 0 14px
        border-radius 4px
        background-color #111111
        font-size 15px
        font-weight normal
        line-height 1.27
        letter-spacing -0.2px

.reset_password_frame
  background-color #fff
  > .don_wrapper > .header
    height 48px
    .title
      display none
    .don_hide_frame__
      position absolute
      top 5px
      right 0
      width 40px !important
      height 40px !important
      z-index 11
      background-position 50% 50% !important
      background-size 12px auto
  .don_page
    > .don_wrapper > .header
      display none
    .don_tab
      padding-top 25px
      background-color #fff
  .don_page[page_name="mp.reset_password_page"] .don_tab
    > .don_wrapper > .header
      display none
    .reset_pw_area
      padding 0 6%
      h2
        margin 0
        padding 0
        font-size 20px
        font-weight normal
        color #111111
      p.txt1
        margin 0
        padding 24px 0 24px
        font-size 14px
        line-height 1.71
        color #111111
    .controls
      .control
        margin 0 0 24px
        input
          width 100%
          padding 14px 16px 12px
          font-size 14px
          margin 0
          color #111
          box-sizing border-box
          border solid 1px #dddddd
          background-color #fff
          border-radius 4px
          &::-webkit-input-placeholder
          &:-ms-input-placeholder
          &::placeholder
            color #a2a2a2
    .submit
      button[type="button"]
        width 100%
        color #fff
        border 0 none
        box-sizing border-box
        text-align center
        padding 15px 0 14px
        border-radius 4px
        background-color #111111
        font-size 15px
        font-weight normal
        line-height 1.27
        letter-spacing -0.2px


.change_password
  background-color #fff
  > .don_wrapper > .header
    height 48px
    .title
    .don_hide_frame
      display none
  .don_page
    > .don_wrapper > .header
      display none
    .don_tab
      padding-top 25px
      background-color #fff
  .don_page .don_tab
    > .don_wrapper > .header
      display none
    .verify_change_pw
      padding 0 6% 150px
      h2
        margin 0
        padding 156px 0 0
        font-size 20px
        color #111111
        text-align center
        background url(//s3.marpple.co/files/u_14355/2018/11/original/f_12428_1541140904027_UJ5bp6OsZC4TA1l8sfdz.png) no-repeat
        background-size 60px auto
        background-position 50% 80px
        html[lang="en"] &
          font-family CircularStd
      h3
        margin 0
        padding 30px 0 0
        font-size 14px
        line-height 1.71
        color #111111
      p.txt2
        margin 13px 0 0
        padding 0
        font-size 14px
        line-height 1.71
        color #111111
    .controls
      margin-top 24px
      .control
        margin 0 0 24px
        input
          width 100%
          padding 14px 16px 12px
          font-size 14px
          margin 0
          color #111
          box-sizing border-box
          border solid 1px #dddddd
          background-color #fff
          border-radius 4px
          &::-webkit-input-placeholder
          &:-ms-input-placeholder
          &::placeholder
            color #a2a2a2
    .submit
      button[type="button"]
        width 100%
        color #fff
        border 0 none
        box-sizing border-box
        text-align center
        padding 15px 0 14px
        border-radius 4px
        background-color #111111
        font-size 15px
        font-weight normal
        line-height 1.27
        letter-spacing -0.2px


html[lang="kr"]
  .mp_email_verify_frame
    > .don_wrapper
      min-width auto
.mp_email_verify_frame
  > .don_wrapper
    > .header
      .title
        font-size 26px
        font-weight 600
        text-align center
    > .body
      margin-top 30px
      .email_verify_cont
        margin 0 20px
        padding 0
        text-align center
        .email
          margin-bottom 10px
        p.txt1
          margin 0 0 10px
          padding 0
          line-height 1.83
        .p.txt2
          margin 0
          padding 18px 0 0
          border-top 1px solid #ccc
        .btn_resend_email_verify
          margin-top 40px
          button
            width 200px
            color #fff
            border 0 none
            box-sizing border-box
            padding 15px 0 14px
            border-radius 4px
            background-color #111111
            font-size 15px
            font-weight normal
            line-height 1.27
            letter-spacing -0.2px
            text-align center
