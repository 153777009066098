.don_dialog_msg
  .content
    >.body
      .msg
        .custom-body
          display flex
          flex-direction column
          align-items center
          gap 8px
          h1
            padding 0
            margin 0
            font-size 16px
            font-weight bold
            line-height 1
            letter-spacing -0.3px
            color $gray-1
          p
            padding 0
            margin 0 !important
            font-size 13px
            line-height 1.69 !important
            letter-spacing -0.2px
            text-align center
            color $gray-1
          span
            font-size 13px
            font-weight 600
            line-height 1.69
            letter-spacing -0.2px
            text-align center
            color #3c89f9

.don_dialog_msg
  .content
    >.body
      .msg
        .youtube-link-alert
          &__ol
            margin pxToRem(10) pxToRem(6) 0 pxToRem(20)
            padding 0 0 14px
          &__ol-li
            color #828282
            font-size pxToRem(14)
            font-weight 400
            line-height 120%
            letter-spacing -0.3px
            text-align left
          &__txt1
            margin 0
            color #111
            font-size pxToRem(16)
            font-weight 600
            text-align center
          &__txt2
            color #828282
            font-size pxToRem(13)
            font-weight 400
            text-align center
          &__txt3
            color #3C89F9
            font-size pxToRem(14)
            font-weight 400
            text-align center
            a
              color #3C89F9
