.pc
  display none
@import "../share.styl"
.up-item-list
  background-color $gray-100
  .store-order
    background-color #fff
    padding-bottom 24px
    margin-bottom 8px
    &__header
      padding 15px 24px 14px
      border-bottom 1px solid $gray-100
    &__shipping-orders-wrapper
      margin 20px 24px 0
.up-item-option-infos
  width 100%
  height 100%
  display flex
  flex-direction column
  justify-content center
  &__item
    display flex
    justify-content space-between
    margin-bottom pxToRem(6px)
    font-size pxToRem(13px)
    color $gray-700
    &:last-child
      margin 0
.up-item
  &__product-wrapper
    display grid
    grid-column-gap pxToRem(18px)
    grid-template-columns 80px 1fr
  &__product-info-wrapper
    overflow hidden
.up-item-product-info
  &__up_c_id
    font-size pxToRem(13px)
    margin-bottom pxToRem(3px)
  &__name
    font-weight 500
    font-size pxToRem(14px)
    margin-bottom pxToRem(3px)
    &-value
      display block
      overflow hidden
      white-space nowrap
      text-overflow ellipsis
  &__second-name
    font-size pxToRem(13px)
    margin-bottom pxToRem(6px)
    overflow hidden
    white-space nowrap
    text-overflow ellipsis
  &__price
    font-weight 500
    font-size pxToRem(14px)
.up-item
  &__warning-message
    padding-top pxToRem(18px)
    font-size pxToRem(13px)
    display flex
    align-items flex-start
    img
      padding-bottom pxToRem(2px)
    span
      margin-left pxToRem(4px)
      line-height pxToRem(16px)
      color #f66565
  &__thumbnail-wrapper
    img
      width pxToRem(80px)
      height pxToRem(80px)
  &__cart-option
    &__delete-btn
      img
        width 13px
        height 13px
    &__revise-btn
      font-size 12px

.cart-style-up-item
  .canvas-basic-anchor
    transform scale(.5)
  .canvas-basic-item
    position relative
    display flex
    canvas
      width pxToRem(200px)
      height pxToRem(200px)
      transform-origin 50% 50%
  .cch-cart-style-up-item-main
    display flex
    &__thumbnail
      margin-right pxToRem(16px)
      flex-shrink 0
      width ptr(100)
      height ptr(100)
      display flex
      justify-content center
      align-items center
      background #f6f6f6
      overflow hidden
      border-radius 16px
      img
        width ptr(100)
        height ptr(100)
        object-fit contain
    &__info
      display flex
      flex-direction column
      width 100%
      padding-right ptr(24)
      &__name
        *
          color $black
          limitLine(2)
      &__second-name
        margin-top ptr(2)
        *
          limitLine(1)
          color $gray-50
      &__price
        margin-top auto
        color $black
        limitLine(1)
        margin-bottom ptr(5)
        &:after
        &:before
          {$kr-14-b}
  .cch-style-option-list
    &__item-wrapper
      padding ptr(4) 0
  .cch-style-option-list__item
    display flex
    justify-content space-between
    margin-bottom pxToRem(4px)
    &:last-child
      margin-bottom 0
    &__info-1
      display flex
      align-items center
      span
        color $gray-80
      &__not-normal-warning
        display none
        background $error
        font-weight bold
        color #fff !important
        margin-right pxToRem(8px)
        border-radius pxToRem(2px)
        font-size 12px
        padding pxToRem(4px) pxToRem(6px) pxToRem(3px)
      &__option-name
        max-width pxToRem(340px)
        padding-right 1px
        limitLine(1)
      &__quantity
        display inline-flex
        justify-content flex-end
        flex-shrink 0
    &__info-2
      display flex
      align-items center
      justify-content flex-end
      flex-shrink 0
      margin-left pxToRem(8)
      &__price
        color $gray-80
        &:after
        &:before
          {$kr-12-m}

  .cch-style-option-list
    &[data-text_options_length="0"]
      .cch-style-option-list__text-item
        display none
    &__item
      &[data-status="NO_STOCK"]
      &[data-status="OPTION_CHANGED"]
      &[data-status="NOT_ENOUGH_STOCK"]
      &[data-status="LIMIT_EXPIRED"]
        .cch-style-option-list__item__info-1__not-normal-warning
          display inline-block
          flex-shrink 0
  .cch-style-option-list__text-item
    padding ptr(4) 0
    &__title
      limitLine(1)
      margin-bottom pxToRem(4px)
      font-size pxToRem(14px)
      color $gray-50
    &__value
      limitLine(1)
      margin-bottom pxToRem(4px)
      color $black
      &:last-child
        margin-bottom 0

  &__option
    &__delete-btn
      position absolute
      background none
      border none
      top ptr(2.5)
      right 0
      background #fff
      img
        width pxToRem(16px)
        height pxToRem(16px)
    &__modify-btn
      background $white
      position absolute
      bottom 0
      right 0
      color $gray-80
      padding pxToRem(4px) pxToRem(8px)
      height ptr(25)
      border 1px solid $gray-50
      border-radius 4px
  &__content-1
    position relative
    margin-bottom pxToRem(8px)
  &__content-2
    margin-bottom 0
  &__content-warning
    display none
    font-size pxToRem(13px)
    align-items flex-start
    img
      padding-bottom pxToRem(2px)
    span
      margin-left pxToRem(4px)
      line-height pxToRem(16px)
      color #f66565

  &[data-has_warning='true']
    .cart-style-up-item__content-warning
      display flex

.up-item-list-price-info
  &__1
    padding-bottom pxToRem(16px)
    border-bottom 1px solid $black
  &__2
    padding pxToRem(16px) 0
    border-bottom 1px dashed $gray-50
    display flex
    flex-direction column
    gap pxToRem(16)
    &__item-shipping-wrap
      background-color $gray-5
      border-radius ptr(8)
      display flex
      flex-direction column
      row-gap ptr(16)
      padding ptr(8) ptr(12)
    &__price
      color $black
      &:after
      &:before
        {$kr-14-m}
    &__item
      display flex
      justify-content space-between
      color $black
    &__item-shipping
      display flex
      justify-content space-between
      color $black
      &__label
        display flex
        align-items center
      img
        width pxToRem(7px)
        height pxToRem(7px)
        margin-right pxToRem(7px)
    &__pickup
      &[data-is_pickup="false"]
        display none
  &__3
    padding-top pxToRem(16px)
    display flex
    justify-content space-between
    align-items center
    &__label
      color $black
    &__price
      color $black
      &:after
      &:before
        {$kr-20-b}
    &.delivery-fee-info
      padding-top ptr(8)
      justify-content flex-end
      & > .up-item-list-price-info__3__label
        color $gray-50
