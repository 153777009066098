$ap = app-product;
$cg = category-group

.custom-slide-selector-wrapper
  .slide-selector
    width auto
    padding 0 25px
    &__row
      padding 0
      margin 0 0 8px
    &__item
      display flex
      align-items center
      height 34px
      border-radius 18px
      background #f8f8f8
      margin-right 8px
      font-size 15px
      padding 0 20px
      line-height 1.27
      letter-spacing -0.2px
      color #4e4e4e
      &:last-child
        margin-right 0
    &__item__icon
      margin-right 9px

.{$ap}
  overflow hidden
  margin-bottom 16px
  &__cate_lists
    margin 2px 0 40px

.{$cg}
  &__title
    margin 0 25px 12px
  &__h1
    margin 0
    font-size 20px
    line-height 1.3
    letter-spacing -0.3px
    color #111111
    word-break keep-all
  &__box
    margin-bottom 40px
    &--large
      .{$cg}-item
        &__item
          width 216px
        &__top-info
          border-radius 52px
    &--small
      .{$cg}-item
        &__item
          margin-right 32px
          width auto
        &__target
          display flex
          margin-bottom 8px
        &__top-info
          width 96px
          margin-bottom 0
          margin-right 14px
          border-radius 32px
        &__bottom-info
          text-align left
          display flex
          align-items center
        &__cate_name
          max-width 145px
          word-break break-all
.{$cg}-editor
  position relative
  margin-bottom 12px
.{$cg}-item-wrapper
  position relative
.{$cg}-item
  padding 0 25px
  z-index auto
  &__list
    z-index auto
  &__item
    margin-right 8px
    width 160px
    &:last-child
      margin-right 0
  &__top-info
    position relative
    display flex
    align-items center
    justify-content center
    border-radius 42px
    background-color #f8f8f8
    margin-bottom 12px
    overflow hidden
  &__img
    width 100%
    height 0
    padding-bottom 100%
    img
      position absolute
      top 0
      left 0
      right 0
      bottom 0
      margin auto
      width 100%
      height 100%
  &__bottom-info
    text-align center
  &__cate_name
    font-size 16px
    line-height 1.25
    letter-spacing -0.2px
    color #111111
    margin-bottom 2px
  &__filter_name
    position relative
    font-size 13px
    line-height 1.38
    letter-spacing -0.2px
    color #828282

.is_mobile
  .don_tab[tab_name="/modules/MShop/App/Product/S/Mui/tab.js"]
    box-sizing border-box
    .product-preview
      margin-top 20px
      &__category
        display flex
        align-items start
        justify-content space-between
        margin-bottom 16px
        padding 0 24px

    .category
      &__title
        display flex
        font-size 18px
        font-weight bold
        letter-spacing -0.3px
        gap 8px
      &__view-more
        color #828282
        font-size 14px
        font-weight 500
      &__arrow
        display inline-block
        background url(//s3.marpple.co/files/u_193535/2022/10/original/24d61fbd62137823a9268754541993f5d1ca72961.svg) no-repeat 50% 50%
        background-size auto
        width 12px
        height 12px

    .swiper-wrapper
      .app-product-item--v5
        &:first-child
          margin-left 24px
        &:last-child
          margin-right 24px