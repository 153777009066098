.don_frame[frame_name="/modules/MShop/App/CustomerOrders/S/Mui/frame.js"]
  >.don_wrapper >.body
    background $gray-6
  .pc
    display none
  @import "../share.styl"
  .up-item-thumbnail
    width pxToRem(100px)
    height pxToRem(100px)
    canvas
      width pxToRem(200px) !important
      height pxToRem(200px) !important
    img
      width pxToRem(100px) !important
      height pxToRem(100px) !important
      border-radius pxToRem(16px)
    &__canvas-wrapper
      position relative
      width pxToRem(100px)
      padding-top 100%
      canvas
        background #f6f6f6
        position absolute
        width 200%
        height 200%
        top 0
        left 0
        transform scale(0.5);
        transform-origin 0 0
        border-radius pxToRem(16px)
  .empty-orders-page
    padding ptr(160) 0
    margin-bottom pxToRem(60px)
    display flex
    flex-direction column
    align-items center
    &__img
      width pxToRem(120px)
      height pxToRem(120px)
      margin-bottom pxToRem(24px)
    &__p1
      color $gray-80
      padding-bottom pxToRem(8px)
    &__p2
      color $gray-50
      padding-bottom pxToRem(24px)
    &__to-main
      min-width pxToRem(196px)
  .customer-orders
    display flex
    flex-direction column
    row-gap ptr(17)
    padding-bottom ptr(60)
  .customer-order
    box-sizing border-box
    padding  0 ptr(16)
    margin-bottom ptr(32)
    .up-item__buttons-wrapper
      display flex
      gap pxToRem(7px)
      .up-item-button
        margin-top pxToRem(12px)
        box-sizing border-box
        background none
        {$font-body-b3-r}
        color $gray-700
        width 100%
        height pxToRem(42px)
        border-radius 4px
        border 1px solid #ddd
        display flex
        padding 0
        justify-content center
        align-items center
        letter-spacing -0.19px
      .up-item-buttons
        &__digital-product-download
          margin-top pxToRem(12px)
          box-sizing border-box
          background none
          font-size pxToRem(13px)
          font-weight 500
          width 100%
          height pxToRem(40px)
          border-radius 4px
          border 1px solid #ddd
          display flex
          padding 0
          justify-content center
          align-items center
          letter-spacing -0.19px
          color #4E4E4E
        &__download-img
          width pxToRem(10px)
          height pxToRem(11px)
          margin-right pxToRem(6px)
    &__created-at-wrapper
      display flex
      box-sizing border-box
      border-bottom 1px solid $gray-5
      width 100%
      padding ptr(16) 0
      margin-bottom ptr(16)
  .customer-order-created-at
    display flex
    justify-content space-between
    align-items center
    width 100%
    &__title
      color $gray-1
      font-size pxToRem(16px)
      margin-right pxToRem(8px)
      width pxToRem(55px)
    &__at
      overflow hidden
      white-space nowrap
      text-overflow ellipsis
      color $gray-80
    &__to-detail
      border none
      background none
      color $gray-80

  .up-items
    display flex
    flex-direction column
    gap pxToRem(24)

  .store-order
    &__header
      display flex
      align-items center
      padding-bottom ptr(8)
      border-bottom 1px solid $black
      box-sizing border-box
    &__name
      display flex
      column-gap pxToRem(8px)
    .store-logo
      width pxToRem(20px)
      height pxToRem(20px)
      {$image-store-logo}
      order 1
      margin-top ptr(2)
      flex-shrink 0
    &__name-text
      order 0

  .up-item
    padding 0
    box-sizing border-box
    &__order-process-step-wrapper
      display flex
      align-items center
      justify-content flex-start
      margin-top ptr(16)
      margin-bottom ptr(12)
      column-gap ptr(8)
      &__order-process-step
        background-color $blue-10
        color $blue-100
        display flex
        justify-content center
        align-items center
        padding ptr(2) ptr(5)
        border-radius 4px
      &__delivery-date
        color $gray-80
        > span:first-child
          color $blue-100
      &__pickup
        margin-left pxToRem(8px)
    &:last-child
      border none
    &__row
      display flex
      width 100%
      margin-bottom pxToRem(12px)

  .up-item-price
    color $gray-1
    font-size pxToRem(16px)
    font-weight 600
  .up-item__product-info-wrapper
    min-width 0
  .up-item-product-info
    width unset
    height 100%
    margin-left pxToRem(16px) !important
    display flex
    flex-direction column
    justify-content space-between
    row-gap ptr(4)
    &__shipping-place
      marginPaddingZero()
      color $gray-50
      overflow hidden
      white-space nowrap
      text-overflow ellipsis
    &__name
      marginPaddingZero()
      color $black
      overflow hidden
      white-space nowrap
      text-overflow ellipsis
    &__second-name
      marginPaddingZero()
      color $gray-50
      overflow hidden
      white-space nowrap
      text-overflow ellipsis
    &__price
      marginPaddingZero()
      color $black
      &:after
        {$kr-14-b}
        color $black

  .order-process-steps
    margin-top $default-header-mo_height
    width 100vw
    background-color $gray-5
    padding ptr(16) ptr(24)
    overflow-x auto
    scrollbar-width none
    -webkit-overflow-scrolling touch
    display flex
    align-items center
    column-gap ptr(16)
    &::-webkit-scrollbar
      display none

  .order-process-step
    padding 0
    margin 0
    border none
    background-color transparent
    display flex
    align-items center
    justify-content center
    gap pxToRem(8)
    text-align center
    flex-shrink 0
    color $gray-50
    &__count
      color inherit
      &[data-id=""]
        display none
    &--selected
      height ptr(22px)
      box-sizing border-box
      color $black
      border-bottom 1px solid $black


  .up-item-buttons
    box-sizing border-box
    display flex
    justify-content center
    align-items center
    width 100%
    height pxToRem(42px)
    border-radius pxToRem(4px)
    padding 0
    {$font-kr-m-14--160}
    &__wrapper
      flex 1 1 0
      min-width pxToRem(280)
      display flex
      flex-direction column
    &__row
      display flex
      margin-bottom pxToRem(8px)
      column-gap pxToRem(8px)
      &:last-child
        margin 0
    &__review
      background $light-blue
      color $blue
      box-sizing border-box
      border none
      border-radius pxToRem(8px)
      height pxToRem(40px)
    &__digital-product-download,
    &__confirmed,
    &__re-purchase
      background none
      border 1px solid $gray-20
      color $black
      border-radius pxToRem(8px)
      height pxToRem(40px)
    &__contact,
    &__contact-link,
    &__tracking,
    &__cancel
      backdrop-filter blur(20px)
      -webkit-backdrop-filter blur(20px)
      box-sizing border-box
      display flex
      justify-content center
      align-items center
      width 100%
      height pxToRem(40px)
      border-radius pxToRem(8px)
      padding 0
      background $white
      border 1px solid $gray-20
      color $black

  .up-item__order-process-step-wrapper__tracking
    display none
