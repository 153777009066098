.ad_res_finish
  margin-top $default-sub-header-mo_height
  padding 0 24px
  &:after
    content ''
    display block
    padding-bottom env(safe-area-inset-bottom)
    padding-bottom constant(safe-area-inset-bottom)
    html[not-safe-area="true"] &
      padding-bottom 0
    html.safe_area_inset_bottom &
      padding-bottom 34px
  .content
    padding-top 80px
    text-align center
  .image
    margin 0 auto 24px
    img
      width 210px
  .text
    h3
      margin-bottom 4px
      font-size 24px
      font-weight 600
      line-height 1.25
      letter-spacing -0.3px
      text-align center
      color #111111
  .link
    position fixed
    bottom 0
    left 0
    right 0
    a
      display block
      width 100%
      height 56px
      font-size 16px
      font-weight 600
      line-height 56px
      letter-spacing -0.2px
      text-align center
      color #ffffff
      background-color #111111
      box-sizing content-box
      padding-bottom env(safe-area-inset-bottom)
      padding-bottom constant(safe-area-inset-bottom)
      html[not-safe-area="true"] &
        padding-bottom 0
      html.safe_area_inset_bottom &
        padding-bottom 34px
  .noti
    justify-self flex-start
    text-align left
    margin 40px 0 0
    padding 0 0 0 12px
    li
      list-style-type '- '
      font-size 16px
      font-style normal
      line-height 1.5
      letter-spacing -0.2px
      color #111111
      word-break keep-all
    li + li
      margin-top 10px
  p.txt1
    margin 60px 0 100px
    padding 24px 0 0
    border-top 1px solid #f8f8f8
    font-size 12px
    line-height 1.29
    letter-spacing -0.2px
    color #a2a2a2
    a
      color #a2a2a2
