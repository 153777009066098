$opacity = 0.85

.toast-message-container
  position fixed
  bottom ptr(16)
  display flex
  flex-direction column
  align-items  center
  z-index 10
  box-sizing border-box
  left 50%
  transform translate(-50%, 0)
  width max-content
  &.with_bottom_tab_bar
    bottom calc(62px + 16px + env(safe-area-inset-bottom))
    bottom calc(62px + 16px + constant(safe-area-inset-bottom))
  &.top
    top calc(62px + 16px)
    bottom auto

.toast-message
  display flex
  align-items center
  padding ptr(8) ptr(16)
  box-sizing border-box
  background $dim-60
  border-radius 99px
  backdrop-filter blur(5px)
  -webkit-backdrop-filter blur(5px)
  color white
  animation-duration 500ms
  animation-name toastIn
  column-gap ptr(16)
  max-width calc(100vw - 32px)
  margin-top ptr(15)
  &--end
    animation-name toastOut
  &__inner
    limitLine(1)
  a
    flex-shrink 0
  &__text-button
    color $bright-60
    cursor pointer
  &__close
    text-wrap nowrap

@keyframes toastIn {
  from {
    opacity: 0;
    transform: scale3d(0.8, 0.8, 0.8);
  }

  50% {
    opacity: $opacity;
  }
}

@keyframes toastOut {
  from {
    opacity: $opacity;
  }

  50% {
    opacity: 0;
    transform: scale3d(0.8, 0.8, 0.8);
  }

  to {
    opacity: 0;
  }
}

