.article-editor-frame
  > .don_wrapper
    > .header
      position: relative;
      .title
        position: absolute;
        top: 0;
        left: 60px;
        right: 60px;
        bottom: 0;
        display flex
        align-items center
        justify-content center

.article-editor
  padding 20px 16px 100px
  &__textarea
    width 100%
    height: 44vh;
    min-height 218px
    font-size 13px
    font-weight normal
    font-stretch normal
    font-style normal
    line-height 1.38
    letter-spacing -0.2px
    color $black
    box-sizing border-box
    border 0 none
    resize none
    outline none
    &::placeholder
      color $gray-50
  &__body-p
    display none
  &__upload
    margin-top 22px
  &__upload_title
    margin 0
    padding 0
    font-size 14px
    font-weight 600
    line-height 1.29
    letter-spacing -0.2px
    color #111
  &__upload_txt1
    color $gray-50
  &__require
    position: relative;
    color $white
    &:after
      content ''
      display block
      width ptr(4)
      height ptr(4)
      position absolute
      top ptr(2)
      left 0
      border-radius ptr(2)
      background-color $blue-100
  &__upload_list
    margin 10px 0 0
    padding 0
    list-style none
  &__upload_item
    position relative
    width 62px
    height 62px
    overflow hidden
    display inline-block
    margin 0 4px 6px 0
    > img
      position absolute
      top 0
      left 0
      right 0
      bottom 0
      margin auto
      width: 100%;
      height: 100%;
      object-fit cover;
      border-radius: ptr(8);
  &__upload_item_del
    position absolute
    top 0
    right 0
    width 21px
    height 21px
    cursor pointer
    background-color #111
    border-radius: ptr(8);
    img
      width 9px
      height 9px
      position absolute
      top 0
      right 0
      bottom 0
      left 0
      margin auto
  &__btn_add
    position relative
    width 62px
    height 62px
    margin 0 4px 6px 0
    display inline-block
    box-sizing border-box
    border-radius: ptr(8);
    border: 1px dashed $gray-20;
    svg
    img
      position absolute
      top 0
      left 0
      right 0
      bottom 0
      margin auto
  &__file_input
    display none
  &__btn_area
    position fixed
    left 0
    right 0
    bottom 0
    border-top 1px solid $gray-5
    margin-top ptr(16)
    padding ptr(16)
    background-color $white
  &__submit
    width 100%
    height ptr(56)
    font-size ptr(16)
    letter-spacing -0.2px
    color: $gray-50;
    text-align: center;
    font-family: Pretendard;
    font-weight: 700;
    line-height: ptr(24);
    letter-spacing: -0.2px;
    background $gray-5
    border 0 none
    border-radius ptr(8)
    &--on
      color $white
      background-color $blue-100


