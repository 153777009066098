$brand-logo = '.brand-logo'

$root-index = 1

{$bottom-tab-bar}
  display none
  position fixed
  left 0
  bottom 0
  width 100%
  border-top 1px solid #f8f8f8
  background-color #fff
  z-index 4
  padding-bottom env(safe-area-inset-bottom)
  padding-bottom constant(safe-area-inset-bottom)
  html[not-safe-area="true"] &
    padding-bottom 0
  html.safe_area_inset_bottom &
    padding-bottom 34px
  &--is-app
    display none
  &__inner
    displayFlex flex-start center
    padding 0 17px
  &__anchor
    flexCenter(column)
    width 100%
    height 66px
    &::before
      stripe-image-before 24px 24px '//s3.marpple.co/files/u_1396787/2021/4/original/caf99d7ea57e4fc400c0ff4df06f868aa30f517f1.png' 280px
      border-radius 5px
      margin-bottom 3px
    &--shopping
      &::before
        background-position -30px -3px
        ^[{$root-index}]__anchor--active^[{$root-index}..-{$root-index}]
          background-position -2px -2px
    &--live
      &::before
        background-position -86px -3px
        ^[{$root-index}]__anchor--active^[{$root-index}..-{$root-index}]
          background-position -58px -2px
    &--community
      &::before
        background-position -142px -2px
        ^[{$root-index}]__anchor--active^[{$root-index}..-{$root-index}]
          background-position -114px -3px
    &--search
      &::before
        background-position -198px -2px
        ^[{$root-index}]__anchor--active^[{$root-index}..-{$root-index}]
          background-position -170px -3px
    &--mypage
      &::before
        background-position -254px -2px
        ^[{$root-index}]__anchor--active^[{$root-index}..-{$root-index}]
          background-position -226px -3px
  &__text
    font 12px, 16px, -.2px
    color #a2a2a2
    ^[{$root-index}]__anchor--active ^[{$root-index}..-{$root-index}]
      color #111

{$brand-logo}
  flexCenter()
  display none
  ^[{$root-index}]__image
    &--small
      width pxToRem(48px)
      aspect-ratio 48 / 24
