@import './share-global.styl';
// @ Mixins
@import './style_mixins.styl';
@import './variables2.styl';
@import './color.styl';
@import './typography.styl';

// 마플샵 2.0
@import 'marppleshop2/Share/index.styl';

// @ Constants
@import './don.frame.mo.styl'
@import 'login_mo.styl'
@import '../all/unsupported_browser_notice.styl'

// core
@import "../../../modules/MShop/Core/F/Style/Mo/index.styl"

$app = '.app'
$creator = '.creator'
$bottom-tab-bar = '.bottom-tab-bar'

input:not([type="radio"]):not([type="checkbox"])
select
  -webkit-border-radius 0
  -webkit-appearance none

body.body-fixed
  position fixed !important
  top 0
  left 0
  right 0
  bottom 0
  &:before
    content ''
    display block
    position fixed
    top 0
    left 0
    right 0
    bottom 0
    z-index 2
    opacity 0.2
    background-color #000


h1, h2, h3, h4, h5, h6, b, string
  font-weight 600

#don_up
#ch-plugin-core
  display none !important
body.mshop
  //.don_loader_wrap
  textarea
  input
    font-family AppleSDGothicNeo, -apple-system, BlinkMacSystemFont, "Droid Sans", "Roboto", "Segoe UI", "Helvetica", Arial, sans-serif
  &.is-studio-iframe
    #don_frame_screen
      display block !important
  .m_br
    display block
  @import '../../../modules/MShop/App/Report/F/Style/Mo/index.styl'

  /* 마플샵 메인 */
  @import '../../../modules/Creator/Home/F/Style/Mo/home.styl';
  @import '../../../modules/MShop/App/Layout/F/Style/Mo/index.styl'
  @import '../../../modules/MShop/App/Main/F/Style/Mo/index.styl'
  @import '../../../modules/MShop/App/Home/F/Style/Mo/index.styl'
  @import '../../../modules/MShop/App/Today/F/Style/Mo/app-today.styl';
  @import '../../../modules/MShop/App/Seller/F/Style/Mo/index.styl'
  @import '../../../modules/MShop/App/Seller/BannerList/F/Style/Mo/index.styl'
  @import '../../../modules/MShop/App/Keyword/F/Style/Mo/index.styl'
  @import '../../../modules/MShop/App/Keyword/Drawer/F/Style/Mo/index.styl'
  @import '../../../modules/MShop/App/Community/F/Style/Mo/index.styl'
  @import '../../../modules/MShop/App/Community/Detail/F/Style/Mo/index.styl'
  @import '../../../modules/MShop/App/Community/MoCommentEditor/F/Style/Mo/index.styl'
  @import '../../../modules/MShop/App/Live/F/Style/Mo/index.styl'

  @import '../../../modules/MShop/App/Adult/Foreigner/F/Style/Mo/index.styl'
  @import '../../../modules/MShop/App/Adult/F/Style/Mo/index.styl'

  @import '../../../modules/MShop/App/Product/Sub/F/Style/Mo/index.styl'

  /* NFT */
  @import '../../../modules/MShop/App/Nft/F/Style/Mo/index.styl'

  /* 셀러샵 */
  @import '../../../modules/MShop/App/Store/Community/Editor/F/Style/Mo/index.styl'
  @import '../../../modules/MShop/App/Store/Community/F/Style/Mo/index.styl'
  @import '../../../modules/MShop/App/Store/About/F/Style/Mo/index.styl'
  @import '../../../modules/MShop/App/Store/About/EditModal/F/Style/Mo/index.styl'

  /* 마이페이지 */
  @import '../../../modules/MShop/App/Mypage/F/Style/Mo/index.styl'
  @import '../../../modules/MShop/App/Mypage/Like/F/Style/Mo/index.styl'
  @import '../../../modules/MShop/App/Mypage/Community/F/Style/Mo/index.styl'
  @import '../../../modules/MShop/App/Mypage/Recent/F/Style/Mo/index.styl'
  @import '../../../modules/MShop/App/Mypage/Account/F/Style/Mo/index.styl'
  @import "../../../modules/MShop/App/CustomerOrderReceipt/F/Style/Mo/index.styl"

  /* 상품상세 */
  @import '../../../modules/MShop/App/ProductDetail/F/Style/Mo/index.styl'
  @import '../../../modules/MShop/App/ProductDetail/Modal/F/Style/Mo/index.styl'
  @import '../../../modules/MShop/App/ProductDetail/Zoom/F/Style/Mo/index.styl'
  @import '../../../modules/MShop/App/TokenGate/StoresProduct/F/Style/Mo/index.styl'

  /* NFT 인증 */
  @import '../../../modules/MShop/App/TokenGate/ConnectSign/F/Style/Mo/index.styl'

  @import '../../../modules/MShop/App/UpItem/F/Style/Mo/index.styl'

  /* 컴포넌트 */
  @import '../../../modules/Components/BottomTabBar/F/Style/Mo/index.styl'
  @import '../../../modules/Components/Drawer/F/Style/Mo/index.styl'
  @import '../../../modules/Components/Footer/F/Style/Mo/index.styl'
  @import '../../../modules/Components/Header/F/Style/Mo/index.styl'

  /* 마플샵 2.0 스타일 */
  @import 'marppleshop2/Mo/index.styl';


//pb 리스트 스타일
@import '../../../modules/MShop/Studio/ListTemplate/F/Style/Mo/index.styl'

@import '../../../modules/MShop/Util/F/Style/Mo/toast.styl'
@import '../../../modules/MShop/Util/F/Style/Mo/tooltip.styl'

@import '../../../modules/MShop/ShareFrame/PopUp/F/Style/Mo/index.styl';
// body.mshop 위에 있어야할 친구들
@import '../../../modules/Components/Layout/F/Style/Mo/index.styl'

@import '../../../modules/MShop/App/Product/Item/F/Style/Mo/index.styl'


/* 셀러 신청 */
@import '../../../modules/MShop/App/AdRes/F/Style/Mo/index.styl'
@import '../../../modules/MShop/App/AdRes/TermsModal/F/Style/Mo/index.styl'
@import '../../../modules/MShop/App/AdRes/Finish/F/Style/Mo/index.styl'
/* 토큰게이팅 */
@import "../../../modules/MShop/Studio/TokenGate/StoresProduct/F/Style/Mo/index.styl"
/* faq */
@import '../../../modules/MShop/App/Help/F/Style/Mo/index.styl'
// 스튜디오 새로운 테이블
@import "studio/studio.table.styl"
// 스튜디오 출금 신청
@import "studio/studio.withdraw.styl"

// 스튜디오 샵 정보
@import '../../../modules/MShop/Studio/ShopInfo/F/Style/Mo/index.styl'

// 스튜디오 상품 등록
@import '../../../modules/MShop/Studio/PbProductCreator/F/Style/Mo/index.styl'

// 스튜디오 디지털/전자책 등록
@import '../../../modules/MShop/Studio/DigitalProductCreator/F/Style/Mo/index.styl'

// pb주문서내역
@import '../../../modules/MShop/Studio/PbItemOrders/F/Style/Mo/index.styl'
@import '../../../modules/MShop/Studio/PbOrders/F/Style/Mo/index.styl'

// 디지털/전자책 주문 내역
@import '../../../modules/MShop/Studio/DigitalProductItemOrders/F/Style/Mo/index.styl'

// 셀러 정보
@import '../../../modules/MShop/Studio/EcommerceInfo/F/Style/Mo/index.styl'
// 배송 정책 추가하기
@import '../../../modules/MShop/Studio/EcommerceInfo/StoresDeliveryCompany/F/Style/Mo/index.styl'
//@import '../../../modules/MShop/Studio/ListTemplate/F/Style/Mo/index.styl'

// 환불클레임 관련
@import '../../../modules/MShop/Studio/RefundOrders/F/Style/Mo/index.styl'

// 문의 내역
@import '../../../modules/MShop/Studio/Question/List/F/Style/Mo/index.styl'
@import '../../../modules/MShop/App/Store/Question/F/Style/Mo/index.styl'
@import '../../../modules/MShop/App/Store/QuestionList/F/Style/Mo/index.styl'
@import '../../../modules/MShop/App/Store/QuestionDetail/F/Style/Mo/index.styl'

// pb 상품 관리
@import '../../../modules/MShop/Studio/PbProduct/F/Style/Mo/index.styl'
@import '../../../modules/MShop/Studio/PbProductsManager/F/Style/Mo/index.styl'

@import '../../../modules/MShop/Studio/PbItemOrderDetail/F/Style/Mo/index.styl'

// 마플샵, 어드민과 공통 사용 부분
@import '../../../modules/MShop/App/Product/Filter/F/Style/Mo/index.styl'
@import '../../../modules/MShop/App/Today/F/Style/Mo/app-today-detail.styl'
@import '../../../modules/Creator/Editor/F/Style/Mo/editor.button.styl';
@import '../../../modules/MShop/App/Today/F/Style/Mo/today.styl';
@import '../../../modules/MShop/App/Product/F/Style/Mo/index.styl'
@import '../../../modules/MShop/App/Store/Product/F/Style/Mo/index.styl'
@import '../../../modules/MShop/Studio/ProductGuidePopup/F/Style/Mo/index.styl'
@import '../../../modules/MShop/Studio/PbProductTip/F/Style/Mo/index.styl'
@import '../../../modules/MShop/Studio/PbProductSize/F/Style/Mo/index.styl'

@import '../../../modules/MShop/PlusAdmin/Product/F/Style/Mo/index.styl'
@import '../../../modules/MShop/PlusAdmin/Youtube/F/Style/index.styl'

/* 장바구니 */
@import '../../../modules/MShop/App/Cart/F/Style/Mo/index.styl'
/* 주문서 */
@import '../../../modules/MShop/App/Checkout/F/Style/Mo/index.styl'

/* 구매내역 */
@import '../../../modules/MShop/App/CustomerOrders/F/Style/Mo/index.styl'
@import '../../../modules/MShop/App/CustomerOrders/Modal/F/Style/Mo/index.styl'
@import '../../../modules/MShop/App/CustomerOrder/F/Style/Mo/index.styl'
@import '../../../modules/MShop/App/CustomerOrder/AdditionalPay/F/Style/Mo/index.styl'

@import '../../../modules/MShop/App/ShippingInfo/F/Style/Mo/index.styl'
@import '../../../modules/MShop/ShareFrame/RefundBank/F/Style/Mo/index.styl'

/* 유튜브 채널아이디 목록 */
@import "../../../modules/MShop/Youtube/SelectChannel/F/Style/Mo/index.styl"
@import "../../../modules/MShop/Youtube/Bridge/F/Style/Mo/index.styl"
@import "../../../modules/MShop/Youtube/BridgeShopping/F/Style/Mo/index.styl"

/* 오프라인 팝업스토어 랜딩 */
@import "../../../modules/MShop/Landing/OfflinePopupStore/F/Style/Mo/index.styl"

/* 셀러 신청 랜딩 */
@import "../../../modules/MShop/Landing/Creator/F/Style/Mo/index.styl"

/* 브랜드 커머스 랜딩 */
@import "../../../modules/MShop/Landing/BrandCommerce/F/Style/Mo/index.styl"

/* 신고접수 */
@import '../../../modules/MShop/App/Solution/F/Style/Mo/index.styl'

/* 에러페이지 */
@import '../../../modules/MShop/App/Error/F/Style/Mo/index.styl'

@import "../../../modules/MShop/App/Product/Tag/F/Style/index.styl"
// pb 팝업
@import '../../../modules/MShop/Studio/PbProductGuidePopup/F/Style/Mo/index.styl'

// 기존과 다른 로그인 로직
@import '../../../modules/MShop/Auth/F/Style/Mo/index.styl'
@import '../../../modules/MShop/Core/F/Style/Mo/index.styl'

// crew authorization 회원가입
@import "../../../modules/MShop/ExtraAuth/Crew/F/Style/Mo/index.styl"

// custom-alert
@import "../../../modules/Alert/S/Tmpl/alert_mo.styl"
@import "../../../modules/MShop/App/Components/Item/F/Style/Mo/index.styl"

/* 스튜디오 리스트 모바일전용 */
@import '../../../modules/MShop/Studio/F/Style/Mo/index.styl'

// 결제 완료
@import "../../../modules/MShop/App/Receipt/F/Style/Mo/index.styl"
// 이용 약관
@import "./terms.styl"

/* mshop 플러스, nbox 와 함께 리뉴얼 디자인 레이아웃 */
@import './layout/Mo/index.styl';
@import './icon.styl';
@import '../../../modules/MpShopPlus/Main/F/Style/Mo/index.styl'
@import '../../../modules/MpShopPlus/Product/F/Style/Mo/index.styl'
@import '../../../modules/MpShopPlus/Terms/F/Style/Mo/index.styl'
@import '../../../modules/MpShopPlus/Privacy/F/Style/Mo/index.styl'

/*팝업스토어*/
@import "../../../modules/MShop/App/PopUpStore/F/Style/Mo/index.styl"
@import "../../../modules/MShop/App/PopUpStore/QrPopUp/F/Style/Mo/index.styl"
@import "../../../modules/MShop/App/PopUpStore/OrderDetail/F/Style/Mo/index.styl"

@import "../../../modules/BpOption/UploadType/F/Style/Mo/index.styl"

@import "./maker_mobile.styl"

html[is_app="true"]
  *
    -webkit-tap-highlight-color transparent !important

  body.mshop
    {$creator}-footer
      &--is-tab
        padding-bottom 0
  input
  textarea
    &:focus
      outline none


#creator_layer_pop
#creator_layer_pop2
#creator_layer_pop3
#creator_layer_pop4
#creator_layer_pop5
#creator_layer_pop6
#creator_layer_pop7
#creator_layer_popup_notice
#nbox_layer_pop1
  position absolute
  top 50%
  transform translateY(-50%)
  left pxToRem(20)
  right pxToRem(20)
  z-index 10
  width auto !important
  overflow hidden
  .banner
    a
      display block
      width 100%
    img
      width 100%
      vertical-align top
    .pc
      display none
  .creator_layer_btm
    position relative
    padding-top 16%
    background-color #333
    .one_day_close
      display flex
      align-items center
      justify-content center
      position absolute
      top 0
      left 0
      right 30%
      bottom 0
      padding 0 10px
      cursor pointer
      font-size 12px
      font-weight 400
      color #fff
    .close
      display flex
      align-items center
      justify-content center
      position absolute
      top 0
      left 70%
      right 0
      bottom 0
      color #fff
      font-size 12px
      font-weight 400
      border-left 1px solid #222
      img
        position absolute
        top 0
        left 0
        right 0
        bottom 0
        height 10px
        margin auto


#creator_layer_popup_notice
  a.link1
    display: block;
    position: absolute;
    top: 44%;
    left: 8%;
    right: 30%;
    bottom: 52.4%;
    width: auto;
  a.link2
    display: block;
    position: absolute;
    top: 47.4%;
    left: 8%;
    right: 30%;
    bottom: 49%;
    width: auto;

.postcode_layer
  box-sizing border-box
  margin-top pxToRem(10)
  margin-bottom pxToRem(16)
  display none
  height pxToRem(500)
  width 100%
  overflow hidden
  z-index 100
  border pxToRem(5) solid #000
  padding-top pxToRem(16)
  position relative
  background white
  .postcode_close
    cursor pointer
    position absolute
    right pxToRem(-3)
    top pxToRem(-3)
    z-index 1
