.app-product-sub
  margin-top 24px
  margin-bottom 72px
  padding 0 24px
  box-sizing border-box
  overflow hidden
  min-height 650px
  &__header
    margin 0 24px 20px
  &__title
    line-height 1.3
    letter-spacing -0.3px
    color #111111
    font-size 23px
    font-weight 600
    margin-bottom 40px
  &__word
    margin-bottom 11px
    margin-right 2px
    display inline-block
    vertical-align middle
  &__selected-wrapper
    position relative
  &__selected-filter
    reset-button()
    position relative
    border-bottom 2px solid #111
    display flex
    align-items center
    color #111111
    font-size 23px
    font-weight 600
    &:after
      content ''
      margin-left 8px
      display block
      width 15px
      height 15px
      background-image url(//s3.marpple.co/files/u_29089/2021/5/original/534e7691127d5ffb69bf7267441f5135a1c71f5e1.png)
      background-repeat no-repeat
      background-size 15px 15px
  &__sort-selector
    position absolute
    top 0
    left 0
    right 0
    opacity 0
    select
      width 100%
      padding 5px 0
  &__style-btn
    margin 0
    background #fff
    padding 8px 14px 6px
    border-radius 16px
    border solid 1px #dddddd
    line-height 1.29
    letter-spacing -0.2px
    color #4e4e4e
  &__selected-style
    display flex
    align-items baseline
    justify-content space-between
    margin-top 6px
    &[data-len="0"]
      margin-top 0
      .app-product-sub__style-init-btn
        display none
  &__style-init-btn
    reset-button()
    line-height 1.4
    letter-spacing -0.2px
    color #4e4e4e
    flex-shrink 0
  &__style-list
    display flex
    flex-wrap wrap
  &__style-item
    reset-button()
    margin 6px 12px 0 0
    line-height 1.29
    letter-spacing -0.2px
    color #1C75FF
    display flex
    align-items center
    &:after
      content ''
      margin 0 0 2px 4px
      width 9px
      height 9px
      background-image url(//s3.marpple.co/files/u_29089/2021/5/original/c4f08c57632db2de339db1296e4410432c54e5b41.png)
      background-size 9px

  &__list
    margin 0 -.5px
    &--false
      display grid
      grid-template-columns repeat(2, minmax(0, 1fr))
      gap 0 16px
    .app-product-item--nbox
      width 100%
    &--app-store
      margin 0 -3.5px
.app-product-zero
  margin 56px 24px 0
  display flex
  flex-direction column
  align-items center
  text-align center
  &__sad-icon
    width 62px
    height 62px
    margin-bottom 12px
    font-size 0
    img
      width 100%
  &__title
    font-size 18px
    font-weight 600
    line-height 1.56
    letter-spacing -0.2px
    color #4e4e4e
    margin-bottom 6px
  &__description
    font-size 15px
    font-style normal
    line-height 1.27
    letter-spacing -0.2px
    color #a2a2a2
    margin-bottom 30px
  &__back
    display block
    padding 9px 18px 7px
    border-radius 4px
    border solid 1px #dddddd
    font-size 16px
    line-height 1.25
    letter-spacing -0.2px
    color #4e4e4e
