.adult-access-denied
  text-align center
  &__img
    width 100px
    height 100px
    margin-bottom 24px
    font-size 0
  &__title
    font-size 16px
    font-weight bold
    line-height 1
    letter-spacing -0.2px
    text-align center
    color #111
    margin-bottom 10px
  &__description
    font-size 13px
    line-height 1.69
    letter-spacing -0.2px
    text-align center
    color #111
.adult-item
  left 50%
  border 1px solid #dfdfdf
  color #dfdfdf
  display flex
  justify-content center
  align-items center
  border-radius 100%
  width 67px
  height 67px
  &__age
    font-weight 100
    font-size 26px
    position relative
    margin 4px 0 0
  &--19
    .adult-item__plus
      display none
  &--18
    .adult-item__age
      margin 4px 0 0 8px
    .adult-item__plus
      font-weight 300
      font-size 15px
.adult-item-wrap
  position absolute
  top 0
  left 0
  bottom 0
  right 0
  background #f3f3f3
  border-radius 100%
  display flex
  justify-content center
  align-items center
  .adult-item
    width 50px
    height 50px
    &__age
      font-weight 100
      font-size 20px
      position relative
      margin 4px 0 0
    &--19
      .adult-item__plus
        display none
    &--18
      .adult-item__age
        margin 4px 0 0 8px
      .adult-item__plus
        font-weight 200
        font-size 15px

.adult-lang
  margin-top ptr(12)
  display flex
  align-items center
  justify-content center
  gap ptr(32)
  &__text
    color $blue
