// modules/MShop/App/PopUpStore/OrderDetail/F/Style/Mo/index.styl

.don_frame[frame_name="/modules/MShop/App/PopUpStore/OrderDetail/S/Mui/frame.js"]
  .orderdetail
    padding 20px
    background-color white
    box-sizing border-box
    //height 100vh
    display flex
    flex-direction column
    &__header
      position relative
      text-align center
      padding-bottom 10.4px
    &__footer
      font-size 11px
      line-height 15px
      font-weight 400
      color #999
      margin-top auto
    &__content
      display flex
      flex-direction column
      height 100%

  .content-title-wrap
    &__title
      .title-wrap
        display flex
        flex-direction row
        justify-content space-between
        padding-bottom 4px
        padding-top 29.53px
        &__title
          font-size 20px
          line-height 28px
        &__reset
          font-size 13px
          color #999
          display flex
          align-items center
          .refresh_icon
            padding 4px
      &__notice
        color #4C4C4C
        font-size 14px
        font-weight 400
    &__search-wrap
      display flex
      flex-direction column
      gap 8px
      padding-top 21px
    &__search-wrap__search
      display flex
      flex-direction row
      height 40px
      gap 4px
      &__select
        padding 11px 10px
        border-radius 3px
        border 1px solid #EDEDED
        background-color white
        display flex
        gap 12px
        width 95px
        min-width 0
        justify-content space-evenly
        /* box-sizing: border-box; */
        > select
          -webkit-appearance none
          border none
          font-size 13px
          font-weight 400
          background-color transparent
          color black
          padding 0
          &:focus
            outline none
      &__input
        padding 11px
        border 1px solid #ededed
        border-radius 3px !important
        font-size 13px
        flex-grow 1
        min-width 0
        &:focus
          outline none
      &__button
        max-width 47px
        width 21%
        padding 11px
        border-radius 3px
        background: var(--Gray-700, #4E4E4E);
        color white
        border none
        font-size 13px
    &__search-wrap__date
      padding-left 12px
      border-radius 3px
      border 1px solid #EDEDED
      background-color white
      display flex
      gap 12px
      height 40px
      align-items center
      margin-bottom 20px
      input[type="date"]
        border none
        background-color transparent
        color black
        font-size 13px
        &::-webkit-inner-spin-button,
        &::-webkit-calendar-picker-indicator
          display none
          -webkit-appearance none
    &__content-table
      border 1px solid #EDEDED
      background-color white
      border-radius 3px
      height 100%
      margin-bottom 20px

  .content-table
    width 100%
    border-collapse collapse
    &__thead
      border-bottom 0.828px solid #4E4E4E
      th
        height 42px
        font-weight 600
      &__status
        padding-right 12px
    &__tbody
      text-align center
      &__date
        width 60px
        padding-left 12px
      &__order-number
        width 90px
      &__name
        width 80px
        color black
      &__status
        width 50px
        padding-right 12px
      > tr
          height 56px
          border-bottom 1px solid var(--Gray-200, #F2F2F2)
        .canceled-tr
          background: var(--Gray-100, #F8F8F8)
          color: var(--Gray-500, #A2A2A2)
        .created-tr
          background: var(--Sky-100, #F5F9FE)
      .canceled
        color #999
      .created
        color blue
        font-weight 600

  ul.pagination-popup
    margin 0
    padding 40px 0
    gap 8px
    .pagination-item
      &[data-page_type="prev"]
        padding-right 8px
      &[data-page_type="next"]
        padding-left 8px
      &[data-page_type="page"]
        a
          padding 0
          width 30px
          height 30px
          font-size 14px
          font-weight 500
          border-radius 8px
          background-color #EDEDED
          color #A2A2A2
          display flex
          align-items center
          justify-content center
        a.current
          background-color #3C89F9
          color white

