ic-close = url("//s3.marpple.co/files/u_18054/2018/9/original/f_5853_1537163999801_xZp4kmo6Zse1am5Ts1h9W.svg")
down_close = url(//s3.marpple.co/files/u_14355/2018/11/original/f_12437_1541409165567_lrHD9Mhcw3lhZ9a9p8kl.png);
$icon-hide = url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1989_62946)'%3E%3Cpath d='M13.2583 11.9986L20.7379 4.50859C21.0874 4.15859 21.0874 3.59859 20.7379 3.25859C20.3884 2.90859 19.8291 2.90859 19.4796 3.25859L12 10.7386L4.52039 3.25859C4.17087 2.90859 3.61165 2.90859 3.26214 3.25859C2.91262 3.60859 2.91262 4.16859 3.26214 4.50859L10.7417 11.9986L3.26214 19.4886C2.91262 19.8386 2.91262 20.3986 3.26214 20.7386C3.4319 20.9086 3.66158 20.9986 3.89126 20.9986C4.12094 20.9986 4.34064 20.9086 4.52039 20.7386L12 13.2586L19.4796 20.7386C19.6494 20.9086 19.8791 20.9986 20.1087 20.9986C20.3384 20.9986 20.5581 20.9086 20.7379 20.7386C21.0874 20.3886 21.0874 19.8286 20.7379 19.4886L13.2583 11.9986Z' fill='%23141414'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1989_62946'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A")
.don_frame
  &.animating_back
    overflow hidden
    position fixed !important
  &.animating_front
    position fixed !important
    overflow hidden
    //transform none
    height auto
.don_page
  &.animating_back
    position fixed
    overflow hidden
  &.animating_front
    overflow-y hidden
    top 0
    left 0
    width 100%
    transform none
    height auto
    box-shadow -5px 0 5px -5px #ddd
.don_frame
.don_page
.don_tab
  position relative
  width 100%
  background-color #fff
  >.don_wrapper
    >.body
      position relative
.don_page
  min-height 100%
  &[tab_length="0"]
  &[tab_length="1"]
    >.don_wrapper
      >.header
        .tab_buttons
          display none
  &[page_index="0"]
    .don_wrapper
      >.header
        .don_back_page
          display none
  &[hide_frame_button_type="V"]
  &[hide_frame_button_type="v"]
    >.don_wrapper
      >.header
        .don_hide_frame
          display block
          background-color transparent
          position absolute
          background-position 50% 50%
          background-repeat no-repeat
          background-size 16px
          background-image url(//s3.marpple.co/files/u_193535/2018/12/original/f_18632_1545117841977_a8X6NA3p8T4cNCvpowk4t.png)
          width 36px
          height 36px
          top 14px
          right 6px
          border none
          outline none
          &:active
            background-color #ced4da
  &[hide_frame_button_type="v"]
    >.don_wrapper
      >.header
        .don_hide_frame
          background-image url(//s3.marpple.co/files/u_14355/2018/11/original/f_12437_1541409165567_lrHD9Mhcw3lhZ9a9p8kl.png)
          top 6px
.don_frame
  left 0
  right 0
  bottom 0
  width 100%
  box-sizing border-box
  &[is_height="false"]
    position absolute
    top 0
  &[is_height="true"]
    position fixed
    background-color transparent
  >.don_wrapper
    >.header
      position fixed
      top 0
      left 0
      width 100%
  &[is_height="true"]
    >.don_wrapper
      >.header
        position absolute
  .don_back_page
    position absolute
    top 4px
    left 4px
    display block
    padding 0
    cursor pointer
    width 40px
    height 40px
    background url(//s3.marpple.co/files/u_14355/2018/11/original/f_12438_1541409323249_q9vQ8BNlU4U9lrAq6ER5V.png) no-repeat
    background-size 19px auto
    background-position 50%
  .don_frame[frame_name="mp.checkout.change_email"]
    .don_page
      >.don_wrapper >.header
        height 48px
  &[hide_frame_button_type="X"]
  &[hide_frame_button_type="x"]
    >.don_wrapper
      >.header
        .don_hide_frame
          display block
          background-color transparent
          position absolute
          background-position 50% 50%
          background-repeat no-repeat
          background-size 18px
          background-image url(//s3.marpple.co/files/u_14345/2021/5/original/e6fc7c6c93a1091aa268db9f6fa0730c8da9793d1.svg)
          width 24px
          height 24px
          top 35-(12/2)px
          right 24-(12/2)px
          border none
          outline none
  &[hide_frame_button_type="V"]
    .don_wrapper >.header >.title
      text-align center
      line-height 48px
      font-size 14px
      color #000
      font-weight bold
    .don_page
      background #fff

body.mshop
  .don_frame:not([frame_index="0"])
    >.don_wrapper
      >.header
        z-index 1
        display flex
        justify-content space-between
        align-items center
        padding 32px 24px 24px
        box-sizing border-box
        background #fff
        .title
          font-size 18px
          font-weight bold
          line-height 1.56
          letter-spacing -0.2px
          color #111111
        .don_hide_frame
          background-image $icon-hide
          position absolute
          top 18px
          right 16px
          background-size 24px

body.mshop.login
  .don_frame:not([frame_index="0"])
    >.don_wrapper
      >.header
        padding 0

body.creator-settings
  .don_frame.frame-studio-modal-style[is_modal="false"]
    >.don_wrapper
      >.header
        display flex
        justify-content space-between
        align-items center
        padding 28px 32px 24px
        box-sizing border-box
        background-color #fff
        .title
          font-size 20px
          font-weight bold
          line-height 1.3
          letter-spacing -0.3px
          color #111111
      .inner_hidden
        display none
