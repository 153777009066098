new-maker_font_14_r_140 =
  font-style normal
  font-weight 400
  font-size 14px
  line-height 140%
.don_frame[frame_name="/modules/NewMaker/Components/ImageSelectFrame/S/Mui/frame.js"]
  .header
    .title
      border-radius 20px

.mp_maker
  &[data-is_dosu_color_app="false"]
    .dosu_image_color
      display none !important
  &[data-is_black_and_white_contrast="true"]
    .pattern.circle_icon
      display none !important
.don_page[page_name="/modules/NewMaker/Components/ImageSelectFrame/S/Mui/page.js"]
  .header
    padding 16px
    text-align center
    font-weight 500
    button
      position absolute
      right 16px
      font-size 14px
      background white
      border 0
      color #FF6B00
      font-weight 500
  .body
    .image_selector_wrap
      .maker-image-selector
        &__options
          display grid
          padding 12px
          overflow auto
          .paper_option
            padding 12px
            text-align center
            color #858585
            cursor pointer
            &__img
              width 100%
            &__img[data-column_length="2"]
              max-width 164px
            &__img[data-column_length="3"]
              width 64px
            &__name
              padding-top 8px
              font-size 14px
          .selected_option
            color #FF6B00

@import "checkbox.styl"
@import "ConditionPopup.styl"
@import "ConditionTooltip.styl"
@import "../../../../../modules/NessCustomMaker/F/Style/Mo/maker_image.styl";
