@import '../../../S/Style/guide.styl';

$review-img-width = ptr(160);
$color-chip-width = ptr(36);
$color-chip-gap = ptr(16);

html[can_selling="false"].product-detail
  .delivery-notification
  button[data-goto="cart"]
  button[data-goto="checkout"]
  div[data-item_name="period_limit"]
  div[data-item_name="quantity_limit"]
    display none !important

body.product-detail
  .store-body
    #product_detail[is_selling_product="false"]
      html[is_seller="false"] &
        .delivery-notification
        button[data-goto="checkout"]
        button[data-goto="cart"]
          display none

// root에 선언하기 위해서 '/' 기호 사용
/.product-detail-page
  .LayoutView
    padding-bottom ptr(72)

.pd-review
  &__title
    {$kr-16-b}
    margin-bottom ptr(8)
  &__empty
    $flex(center, center, column)
    gap ptr(8)
    padding ptr(80) 0
    h4
      {$kr-16-b}
      color $gray-80
      margin 0
    p
      {$kr-14-m}
      color $gray-500
      margin 0
      text-align center

#pd-review
  .pd-review-more
    text-align center
    padding 9px 10px 8px
    cursor pointer
    width 100%
    box-sizing border-box
    margin 0 auto
    color #a2a2a2
    border-radius 22px
    border solid 1px #dddddd
    background-color #ffffff
  .pd-review-list
    .grid-stack-container__item_wrapper
      padding ptr(24) 0
      margin-bottom 0 !important
    .grid-stack-container__hide_wrapper + .grid-stack-container__hide_wrapper
    .grid-stack-container__item_wrapper + .grid-stack-container__item_wrapper
      border-top 1px solid $gray-100
    .grid-stack-container__hide_wrapper:first-child > .grid-stack-container__item_wrapper:first-child
      padding-top 0

  .pd-review-item
    width 100%
    position relative
    padding ptr(24) 0
    border-bottom 1px solid $gray-10
    &__wrapper
      $flex(space-between, stretch, column)
    &__info
      $flex(space-between, flex-start)
      gap ptr(12)
      padding-bottom ptr(8)
    &__user
      $flex(flex-start, center)
      gap ptr(12)
    &__profile
      flex-shrink 0
      width ptr(42)
      height ptr(42)
      img
        width 100%
        height 100%
        object-fit cover
        border-radius 50%
    &__name
      margin 0
      color $gray-800
      margin-left ptr(2)
      & > span
        limitLine(1)
        margin-right ptr(16)
      & > a
        display flex
        align-items center
        column-gap ptr(4)
        margin-right ptr(16)
        & > span
          limitLine(1)
        & > img
          flex-shrink 0
    &__date
      flex-shrink 0
    &__star
      .article-review-score
        $flex(flex-start, center)
      .star
        display inline-block
        margin-right pxToRem(2px)
        width pxToRem(16px)
        height pxToRem(16px)
        background url("//s3.marpple.co/files/u_2308668/2024/4/original/28137197cd5a35fee38531263c8ff9a856cfc9731.png") no-repeat center center
        background-size pxToRem(16px) auto
        &[is="true"]
          background-image url("//s3.marpple.co/files/u_2308668/2024/4/original/62c1c5e9e64dcdbf684e8dec0b6b8444ee3a81f01.png")
    &__content
      $flex(space-between, flex-start)
    &__body
      $flex(space-between, stretch, column)
      {$kr-14-m}
      color $black
      margin-bottom ptr(8)
    &__option
      margin 0 0 ptr(4)
      {$kr-12-m}
      color $gray-50
    &__sns
      $flex(flex-start, center)
      display inline-flex
      align-self flex-start
      gap ptr(4)
      margin-top ptr(8)
      padding ptr(4) ptr(8)
      {$kr-12-m}
      color $blue
      border-radius ptr(4)
      background $blue-10
      svg
        width ptr(14)
    &__images
      flex-shrink 0
      width ptr(108)
      height ptr(108)
      margin-left ptr(16)
    &__img
      position relative
      width 100%
      height 100%
      aspect-ratio 1/1
      border-radius ptr(16)
      overflow hidden
      img
        width 100%
        height 100%
        object-fit cover
      .dim
        $flex(center, center)
        position absolute
        inset 0
        background $dim-30
        border-radius ptr(8)
        {$en-16-unica-m}
        color $white
    &__meta
      width 100%

&.body-fixed.mobile-cart-form-open:before
.pd-form-modal.don_frame
.token-gate-frame.don_frame
  bottom ptr(84)
  padding-bottom env(safe-area-inset-bottom)
  padding-bottom constant(safe-area-inset-bottom)
  html[not-safe-area="true"] &
    padding-bottom 0
  html.safe_area_inset_bottom &
    margin-bottom 34px

.pd
  common()
  width 100%
  margin 0 auto
  position relative
  .tag
    padding 3px 8px 1px 9px
    border-radius 4px
    font-size 16px
    line-height 1.38
    &[data-tag="coming_soon"]
      background-color rgb(51, 203, 181)
    &[data-tag="new"]
      background-color rgba(255, 0, 0, 0.8)
    &[data-tag="limited_edition"]
      color #ffffff
      background-color #24d8b3
    &[data-tag="sold_out"]
      background-color rgba(0, 0, 0, 0.8)
  .swiper
    &-container[data-len="1"]
      .swiper-pagination,
      .swiper-button-next,
      .swiper-button-prev
        display none !important
    &-button-next.custom
      right 17px
    &-button-prev.custom
      left 17px
    &-button-next.custom, &-button-prev.custom
      background-image none
      width 22px
      height 52px
    &-pagination-fraction
      width auto
      left auto
      bottom auto
      top 20px
      right 20px
      font-size 13px
      font-weight 200
      line-height 1.38
      color #ffffff
      padding 3px 10px 1px
      border-radius 13px
      background-color rgba(17, 17, 17, 0.3)
    &-pagination-progressbar
      top auto !important
      left 0 !important
      bottom 0 !important
      height 2px !important
      background transparent
      &-fill
        background $gray-800
    &-scrollbar
      position static
      left auto
      bottom auto
      height ptr(2)
      margin-top ptr(12)
    &-pagination-bullets
      bottom ptr(16)
    &-pagination-bullet
      width ptr(6)
      height ptr(6)
      border-radius ptr(6)
      transition width 0.3s
    &-pagination-bullet-active
      width ptr(16)
      background $gray-80
  &__content
    width 100%
    #t_shirts_sizes
    #promotional_products
    .mt
      margin-top ptr(24)
  &__options
    position relative
  &__max-purchase-per-user
    display flex
    align-items center
    gap ptr(6)
    margin ptr(20) 0 ptr(4)
    padding ptr(12)
    border-radius ptr(8)
    {$font-body-b3-r}
    background-color $red-200
    color $red-700
  &__seller
    position relative
  &__colors .colors
    padding-bottom ptr(8)
  &__action-btn.share
    reset-button()
    control-button()
    square(24px)
    background transparent
  &__action-btn.like
    &.in_mobile_form
      control-button()
      d-flex(center, center)
      square(52px)
      padding 0
      color $gray-20
      background #fff
      box-sizing content-box
      border-radius 50%
      svg
        width ptr(32)
        height ptr(32)
      &.like.active
        color $blue


    html[not-safe-area="true"] &
      padding-bottom 0
    html.safe_area_inset_bottom &
      margin-bottom 34px
    &.active
      svg g g
        fill #ec1131
        stroke #ec1131
  &__action-btn.report
    display none
    reset-button()
    margin-left 5px
  &__action-box
    displayFlex(flex-start, center)
    position absolute
    top 0
    right 0
  &__hyundai_n
    position relative
  &__dtg-info
    display flex
    flex-direction column
    gap ptr(16)
    padding ptr(24) 0 0
    margin ptr(12) 0 0
    border-top 1px solid $gray-10
    & > .info-wrap
      display flex
      gap ptr(16)
    & .title
      min-width ptr(70)
      {$kr-12-m}
      color $gray-50
    & .contents
      $flex(flex-start, flex-start, column)
    & .content
      $flex(flex-start, center)
      gap ptr(8)
      {$kr-12-m}
      color $black
      & > .file-type-msg
        word-break break-word
      & > .file-type-msg-divider
        width 1px
        height 12px
        background-color #dddddd
        display inline-block
      & > .flex-shrink-zero
        flex-shrink 0
      &__description
        color #A2A2A2
        font-size 13px
        font-weight 400
        letter-spacing -0.5px
        margin-top 8px
        max-width 278px
    & .dtg-disabled
      $flex(flex-start, flex-start)
      gap ptr(4)
      color $gray-80
      & > .pd-submit__disabled-text
        {$kr-14-m}
      & > svg
        flex-shrink 0
        margin-top ptr(3)
        width ptr(16)
  &__quantity
    display flex
    flex-direction column

.pd-contents
  margin-top $default-header-mo_height
  margin-bottom ptr(70)
  background $gray-5
  &__group
    padding 0 ptr(16)
  &__group:not(:last-child)
    margin-bottom ptr(60)
  & .preview
    &__title
      {$kr-14-m}
      margin-bottom ptr(8)
    &__player
      display none
    &__custom-player
      $flex(space-between, center)
      gap ptr(12)
      height ptr(64)
      padding ptr(16)
      border-radius ptr(8)
      border solid 1px $gray-10
      box-sizing border-box
      background $white
    &__play-buttons
      background-color $light-blue
      border-radius 50%
      width ptr(32)
      height ptr(32)
      display flex
      justify-content center
      align-items center
      padding 0
      cursor pointer
    &__play-button
      border none
      outline none
      margin 0
      padding 0
      background transparent
      width ptr(32)
      color $blue
      font-size 0
      svg
        width ptr(20)
      .play-icon
        display none
      .pause-icon
        display block
      &.pause
        .play-icon
          display block
        .pause-icon
          display none
    &__progress-container
      height ptr(20)
      flex 1
      display flex
      align-items center
      width 100%
    &__progress-background
      border-radius ptr(4)
      background-color $gray-5
      height ptr(4)
      width 100%
      position relative
    &__progress
      position absolute
      top 0
      left 0
      background-color $ss-sky
      height ptr(4)
      border-radius ptr(4)
    &__time-container
      -ms-user-select none
      -moz-user-select -moz-none
      -khtml-user-select none
      -webkit-user-select none
      user-select none
    &__time
      {$en-14-unica-m}
      color $gray-80
    &__pb-multi-options
      display none

  &__extra
    margin ptr(8)
  &__options
    $flex(flex-start, stretch, column)
    gap ptr(12)
    padding ptr(16) ptr(16) ptr(24)
    background $gray-5
  &__devices
    padding-top ptr(12)
    & + .pd-contents__colors
      padding 0
  &__colors
    padding ptr(8) 0
  &__guides
    $flex(flex-start, stretch, column)
    gap ptr(16)
    margin-top ptr(12)
    padding ptr(24) 0 0
    border-top 1px solid $gray-10
    > div
      width 100%
  &__popup
    margin 0 0 ptr(24)
    img
      width 100%
  &__tabs
    position sticky
    top $default-header-mo_height
    background $white
    z-index 3
  &__tabs-contents
    padding ptr(24) 0 0
    background $white


.pd-links
  $flex(center, center)
  gap ptr(8)
  width 100%
  &__item
    $flex(center, center)
    padding ptr(9) ptr(24)
    height ptr(48)
    {$kr-14-b}
    color $gray-80
    white-space nowrap
    border-radius ptr(8)
    border 1px solid $gray-20
    flex 1

.pd-noti-container
  position absolute
  bottom 105%
  left 50%
  transform translateX(-50%)
  &__triangle
    position absolute
    top 100%
    left 50%
    width ptr(13)
    font-size 0
    transform translateX(-50%) rotate(180deg)
  .pd-noti
    position relative
    $flex(center, center)
    padding ptr(16)
    height ptr(36)
    color $red-80
    border-radius ptr(999)
    background #FFE4FF
    &__label
      $flex(center, center)
      gap ptr(4)
      {$kr-14-m}
      white-space nowrap
      svg
        width ptr(16)

.pd-tabs
  $flex(space-between, stretch)
  &__item
    padding ptr(12) 0
    flex 1
    {$kr-14-b}
    color $gray-50
    text-align center
    cursor pointer
    border-bottom 2px solid $gray-20
    &.active
      border-color $black !important
    &.active, &:hover
      color $black

.pd-story
  padding 0 ptr(16)
  .product-story-image
    display flex
  &__title
    {$kr-24-b}
    margin 0 0 ptr(8)
  &__desc
    margin 0
    word-break break-all
  &__section
    margin-top ptr(24)
  &__section.images
    img
      width 100%
  &__section.sns
    > blockquote
      max-width calc(100%) !important
    iframe:not(.instagram-media)
      width 100%
      min-height calc((100vw - 48px) * 0.5625)
    .youtube-wrap iframe
      width 100%
      min-height calc((100vw - 48px) * 0.5625)
    iframe.instagram-media
      max-width calc(100%) !important
    iframe.instagram-media:not(.instagram-media-rendered)
      border none !important
    .fb_iframe_widget
      display block
      span, video, iframe
        width 100% !important
        max-width 100% !important

.pd-bp
  &__section
    padding 0 ptr(16)
  &__section-header
    padding 0 ptr(16)
    margin-bottom ptr(8)
    .tit
      {$kr-14-b}
  &__section.thumbs
    margin 0 ptr(-16) ptr(32)
    padding 0 ptr(16)
    .swiper-slide
      width 100%
      max-width ptr(288)
    .swiper-slide:not(:last-child)
      margin-right ptr(8)
  &__section.size
    margin-top ptr(32)
  &__section.size_compare
    margin ptr(32) ptr(-16)
    padding 0 ptr(16)
    .swiper-slide
      width 100%
      max-width ptr(215)
    .swiper-slide:not(:last-child)
      margin-right ptr(4)
  &__swiper
    padding 0 ptr(16)
    overflow hidden
    .swiper-container
      overflow visible
  &__info
    {$kr-14-m}
    color $black
    p, ul
      margin 0 0 ptr(8)
    img
      max-width 100%
    > p > strong
      {$kr-14-b}
      color $black
      display block
      margin-top ptr(32)
      margin-bottom ptr(8)
  img
    max-width 100%
  p img
    single-image()
  ul
    unordered-list()
  .table-wrapper
    overflow-x auto
  table
    width 100%
    margin-bottom 12px
    border-collapse collapse
    th, td
      {$kr-14-m}
      padding 0 ptr(12)
      vertical-align middle
      text-align center
      white-space nowrap
    th
      height ptr(46)
      background $gray-5
    td
      padding ptr(12)
      color $black
      background $white
  &__size-info
    .size_info_url
      margin 0 0 11px
    img
      single-image()

.pd-purchase-info
  &__title
    {$kr-16-b}
    margin 0 0 ptr(8)
  &__header
    $flex(space-between, center)
    padding ptr(16) 0
    h4
      {$kr-14-b}
      margin 0
    .arrow
      width ptr(16)
      height ptr(16)
      transform rotate(0deg)
      font-size 0
  &__section.active .pd-purchase-info__header .arrow
    transform rotate(180deg)
  &__divider
    height 1px
    background $gray-10
  &__body
    height 0
    overflow hidden
    transition height 0.3s
    &--inner
      position relative
      bottom 0
      padding-bottom ptr(24)
    &__desc
      margin-top ptr(4)
      margin-bottom ptr(16)
      p
        display inline-block
        {$kr-14-m}
        color $black
        margin 0
      p span
        font-weight 600
        word-break keep-all
  &__table
    border-collapse collapse
    border-spacing 0
    th, td
      text-align left
      padding ptr(4) 0
      vertical-align top
    th
      {$kr-14-m}
      color $gray-50
      min-width ptr(72)
      padding-right ptr(8)
      html:not([lang="kr"]) &
        min-width ptr(120)
    td
      {$kr-14-m}
      color $black
  &__anchor
    $flex(flex-start, flex-start, column)
    margin ptr(8) 0
    gap ptr(8)
    span
      font-size 0
    a
      $flex(flex-start, center)
      gap ptr(4)
      color $gray-80
      svg:first-child
        width ptr(24)
      svg:last-child
        width ptr(12)
    a.product_report_btn
      svg:first-child
        width ptr(16)
  &__links
    margin-top ptr(16)
  &__li
    {$kr-14-m}
    text-align left
    display flex
    justify-content flex-start
    margin 0
    &:before
      content ''
      display block
      width 2px
      height 2px
      background-color #4e4e4e
      border 100%
      margin 8px 8px 0 0
      flex-shrink 0

.pd-pb_info
  $flex(flex-start, stretch, column)
  gap ptr(32)
  padding 0 ptr(16)
  &__title
    margin 0 0 ptr(8)
    {$kr-16-b}
    color $black
  &__body
    {$kr-14-m}
    color $black

.kc-cert
  $flex(flex-start, stretch, column)
  gap ptr(8)
  &__body
    $flex(flex-start, center)
    gap ptr(8)
  &__mark
    width ptr(48)
  &__info-box
    $flex(flex-start, stretch, column)
    gap ptr(4)
  &__info-f
    $flex(flex-start, center)
    gap ptr(8)
  &__info-no
    {$en-14-unica-b}
  &__info-link
    {$kr-12-m}
    color $gray-80
    a
      $flex(flex-start, center)
      gap ptr(4)
    svg
      width ptr(10)
  &__info-s
    {$kr-12-m}
    color $gray-50
  &__footer
    {$kr-14-m}
    color $black

.pd-faq
  &__title
    {$font-body-b1-b}
    margin 0 0 ptr(8)
  &__list
    padding 0
  &__qna
    list-style-type none
    word-break keep-all
    &-q
      {$font-body-b3-r}
      display flex
      justify-content space-between
      align-items center
      width 100%
      padding ptr(12) 0
      color $gray-600
      cursor pointer
      .arrow
        width ptr(20)
        height ptr(20)
        transform rotate(0)
        svg
          width 100%
    &-a
      {$font-body-b3-r}
      height 0
      overflow hidden
      transition height 0.3s
      &--inner
        position relative
        bottom 0
        padding  0 0 ptr(12)
      img
        max-width 100%
    &.active .pd-faq__qna-q
      color $gray-800
      .arrow
        transform rotate(180deg)

.pd-form-modal
  max-height calc(90dvh - 6rem)
  > .don_wrapper
    > .header
      justify-content flex-end !important
      align-self center !important
      padding ptr(16) !important
      border-top-left-radius ptr(16)
      border-top-right-radius ptr(16)
      overflow hidden
  &__close
    width ptr(24)
    color $black
    margin 0
    padding 0
    border none
    outline none
    background transparent
  &__options
    $flex(flex-start, stretch, column)
    row-gap ptr(16)

.pd-form
  $flex(flex-start, stretch, column)
  gap ptr(16)
  margin 0
  padding 0 ptr(16) ptr(16)
  max-height calc(90dvh - 6rem - 58px)
  overflow-x hidden
  overflow-y auto
  box-sizing border-box
  .pd-field
    $flex(flex-start, flex-start, column)
    row-gap ptr(16)
    &.direction-column
      $flex(flex-start, stretch, column)
      applyGap(16)
    &__label
      display block !important
      margin 0
      {$kr-12-b}
    &__row
      $flex(space-between, center)
  .pd-field.size
    .pd-radio-group
      $flex(flex-start, flex-start)
      gap ptr(8)
      flex-wrap wrap
  .pd-field.color
    .pd-field__text
      $flex(flex-start, center)
      gap ptr(8)
    .color_chip
      width ptr(20)
      height ptr(20)
      border-radius 50%
      border 1px solid $gray-5
      box-sizing border-box

  &__divider
    height 1px
    margin 24px 0 19px
    background-color #f8f8f8
  &__prod-info
    position relative
    padding-bottom 24px
    border-bottom 1px solid $gray-6
    margin 28px 24px
  &__submit
    position fixed
    bottom 0
    left 0
    right 0
    z-index 15
  &__pb-multi-options
    display none

.pd-field
  position relative
  padding 0
  margin 0
  border none
  &__label
    {$kr-14-b}
    margin-bottom ptr(12)
  &__label:not(.visible)
    display none
  &__text
    {$kr-14-m}
  &__max_count
    margin 0
    $flex(flex-start, flex-start)
    gap ptr(8)
    color $blue
  &__max_count_icon
    svg
      width ptr(20)
  &__max_count_text
    $flex(flex-start, stretch, column)
    {$kr-14-m}
  .expand-btn
    display none
  &.counter
    justify-content space-between
  &.colors
    .expand-btn
      $flex(flex-start, center, column)
      display none
      gap ptr(4)
      .pd-radio__face
        margin ptr(2)
        width ptr(32)
        height ptr(32)
        border ptr(2) solid $gray-5
        border-radius 50%
        background conic-gradient(from 180deg at 50% 50%, #0C6BFF 0deg, #009618 91.79999828338623deg, #FCD042 185.3999948501587deg, #FF4646 271.79999828338623deg, #C900C1 360deg)
        box-sizing border-box
      .pd-radio__text
        margin-top 0
    &.fold
      .pd-radio:nth-child(n+7)
        display none
      .expand-btn
        display flex !important
    .dynamic-html
      position relative
  &__count-price
    $flex(center, center, column)
    &.hide
      display none
  &__pd-counter-wrap
    width 100%
    padding-bottom ptr(8)
    $flex(space-between, center)
    &.hide
      display none

.pd-select
  padding ptr(12)
  border-radius ptr(8)
  border 1px solid $gray-20
  width 100%
  {$kr-14-m}
  color $black
  background #fff
  background-image url(//s3.marpple.co/files/u_1504988/2024/6/original/239213dad3ed50f191a011e791e05ab8eb55f73e1.png)
  background-repeat no-repeat
  background-position calc(100% - 16px) center
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

//.pd__pb_options
.spo_custom_options,
.pb-options
  &__label
    display none
  &__options
    $flex(flex-start, stretch, column)
    gap ptr(16)
  select.spo_custom_option,
  select.spo_option
    position relative
    appearance none
    display block
    width 100%
    padding ptr(12)
    padding-right ptr(40)
    {$kr-14-m}
    color $black
    border-radius ptr(8)
    border 1px solid $gray-20
    outline none
    cursor pointer
    background-image url(//s3.marpple.co/files/u_1504988/2024/6/original/239213dad3ed50f191a011e791e05ab8eb55f73e1.png)
    background-repeat no-repeat
    background-position calc(100% - 16px) center
    background-size ptr(16)
    background-color #fff
    text-overflow: ellipsis
    white-space nowrap
    &[data-is_default_selected="true"]
      color $gray-50

  .spo_text_option_wrap
    display flex
    align-items center
    column-gap ptr(16)
    padding-right ptr(16)
    border-bottom 1px solid $gray-20
    &:last-of-type
      margin-bottom ptr(8)
    &.active
      border-bottom 1px solid $blue-100
  input.spo_text_option
    appearance none
    display block
    width 100%
    padding ptr(12)
    {$kr-14-m}
    border-radius ptr(8)
    border none
    outline none
    cursor pointer
    box-sizing border-box
    &::placeholder
      color $gray-50
    &:disabled
      color $gray-50
      background $gray-5

.pd-radio-group
  &.color
    display grid
    grid-template-columns repeat(8, minmax(0, 1fr))
    gap ptr(4)
    margin 0
  &.value
    $flex(flex-start, flex-start)
    flex-wrap wrap

.pd-radio
  cursor pointer
  input[type="radio"]
    position fixed
    width 0
    height 0
    margin 0
    padding 0
    opacity 0
    z-index -1
  &:hover:not(.disabled):not(.color) .pd-radio__face
  [type="radio"]:checked + .pd-radio__face
    text-shadow 0 0 0 #000
    box-shadow inset 0 0 0 2px #111111
  &__face:not(.color)
    control-button()
    display block
    padding 0
  [type="radio"]:checked + .pd-radio__face.color
    border-color inherit !important
    .pd-radio__face--color
      border-color $white !important
  &.color
    $flex(flex-start, center, column)
    gap ptr(4)
    width 100%
    box-sizing border-box
    &:hover
      .pd-radio__face
        border-color inherit !important
    &[style="border-color: #ffffff"],
    &[style="background: #fefefe"]
      border-color #eee !important
    .pd-radio__face
      width ptr(36)
      height ptr(36)
      display block
      margin 0 auto
      padding 0
      background transparent
      border-radius 50%
      border ptr(2) solid $gray-5
      box-sizing border-box
    .pd-radio__face--color
      width ptr(32)
      height ptr(32)
      display block
      border-radius 50%
      border ptr(2) solid $gray-5
      box-sizing border-box
      &[style="background: #ffffff"]
      &[style="background: #fefefe"]
        background linear-gradient(180deg, rgba(248,248,248,1) 0%, rgba(240,240,240,1) 100%) !important
    .pd-radio__text
      {$kr-12-m}
      color $gray-600
  &.value
    .pd-radio__face
      $flex(center, center)
      padding ptr(12)
      height ptr(44)
      min-width ptr(55)
      {$en-16-unica-m}
      color $black
      background $gray-5
      box-sizing border-box
      border-radius ptr(6)
    input[disabled] + .pd-radio__face
      color $gray-20 !important
    input:not([disabled]):checked + .pd-radio__face
      color $white !important
      background $black
  &__text
    ellipsis()
    display block
    margin 4px 0 0
    font-size 12px
    line-height 1.33
    text-align center
    color $gray-50
.pd-counter
  $flex(space-between, center)
  width ptr(114)
  height ptr(44)
  padding ptr(14) ptr(12) ptr(15) ptr(12)
  border-radius ptr(8)
  background $gray-5
  box-sizing border-box
  &__btn
    width ptr(30)
    height ptr(30)
    padding 0
    color $black
    control-button()
    background transparent
    $flex(center, center)
    &:disabled
      cursor unset
      color $gray-20
      pointer-events none
    svg
      width ptr(12)
  &__current
    $flex(center, center)
    width ptr(33)
    {$en-16-unica-m}
    text-align center
    border none
    outline none
    background transparent
    color $black
    &:disabled
      cursor unset
      color $gray-20

.pd-guide
  $flex(flex-start, flex-start)
  &__left-title
    $flex(flex-start, center)
    gap ptr(4)
    {$kr-12-m}
    color $gray-50
    min-width ptr(80)
    white-space nowrap
    svg
      width ptr(16)
  &.ruler
    margin ptr(12) 0 ptr(20)
    $flex(flex-start, center)
  &__icon
    transform translateY(-1px)
    margin-right 8px
    height 20px
  &__start-end
    color $gray-1
    font-weight normal
  &__start-end-strong
    {$kr-12-b}
  &__desc
    {$kr-12-m}
    display flex
    align-items center
    flex-wrap wrap
    margin-bottom ptr(4)
    &--wait
      margin-top ptr(8) !important
      {$kr-12-m}
      color $gray-80
  &__table
    border-collapse collapse
    width 100%
    tr + tr
      th, td
        padding-top ptr(16)
    th, td
      vertical-align top
      text-align left
      {$kr-12-m}
    th
      $flex(flex-start, center)
      gap ptr(4)
      width ptr(80)
      text-align left
      color $gray-50
      svg
        width ptr(16)
      .tooltip-icon
        font-size 0
        line-height 0
        &[aria-expanded="true"]
          color $blue
    td
      width 100%
      & > div
        $flex(flex-start, stretch, column)
        gap ptr(4)
      p
        $flex(flex-start, center)
        margin 0

.pd-total
  border-top 1px solid $black
  padding-top ptr(8)
  gap ptr(16)
  width 100%
  d-flex-row(space-between, center)
  &.hide
    width 100%
    border-top unset
  &__count
    {$kr-14-b}
  &__price
    {$en-20-unica-b}
    color $black
    &::after
      {$kr-20-b}
    &.through
      margin-right 10px
      text-decoration line-through
      color #a2a2a2
.pd-submit
  $flex(space-between, center)
  gap ptr(8)
  padding ptr(16)
  &.open
    background #fff
    .pd-submit__submit_btn_wrapper
      display none
  &__btns
    width 100%
    $flex(space-between, center)
    gap ptr(8)
    &--hide
      display none
  &__submit_btn_wrapper
    position relative
    width 100%
  &__btn
    $flex(center, center)
    width 100%
    height ptr(52)
    {$kr-16-b}
    text-align center
    color $whtie
    outline none
    border none
    border-radius ptr(8)
    background-color $blue
    padding 0
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    margin-bottom env(safe-area-inset-bottom)
    margin-bottom constant(safe-area-inset-bottom)
    margin 0
    html[not-safe-area="true"] &
      padding-bottom 0
    html.safe_area_inset_bottom &
      margin-bottom ptr(34)
    span.cart
      display none
    html[lang="kr"] &
      span.cart
        display block
      span.add_to_cart
        display none
    &:disabled
      background $gray-20 !important
      border solid 1px $gray-20
      color #ffffff
    &--cart
      color $blue
      background $light-blue
    &--membership-signup
      color $white
      background-color $black
      &.app
        color $white
        background-color $gray-20
        pointer-events none
  .pd-noti.text
    $flex(center, center)
    color $white
    .pd-noti__label
      $flex(center, center)
      gap ptr(4)
      {$kr-16-b}
      white-space nowrap
      svg
        width ptr(16)

.pd-divider
  height 1px
  margin 30px 24px 16px
  background-color #f8f8f8

.pd-preview
  width 100%
  background #f8f8f8
  .wrap-tag
    position absolute
    top 24px
    left 24px
    z-index 1
  &__badges
    $flex(center, center)
    gap ptr(2)
    position absolute
    top ptr(16)
    left ptr(16)
    z-index 10
    .badge
      $flex(center, center)
      padding ptr(1) ptr(4) ptr(2)
      border-radius ptr(4)
      {$en-12-unica-r}
      &__limit
        color $white
        background $blue
      &__sold_out
        color $white
        background $gray-50

.pd-product
  $flex(flex-start, stretch, column)
  gap ptr(12)
  background $gray-5
  &__hide
    display none
  &__seller
    $flex(space-between, center)
  &__action
    $flex(flex-end, center)
    gap ptr(16)
    button
      width ptr(24)
      height ptr(24)
    svg path
      stroke #CCCCCC !important
  &__title_area
    $flex(flex-start, stretch, column)
    gap ptr(4)
  &__badges
    $flex(flex-start, center)
    gap ptr(4)
  &__product_type
    $flex(center, center)
    {$kr-12-m}
    width fit-content
    height ptr(20)
    padding 0 ptr(4)
    border-radius ptr(4)
    color $purple
    background $purple-10
  &__bottom-area
    $flex(space-between, flex-end)
  &__goods_type
    $flex(center, center)
    gap ptr(4)
    {$kr-14-m}
    color $gray-50
    cursor pointer
    img
      width 16px
    span
      text-decoration underline
    &.mps
      color $blue
      background $light-blue
    &.pbg
      color $green
      background $green-10
    &.tpl
      color $green
      background $green-10
    &.dtg
      color $brown
      background $brown-10
  &__name
    {$kr-20-m}
    margin 0
  &__bp-name
    {$kr-14-m}
    color $gray-80
    margin 0
  &__price
    margin 0
    display flex
    align-items center
    {$en-24-unica-b}
    color $black
    &::after
      {$kr-24-b}
    &.through
      margin-right 10px
      text-decoration line-through
      color #a2a2a2
  &__like
    $flex(center, center, column)
    {$kr-12-m}
    color: $gray-20
    span
      display none
  &__review
    $flex(flex-start, center)
    gap ptr(8)
    &__star
      .article-review-score .star
        color $blue
        width ptr(14)
        height ptr(14)
        background-size ptr(14)
        &[is="true"]
          background-image url("//s3.marpple.co/files/u_2308668/2024/4/original/62c1c5e9e64dcdbf684e8dec0b6b8444ee3a81f01.png")
    &__count
      {$kr-12-m}
      color $gray-50
      height ptr(17)
      cursor pointer
      border-bottom 1px solid $gray-50
  .tooltip-icon
    & > svg
      width ptr(16)
    &[aria-expanded="true"]
      color $blue
      > svg path
        fill $blue

.pd-seller
  $flex(flex-start, center)
  &__name
    $flex(flex-start, center)
    {$kr-14-b}
    color $black
    margin 0
    svg
      width ptr(20)
      height ptr(20)
      margin-left ptr(4)

.relation_products
  display flex
  padding 12px 24px 0 24px
  margin 0 -3.5px
  box-sizing border-box
  width 100%
  overflow-x auto
  .app-product-item
    flex-shrink 0
    .app-product-item__like
      display none


.pd-default-story
  padding ptr(24) ptr(16)
  margin 0 ptr(16) ptr(24)
  border-radius ptr(12)
  background-color $gray-5
  &__header
    $flex(center, center)
    gap ptr(4)
    {$kr-14-m}
    color $black
    margin 0 0 ptr(8)
    svg
      width ptr(20)
  &__body
    {$kr-12-m}
    text-align center
    color $gray-80

.pd-info-group
  position relative
  max-height ptr(1600)
  padding 0 0 ptr(70)
  overflow hidden
  &.open
    max-height none
    .pd-info-group__hide
      height auto
  &__hide
    position absolute
    bottom 0
    left 0
    right 0
    $flex(center, flex-end)
    height ptr(160)
    background linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%)
    z-index 1
    opacity 1
    transition opacity 0.5s
    &.hide
      opacity 0
    &.none
      display none
  &__btn
    {$kr-14-b}
    padding ptr(8) ptr(24)
    color $gray-80
    border-radius ptr(8)
    border: 1px solid $gray-20
    background $white
    box-sizing border-box
  .info_el + .info_el
    margin-top ptr(24)

.tooltip-container.mps2-tooltip
  padding ptr(16)
  border-radius ptr(16)
  background rgba(235, 243, 254, 0.60)
  backdrop-filter blur(20px)
  -webkit-backdrop-filter blur(20px)
  border none
  box-shadow none
  box-sizing border-box
  h4
    {$kr-14-b}
    margin 0
    color $blue
  p
    {$kr-14-m}
    margin 0
    color $blue


.private-product-detail
  margin-top $default-header-pc_height
  flex 1
  $flex(center, center, column)
  gap ptr(24)
  text-align center
  padding ptr(160) 0
  &__image
    width ptr(120)
    height ptr(120)
    img
      width 100%
      height 100%
      object-fit cover
  &__title
    {$kr-20-b}
    color $black
    margin-bottom ptr(8)
  &__description
    {$kr-16-m}
    color $gray-50
  &__btn
    display block
    padding ptr(16) ptr(24)
    color $white
    {$kr-16-b}
    border-radius ptr(12)
    background $blue

@keyframes slideLeft {
  0% {
    left: 0;
  }
  100% {
    left: -2px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.pd-membership-tag
  display flex
  justify-content center
  align-items center
  background-color $blue-10
  color $blue-100
  padding ptr(8)
  border-radius ptr(8)
  flex-wrap wrap
  word-break break-word
