$transition_common = 0.3s ease

.keyvisual
  position relative
  &__wrapper
    reset-list()
    width 100vw
  &__slide
    width 100%
    display flex
    align-items center
    justify-content center
  &__item
    position relative
    width 100.1%
    flex-shrink 0
  &__image
    vertical-align top
    width 100%
    aspect-ratio 375/500
  &__anchor
    display block
    width 100%
  &__text-box
    position absolute
    left pxToRem(24px)
    bottom pxToRem(48)
    z-index 10
  &__title
    limitLine(2)
    word-break keep-all
    {$font-heading-h2-b}
    color inherit
  &__description
    limitLine(1)
    {$font-body-b1-m}
    color inherit
  &__pagination
    &__wrapper
      z-index 2
      position absolute
      right pxToRem(24)
      bottom pxToRem(24)
      &[data-item_color="black"]
        .keyvisual__pagination__info
          color $black
    &__info
      font-size pxToRem(14)
      font-weight 475
      letter-spacing -0.3px
      display inline-flex
      align-items center
      gap pxToRem(4)
      color $white
      transition color $transition_common
      & > span
        opacity 0.5
      & > b
        font-weight 475
        opacity 1
  &__navigation
    width 100%
    height pxToRem(60)
    padding-top pxToRem(12)
    box-sizing border-box
    &__menu
      {$font-heading-h3-b}
      color $gray-400
      &[data-active="true"]
        color $black
    &__menus
      width 100%
      padding pxToRem(16) pxToRem(24)
      display flex
      gap pxToRem(24)
      background-color $white
      align-items center
      box-sizing border-box
      z-index 199
      &.transition-ready
        transition transform 0.3s ease
      &.headroom--not-top
        position fixed
        left 0
        right 0
        top pxToRem(55)
        padding pxToRem(21) pxToRem(24) pxToRem(16)
        &.headroom--pinned
          transform translateY(0px)
        &.headroom--unpinned
          transform translateY(pxToRem(-60))
        html[is_app="true"] &
          &.headroom--pinned
            transform translateY(44px)
          &.headroom--unpinned
            transform translateY(0px)
