.app-report
  position relative
  height 100%
  &__inner-container
    display flex
    flex-direction column
    justify-content space-between
    padding 0 24px 32px
  &__contents
    box-sizing: border-box;
    min-height: calc(100vh - 174px);
  &__description
    width 100%
    height 130px
    padding 14px 22px 16px
    box-sizing border-box
    border-radius 4px
    border solid 1px #ddd
    resize none
  &__text
    font(13px, 18px, -0.2px)
    &--bold
      font-weight 600
  &__box
    &--background
      box-sizing border-box
      margin-top 6px
      padding 18px 22px 11px
      border-radius 6px
      background-color #f8f8f8
  &__list
    reset-list()
  &__list-item
    position relative
    padding-left 12px
    &::before
      position absolute
      top 0
      left 0
      width 12px
      height 18px
      content: '- '
  &__button
    reset-button()
    flexCenter()
    font(16px, 1.25, -0.2px, 600)
    box-sizing content-box
    width 100%
    height 56px
    border-radius 4px
    padding-bottom env(safe-area-inset-bottom)
    padding-bottom constant(safe-area-inset-bottom)
    html[not-safe-area="true"] &
      padding-bottom 0
    html.safe_area_inset_bottom &
      padding-bottom 34px
    &--report
      background-color #111
      color #fff
  &__radio-group
    margin-top -10px
  &__radio
    padding 10px 0
    display flex
    align-items center
  &__radio-input
    margin 0
  &__radio-label
    padding-left 10px
    font-size 15px
  &__checkbox
    margin-top 25px
    padding-left 20px
    position relative
  &__checkbox-input
    margin 0
    position: absolute;
    top 2px
    left 0
  &__checkbox-label
    font-size 15px
  &__checkbox-description
    margin-top 3px
    display block
    font-size 12px
    color #a2a2a2
  &__box
    margin-top 25px



