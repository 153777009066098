// modules/MShop/App/Receipt/F/Style/Mo/index.styl

.creator_receipt
  width 100%
  min-height 80vh
  display flex
  align-items center
  justify-content center
  flex-direction column
  margin-top $default-header-mo_height
  &__unica
    margin-bottom ptr(2)
  &__icon
    margin-bottom ptr(24)
    text-align center
    img
      width ptr(180)
      height ptr(180)
  &__message
    display flex
    flex-direction column
    min-width ptr(327)
  &__message-title
    text-align center
    padding 0
    margin 0
    color $black
  &__message-desc
    margin-top ptr(8)
    margin-bottom ptr(24)
    color $gray-50
    text-align center
  &__vbank_info
    border-radius ptr(16)
    margin ptr(24) 0
    padding ptr(24) ptr(40)
    background-color $gray-5
    li
      display flex
      align-items center
      margin-bottom ptr(12)
      b
        min-width ptr(100)
        margin-right ptr(16)
  &__vbank_copy
    margin-left ptr(8)
    color $gray-50
    cursor pointer
  &__buttons
    display flex
    flex-direction column
    align-items center
    gap pxToRem(8)
    a
      display flex
      align-items center
      justify-content center
      height pxToRem(52)
      width pxToRem(196)
      border-radius pxToRem(12)
      background $blue-10
      color $blue-100
  &__button-continue
    transition: background-color 200ms ease-out, border 200ms ease-out;
    &:hover
      background-color $blue-10 !important
      color $blue-100 !important
  &__button-order
    color $blue-100 !important
    transition: background-color 200ms ease-out, border 200ms ease-out;
    &:hover
      background-color $blue-100
      color $white !important

