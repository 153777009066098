.app-nft
  position relative
  width 100%
  min-height 500px
  overflow hidden
  background-color #000
  &__header
    height 42px
    background-color #000
    h1
      font-size 1px
      line-height 0
      margin 0
      padding 24px 24px 0
      img
        height 10px
  .video-height
    width 100%
    padding-top 166%
  video
    position absolute
    top 42px
    left 0
    right 0
  &__txts
    position absolute
    top 50%
    left 50%
    transform translate(-50%, -219%)
    margin 0
    padding 0
    width 300px
    text-align center
  &__txt1
    margin 0
    padding 0
    font-size 16px
    font-weight 600
    color #fff
    line-height 1.4
  &__txt2
    margin 8px 0 0
    padding 0
    font-size 12px
    color #fff
    line-height 1.4

