.don_page[page_name="/modules/MShop/App/CustomerOrders/S/Mui/page.js"]
.don_page[page_name="/modules/MShop/App/Mypage/Community/S/Mui/page.js"]
.don_page[page_name="/modules/MShop/App/Mypage/Like/S/Mui/page.js"]
.don_page[page_name="/modules/MShop/App/Mypage/Recent/S/Mui/page.js"]
.don_page[page_name="/modules/MShop/App/Store/QuestionList/S/Mui/page.js"]
.don_page[page_name="/modules/MShop/App/Solution/Home/S/Mui/page.js"]
.don_page[page_name="/modules/MShop/App/Mypage/MembershipList/S/Mui/page.js"]
  .tab_buttons
    margin-top $default-header-mo_height
    display flex
    align-items center
    justify-content center
    .tab_button
      reset-button()
      flexCenter()
      width 100%
      height ptr(40)
      text-align center
      box-sizing border-box
      column-gap ptr(4)
      {$kr-14-m}
      &[selected="false"]
        color $gray-50
        border-bottom 1px solid $gray-5
      &[selected="true"]
        color $black
        border-bottom 1px solid $black
      & > .count
        {$en-14-unica-m}

.header-right-setting a
  display block
  padding 0 pxToRem(12)

.mypage-main
  padding $default-header-mo_height + ptr(32) ptr(16) ptr(48)
  &__login
    box-sizing border-box
    display flex
    align-items center
    justify-content center
  &__body
    min-height pxToRem(300)
  &__left
  &__right
    margin-bottom ptr(24)
  &__title
    margin ptr(8) 0 ptr(12)
    padding 0
    color $gray-50
  &__banners
    margin-top pxToRem(40)
  &__ad-res-banner
    position relative;
    width 100%
    height ptr(80)
    background-color $blue
    border-radius ptr(16)
    overflow hidden
    display flex
    justify-content center
    flex-direction column
    align-items center
    gap ptr(2)
    color $white
  &__ad-res-banner-button
    margin-left ptr(24)
    padding ptr(9) ptr(24)
    border-radius ptr(8)
    background $blue-10
    backdrop-filter: blur(20px)
    -webkit-backdrop-filter: blur(20px)
    color $blue
    display flex
    align-items center
  &__ad-res-banner-image1
    transform translateY(-50%) rotate(-11.445deg)
    position absolute
    left -2.5%
    top 50%
    width ptr(76)
  &__ad-res-banner-image2
    position absolute
    transform rotate(-18.074deg)
    width ptr(63)
    filter blur(2.5px)
    top 41%
    right 9%
  &__ad-res-banner-image3
    position absolute
    width ptr(37)
    transform rotate(4.6deg)
    filter blur(0.5px)
    top 12%
    right 4%
  &__ad-res-banner-text
    z-index 1


.mypage-main-login
  &__text
    margin 0 0 pxToRem(20)
    padding 0
    font-size pxToRem(28)
    font-weight 600
    line-height 1.36
    letter-spacing pxToRem(-0.3)
    color #111111
  &__btn_apply_seller
    margin-right pxToRem(6)
    padding pxToRem(6) pxToRem(13) pxToRem(5)
    font-size pxToRem(14)
    color #fff
    line-height 1.29
    letter-spacing pxToRem(-0.2)
    background-color #0e2ee2
    border-radius pxToRem(15)
    border 0 none
    outline none

.mypage-before-login
  display flex
  justify-content center
  width 100%
  margin-bottom ptr(32);
  &__welcome
    width 100%
    display flex
    flex-direction column
  &__title
    margin-bottom ptr(12)
  &__subscription
    margin-bottom ptr(16)
  &__auth-buttons
    display flex
    column-gap ptr(8)
  &__auth-button
    width ptr(120)
.mypage-user
  display flex
  justify-content center
  width 100%
  flex-direction column
  &__welcome
    width 100%
    display flex
    flex-direction column
    row-gap pxToRem(16)
    align-items center
  &__pic
    width ptr(120)
    height ptr(120)
    border-radius ptr(999)
    overflow hidden
    background-color $white
    border 1px solid $gray-5
    img
      width 100%
      height 100%
      object-fit cover
  &__right
    padding-left pxToRem(88)
    min-height pxToRem(72)
    display flex
    flex-direction column
    justify-content center
  &__content-icon
    width ptr(16)
    display inline-flex
    align-items center
  &__buttons
    display flex
    gap pxToRem(8)
    flex-direction column
    justify-content center
  &__text
    text-align center
  &__btns
    margin-top ptr(16)
    display flex
    justify-content center
    column-gap ptr(8)
    row-gap ptr(8)
    flex-wrap wrap
  &__btn
    min-width ptr(120)
  &__infos
    display flex
    margin ptr(32) 0

.mypage-user-info
  flex: 1
  text-align center
  border-right 1px solid $gray-5
  &:last-child
    border-right none
  &__title
    color $gray-50
  &__number
    color $black

.mypage-menu
  reset-list()
  &__item
    padding pxToRem(12) 0
  &__version
    position absolute
    top 50%
    right 0
    transform translateY(-50%)
    font-weight 600
    &--is_login
      display block
  a
    display flex
    align-items center
    gap ptr(4)


.mypage-settings
  margin pxToRem(10) pxToRem(24) 0



.mypage-site-guide
  p
    font-size pxToRem(14) !important
    font-weight normal !important
    line-height 1.43 !important
    letter-spacing pxToRem(-0.2) !important
    color #111111 !important
    text-align justify !important



.app-live-chat
  font-size pxToRem(14) !important
  line-height 1.43 !important
  letter-spacing pxToRem(-0.2) !important
  color #111111 !important
  text-align justify !important

.mypage__outlink
  display inline-block
  width ptr(16)
  height ptr(16)
