html[is_mobile="true"] .today-body
  margin-top s('calc(%s)', $default-header-mo_height)
  &__container
    padding-top ptr(24)

  &__content
    @import '../../../../../../../www/css/creator/feed/editor.styl'

    .editor
      // 첫 번째 이미지 margin-top 안주기 위함
      padding 0 ptr(16)
      & > *:first-child
        margin-top: 0;

  &__title-upper {
    display block
    margin-bottom ptr(8)
  }

  &__related-link-btn {
    margin-top ptr(16)
    padding ptr(16) ptr(24);
    display: inline-flex;
    min-width: ptr(160);
    justify-content center
    align-items: center;
    gap: 8px;
    border-radius: ptr(12);
    background: $blue-10;
    color: $blue-100;
    svg {
      width: ptr(20);
      aspect-ratio: 1;
    }
  }

  &__share
    margin 16px 0 64px

    .today-share-btn
      display flex
      justify-content center
      align-items center
      width 100%
      padding 11px
      font-size 13px
      line-height 1.38
      letter-spacing -0.2px
      color #000
      text-align center
      border none
      outline none
      background-color #f8f8f8

      svg
        width 15px !important
        height 15px !important
        margin-right 12px

        g
          stroke #000

  &__footer
    padding 0 24px

  &__left_sidebar
    width 100%
    position relative
    margin-bottom ptr(24)

  &__meta-container
    padding 0 ptr(16)
    width 100%
    overflow hidden
    box-sizing border-box

  &__title {
    word-break break-all
  }

  &__description
    margin-top ptr(32)

  &__publication-date {
    margin-top ptr(16)
    color $gray-50
  }

  &__tags
    padding ptr(24) ptr(16)
    display flex
    column-gap ptr(8)
    flex-wrap wrap
    row-gap ptr(4)
  &__tag
    padding ptr(4) ptr(8)
    background-color $gray-5
    color $gray-80
    border-radius ptr(4)

  &__products-title
    margin ptr(60) 0 ptr(16)
    padding 0 ptr(16)

  &__products
    display grid
    padding 0 ptr(16)
    grid-template-columns repeat(2, minmax(0, 1fr))
    row-gap ptr(32);
    column-gap ptr(16);

html[is_mobile="true"]
  .today-relates
    margin-top 64px
    text-align left
    &__title
      display flex
      padding 0 ptr(16)
      margin-bottom ptr(16)
    &__items
      overflow: hidden;
      padding-left 16px;
    &__items > .swiper-wrapper
      display flex
      grid-template-columns repeat(4, 1fr)
    &__see-more
      margin-left auto
      color $gray-80
      align-self flex-end
    .swiper-slide
      margin-right: ptr(16);
