.app-home
  display flex
  flex-direction column
  align-items center
  gap pxToRem(60)
  margin-bottom pxToRem(60)
  &__section
    width 100%
    &[data-section-hide="true"]
      display none !important
  &__title
    display flex
    flex-direction column
    gap pxToRem(4)
    margin-bottom pxToRem(16)
    padding 0 pxToRem(24)
    &[data-theme="white"]
      .app-home__sub-title
      .app-home__mid-title
        color $white
  &__sub-title
    {$font-body-b3-r}
    color $gray-600
  &__mid-title
    margin 0
    {$font-heading-h3-b}
  &__more-anchor
    display inline-flex
    align-items center
    gap pxToRem(4)
  &__more-anchor-arrow
    width pxToRem(24)
    height pxToRem(24)
    transform rotate(180deg)
  &__more-box
    display flex
    margin 0 24px
    align-items center
    justify-content center
    height 30px
    font-size 13px
    line-height 1.38
    letter-spacing -0.2px
    color #a2a2a2
    box-sizing border-box
    width -webkit-fill-available
    border-radius 22px
    border solid 1px #dddddd
    background-color #ffffff

.app-home-advance
  &__target
    display block
    margin 0 24px
  &__item
    background url("//s3.marpple.co/files/u_29089/2021/5/original/face371aa0b602f589f58daf72a91f82aceea6d81.jpg") no-repeat center/100%
    position relative
    display flex
    justify-content space-between
    padding 24px 34px 21px 24px
    @media screen and (max-width: 374px)
      padding 24px 25px 21px 24px
  &__left
    margin-right 32px
    @media screen and (max-width: 374px)
      margin-right 20px
  &__right
    flex-shrink 0
    position relative
    width 95px
    font-size 0
    @media screen and (max-width: 374px)
      width 70px
  &__hand
    width 100%
  &__dot
    position absolute
    border-radius 100%
    &--black
      width 10px
      height 10px
      top 0
      right 0
      background-color #000000
    &--red
      width 6px
      height 6px
      bottom 8px
      left -3px
      background-color #ed1638
  &__title
    word-break break-word !important
    font-size 20px
    line-height 1.3
    letter-spacing -0.3px
    color #ffffff
    margin 0 0 10px 0
    @media screen and (max-width: 374px)
      font-size 16px
  &__subtitle
    font-size 15px
    line-height 1.27
    letter-spacing -0.2px
    color #ffffff
    @media screen and (max-width: 374px)
      font-size 14px


.app-home-apply
  display flex
  justify-content space-between
  > div
    a
      display flex
      align-items center
      justify-content center
      color #fff
      text-align center
      img
        width 100%
  &__btn_app_seller
    width 100%
    background-color #1C75FF
