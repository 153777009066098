.request-of-solution
  margin-top $default-header-mo_height
  .request-of-solution-body
    color #111111
    h1
      font-size 23px
      line-height 1.3
      letter-spacing -0.3px
      margin 0 0 40px
    h2
      font-size 18px
      line-height 1.56
      letter-spacing -0.2px
      margin 0 0 6px
    .request-of-solution-home
      padding 0 24px
    .article
      margin 0 0 40px
      .org-list
        display flex
        padding 0
        margin 0 0 10px
        list-style none
        li
          margin-right 0
          text-align center
          position relative
          a
            display block
            margin-right 4px
            overflow hidden
            padding 13px 16px
            border-radius 8px
            border solid 1px #dddddd
            background-color #ffffff
            img
              width 100%
          &:last-child
            a
              margin-right 0
              margin-left 4px
      .note
        margin-bottom 60px
        >div
          margin-bottom 20px
    p
      line-height 1.71
      letter-spacing -0.2px
      color #4e4e4e
      margin 0 0 8px
  .request-of-solution-home
    .process-img
      width 100%
      margin 20px 0
      font-size 0
      img
        width 100%
