body.creator-settings
  .studio-info
    margin-top pxToRem(20px)
    &--cert
      margin-top 0
    &__header
      margin-bottom 10px
      display flex
      align-items center
      justify-content space-between
    &__header-btn
      button
        min-width 60px
        padding 9px 5px 8px
        border-radius 2px
        background-color #3c89f9
        border 0 none
        font-size 14px
        font-weight 600
        letter-spacing -0.5px
        text-align center
        color #fff !important
    &__h2
      margin 0
      padding 0
      font-size 15px
      font-weight 600
      font-stretch normal
      font-style normal
      line-height 1.4
      letter-spacing -0.2px
      color #111
      &:before
        content ''
    &__buttons
      margin-top pxToRem(40px)
    &__btn-save
      width 100%
      padding 11px 10px
      color #fff !important
      order 1
      border-radius ptr(4)
      border solid 1px #3c89f9
      background-color #3c89f9
      box-sizing border-box
      &[disabled="true"]
        cursor not-allowed
        background-color $gray-5 !important
        color $gray-50 !important
        border none
    &__btn-cancel
      min-width 120px
      padding 11px 10px
      color #4c4c4c
      font-size 14px
      font-weight 500
      letter-spacing -0.5px
      text-align center
      border-radius 2px
      border solid 1px #ddd
      background-color #fff
    &__help-box
      margin 20px 0 48px
      padding 20px
      border-radius 3px
      background-color #f8f8f8
    &__help-txt1
      margin 0
      padding 0
      font-size 14px
      font-weight 600
      line-height 1.29
      letter-spacing -0.2px
      color #111
    &__help-ul
      margin 10px 0 0
      padding 0
      list-style none
      color #111
    &__help-li
      font-size 13px
      line-height 1.54
      letter-spacing -0.2px
      color #757575
    &__help-confirm-box
      margin-top 12px
      font-size 14px
      font-weight 500
      line-height 1.36
      letter-spacing -0.2px
      color #000
      label
        display flex
        align-items center
      input
        margin 0 5px 2px 0


  .studio-info-form
    font-size pxToRem(13px)
    &__row
      margin 0
    &__btn-blue
      display inline-block
      padding pxToRem(9px) pxToRem(10px) pxToRem(8px)
      min-width pxToRem(80px)
      color #fff !important
      font-size pxToRem(13px)
      font-weight 500
      letter-spacing -0.85px
      text-align center
      border-radius pxToRem(2px)
      background-color #3c89f9
      border 1px solid #3c89f9
      box-sizing border-box
    &__btn-white
      display inline-block
      padding pxToRem(9px) pxToRem(10px) pxToRem(8px)
      min-width pxToRem(80px)
      color #000 !important
      font-size pxToRem(13px)
      font-weight 500
      letter-spacing -0.5px
      text-align center
      border-radius pxToRem(2px)
      background-color #fff
      border 1px solid #ddd
      box-sizing border-box
    &__btn-gray
      display inline-block
      padding pxToRem(9px) pxToRem(10px) pxToRem(8px)
      min-width pxToRem(80px)
      color #fff !important
      font-size pxToRem(13px)
      font-weight 500
      letter-spacing -0.5px
      text-align center
      border-radius pxToRem(2px)
      background-color $gray-3
      border 1px solid $gray-3
      box-sizing border-box
    &__control
      display flex
      align-items stretch
      border solid 1px #f2f2f2
      margin-bottom -1px
      &--width100
        width 100%
      &--no-border
        border 0 none
    &__label
      display flex
      align-items stretch
      justify-content space-between
      width 100%
      &--width100
        width 100%
      &--mr0
        margin-right 0 !important
      &--inline
        display inline-flex
        align-items center
        input
          margin-bottom pxToRem(4px)
          margin-right pxToRem(5px)
    &__label-txt
      width 100px
      font-size pxToRem(13px)
      padding pxToRem(18px) pxToRem(15px)
      box-sizing border-box
      background-color #fafafa
      border-right solid 1px #f2f2f2
      line-height 1.38
      color #4c4c4c
      word-break keep-all
    &__body-wrap
      position relative
      padding 10px
      flex 1
      align-items center
      &--pl0
        padding-left 0
      &--p0
        padding 0
    &__body
      position relative
      &--mb-6
        margin-bottom 6px
      &--radio
        width 100%
        padding-left pxToRem(5px)
        border 0 none
        > div
          > label
            display block
            margin-top pxToRem(6px)
            &:first-child
              margin-top 0
      &--align-center
        display flex
        align-items center
        height 100%
      &--align-center-max-40
        height 40px
      &--error
        border-color #f66565
      label
        margin-right 20px
        display inline-block
      &-span
        position absolute
        left pxToRem(18px)
        top 50%
        transform translateY(-50%)
        color #a2a2a2
      &-ciety
        color $black !important
        padding-left pxToRem(80px) !important

    &__tooltip
      $flex(flex-start, flex-start, column)
      column-gap pxToRem(4px)
    &__tooltip-wrap
      ul
        list-style none
      b
        padding 0 pxToRem(3px)
      p
        display inline-flex
        align-items center
        padding-top pxToRem(16px)
        word-wrap break-word
        overflow-wrap break-word
        white-space normal
      a
        color $blue !important
        text-decoration underline
        text-decoration-style solid
        text-decoration-skip-ink none
        text-decoration-thickness auto
        text-underline-offset auto
        text-underline-position from-font
      div
        display inline-flex
      section
        padding-top pxToRem(16px)
        display flex
        align-items center
        column-gap pxToRem(8px)
    &__tooltip-link-icon
      display inline-flex
      align-items center
      padding-left pxToRem(2px)
    &__body-textarea
      min-height: 200px;
      resize: none;
    &__body-input
    &__body-textarea
      width 100%
      padding pxToRem(8px)
      color #000
      font-size pxToRem(13px)
      box-sizing border-box
      border-radius 3px
      border solid 1px #ededed !important
      background-color transparent !important
      outline none
      &--size1
        width 60%
        margin-right 5px
      &--align-right
        padding-right 8px
        text-align right
      &--error
        border-color #f66565 !important
      &[disabled]
        color #a2a2a2 !important
        border solid 1px #ededed !important
        background-color #f8f8f8 !important
      &--default-css
        &[disabled]
          color #000 !important
          border solid 1px #ededed !important
          background-color transparent !important
    &__body-ciety-exist
      display flex
      align-items center
      width 100%
      padding 0 pxToRem(12px)
      border-radius pxToRem(4px)
      box-sizing border-box
      border 1px solid $gray-10
      background-color $gray-5
      height pxToRem(40px)
      margin-top 0
    &__body-ciety-wrap
      width 100%
    &__select
      -webkit-appearance none
      -moz-appearance none
      border 1px solid $gray-s1
      border-radius 2px
      background $image-down-arrow no-repeat 95% center
      background-size 18px 18px
      width 100%
      height pxToRem(40px)
      font-size pxToRem(13px)
      text-indent 10px
      &--error
        border-color #f66565 !important
    &__txt1
      margin pxToRem(4px) pxToRem(2px) 0
      padding 0
      letter-spacing -0.3px
      font-size pxToRem(12px)
      line-height 1.42
      letter-spacing normal
      color #4c4c4c
      &--important
        padding-left 18px
        text-indent pxToRem(-18px)
    &__body-input-radio
      margin 0 4px 2px 0
      vertical-align middle
    &__icon-important
      margin-top 2px
      margin-left pxToRem(2px)
      display inline-block
      vertical-align top
      width pxToRem(15px)
      height pxToRem(15px)
      background url(//s3.marpple.co/files/u_218933/2022/11/original/b68ec617c1ff343b353f7fc0ab9c92d8fa40066f1.png) no-repeat
      background-size pxToRem(15px) pxToRem(15px)
      &--ml0
        margin-left 0
        margin-right pxToRem(2px)
    &__error-msg
      display none
      color #f66565
      &--show
        display block
        padding-top ptr(10)
        color $red-80
        margin 0 !important
    &__postcode-layer
      position absolute
      top 36px
      right 0
      background #fff
      min-width 300px
  .studio-alert
    &__title
      font-size pxToRem(20px) !important
    &__body
      margin-bottom pxToRem(10px)
    &__p
      font-size pxToRem(13px) !important
      text-align center !important
