.app-home-cate-list
  margin-top 10px
  &__item
    margin-bottom 64px
  &__product-list
    margin 0 20px
    display flex
    flex-wrap wrap
  &__feed-item
    margin-bottom 32px
    &:last-child
      margin-bottom 16px
  &__feed-img
    width 100%
    height 0
    padding-bottom 60%
    background-size cover
    background-position center
    background-color #f6f6f6
  &__feed-content
    margin 14px 24px 0
  &__feed-title
    font-size 20px
    font-weight bold
    line-height 1.3
    letter-spacing -0.2px
    color #111
    margin-bottom 8px
  &__feed-description
    line-height 1.29
    letter-spacing -0.2px
    color #111

.app-home-select-category
  height 58px
  &__wrapper
    transform translateY(0)
    display flex
    list-style none
    margin 0
    padding 10px 0 16px 22px
    overflow-y hidden
    overflow-x auto
    align-items center
    -webkit-overflow-scrolling touch
    height 32px
    background #fff
    z-index 199
    will-change transform
    &::-webkit-scrollbar
      display: none
    &.headroom--not-top
      position fixed
      left 0
      right 0
      top 0
      &.headroom--pinned
        transform translateY(104px)
      &.headroom--unpinned
        transform translateY(60px)
      html[is_app="true"] &
        &.headroom--pinned
          transform translateY(44px)
        &.headroom--unpinned
          transform translateY(0px)
    &.headroom--transform
      transition transform 0.3s
  &__item
    padding 0 2px
    flex-shrink 0
    &:last-child
      padding 0 24px 0 0
  &__btn
    reset-button()
    padding 7px 12px 5px
    border-radius 18px
    border solid 1px #ddd
    line-height 1.29
    letter-spacing -0.2px
    color #4e4e4e
    &--selected
      background-color #1C75FF
      border-color #1C75FF
      color #fff


