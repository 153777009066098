

.question-empty
  padding ptr(160) 0
  margin-bottom pxToRem(60px)
  text-align center
  display flex
  flex-direction column
  align-items center
  &__icon
    margin-bottom pxToRem(25px)
    img
      width pxToRem(120px)
  &__txt1
    margin 0 0 pxToRem(10px)
    padding 0
    color $gray-80
  &__txt2
    margin 0 0 pxToRem(30px)
    padding 0
    color $gray-50
    width ptr(296)
  &__btn
    display none

.question-list
  margin-top $default-header-mo_height
  margin-bottom pxToRem(84px)
  &__ul
    margin 0 pxToRem(16px) pxToRem(32px)
    padding 0
    list-style none
  &__li
    margin 0
    padding pxToRem(24px) 0
    border-bottom 1px solid #f2f2f2
  &__txt1
    text-align center
    display flex
    align-items center
    justify-content center
    color $gray-50
    margin 0
    img
      width pxToRem(20px)
      margin-right pxToRem(8px)
      vertical-align top
.question-list-item
  .pc
    display none
  position relative
  &__status-area
    display flex
    align-items center
    margin-bottom pxToRem(16px)
  &__status
    border-radius pxToRem(4px)
    padding 0 ptr(8)
    height ptr(24)
    border none
    &--question
      color $blue-100
      background-color $blue-10
    &--answer
      color $gray-80
      background-color $gray-5
  &__created-at
    margin-top ptr(8)
    color $gray-80
  &__title
    margin-bottom pxToRem(8px)
    a
      color $black
  &__shop-wrap
    display flex
    column-gap ptr(16)
  &__shop-detail
    display flex
    flex-direction column
    row-gap ptr(4)
    a
      color $black
      text-decoration underline
  &__shop-name
  &__pd-name
    limitLine(1)
  &__shop-detail-header
    min-width ptr(90)
    color $gray-50




