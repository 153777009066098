.shipping-info-editor-frame[is_modal="false"]
  >.don_wrapper
    >.header
      z-index 1
      height 60px
      & > .title
        position absolute
        top 18px
        left 50%
        transform translateX(-50%)
        font-size 16px !important
        font-weight 700 !important
        line-height 24px !important

.shipping-info-editor
  padding pxToRem(16px)
  padding-bottom calc(env(safe-area-inset-bottom) + 116px)
  padding-bottom calc(constant(safe-area-inset-bottom) + 116px)
  html[safe-area="true"] &
    padding-bottom calc(env(safe-area-inset-bottom) + 150px)
    padding-bottom calc(constant(safe-area-inset-bottom) + 150px)
  &__error-msg
    display none
    margin pxToRem(7px) 0 0
    padding 0
    font-size pxToRem(13px)
    font-weight 500
    line-height 1.38
    letter-spacing -0.5px
    color #f66565
    &--show
      display block
  &__checks
    display flex
    column-gap pxToRem(16px)
    margin-bottom pxToRem(32px)
    .mshop-core__checkbox__title
      color $gray-80

  &__control
    position relative
    margin-top pxToRem(24px)
    &:firat-child
    &--mt0
      margin-top 0
    &--mt8
      margin-top pxToRem(8px)
  &__control-area
    position relative
    &--cols2
      display grid
      grid-gap pxToRem(10px)
      grid-template-columns repeat(2, 1fr)
  &__label
    display block
    &--inline
      display inline-flex
      align-items center
      input
        margin-bottom pxToRem(4px)
        margin-right pxToRem(5px)
    &--lm10
      margin-left pxToRem(10px)
    &--lm-minus3
      margin-left pxToRem(-3px)

  &__label-txt
    margin-bottom pxToRem(8px)
    color $black
  &__body
    position relative
    min-height pxToRem(50px)
    box-sizing border-box
    &--error
      border-color #f66565
    &--postcode
      width 70%
  &__body-area
    position relative
    &--cols2
      display grid
      grid-gap pxToRem(10px)
      grid-template-columns repeat(2, 1fr)
  &__body-input
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    padding pxToRem(6px) pxToRem(2px) pxToRem(5px)
    color $black
    border none
    border-bottom 1px solid $gray-10
    background-color #fff
    outline none
    border-radius 0
    -webkit-border-radius 0
    &::placeholder
      -webkit-text-fill-color $gray-50
      color $gray-50
      font-weight 500
    &--error
      border-color #f66565
  &__body-select
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    padding pxToRem(6px) 0
    box-sizing border-box
    -webkit-appearance none
    -moz-appearance none
    border-radius pxToRem(4px)
    background $icon-dropdown-arrow no-repeat 97% center
    background-size pxToRem(18px)
    width 100%
    height 100%
    border none
    border-bottom 1px solid $gray-10
    color $black
  &__btn-postcode
    width 100%
    height 100%
    border-radius pxToRem(8px)
    border none
    background-color $blue-10
    text-align center
    color $blue-100
  &__icon-important
    margin-right pxToRem(5px)
    padding-top pxToRem(4px)
    img
      width pxToRem(15px)
  &__body-right
    position absolute
    top 0
    right 0
    bottom 0
    padding 0 pxToRem(11px)
    display flex
    align-items center
  &__control-wrap
    display flex
    align-items center
    justify-content space-between
  &__control-wrap-grid2
    display grid
    grid-gap pxToRem(8px)
    grid-template-columns auto ptr(120)
  &__buttons
    position fixed
    bottom 0
    left 0
    right 0
    background-color $white
    padding pxToRem(16px)
    border-top 1px solid $gray-5
    z-index 10
    display flex
    &:after
      display block
      content ''
      padding-bottom env(safe-area-inset-bottom)
      padding-bottom constant(safe-area-inset-bottom)
    html[safe-area="true"] &
      padding-bottom 34px
    & > button
      width 100%
      height pxToRem(52px)
      border-radius pxToRem(8px)
      padding 0
  &__btn-submit
    color $gray-50
    background-color $gray-5
    border 0 none
    &.active
      color $white
      background-color $blue-100
  &__btn-cancel
    color $gray-80
    border 1px solid $gray-20
    background-color $white
    margin-right ptr(8)