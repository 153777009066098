


.maker-upload-type-mobile
  &__body
    height 100%
    padding 12px
    color $blue-1
    background #EBF3FE
    display flex
    justify-content center
    align-items center
  &__text
    width 100%

.don_page[page_name="m_image_dosu_color_picker_and_pattern_page"]
  .tab_button[tab_name="m_image_pattern"]
    display none
  .tab_button[tab_name="m_image_dosu_color_picker"]
    border-bottom none
.m-image-dosu-color-picker
  .dosu-colors
    display flex
    flex-direction column
    justify-content center
    .color-text
      text-align center
      margin 5px 0 16px 0
    .color-pickers
      display flex
      flex-wrap wrap
      .color-picker
        display flex
        justify-content center
        cursor pointer
        margin 6px
        border-radius 100%
        span
          box-sizing border-box
          width 24px
          height 24px
          border-radius 100%
          margin 1px
          &.too_bright
            border 1px solid #CED4DA
        &.original_tag
          span
            background url(//s3.marpple.co/files/u_1187078/2023/10/original/4b0a81febbcf4ebb28d05033ce040cb71b9e32e71.png) center / 27px no-repeat
            border none
            border-radius 0
          &.selected
            box-shadow unset
        &.selected
          box-shadow: 0 0 0 1px $blue-1
        &.used
          &::before
            content ""
            background-color #555
            width 4px
            height 4px
            border-radius 100%
            margin-top -8px
.maker-color-picker
  display flex
  flex-direction column
  justify-content center
  &__title
    text-align center
    margin 5px 0 16px 0
  &__list
    display flex
    flex-wrap wrap
  &__item
    display flex
    justify-content center
    cursor pointer
    margin 6px
    border-radius 100%
    span
      box-sizing border-box
      width 24px
      height 24px
      border-radius 100%
      margin 1px
      &.too_bright
        border 1px solid #CED4DA
    &.original_tag
      span
        background url(//s3.marpple.co/files/u_1187078/2023/10/original/4b0a81febbcf4ebb28d05033ce040cb71b9e32e71.png) center / 27px no-repeat
        border none
        border-radius 0
      &.selected
        box-shadow unset
    &.selected
      box-shadow: 0 0 0 1px $blue-1
    &.used
      &::before
        content ""
        background-color #555
        width 4px
        height 4px
        border-radius 100%
        margin-top -8px
#maker_frame
  .dosu_warning
    z-index 2 !important
    position absolute
    top 78px
    left calc(50% - 133px)
    box-sizing border-box
    text-align center
    width 266px
    padding 12px
    border-radius 6px
    background-color $blue-1
    color #fff
    font-size 12px
    font-weight 500
.page_in_mp_maker
  >.don_wrapper
    >.body
      margin 0 auto
.mp-maker-padding-tab-1
  overflow hidden
  box-sizing border-box
  padding 24px 24px 0
  height 232px
  max-width 550px
  margin 0 auto

.maker-mobile-product-info
  &__basic-info
    margin-bottom 16px
.maker-mobile-product-info__basic-options
  margin-bottom 16px

body.setting-products
  @import "./maker/mo/index.styl"
  @import "new_maker_guide.styl"
  .mp_maker
    .btm_menu
      .is_inverted_image
        .icon
          background-color: #fff;
          z-index: 100;
          position: relative;
          width: 50px;
          height: 50px;
          background-repeat: no-repeat;
          border-radius: 50%;
          cursor: pointer;
          background-size: 25px;
          background-position: 50% 50%;
          background-image url(//s3.marpple.co/files/u_193535/2023/7/original/5b593f62ffccc32629cd4fb39467e89a9b12b5d32.svg)
        &.checked
          .icon
            background-image url(//s3.marpple.co/files/u_193535/2023/7/original/a3b4e4f389f3b19ca9a13af819f2b0f955933cda1.svg)

