.question-frame
  &.don_frame[is_modal="false"]
    > .don_wrapper
      & > .header
        z-index 10
        & > .title
          position absolute
          top 18px
          left 50%
          transform translateX(-50%)
          font-size 16px !important
          font-weight 700 !important
          line-height 24px !important

.store-qna-editor
  padding pxToRem(16px)
  padding-bottom calc(env(safe-area-inset-bottom) + 116px)
  padding-bottom calc(constant(safe-area-inset-bottom) + 116px)
  html[safe-area="true"] &
    padding-bottom calc(env(safe-area-inset-bottom) + 150px)
    padding-bottom calc(constant(safe-area-inset-bottom) + 150px)
  hr
    margin-top pxToRem(12px)
    margin-bottom pxToRem(12px)
    border 0
  &__required
    display none
  &__header
    display none
  &__error-msg
    display none
    margin pxToRem(7px) 0 0
    padding 0
    font-size pxToRem(13px)
    font-weight 500
    line-height 1.38
    letter-spacing -0.5px
    color #f66565
    &--show
      display block
  &__container
    width 100%
    display flex
    column-gap pxToRem(24px)
  &__controls
    display flex
    flex-direction column
    row-gap pxToRem(32px)
  &__control
    width 100%
    position relative
    &:firat-child
    &--mt0
      margin-top 0
  &__control-area
    position relative
    &--cols2
      display grid
      grid-gap pxToRem(10px)
      grid-template-columns repeat(2, 1fr)
  &__label
    display block
    &--inline
      display inline-flex
      align-items center
      input
        margin-bottom pxToRem(4px)
        margin-right pxToRem(5px)
    &--lm10
      margin-left pxToRem(10px)
    &--lm-minus3
      margin-left pxToRem(-3px)

  &__label-txt
    margin-bottom pxToRem(8px)
    color $black
  &__label-txt-sub
    font-weight 400
    color #757575
  &__label-txt-sub2
    font-weight 400
    color #3d89f9
  &__body
    position relative
    min-height pxToRem(40px)
    border-bottom 1px solid $gray-10
    box-sizing border-box
    border-radius 0
    &--error
      border-color #f66565
    &--radio
    &--check
      padding pxToRem(24px) 0
      min-height auto
      border 0 none
      border-radius 0
      display inline-flex
      align-items center
      justify-content space-between
      width 100%
      & .mshop-core__checkbox__title
        color $black
    &--is_public
      min-height pxToRem(20px)
    &--textarea
      padding pxToRem(9px) 0
      border-bottom 1px solid $gray-10
    .flatpickr-wrapper
      position absolute
      top 0
      left 0
      right 0
      bottom 0
    &--terms
      padding pxToRem(10px)
      height pxToRem(160px)
      overflow auto
    &--file
      width 100%
      position relative
      &[can_cancel="false"]
        .store-qna-editor__body-file-del
          display none
  &__body-area
    position relative
    &--cols2
      display grid
      grid-gap pxToRem(10px)
      grid-template-columns repeat(2, 1fr)
  &__body-input
  &__body-textarea
    color $black
    border 0 none !important
    background-color transparent !important
    outline none
    &::placeholder
      color $gray-50
  &__body-input
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    padding pxToRem(6px) pxToRem(2px) pxToRem(5px)
  &__body-textarea
    padding 0
    width 100%
    height pxToRem(140px)
    resize none
  &__body-textarea-length
    margin-top pxToRem(15px)
    font-size pxToRem(13px)
    font-weight bold
    line-height 1.54
    letter-spacing -0.2px
    text-align right
    color #828282
  &__txt1
    margin-top pxToRem(8px)
    color $gray-50
    &--icon1
      display flex
      justify-content flex-start
  &__icon-important
    display none
  &__body-right
    position absolute
    top 0
    right 0
    bottom 0
    padding 0 pxToRem(11px)
    display flex
    align-items center

  &__control--file
    border 1px solid transparent
  &__control-wrap
    display grid
    width 100%
    height pxToRem(40px)
    grid-template-columns auto pxToRem(120px)
    column-gap pxToRem(8px)
  &__input-file
    position absolute
    top 0
    left 0
    width 0
    height 0
    opacity 0
  &__body-file-text
    position absolute
    width 90%
    top 50%
    left 0
    transform translateY(-50%)
    limitLine(1)
    color $gray-50
    &.active
      color $black
  &__body-file-del
    position absolute
    top 50%
    right pxToRem(16px)
    transform translateY(-50%)
    cursor pointer
    img
      width pxToRem(20px)
  &__btn-file-area
    display flex
    align-items center
    justify-content center
    label
      position relative
      display block
      width 100%
      height 100%
      overflow hidden
  &__btn-file
    width 100%
    height 100%
    padding 0
    box-sizing border-box
    border-radius pxToRem(8px)
    border none
    background-color $blue-10
    color $blue-100
    text-align center
  &__agree-view
    cursor pointer
    color $gray-50
    text-decoration underline
  &__buttons
    position fixed
    bottom 0
    left 0
    right 0
    background-color $white
    padding pxToRem(16px)
    border-top 1px solid $gray-5
    z-index 10
    display flex
    &:after
      display block
      content ''
      padding-bottom env(safe-area-inset-bottom)
      padding-bottom constant(safe-area-inset-bottom)
    html[safe-area="true"] &
      padding-bottom 34px
    & > button
      width 100%
      height pxToRem(52px)
      border-radius pxToRem(8px)
      padding 0
  &__btn-submit
    color $gray-50
    background-color $gray-5
    border 0 none
    transition background-color 200ms ease-out
    pointer-events none
    &.active
      pointer-events auto
      color $white
      background-color $blue-100
      &:hover
        background-color $blue-10 !important
        color $blue !important
  &__btn-cancel
    color $gray-80
    border 1px solid $gray-20
    background-color $white
    margin-right ptr(8)
  &__agree-underline
    text-decoration underline !important

.question-alert
  h2
    margin 0 0 pxToRem(12px) !important
    padding 0 !important
    font-size pxToRem(16px) !important
    font-weight bold !important
    font-style normal !important
    line-height 1 !important
    letter-spacing -0.3px !important
    text-align center !important
    color #111 !important
  p
    margin 0 0 pxToRem(12px) !important
    padding 0 !important
    font-size pxToRem(12px) !important
    font-weight normal !important
    line-height 1.73 !important
    letter-spacing -0.2px !important
    text-align center !important
    color #111 !important
    a
      font-size pxToRem(12px) !important
      font-weight normal !important
      line-height 1.73 !important
      letter-spacing -0.2px !important
      text-align center !important
      color #111 !important
      text-decoration underline !important

.question-file-alert
  h3
    margin 0
    color $black
  p
    margin 0 !important
    margin-top pxToRem(12px) !important
    color $gray-80 !important
    white-space pre-wrap !important
    text-align center !important

.question-privacy-frame
  & > .don_wrapper
    & > .header
      z-index 10
      & > .title
        position absolute
        top 18px
        left 50%
        transform translateX(-50%)
        font-size 16px !important
        font-weight 700 !important
        line-height 24px !important
    & > .body
      & .title
        display none
  &__body
    border 0 none !important
    padding 0 !important
    margin-bottom 0 !important
    & p
      {$kr-12-m}
      margin-top pxToRem(12px)
      padding 0 !important
      color $gray-80
  & .terms_body
    padding ptr(16) !important
  &__table
    border-spacing 0
    width 100%
    border 1px solid $gray-20
    th
      background-color $gray-5 !important
    th
      box-sizing border-box !important
      height pxToRem(33px) !important
      border 1px solid $gray-20 !important
      font-family Pretendard
      color $gray-80 !important
      font-size 12px !important
      font-style normal !important
      font-weight 700 !important
      line-height 17px !important
      letter-spacing -0.2px !important
    tr
      border 1px solid $gray-20 !important
    td
      box-sizing border-box !important
      color $gray-80 !important
      font-family Pretendard !important
      font-size 12px !important
      font-style normal !important
      font-weight 500 !important
      line-height 17px !important
      letter-spacing -0.2px !important
      text-align center !important
      border 1px solid $gray-20 !important
      height pxToRem(84px) !important
    td
      font-size pxToRem(12px) !important
  & .option
    padding ptr(16) !important
  & .done
    height pxToRem(52px)
    display flex
    justify-content center
    align-items center
    color $white !important
    background-color $blue-100 !important
    width 100%
    border none
    border-radius 4px

.question-rune-modal
  position fixed
  top 0
  .rune-modal-body
    height 100% !important
