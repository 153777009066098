.maker_sticker_frame.maker_custom_asset_lib_frame
  .frame_close_bg
    opacity: 0.5;
  > .don_wrapper
    > .header
      display: block !important;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: 60px;
      box-sizing: border-box;
      padding: 24px;
      background rgba(255,255,255,0.9)
      z-index 1
      .title
        color: $ness-black;
        font-family: "Pragmatica Extended";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
      .don_hide_frame
        position: absolute !important;
        top: 19px !important;
        right: 19px !important;
        background-image: url("//s3.marpple.co/files/u_218933/2024/10/original/ba121cc4be96331487611d185440e03f717d3ae61.svg");
        background-repeat: no-repeat;
        background-size: 24px;
        background-position: center;
  .maker_custom_asset_lib_page
    > .don_wrapper
      > .header
        .don_hide_frame
          display: none;
  .maker_custom_asset_lib_page2
  .maker_custom_asset_lib_page3
    > .don_wrapper
      padding-top 68px
      > .header
        padding 0
        display flex
        position fixed
        top 60px
        left 24px
        right 24px
        align-items center
        border-bottom 0 none
        background rgba(255,255,255,0.9)
        .don_back_page
          position static
          background url("//s3.marpple.co/files/u_218933/2024/10/original/77907bf056764d35cbc45de4b2033497cb67f8ec1.svg") no-repeat
          background-size 24px 24px
          width 24px
          height 24px
        .name
          position static
          text-align left
          margin-left 10px
          color: $ness-black;
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%;
        .status
          position static
          margin-left 6px
          color: $ness-blue;
          font-family: "Pragmatica Extended", "Pretendard";
          font-size: 14px;
          font-weight: 700;
          line-height: 120%;
          &[is_badge="free"]
            display inline-flex
            padding: 4px 5px;
            color: $ness-black;
            text-align: center;
            font-size: 8px;
            font-weight: 700;
            line-height: 120%;
            background: $ness-blue;
            backdrop-filter: blur(3.7333333492279053px);
      > .body
        padding-top 44px
    .maker_custom_asset_image_list
      padding-top 4px
      padding-left 24px
      padding-right 24px
      padding-bottom 24px
      .infi_items
        margin-bottom 12px
        > div
          display grid
          gap 40px 12px
          grid-template-columns repeat(2, 1fr)
          @media screen and (min-width 768px)
            grid-template-columns repeat(4, 1fr)
  .maker_custom_asset_lib_page3
    > .don_wrapper
      > .header
        .group_image_name
          display: inline-block;
          position: relative;
          margin-left: 8px;
          padding-left: 8px;
          color: $ness-gray-80;
          font-family: Pretendard;
          font-size: 14px;
          font-weight: 700;
          line-height: 120%;
          &:before
            content: '';
            display: inline-flex;
            position: absolute;
            top: 50%;
            left: 0;
            height: 10px;
            width: 1px;
            transform: translateY(-50%);
            background-color $ness-gray-20
    .maker_custom_asset_group_image_list
      padding-top 0

  .maker_custom_asset_list
  .maker_custom_asset_group_image_list
    padding-top: 84px;
    padding-left 24px
    padding-right 24px
    padding-bottom 24px
    .infi_items
      margin-bottom 12px
      > div
        display grid
        gap 12px
        grid-template-columns repeat(2, 1fr)
        @media screen and (min-width 768px)
          grid-template-columns repeat(4, 1fr)
  .custom_asset_folder_item
    position relative
    vertical-align top
    width 100%
    cursor pointer
    &:active
      .image
        border-color $ness-blue

    .image
      position relative
      padding 0
      padding-top 100%
      box-sizing border-box
      border-radius: 8px;
      border: 1px solid $ness-gray-20;
      img
        position absolute
        top 0
        left 0
        right 0
        bottom 0
        max-width 90%
        max-height 90%
        margin auto
        border-radius: 8px;
    .name
      margin-top 8px
      text-align center
      height 19px
      overflow hidden
      text-overflow ellipsis
      white-space nowrap
      color: $ness-black;
      font-family: Pretendard;
      font-size: 12px;
      font-weight: 500;
      line-height: 140%;
    .status
      margin-top 2px
      color: $ness-blue
      text-align: center;
      font-family: "Pragmatica Extended", "Pretendard";
      font-size: 12px;
      font-weight: 700;
      line-height: 120%;
  .custom_asset_image_item
    position relative
    margin 0
    width 100%
    cursor pointer
    box-sizing border-box
    border-radius: 8px;
    border: 1px solid $ness-gray-20;
    &[is_group="true"]
      .thumbs
        padding 100% 0 0
        display block
        text-align center
        &[group_length="0"]
          display none
        .thumb
          display inline-block
          width 30%
          height 30%
          margin 0
          background-repeat no-repeat
          background-size contain
          background-position center center
          &:nth-child(1)
            position absolute
            top 12%
            left 12%
          &:nth-child(2)
            position absolute
            top 12%
            right 12%
          &:nth-child(3)
            position absolute
            bottom 12%
            left 12%
          &:nth-child(4)
            position absolute
            bottom 12%
            right 12%
      .image
        display none
      .name
        position absolute
        bottom -26px
        left 0
        right 0
        display block
        overflow: hidden;
        color: $ness-black;
        text-align: center;
        text-overflow: ellipsis;
        font-family: Pretendard;
        font-size: 13.44px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 18.816px */
    &.selected
    &:active
      border-color: $ness-blue
      background-color $ness-white
    .thumbs
      display none
    .image
      border-radius 8px
      padding 100% 0 0
      background url(//s3.marpple.co/files/u_218933/2024/10/original/c0f2b3a8218b1e0ccba0c6daa30cb8bb114e6d201.png) no-repeat
      background-size contain
      img
        position absolute
        top 0
        left 0
        right 0
        bottom 0
        max-width 90%
        max-height 90%
        margin auto
        vertical-align top
    .name
      display none

.maker_sticker_frame.maker_custom_user_asset_lib_frame
  .frame_close_bg
    opacity: 0.5;
  > .don_wrapper
    > .header
      display: block !important;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: 60px;
      box-sizing: border-box;
      padding: 24px;
      background rgba(255,255,255,0.9)
      z-index 1
      .title
        color: $ness-black;
        font-family: "Pragmatica Extended";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
      .don_hide_frame
        position: absolute !important;
        top: 19px !important;
        right: 19px !important;
        background-image: url("//s3.marpple.co/files/u_218933/2024/10/original/ba121cc4be96331487611d185440e03f717d3ae61.svg");
        background-repeat: no-repeat;
        background-size: 24px;
        background-position: center;
  .maker_custom_user_asset_lib_page
    > .don_wrapper
      > .header
        .don_hide_frame
          display: none;
  .maker_custom_user_asset_lib_page2
  .maker_custom_user_asset_lib_page3
    > .don_wrapper
      padding-top 68px
      > .header
        display none
        padding 0
        position fixed
        top 60px
        left 24px
        right 24px
        align-items center
        border-bottom 0 none
        background rgba(255,255,255,0.9)
        .don_back_page
          position static
          background url("//s3.marpple.co/files/u_218933/2024/10/original/77907bf056764d35cbc45de4b2033497cb67f8ec1.svg") no-repeat
          background-size 24px 24px
          width 24px
          height 24px
        .name
          position static
          text-align left
          margin-left 10px
          color: $ness-black;
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%;
        .status
          position static
          margin-left 6px
          color: $ness-blue;
          font-family: "Pragmatica Extended";
          font-size: 14px;
          font-weight: 700;
          line-height: 120%;
          &[is_badge="free"]
            display inline-flex
            padding: 4px 5px;
            color: $ness-black;
            text-align: center;
            font-size: 8px;
            font-weight: 700;
            line-height: 120%;
            background: $ness-blue;
            backdrop-filter: blur(3.7333333492279053px);
      > .body
        padding-top 0
    .maker_custom_user_asset_image_list
      padding-top 4px
      padding-left 24px
      padding-right 24px
      padding-bottom 24px
      .infi_items
        margin-bottom 12px
        > div
          display grid
          gap 12px
          grid-template-columns repeat(2, 1fr)
          @media screen and (min-width 768px)
            grid-template-columns repeat(4, 1fr)
  .maker_custom_user_asset_lib_page3
    > .don_wrapper
      > .header
        .group_image_name
          display: inline-block;
          position: relative;
          margin-left: 8px;
          padding-left: 8px;
          color: $ness-gray-80;
          font-family: Pretendard;
          font-size: 14px;
          font-weight: 700;
          line-height: 120%;
          &:before
            content: '';
            display: inline-flex;
            position: absolute;
            top: 50%;
            left: 0;
            height: 10px;
            width: 1px;
            transform: translateY(-50%);
            background-color $ness-gray-20
    .maker_custom_user_asset_group_image_list
      padding-top 0

  .maker_custom_user_asset_list
  .maker_custom_user_asset_group_image_list
    padding-top: 84px;
    padding-left 24px
    padding-right 24px
    padding-bottom 24px
    .infi_items
      margin-bottom 12px
      > div
        display grid
        gap 12px
        grid-template-columns repeat(2, 1fr)
        @media screen and (min-width 768px)
          grid-template-columns repeat(4, 1fr)
  .custom_asset_folder_item
    position relative
    vertical-align top
    width 100%
    cursor pointer
    &:active
      .image
        border-color $ness-blue

    .image
      position relative
      padding 0
      padding-top 100%
      box-sizing border-box
      border-radius: 8px;
      border: 1px solid $ness-gray-20;
      img
        position absolute
        top 0
        left 0
        right 0
        bottom 0
        max-width 90%
        max-height 90%
        margin auto
        border-radius: 8px;
    .name
      margin-top 8px
      text-align center
      height 19px
      overflow hidden
      text-overflow ellipsis
      white-space nowrap
      color: $ness-black;
      font-family: Pretendard;
      font-size: 12px;
      font-weight: 500;
      line-height: 140%;
    .status
      margin-top 2px
      color: $ness-blue
      text-align: center;
      font-family: "Pragmatica Extended", "Pretendard";
      font-size: 12px;
      font-weight: 700;
      line-height: 120%;
  .custom_asset_image_item
    position relative
    margin 0
    width 100%
    cursor pointer
    box-sizing border-box
    border-radius: 8px;
    border: 1px solid $ness-gray-20;
    &[is_group="true"]
      .thumbs
        padding 100% 0 0
        display block
        text-align center
        &[group_length="0"]
          display none
        .thumb
          display inline-block
          width 30%
          height 30%
          margin 0
          background-repeat no-repeat
          background-size contain
          background-position center center
          &:nth-child(1)
            position absolute
            top 12%
            left 12%
          &:nth-child(2)
            position absolute
            top 12%
            right 12%
          &:nth-child(3)
            position absolute
            bottom 12%
            left 12%
          &:nth-child(4)
            position absolute
            bottom 12%
            right 12%
      .image
        display none
      .name
        display none
        position absolute
        bottom -26px
        left 0
        right 0
        overflow: hidden;
        color: $ness-black;
        text-align: center;
        text-overflow: ellipsis;
        font-family: Pretendard;
        font-size: 13.44px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 18.816px */
    &.selected
    &:active
      border-color: $ness-blue
      background-color $ness-white
    .thumbs
      display none
    .image
      border-radius 8px
      padding 100% 0 0
      background url(//s3.marpple.co/files/u_218933/2024/10/original/c0f2b3a8218b1e0ccba0c6daa30cb8bb114e6d201.png) no-repeat
      background-size contain
      img
        position absolute
        top 0
        left 0
        right 0
        bottom 0
        max-width 90%
        max-height 90%
        margin auto
        vertical-align top
    .name
      display none
