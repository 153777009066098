.additional_or_change_pay_method
  >.don_wrapper
    >.header
      z-index 1 !important
      height 60px !important
      >.title
        position absolute !important
        top 18px !important
        left 50% !important
        transform translateX(-50%)
        font-size 16px !important
        font-weight 700 !important
        line-height 24px !important

  .payment_type_container
    padding pxToRem(16px) !important
    padding-bottom calc(env(safe-area-inset-bottom) + 92px) !important
    padding-bottom calc(constant(safe-area-inset-bottom) + 92px) !important
    html[safe-area="true"] &
      padding-bottom calc(env(safe-area-inset-bottom) + 126px) !important
      padding-bottom calc(constant(safe-area-inset-bottom) + 126px) !important

    .bank_info
      margin 0 !important

    select
      padding pxToRem(12px) pxToRem(16px) pxToRem(12px) 0 !important
      box-sizing border-box !important
      -webkit-appearance none !important
      -moz-appearance none !important
      border 1px solid $gray-10 !important
      border-radius pxToRem(8px) !important
      background $icon-dropdown-arrow no-repeat 97% center !important
      background-size pxToRem(16px) !important
      width 100% !important
      height 100% !important
      text-indent pxToRem(12px) !important
      color $black !important
      margin-bottom ptr(12) !important

    input
      padding pxToRem(12px) 0 !important
      color $black !important
      border none !important
      border-bottom 1px solid $gray-10 !important
      background-color #fff !important
      outline none !important
      border-radius 0 !important
      -webkit-border-radius 0 !important
      margin-bottom ptr(12) !important
      &::placeholder
        -webkit-text-fill-color $gray-50 !important
        color $gray-50 !important


    /* 하단 버튼 영역 */
    .options
      margin 0 !important
      position fixed
      bottom 0
      left 0
      right 0
      background-color $white
      padding pxToRem(16px)
      border-top 1px solid $gray-5
      z-index 10
      display flex
      column-gap ptr(8)
      &:after
        display block
        content ''
        padding-bottom env(safe-area-inset-bottom)
        padding-bottom constant(safe-area-inset-bottom)
      html[safe-area="true"] &
        padding-bottom 34px
      & > button
        width 100%
        height pxToRem(52px)
        border-radius pxToRem(8px)
        padding 0
