.don_frame[frame_name="/modules/MShop/App/Keyword/S/Mui/frame.js"]
  @import './keyword-container.styl'
  .keyword-container
    position fixed
    top 0
    left 0
    right 0
    padding 10px 24px
    background-color #fff
    box-sizing border-box
    z-index 2

  .header
    display none

  .creator-layout
    position relative

  .don_loader_wrap
    width 100% !important

  // Tab 네비게이션
  .app-search__tabs
    position fixed
    display flex
    align-items center
    z-index 1
    top 60px
    left 0
    right 0
    height 48px
    border-bottom 2px solid #f8f8f8
    box-sizing border-box
    background-color #fff
    padding 0 24.5px
    overflow scroll
    white-space nowrap
    &::-webkit-scrollbar
      display none
    &--hidden
      opacity 0
    > a
      margin-right 20px
    .header-menu__name
      font-size 15px
      font-weight 500
      letter-spacing -0.2px
      color #828282
    .header-menu__count
      font-size 15px
      font-weight 500
      letter-spacing -0.5px
      color #a2a2a2
    &--selected
      .header-menu__name
        color #111111
      .header-menu__count
        color #1C75FF

  // 검색 결과
  .app-search
    &__results
      margin-top 108px
      &.app-search__results--empty
        position absolute
        top 50%
        left 50%
        transform translate(-50%, -50%)
        width 100%
        margin-top 0
    &__result--products
      padding 32px 24px
      border-bottom 8px solid #f8f8f8
    &__result--todays
      padding 32px 24px
    &__result:last-child
      margin-bottom 20px
      border-bottom none
    &__title
      display flex
      justify-content space-between
      font-size 18px
      letter-spacing -0.2px
      color #000000
      font-weight bold
      margin-bottom 20px
    &__count
      font-weight normal
      color #a2a2a2
      margin-left 4px
    &__view-more
      font-size 14px
      font-weight 500
      letter-spacing -0.3px
      color #828282
      cursor pointer
    &__view-more::after
      content ''
      display inline-block
      background url(https://s3.marpple.co/files/u_2232571/2023/5/original/e81934b374b55990f3f06134143b5d26d32374ce1.png) no-repeat center
      background-size 12px
      width 12px
      height 10px

    &__result-list
      display flex
      flex-direction column
      flex-wrap wrap
      &--todays
        display block
  &__popular
    margin 56px 0 16px
  &__popular-list
    display flex
    flex-wrap wrap
  &__popular-item
    padding 11px 11px 11px 12px
    border-radius 2px
    background-color #f8f8f8
    margin-right 8px
    margin-bottom 8px
    button
      font-size pxToRem(18px)
      line-height 1
      letter-spacing -0.2px
      color #4e4e4e
      reset-button()
  .app-search__zero
    margin 0
    padding 0 60px
  .app-product-zero__description
    margin-bottom 0

  .app-search.is_app
    .keyword-container
      padding 0
    .keyword-container__form
      display none
    .auto-complete__list
      top 0
    .app-search__tabs
      top 0
    .app-search__results
      margin-top 50px
      &--empty
        margin 0

  //.app-product-item
  //  &--v5
  //    margin-right pxToRem(20px)
  //  &__p-name
  //    font-size pxToRem(15px)
  //    color #828282
  //  &__price
  //    font-size pxToRem(16px)

  .app-store-like
    display flex
    align-items center
    &__btn
      reset-button()
      position relative
      min-width 24px
      .count
        padding 0 2px
      &:before
        content ''
        display block
        width 22px
        height 22px
        border-radius 100%
        margin auto
        background-image url(//s3.marpple.co/files/u_29089/2021/4/original/e3906e0ac1fe7396b7f4f37dba60467c71afb9cb1.png)
        background-size 16px 16px
        background-position center
        background-repeat no-repeat
    &__heart
      display none
      &--on
        display block
        background-image url(//s3.marpple.co/files/u_29089/2021/4/original/04f209f889e653e24cd3954e32f73bbdfb3f6d991.png)
        background-size 17px 17px
        background-position left
        background-repeat no-repeat
        position absolute
        top 0
        left 3px
        right 0
        bottom 0
  .app-product-zero
    .keyword
      color #1C75FF
  .app-product-sub__sort
    position relative
    &-btn
      border none
      background-color #fff
      color #070918
      display flex
      align-items center
      font-size 13px
      font-weight 500
      letter-spacing -0.2px
    &-btn:after
      content ''
      margin-left 6px
      display block
      background url(https://s3.marpple.co/files/u_2232571/2023/5/original/b05f123addfbe454cdb5deb2185b204f94f3526a1.png) no-repeat center center
      background-size 12px 13px
      width 12px
      height 13px
    &-selector
      position absolute
      top 100%
      right 0
      z-index 1
      visibility hidden
      min-width pxToRem(158px)
    &-selector--show
      visibility visible
      left auto
      opacity 1
    &-list
      margin-top 4px
      color #111111
      background-color #ffffff
      border-radius 4px
      border solid 1px #dddddd
    &-item
      text-align center
      border 1px solid #f8f8f8
      button
        reset-button()
        padding 10px 0
        font-size 15px
        line-height 1.4
        letter-spacing -0.2px
        color #424242
        width 100%
      &--selected
        button
          color #111
          font-weight 600
      &:last-child
        border none
  .seller-item2__sns_icon
    width 28px
    height 28px
    padding 2px
    bottom -2px
    right -2px
    box-sizing content-box
  .seller-product-item-empty
    margin-top 40px
    font-size 14px
    font-weight 400
    color #a2a2a2
    justify-content start


  // 전체 탭
  .don_tab[tab_name="/modules/MShop/App/Keyword/S/Mui/tab.js"]
    .app-search
      margin-bottom 60px
    .app-search__result--sellers
      padding 32px 0
      border-bottom 8px solid #f8f8f8
      &.app-search__result:last-child
        border-bottom none
      .app-search__title
        padding 0 24px

    .app-search__results
      overflow-y scroll
    .app-search__result-list--sellers
      flex-direction row
      display flex
      flex-wrap nowrap
      overflow-y scroll
      scrollbar-width none
      margin 0
      gap 20px
      box-sizing border-box
      &::-webkit-scrollbar
        display none
      .seller-item2
        width 50%
        margin-bottom 56px
        box-sizing border-box
        padding 0 28px 8px
      .seller-item2__sns_icon
        width 32px
        height 32px
      .about
        width 120px
        padding 0
        margin-bottom 0
        &:first-child
          padding-left 20px
        &:last-child
          padding-right 20px
      .about__profile
        flex-direction column
        align-items center
      .about__thumb
        margin 0
        width 120px
      .about__thumb-img
        border-radius 50%
      .about__name
        color #111111
        font-size 14px
        line-height 1.4
        letter-spacing -0.2px
        margin-top 20px
        margin-bottom 12px
        overflow hidden
        text-overflow ellipsis
        white-space nowrap
        text-align center
      .about__meta
        background-color #f8f8f8
        border-radius 18px
        padding 4px 10px
        width fit-content
        min-width 40px
        margin 0 auto


    .app-search__result-list--products
      display grid
      grid-template-columns repeat(2, minmax(0, 1fr))
      grid-column-gap 15px
      grid-row-gap 32px
      .app-product-item
        width 100%


  .app-search__result--todays
    margin-bottom 8px
    .app-search__title
      margin-left 0
      margin-right 0
      > span
        font-size 18px
        font-weight bold
        color #000000
    .app-search__count
      font-size 18px
      font-weight 500
      color #a2a2a2
      margin-left 4px

  .app-search__result-list--todays
    margin 0
    .today-feed
      margin-bottom 0
    .today-feed__content
      padding-left 0
    .today-feed__thumbnail
      object-fit contain
  .app-search__result--products
    .app-search__title
      margin-left 0
      margin-right 0
    .app-search__view-more.app-search__view-more__footer
      reset-button()
      width 100%
      height 40px
      margin-top 32px
      border-radius 20px
      border: solid 1px #dddddd
      &::after
        display none
  .app-search__result-list--products
    margin 0
    flex-direction row
    column-gap 20px
    row-gap 32px

  .app-product-item
    margin 0
    width calc((100% - 20px) / 2)
  .app-product-item__p-name
    color #828282
  .mp-product-img-bg-wrap
    border-radius 0

  .don_tab[tab_name="/modules/MShop/App/Keyword/Seller/S/Mui/tab.js"]
    overflow hidden
    .app-search
      margin-bottom 0
    .app-search__title
      margin-left 0
      margin-right 0
      > span
        font-size 18px
        font-weight bold
        color #000000
    .app-search__results
      padding 32px 24px
    .app-search__result-list--sellers
      .infi_wrapper
        width 100%
      .infi_items > div
        display flex
        flex-wrap wrap
        gap 40px
      .about
        margin 0
        padding 0
        width 100%
      .about__info
        display flex
        flex-direction column
        gap 12px
      .about__name
        text-align start
        margin 0
      .about__description
        display none
      //.about__meta
      //  margin 0
      .about__meta
        background-color #f8f8f8
        border-radius 18px
        padding 4px 10px
        width fit-content
        min-width 40px
      .about__thumb
        width 104px
        margin-right 0
      .about__description
        margin-top 0
        color #828282
        font-size 14px
        letter-spacing -0.2px
        font-weight 500
        overflow hidden
        text-overflow ellipsis
        white-space nowrap
      .about__thumb-img
        border-radius 50%
        width 104px
      .about__profile
        flex-direction row
        align-items center
        gap 16px
        margin-bottom 16px
        height 104px
      .mp-product-img-bg-wrap
        border-radius unset
      .seller-item2__products
        display flex
        gap 8px
        height auto
      .seller-product-item
        margin 0
        width calc((100% - 8px) / 3)
        height fit-content


    // 상품탭
  .don_tab[tab_name="/modules/MShop/App/Keyword/Product/S/Mui/tab.js"]
    overflow hidden
    .app-search__title
      margin-left 0
      margin-right 0
      > span
        font-size 18px
        font-weight bold
        color #000000
    .app-search__result-list--products
      .infi_wrapper
        width 100%
      .infi_items > div
        display grid
        grid-template-columns repeat(2, minmax(0, 1fr))
        grid-column-gap 15px
        grid-row-gap 32px
        margin-bottom 32px
      .infi_item
        width 100%



  .auto-complete
    &.hidden
      display none
    &__list
      opacity 1
      padding 14px 0 0 0
      left 0
      margin-top 0
      background-color #fff
      &.hide
        opacity 0
        visibility hidden
        height 0
    &__item
      padding 18px 24px
      color #111111
      list-style none
      font-size 15px
      font-weight 500
      letter-spacing -0.2px
      &--matched
        color #1C75FF

  // 콘텐츠 탭
  .don_tab[tab_name="/modules/MShop/App/Keyword/Contents/S/Mui/tab.js"]
    .app-search__result-list--todays
       display flex
       flex-direction column
       gap 48px



