.hello-home-header
  margin-top 18px
  padding 0 24px
  display flex
  align-items center

  &__left_arrow
    width 20px
    margin-right 10px
    cursor pointer

  &__title
    font-size 18px
    color white
    font-weight bold
    letter-spacing -0.2px
    margin-bottom -5px
    line-height 1.56


.hello-home-top-feature
  z-index 1
  display flex
  align-items center
  padding 24px 0
  background #000
  position sticky
  top 0


.hello-home-selector-slider
  padding 0 24px
  width 100%
  box-sizing border-box

  &__wrapper
    width 100%

  &__row
    list-style none
    padding 0
    margin 0
    &:first-child
      margin-top 0

  &__item
    color white
    border 1px solid black
    align-items center
    font-weight bold
    letter-spacing -0.2px
    font-size 15px
    margin-right 20px
    cursor pointer

    &:first-child
      margin-left 1px

  &__item__icon
    margin-right 10px

