.mshop-default-footer
  width 100%
  display flex
  flex-direction column
  background-color $gray-100

  [data-show="false"]
    display none !important

  &__upper
    display flex
    align-items center
    padding 24px
    justify-content center
  &__main-links
    {$font-body-b3-r}
    color $gray-700
    display flex
    flex-wrap wrap
    justify-content center
    row-gap 4px
    align-items center
  &__vertical-separator
    display inline-flex
    width 1px
    height 10px
    background-color $gray-300
    &--lg
      margin 0 16px
    &--md
      margin 0 8px
  &__main-link
    cursor pointer
    word-break keep-all
    &.bold
      font-weight bold
  &__langs-pc
    display none
  &__hr
    height 1px
    background-color $gray-200
    border 0
    margin 0 24px
  &__bottom
    flex-grow 1
    padding 24px
    display flex
    justify-content center
    flex-direction column
  &__company
    color $black
    cursor pointer
    margin 0
    display inline-flex
    {$font-body-b3-r}
    justify-content center
    align-items center
  &__company[data-open="true"] &__company-icon
    transform rotate(-180deg)
  &__company-detail
    display none
    text-align center
    margin-top 8px
    {$font-body-b5-r}
    color $gray-500
    &[data-open="true"]
      display block
    &[data-always-open="true"]
      display block
  &__company-icon-wrapper
    width 16px
    height 16px
    display inline-flex
    border-radius 50%
    background-color $white
    border 1px solid $gray-200
    margin-left 6px
    align-items center
    justify-content center
  &__company-icon
    display inline-flex
    width 12px
    transition transform 0.3s linear
  &__description
    display flex
    flex-direction column
    align-items center
  &__description-text
    {$font-body-b4-r}
    color: $gray-500
    margin 0
    &--1
      margin-top 16px
    &--2
      margin-top 12px
  &__langs-mobile
    display flex
    align-items center
    justify-content center
    margin-top 24px
    {$font-body-b3-r}
    [data-lang="jp"]
      font-family Pretendard JP
  &__sns-list
    display flex
    column-gap 16px
    justify-content center
    margin-top 24px
  &__sns
    cursor pointer
    display flex
    align-items center
    justify-content center
    width 32px
    height 32px
    background-color $black
    border-radius 50%
  &__sns-icon
    width 16px
    height 16px
