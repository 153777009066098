// modules/MpShopPlus/Terms/F/Style/Mo/index.styl

.nbox-terms-page
  padding 0 20px
  margin 50px auto
  h2
    margin-top 30px
  p
    line-height 1.5

