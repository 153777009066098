.mall-communities
  padding pxToRem(16px) pxToRem(24px) pxToRem(60px)
  .community-recent-list
    min-height pxToRem(540px)
  &__sub_title
    margin 64px 24px 16px
    font-size 24px
    font-weight 600
    line-height 1.25
    letter-spacing -0.3px
    color #111
    &:first-child
      margin-top 10px
  &__title
    margin 0 24px
    p
      margin 0 0 6px 0
      font-size pxToRem(16px)
      font-weight 600
      line-height 1.25
      color #a2a2a2
    h2
      margin 0 0 24px
      font-size pxToRem(23px)
      font-weight 600
      line-height 1.3
      letter-spacing -0.3px
  &__king_of_reviews
    padding 0 24px
    margin-bottom 40px

.user-profile-image
  background-color: $white;

.community-review-king
  width 22.02%
  margin-left 8px
  overflow hidden
  &:first-child
    margin-left 0

.community-review-king-trs
  &__thumb
    position relative
    max-width 100%
    margin-bottom pxToRem(10px)
  &__thumb-img
    position relative
    width 100%
    padding-top 100%
    background-size cover
    background-repeat no-repeat
    background-position center
    border-radius pxToRem(20px)
  &__sns
    position absolute
    bottom -3px
    right -3px
    width pxToRem(22px)
    height pxToRem(22px)
    border pxToRem(3px) solid #fff
    border-radius 50%
    overflow hidden
    img
      width 100%
  &__rank
    margin-bottom pxToRem(7px) !important
  &__name
    margin-bottom pxToRem(4px)
    font-size pxToRem(15px)
    line-height 1.27
    letter-spacing -0.2px
    color #111
    white-space nowrap
    text-overflow ellipsis
    overflow hidden
  &__info
    display flex
    justify-content flex-start
    align-items center
    font-weight 600
    font-size 14px
    line-height 1.29
    letter-spacing -0.2px
    color #111
  &__star
    margin-right 3px
    img
      display inline-block
      margin-bottom -1px
      height 14px
      width 14px
  &__avg
    margin-right 10px
  &__review-txt
    font-weight normal


.community-item
  margin-bottom 40px
  &__photo
    position relative
    width 100%
  &__photo_img
    background-color rgba(0, 0, 0, 0.04)
    img
      width 100%
      height 100%
      max-width 100%
      max-height 800px
      vertical-align top
      object-fit cover
  &__more
    display none
    &[is_more="true"]
      display block
      position absolute
      top 8px
      right 8px
      width 35px
      height 35px
      background url("//s3.marpple.co/files/u_218933/2021/5/original/7182c9727bf6f6e5986a74dadebdfc08732530821.png") no-repeat
      background-size 35px 34px
  &__user_name
    position absolute
    left 18px
    bottom 18px
    font-size 15px
    line-height 1.33
    letter-spacing -0.2px
    color #fff
  &__user
    position absolute
    left pxToRem(14px)
    bottom pxToRem(14px)
    color #fff
  &__body
    margin pxToRem(12px) pxToRem(14px)
    font-size pxToRem(14px)
    line-height 1.5
    letter-spacing -0.2px
    color #000
    limitLine(4)
  &[article_type="text"]
  &[article_type="community_text"]
    .community-item__body
      height auto
      min-height pxToRem(106px)
      border 1px solid #e5e5e5
      margin-top 0
      margin-left 0
      margin-right 0
      padding pxToRem(15px) pxToRem(20px)
    .community-item__body-text
      height pxToRem(86px)
      limitLine(4)
    .community-item__body-user
      margin-top pxToRem(10px)
      font-weight 500
  &__controls
    margin 0 pxToRem(10px) 0 pxToRem(12px)

.community-review-item
  aspect-ratio 343 / 276
  padding ptr(16)
  border 1px solid $gray-10
  border-radius ptr(16)
  display flex
  flex-direction column
  gap ptr(12)
  flex-shrink 0
  &[css_type="small"]
    .community-review-item__user_name
      font-size 13px
      left 12px
      bottom 9px
    .community-review-item__body
      height 42px
      overflow hidden
      font-size 14px
      line-height 1.5
      word-break break-word
    .community-review-item__more
      width 25px
      height 24px
      background-size 25px 24px
    .community-review-item__score
      left 12px
      bottom 27px
      .article-review-score
        white-space nowrap
        .star
          display inline-block
          margin-right pxToRem(2px)
          width pxToRem(16px)
          height pxToRem(16px)
          background url("//s3.marpple.co/files/u_2308668/2024/4/original/28137197cd5a35fee38531263c8ff9a856cfc9731.png") no-repeat center center
          background-size pxToRem(16px) auto
          &[is="true"]
            background-image url("//s3.marpple.co/files/u_2308668/2024/4/original/62c1c5e9e64dcdbf684e8dec0b6b8444ee3a81f01.png")
  &[css_type="small_fix"]
    width 56.27%
    min-height 350px
    margin-bottom 0
    margin-right 8px
    .community-review-item__photo_img
      position relative
      width 100%
      padding-top 100%
      overflow hidden
      img
        position absolute
        top 0
        left 0
        right 0
        bottom 0
        margin auto
        width 100%
    .community-review-item__body
      margin-left 14px
      margin-right 14px
      margin-bottom 10px
      height 44px
      overflow hidden
      font-size 14px
      line-height 1.5
      word-break break-word
    .community-review-item__user_name
      font-size 13px
      left 12px
      bottom 9px
    .community-review-item__more
      width 25px
      height 24px
      background-size 25px 24px
    .community-review-item__score
      left 12px
      bottom 27px
      .article-review-score
        .star
          width 12px
          height 12px
          background-size 12px auto
          margin-right 1px
  &__photo
    flex-shrink 0
    position relative
    width ptr(88)
    cursor pointer
    aspect-ratio 1
    overflow hidden
    background-color #F6F6F6
    display flex
    align-items center
    border-radius pxToRem(12px)
  &__photo_img
    background-size cover
    background-position center
    background-repeat no-repeat
    width 100%
    height 100%
    z-index 1
    &[data-is_swiper="false"]
      width 100%
      height 100%
    img
      object-fit cover
      object-position center
      width 100%
      height 100%
      z-index 1
  &__more
    display none
    &[is_more="true"]
      display none
      position absolute
      top 8px
      right 8px
      width 35px
      height 35px
      background url("//s3.marpple.co/files/u_218933/2021/5/original/7182c9727bf6f6e5986a74dadebdfc08732530821.png") no-repeat
      background-size 35px 34px
      z-index 1
  &__user-info
    display flex
    align-items center
  &__user-info-middle
    display flex
    flex-direction column
    row-gap ptr(2)
    margin-left ptr(12)
  &__score
    background-color inherit
  &__user_name
    color $black
    margin-left ptr(2)
    & > span
      limitLine(1)
    & > a
      display flex
      align-items center
      column-gap ptr(4)
      & > span
        limitLine(1)
      & > img
        flex-shrink 0
  &__user-profile-wrap
    width pxToRem(42px)
    height pxToRem(42px)
    flex-shrink 0
  &__body
    margin 0
    color $black
    cursor pointer
    min-height pxToRem(66px)
    limitLine(3)
    word-break break-word
  &__product
    margin 0
  &__controls
    margin 0
  &__remove
    display flex
    justify-content center
    align-items center
  &__remove-btn
    display flex
    align-items center
    border none
    color $gray-80
    cursor pointer
    padding 0
    height pxToRem(22px)
    background-color $white
  &__middle
    display flex
    flex-direction column
    justify-content space-between
    row-gap ptr(12)
  &__middle-top
    display flex
    column-gap ptr(8)
    justify-content space-between

.community-pd-review-item
  margin-bottom 40px
  &__photo
    position relative
    width 100%
    cursor pointer
  &__photo_img
    background-size cover
    background-position center
    background-repeat no-repeat
    img
      width 100%
      max-height 800px
  &__more
    display none
    &[is_more="true"]
      display block
      position absolute
      top 8px
      right 8px
      width 35px
      height 35px
      background url("//s3.marpple.co/files/u_218933/2021/5/original/7182c9727bf6f6e5986a74dadebdfc08732530821.png") no-repeat
      background-size 35px 34px
  &__info
    margin-top 6px
    padding 0 20px
    display flex
    justify-content space-between
    align-items center
  &__user_name
    color $black
    & > span
      limitLine(1)
      margin-right ptr(16)
    & > a
      display flex
      align-items center
      column-gap ptr(4)
      margin-right ptr(16)
      & > span
        limitLine(1)
      & > img
        flex-shrink 0
  &__created_at
    font-size 15px
    line-height 1.4
    letter-spacing -0.2px
    text-align right
    color #a2a2a2

.article-item-control
  display flex
  justify-content space-between
  align-items center
  &[css_type="small"]
    margin 0
    .article-item-control__date
      {$font-body-b3-r}
      color $gray-500
  &__btn_like
    box-sizing border-box
    display flex
    justify-content flex-end
    align-items center
    border none
    color $gray-50
    cursor pointer
    padding-left ptr(24)
    background-image url('//s3.marpple.co/files/u_2308668/2024/4/original/a00fb932fb5434114fe868c8f1d0082a491ea2e21.png')
    background-size pxToRem(20px)
    background-position-x 0
    background-repeat no-repeat
    height pxToRem(20px)
    &[is_like="true"]
      background-image url('//s3.marpple.co/files/u_2308668/2024/4/original/7757e7d591949f6dfb32e0c5287ae01758a00d4c1.png')
  &__btn_comment
    display none
  &__date
    flex 1
    text-align right
    color $gray-50
    height 100%
    margin-left ptr(16)
  &__btn_report
    display none


.article-cheer-up-item-control
  display inline-flex
  justify-content space-between
  align-items center
  gap ptr(12)
  &[css_type="small"]
    margin 0
    .article-item-control__date
      {$font-body-b3-r}
      color $gray-500
  &__btn_like
    flex 1
    box-sizing border-box
    display flex
    justify-content flex-start
    align-items center
    border none
    color $gray-50
    cursor pointer
    padding-left ptr(28)
    background-image url('//s3.marpple.co/files/u_2308668/2024/11/original/dd91688cff5a0a4e228e5c6a5c12e64444170ae71.svg')
    background-size pxToRem(24px)
    background-position-x 0
    background-repeat no-repeat
    height pxToRem(24px)
    &[is_like="true"]
      background-image url('//s3.marpple.co/files/u_2308668/2024/11/original/c858d7f3e0deac1b6233ad2b33d1807f2447ea4a1.svg')
  &__btn_comment
    box-sizing border-box
    display flex
    justify-content flex-end
    align-items center
    border none
    color $gray-50
    cursor pointer
    padding-left ptr(28)
    background-image url('//s3.marpple.co/files/u_2308668/2024/11/original/3008cec05f662657936e52e95e11b2e0b863383d1.svg')
    background-size pxToRem(24px)
    background-position-x 0
    background-repeat no-repeat
    height pxToRem(24px)
  &__date
    flex 1
    text-align right
    color $gray-50
    height 100%
  &__btn_report
    display none

.article-product
  .community-review-item__date
    display none
  margin 0
  &[css_type="small"]
    margin 0
    > a
      min-height 60px
      padding-left 72px
    .article-product__thumb
      width 60px
      height 60px
      border-radius 8px
      overflow hidden
    .article-product__store_name
      display none
    .article-product__name
      color $gray-80
      limitLine(1)
    .article-product__price
      color $gray-80
      limitLine(1)
      margin-top 2px
  > a
    display flex
    flex-direction column
    justify-content center
    position relative
    padding-left 90px
    margin 0
    min-height 72px
  &__thumb
    display flex
    align-items center
    justify-content center
    position absolute
    top 0
    left 0
    width pxToRem(60px)
    height pxToRem(60px)
    background-color #f8f8f8
    overflow hidden
    border-radius pxToRem(8px)
    img
      max-width 100%
      max-height 100%
  &__name
  &__store_name
  &__price
    color $gray-80
    limitLine(1)
    margin-top ptr(2)
  &__store_name
    display none
  &__price
    color $gray-80
    &[lang="kr"]
      &:after
        content "원"
    &[lang="en"]
      &:before
        content "$"
    &[lang="jp"]
      &:before
        content "¥"

.article-review-score
  white-space nowrap
  .star
    display inline-block
    margin-right 2px
    width 14px
    height 14px
    background url("//s3.marpple.co/files/u_2308668/2023/9/original/78182d5a3a9efc80d1b3c11d86dc8f6db48ea7751.png") no-repeat center center
    background-size 14px auto
    &[is="true"]
      background-image url("//s3.marpple.co/files/u_2308668/2023/9/original/13bdf7d003e31202013d471187df9d2b6cbbf2031.png")


.cheer-up-item
  padding ptr(16)
  border 1px solid $gray-10
  border-radius ptr(16)
  display flex
  flex-direction column
  gap ptr(12)
  flex-shrink 0
  &[css_type="small"]
    .cheer-up-item__user_name
      font-size 13px
      left 12px
      bottom 9px
    .cheer-up-item__body
      display flex
      height 42px
      overflow hidden
      font-size 14px
      line-height 1.5
      word-break break-word
    .cheer-up-item__more
      width 25px
      height 24px
      background-size 25px 24px
    .cheer-up-item__score
      left 12px
      bottom 27px
      .article-review-score
        .star
          width 12px
          height 12px
          background-size 12px auto
          margin-right 1px
  &[css_type="small_fix"]
    width 56.27%
    min-height 350px
    margin-bottom 0
    margin-right 8px
    .cheer-up-item__photo_img
      position relative
      width 100%
      padding-top 100%
      overflow hidden
      img
        position absolute
        top 0
        left 0
        right 0
        bottom 0
        margin auto
        width 100%
    .cheer-up-item__body
      margin-left 14px
      margin-right 14px
      margin-bottom 10px
      height 44px
      overflow hidden
      font-size 14px
      line-height 1.5
      word-break break-word
    .cheer-up-item__user_name
      font-size 13px
      left 12px
      bottom 9px
    .cheer-up-item__more
      width 25px
      height 24px
      background-size 25px 24px
    .cheer-up-item__score
      left 12px
      bottom 27px
      .article-review-score
        .star
          width 12px
          height 12px
          background-size 12px auto
          margin-right 1px
  &__photo
    flex-shrink 0
    position relative
    width ptr(88)
    cursor pointer
    aspect-ratio 1
    overflow hidden
    background-color #F6F6F6
    display flex
    align-items center
    border-radius pxToRem(12px)
  &__photo_img
    background-size cover
    background-position center
    background-repeat no-repeat
    width 100%
    height 100%
    z-index 1
    object-fit cover
    object-position center
    &[data-is_swiper="false"]
      width 100%
      height 100%
    img
      object-fit cover
      object-position center
      width 100%
      height 100%
      z-index 1
  &__more
    display none
    &[is_more="true"]
      display none
      position absolute
      top 8px
      right 8px
      width 35px
      height 35px
      background url("//s3.marpple.co/files/u_218933/2021/5/original/7182c9727bf6f6e5986a74dadebdfc08732530821.png") no-repeat
      background-size 35px 34px
      z-index 1
  &__user-info
    display flex
    align-items center
  &__user-info-middle
    display flex
    flex-direction column
    row-gap ptr(2)
    margin-left ptr(12)
  &__score
    background-color inherit
  &__user_name
    color $black
    & > span
      limitLine(1)
    & > a
      display flex
      align-items center
      column-gap ptr(4)
      & > span
        limitLine(1)
      & > img
        flex-shrink 0
  &__shop-icon
    width: ptr(16)
    height: ptr(16)
    color: $blue-100
  &__user-profile-wrap
    width pxToRem(42px)
    height pxToRem(42px)
    flex-shrink 0
  &__body
    display flex
    align-items flex-start
    justify-content space-between
    gap ptr(20)
    margin 0
    color $black
    cursor pointer
    min-height pxToRem(88px)
    limitLine(3)
    word-break break-word
  &__product
    margin 0
  &__controls
    margin 0
  &__remove
    display flex
    justify-content center
    align-items center
  &__remove-btn
    display flex
    align-items center
    border none
    color $gray-80
    cursor pointer
    padding 0
    height pxToRem(22px)
    background-color $white
  &__middle
    display flex
    flex-direction column
    justify-content space-between
    row-gap ptr(12)
  &__middle-top
    display flex
    column-gap ptr(8)
    justify-content space-between

  .mps2_action_button__wrap
    display flex
    align-items center
    justify-content center
    gap ptr(4)
    height: ptr(40);
    box-sizing border-box
  .mps2_action_button__icon
    width ptr(16)
    height ptr(16)
