// modules/MShop/Studio/PbProductSize/F/Style/Mo/index.styl
.creator-settings
  .pb-product-size-frame
    > .don_wrapper
      .header
        display flex
        justify-content center
        align-items center
        background-color #FFFFFF
        > .title
          font-weight 500
          font-size pxToRem(16px)
          line-height pxToRem(22px)
    .pb-product-size
      &__title
        padding 0 pxToRem(16px)
        > h4
          display none
      &__options
        font-weight 500
        font-size pxToRem(18px)
        line-height pxToRem(21.6px)
      &__wrapper
        margin-top pxToRem(20px)
      &__form
        display flex
        flex-direction column
      &__inputs
        display flex
        flex-direction column
        gap pxToRem(16px)
        padding 0 pxToRem(16px)

      &__form_control
        display flex
        align-items center
        > label
          flex-basis pxToRem(40px)
          font-weight 500
          font-size pxToRem(14px)
          line-height pxToRem(16.8px)
          letter-spacing pxToRem(-0.5px)
        > input
          flex-basis pxToRem(40px)
          flex-grow 1
          border-radius 4px
          border 1px solid #cccccc
          padding pxToRem(11px) pxToRem(16px)
      &__volume
        padding 0 pxToRem(16px)
        margin-top pxToRem(32px)
        border-top 1px solid #dddddd
        padding-top pxToRem(20px)
        > label
          font-weight 500
          font-size pxToRem(18px)
          line-height pxToRem(21.6px)
          letter-spacing pxToRem(-0.5px)
      &__volume-text
        font-weight 700
        font-size pxToRem(18px)
        line-height pxToRem(21.6px)
        letter-spacing pxToRem(-0.5px)
        color #3C89F9
      &__buttons
        position fixed
        bottom 0
        width 100%
        display flex
        margin-top pxToRem(22.39px)
        padding pxToRem(9px)
        gap pxToRem(7px)
        border-top 1px solid #f2f2f2
        > button
          flex-grow 1
          height pxToRem(48px)
          border 1px solid #dddddd
          background-color #FFFFFF
          border-radius 2px
          font-weight 600
          font-size pxToRem(15px)
          line-height pxToRem(18px)
          letter-spacing pxToRem(-0.2px)
          &.primary
            border none
            background-color #3C89F9
            color #FFFFFF
            &:disabled
              background-color #828282
              cursor default


