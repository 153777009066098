// modules/MShop/Landing/OfflinePopupStore/F/Style/Mo/index.styl

.OfflinePopupStorePage
  &.editing
    display none
  h3
  h4
  p
    padding 0
    margin 0
  ul
  li
    margin 0
    padding 0

  /* cta button */
  .cta-button
    //transition .3s ease-in-out
    position: fixed;
    bottom: calc(16px + env(safe-area-inset-bottom));
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;

  .scroll-x::-webkit-scrollbar {
    display none;
  }
  .scroll-x
    overflow scroll
    overflow-y hidden
    -ms-overflow-style: none;
    scrollbar-width: none;
  .section
    overflow hidden
    box-sizing border-box
  .section1
    position relative
    width: 100%;
    height 100svh
    h1
      position absolute
      top 0
      left 0
      right 0
      text-align center
      z-index 3
      margin 12px 0
      padding 0
    .overlay
      position absolute
      top 0
      left 0
      right 0
      bottom 0
      width 100%
      height 100%
      background: rgba(0, 0, 0, 0.30);
      z-index 1
    .bg
      position absolute
      top 0
      left 0
      width 100%
      height 100%
      z-index 1
      display flex
      justify-content center
      img
      video
        width 100%;
        object-position center
        object-fit cover
    .texts
      color #fff
      text-align center
      position absolute
      left 0
      right 0
      bottom 88px
      z-index 2
      h3
        color: $white;
        text-align: center;
        {$kr-20-b}
        span:nth-child(1)
          {$kr-14-m}
          display block
          margin-bottom 12px
        span:nth-child(2)
          {$kr-28-b}

  .section2
    position relative
    width: 100%;
    box-sizing border-box
    text-align center
    display: flex;
    padding: 40px 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    .texts
      box-sizing border-box
      display: flex;
      width: 100%;
      aspect-ratio 1.58
      padding: 0 32px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 16px;
      *
        color: $blue-100
        text-align: left;
        {$kr-48-b}
      h3
        padding 0
        margin 0;
        {$kr-28-b}
      p
        padding 0
        margin 0
        {$kr-14-m}
    .imgs
      display: flex;
      width: 100%;
      box-sizing border-box;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;
      padding-bottom 32px
      position relative
      .swiper-slide
        width 100%
        text-align center
      img
        width calc(100% - 64px)
      .swiper-pagination
        bottom 0
      .swiper-pagination-bullet
        background: $dim-10;
        margin: 0 4px
        opacity 1
      .swiper-pagination-bullet.swiper-pagination-bullet-active
        background #1C75FF

  .section3
    display: flex;
    width: 100%;
    padding: 83px 0 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
    background: $brown-100
    text-align center
    h3
      color: $brown-10
      text-align: center;
      {$kr-28-b}
    .text-banners
      display: flex;
      box-sizing: border-box;
      width: 100%;
      padding: 0 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 40px;
      .banner-wrap
        display: flex;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;
      .banner
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        width calc(50% - 12px)
        *
          align-self: stretch;
        img
          width: 24px;
          height: 24px;
        h4
          margin 0
          padding 0
          color: $brown-10
          text-align: center;
          {$kr-14-b}
        p
          color: $brown-10
          text-align: center;
          {$kr-12-m}

    .footer
      padding-bottom 43px
      a
        display: flex;
        min-width: 160px;
        padding: 16px 24px;
        align-items: center;
        gap: 8px;
        border-radius: 12px;
        background: $brown-10
        color: $brown-100
        text-align: center;
        {$kr-16-b}

  .section4
    display: flex;
    width: 100%;
    padding: 40px 0 86px;
    flex-direction: column;
    justify-content: center;
    box-sizing border-box
    gap: 60px;
    .texts
      color: $brown-100
      text-align: center;
      h3
        {$kr-28-b}
        margin-top 46px
    .slide-imgs
      padding: 0 0 26px;
      position relative
      opacity 0
      align-items: center;
      &.swiper-initialized
        opacity: 1;
      .swiper-wrapper
        display: flex;
        align-items: center;
        align-self: stretch;
      .swiper-slide
        width 100%
        text-align center
      img
        width calc(100% - 32px);
        border-radius 16px
    .swiper-pagination {
      position: absolute;
      left: 16px;
      right: 16px;
      width: auto;
      top auto;
      bottom: 0;
      height: 2px;
      background: $dim-10;
    }
    .swiper-pagination-progressbar-fill {
      background: #704C00;
    }

  .section5
    width 100%
    display: flex;
    padding: 80px 0 40px 0;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    background: $blue-100
    .texts
      padding 0 32px
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 16px;
      flex: 1 0 0;
      align-self: stretch;
      .left
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        flex: 1 0 0;
        padding 47px 0
        *
          color: $blue-10
          {$kr-28-b}
        p
          {$kr-14-m}
      .right
        display none

    .slide-imgs
      position relative
      padding-bottom 58px
      width 100%
      opacity 0
      &.swiper-initialized
        opacity: 1;
      .swiper-pagination {
        position: absolute;
        left: 32px;
        right: 32px;
        width: auto;
        top auto;
        bottom: 0;
        height: 2px;
        background: $bright-20
      }
      .swiper-pagination-progressbar-fill {
        background: #fff;
      }
      .items
        width 100%
        display: flex;
        align-items: flex-start;
        align-self: stretch;
      .item
        display: flex;
        width: calc(100% - 64px);
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        flex-shrink: 0;
        img
          width: 100%
          aspect-ratio 1
          border-radius: 16px;

      .caption
        color: $white;
        {$kr-12-m}

  .section6
    display: flex;
    width: 100%
    aspect-ratio 0.461
    padding: 80px 0;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    background: $blue-10
    overflow hidden
    position relative
    .content
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 80px;
      align-self: stretch;
      position relative
      z-index 2
      .texts
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        *
          color: $blue-100
          text-align: center;
          {$kr-28-b}
        p
          {$kr-14-m}


      .left
        display: flex;
        align-items: flex-start;
        gap: 32px;
        text-align left
        .item
          width calc(100% - 115px)
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          flex-shrink: 0;
          &:first-child
            margin-left: 32px;
          &:last-child
            margin-left: 32px;
          &:nth-child(2)
            width calc(100% - 100px)
          *
            color: $blue-100
            {$kr-16-b}
            
          .number
            {$kr-14-b}
          p
            {$kr-14-m}
          .small
            display: flex;
            align-items: center;
            gap: 4px;
            font-size 12px
            line-height 17px

    .bg
      position absolute
      right 0
      bottom -155px
      width 518px
      height 518px
      img
        width 100%
        height 100%


  .section7
    width 100%
    display: flex;
    padding: 89px 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
    background: $purple-100;
    img
      width: 115%;
      margin-left: 11%
      flex-shrink: 0;
    .texts
      display: flex;
      padding: 0 32px 0;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px;
      text-align center
      *
        color: $blue-10
        {$kr-28-b}
      p
        {$kr-14-m}

  .section8
    display: flex;
    aspect-ratio 0.461
    padding: 96px 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
    align-self: stretch;
    background: url("//s3.marpple.co/files/u_14345/2024/4/original/8838cda0e58cd9bbb5fc89a53ad13142884eab011.png") no-repeat;
    background-size cover;
    .texts
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      color: $white;
      text-align: center;
      *
        {$kr-28-b}
      p
        {$kr-14-m}

    .imgs
      display: flex;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      img
        width: 240px;
        height: 381.887px;
        border-radius: 16px;
        &:first-child
          margin-left 32px
        &:last-child
          margin-right 32px


  .section9
    background #fff;
    display: flex;
    padding: 40px 32px;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    align-self: stretch;
    text-align center
    img
      width: calc(100% - 30px);
      display: flex;
      flex-direction: column;
    h3
      color: $blue-100
      {$kr-28-b}
      text-align left
    ul
      text-align left
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      li
        display: flex;
        padding-bottom: 16px;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        border-bottom: 1px solid $blue-100
        color: $blue-100
        {$kr-14-b}
        h4
          width: 96px;
        .body
          font-weight 500
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;

    a
      display: flex;
      width 100%
      box-sizing border-box
      padding: 16px 24px;
      border-radius: 12px;
      background: $blue-100
      color: $white;
      text-align: center;
      {$kr-16-b}
      
      svg
        margin-top 2px


  .section10
    .left
      box-sizing border-box
      width 100%;
      background: #EBF3FE;
      display: flex;
      padding: 40px 32px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 60px;
    .right
      box-sizing border-box
      width 100%;
      display: flex;
      padding: 60px 32px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 32px;
      background: $blue-100
    .left
      color: #1C75FF;
      {$kr-16-m}
      .header
        display: flex;
        align-items: center;
        gap: 8px;
      .heading
        color: $blue-100
        text-align: center;
        {$kr-16-b}
      .rows
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
      .row
        display: flex;
        padding-bottom: 16px;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        border-bottom: 1px solid $blue-100
      .title
        width: 60px;
        color: $blue-100
        {$kr-14-b}
      .body
        display: flex;
        padding 0 0 0 24px
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        flex: 1 0 0;
        color: $blue-100
        {$kr-14-m}
        list-style none
        li {
          position relative
          padding-left 2px
        }
        li::before {
          position absolute
          display block
          content '·';
          font-size 22px
          top -1px
          left -13px
        }
      .text1
        align-self: stretch;
      a
        margin-top 16px
        padding-left: 12px; padding-right: 12px; padding-top: 8px; padding-bottom: 8px; background: #1C75FF; border-radius: 8px; border-bottom: 1px #1C75FF solid; justify-content: flex-start; align-items: flex-start; gap: 10px; display: inline-flex
        span
          color: #EBF3FE;
          {$kr-14-m}

    .right
      img
        width 291px
        height 291px
      h3
        color: $blue-10
        text-align: center;
        {$kr-28-b}

      a
        border 0 none
        display: flex;
        min-width: 160px;
        padding: 16px 24px;
        align-items: center;
        gap: 8px;
        border-radius: 12px;
        background: #EBF3FE;
        color: #1C75FF;
        text-align: center;
        {$kr-16-b}

  .section11
    display: flex;
    width: 100%;
    min-height 812px;
    padding: 40px 0;
    flex-direction: column;
    justify-content: center;
    box-sizing border-box;
    gap: 48px;

    .texts
      color: $black;
      text-align: center;
      h3
        {$kr-28-b}

    .offline-popupstores
      padding: 0 0 24px;
      position relative
      opacity 0
      align-items: center;
      &.swiper-initialized
        opacity: 1;
      .swiper-wrapper
        display: flex;
        align-items: center;
        align-self: stretch;
      .swiper-slide
        width 100%
        text-align center
    .swiper-pagination {
      position: absolute;
      left: 32px;
      right: 32px;
      width: auto;
      top auto;
      bottom: 0;
      height: 2px;
      background: $dim-10;
    }
    .swiper-pagination-progressbar-fill {
      background: $blue-100
    }
    .more_button
      display flex
      margin 0 auto
      height pxToRem(56) !important
      line-height pxToRem(24) !important
      width pxToRem(234)
      flex 0

      & > svg
        height pxToRem(20)
        width pxToRem(20)

  .section12
    width 100%
    display: flex;
    padding: 40px 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    background $brown-10;

    .header
      margin-top 28px
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      color: $brown-100
      text-align: center;
      *
        align-self: stretch;
        {$kr-28-b}
      p
        align-self: stretch;
        {$kr-14-m}
    .footer
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      padding-bottom 28px
      *
        color: $brown-100
        {$kr-20-m}
        text-align center
      .text2
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        *
          font-size: 14px;
        h4
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 4px;
          align-self: stretch;
          font-weight: 700;
          line-height: 22px; /* 150% */
          svg
            position relative
            top -1px
        p
          font-weight 500
          line-height: 22px; /* 150% */

    .right
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      a
      img
        display block
        width 100%
        height 100%
      img
        border-radius: 24px;

  .section13
    display: flex;
    padding: 185.014px 0 185.014px 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: $blue-100
    img
      width 100%
    .texts
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 32px;
      margin-top 24px
    h3
      color: #FFF;
      text-align: center;
      {$kr-28-b}

.OfflinePopupStoreEditor
  position relative
  z-index 4
  background url(//s3.marpple.co/files/u_14345/2024/4/original/1e6cb560529cf0159ac8591ee32f430c0e1189521.png) no-repeat;
  background-size cover;
  background-position center;
  .bg
    background: rgba(20, 20, 20, 0.60);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px); /* Add this line first, it fixes blur for Safari*/
    height 100%
  *
    box-sizing border-box
    {$kr-16-m}
  .close
    display: flex;
    height: 48px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    padding-top 8px
    margin 0 0 24px
    button
      border 0 none
      display: flex;
      width: 40px;
      height: 40px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 999px;
      background: $bright-5
      svg
        flex-shrink: 0;
  .body
    flex-shrink: 0;
  .form
    margin 0 auto
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    padding 0 32px
    gap: 24px;
    align-self: stretch;
    > .header
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      .text1
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        span
          color: $blue-100
          {$kr-14-m}
        h2
          margin 0
          padding 0
          color: $white;
          {$kr-28-b}
      .text2
        color: #FFF;
        {$kr-14-m}

    > .inputs
      width: 100%; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 12px; display: flex;
      *
        color: #A2A2A2;
      .input-wrap2
        width: 100%; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 12px; display: flex;
      .marppleshop-url-display.hide
        display none !important
      .input-wrap
        padding-top: 12px;
        padding-bottom: 12px;
        border-bottom: 1px #A2A2A2 solid;
        justify-content: flex-start;
        align-items: flex-start;
        display: flex;
        width 100%
        input[type="text"]
          width 100%
          word-wrap: break-word
          background transparent
          border 0 none
        textarea
          width 100%
          height 80px
          background transparent
          border 0 none
        textarea::placeholder {
          color: #A2A2A2;
        }
        input
        textarea
          outline none
        button
          border-radius 999px
          border 0 none
          background: $gray-5;
          display: flex;
          width: 24px;
          height: 24px;
          padding: 6.4px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          flex-shrink: 0;
      > .input-wrap:nth-child(2)
        gap: 16px;
        input[type="text"]
          width 186px
          margin-right -16px;

      .info
        height 24px;
        color: $gray-50
        {$kr-12-m}
    .footer
      padding-bottom 80px
      .agree
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        label
          color: $white;
          {$kr-16-m}
        p
          margin 0
          padding 0
          color: $white;
          {$kr-12-m}
          b
            font-weight 700


.OfflinePopupStoreEditorButton
  z-index 5
  position fixed
  bottom 0
  left 0
  right 0
  border 0 none
  display: flex;
  padding: 16px 24px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: $blue-10
  color: $blue-100
  text-align: center;
  {$kr-16-b}
  span
    display block
    width 100%
    height 100%
    padding-bottom env(safe-area-inset-bottom)
    padding-bottom constant(safe-area-inset-bottom)
    html[not-safe-area="true"] &
      padding-bottom 0
    html.safe_area_inset_bottom &
      padding-bottom 34px

html.offline-popup-store .don_small_loader_wrap, .don_loader_wrap
  background: rgba(255, 255, 255, 0.5);
