.app-home-styles
  &__button-box
    margin-top 24px
    padding 0 26px
    displayFlex center center
  &__button
    displayFlex center center
    position relative
    width 327px
    height 40px
    border-radius 20px
    background-color #111
    color #fff
    font(14px, 18px, -0.2px, 600)
  &__bubble
    position absolute
    top -10px
    right 0
    max-width 132px
    width 100%
    height 19px
    img
      width 100%
      vertical-align top

.app-home-styles-tab
  display block
  &__header
    overflow hidden
  &__header-scroll
    overflow-x scroll
    overflow-y hidden
    margin-bottom -80px
    padding-bottom 80px
    -webkit-overflow-scrolling touch
  &__header-contents
    displayFlex flex-start flex-start
    margin-left 24px
    flex-wrap nowrap
    &::after
      display block
      padding-right 26px
      height 26px
      content: ''
  &__header-image-box
    position relative
    box-sizing border-box
    margin-bottom 4px
    width 72px
    height 72px
    border 2px solid transparent
    border-radius 100%
    overflow hidden
    &--is-active
      border 2px solid #000
      & ~ .app-home-styles-tab__header-text
        font-weight 600
  &__header-image
    position absolute
    top 0
    left 0
    width 100%
    vertical-align top
    border 2px solid #fff
    border-radius 100%
    box-sizing border-box
  &__header-button
    reset-button()
    margin-left 4px
    width 72px
    display block
    text-align center
    &:first-child
      margin-left 2px
  &__body
    padding 16px 26px 0
  &__body-inner
    position relative
    padding-bottom 100%
  &__contents
    position absolute
    top 0
    left 0
    visibility hidden
    opacity 0.4
    display grid
    grid-gap 8px
    grid-template-columns repeat(3, 1fr)
    transition opacity linear .3s
    max-width 100%
    &--is-active
      visibility visible
      opacity 1
  &__anchor
    display block
  &__image
    width 100%
    vertical-align top
  &__header-text
    font 13px 18px -0.2px
    word-break: break-word;
    limitLine(1)
