// modules/MShop/Core/F/Style/Mo/index.styl
@import "./inhouse.styl"
@import './dropdown.styl'

.mshop-core__checkbox
  display flex
  align-items center
  gap pxToRem(8)
  cursor pointer
  &> input[type="checkbox"]
    display none
    ~.mshop-core__checkbox__box-wrapper .mshop-core__checkbox__full
      display none
    ~.mshop-core__checkbox__box-wrapper .mshop-core__checkbox__empty
      display block
    &:checked
      ~.mshop-core__checkbox__box-wrapper .mshop-core__checkbox__empty
        display none
      ~.mshop-core__checkbox__box-wrapper .mshop-core__checkbox__full
        display block
  &__box-wrapper
    width pxToRem(18)
    height pxToRem(18)
  &__title
    {$font-body-b3-r}
    color $gray-700

.mshop-core__button
  width 100%
  height pxToRem(60)
  {$font-body-b1-sb}
  color $gray-800
  border none
  background $white
  border-radius pxToRem(8)
  &[data-style_border="true"]
    border 1px solid $gray-300
