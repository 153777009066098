
body.setting-question-detail
  .question-detail
    margin-top 0
    padding-top 0

  #creator_settings_body
    padding-left 0
    padding-right 0

  .settings-footer
    padding-left 20px !important
    padding-right 20px !important


.settings-question-detail
  .w_br
  .m_br
    display block

.question-detail
  margin-top $default-header-mo_height
  padding ptr(16)
  padding-bottom ptr(60)
  display flex
  flex-direction column
  row-gap ptr(48)

  &__question
    display flex
    flex-direction column
    row-gap ptr(16)

  &__default-info-wrap
    display flex
    flex-direction column
    row-gap ptr(8)

  &__h2
    margin 0
    padding 0
    color $black

  &__user
    display flex
    flex-direction column
    row-gap ptr(16)

  &__header
    position relative

  &__btn-fold
  display none
  &__info
    display flex
    column-gap ptr(16)
    min-width ptr(134)

  &__head
    color $gray-50
    min-width ptr(134)

  &__content
    color $black
    display flex
    align-items center
    gap ptr(8)

    & > a
      text-decoration underline

    & > img
      border-radius ptr(24)
      object-fit cover
      width ptr(24)
      height ptr(24)

  &__commission
    display flex
    align-items center
    column-gap ptr(16)
    min-width ptr(134)

  &__button
    border-radius  pxToRem(8px) !important
    padding pxToRem(9px) pxToRem(24px) !important
    color $blue-100 !important
    background-color $blue-10 !important
    border none !important

  &__head-date
    color $gray-80

  &__order-item-id
  &__shop-name
  &__pd-name
    display grid
    grid-gap pxToRem(10px)
    grid-template-columns pxToRem(48px) 1fr
    margin-top pxToRem(10px)

    &:first-child
      margin-top 0

    a
      text-decoration underline

  &__body
    max-height pxToRem(400px)
    overflow auto

  &__info-box
    margin-top ptr(16)
    padding pxToRem(16px)
    border-radius pxToRem(16px)
    border none
    background-color $gray-5
    display flex
    flex-direction column
    row-gap ptr(16)

    &[data-is-answer="true"]
      background-color $blue-10

  &__info-box-left
    display flex
    justify-content space-between

    &__title
      color $black

      &[data-is-answer="true"]
        color $blue-100

    &__date
      color $gray-80

      &[data-is-answer="true"]
        color $blue-100

  &__info-box-right
    display flex
    flex-direction column
    row-gap ptr(8)

    &__content-wrap
      display flex
      column-gap ptr(8)

    &__content-title
      min-width ptr(72)
      color $gray-80

    &__content-body
      color $black
      overflow auto

      & > a
        text-decoration underline

  &__file
    display flex
    justify-content space-between
    align-items center
    margin pxToRem(25px) 0 0
    padding pxToRem(17px) pxToRem(19px) pxToRem(17px) pxToRem(20px)
    border-radius pxToRem(4px)
    background-color #f2f2f2

  &__file-txt
    display block
    limitLine(1)

  &__file-down
    img
      width pxToRem(14px)

  &__buttons
    margin-top pxToRem(16px)

  &__buttons-area
    min-height pxToRem(52px)

    &--add-question
      display grid
      grid-column-gap ptr(8)
      grid-template-columns repeat(2, 1fr)

      > *
        width 100%

  &__btn-add-question
    border-radius ptr(8px)
    border solid 1px $gray-20 !important
    color $gray-80 !important
    background-color $white !important
    text-align center
    {$button-white-theme}

  &__btn-complete
    border-radius ptr(8px)
    border none
    color $white !important
    background-color $blue-100 !important
    text-align center
    {$button-blue-theme}


.add-question-item-editor
  > *
    margin-top pxToRem(24px)

  &__required
    color #1C75FF

  &__header
    margin-top pxToRem(40px)
    display flex
    align-items center
    justify-content space-between

    &:first-child
      margin-top pxToRem(24px)

    h2
      margin 0
      padding 0

    p
      margin 0
      padding 0
      line-height 1.57
      letter-spacing -0.2px
      color #828282

  &__error-msg
    display none
    margin pxToRem(7px) 0 0
    padding 0
    font-size pxToRem(13px)
    font-weight 500
    line-height 1.38
    letter-spacing -0.5px
    color #f66565

    &--show
      display block

  &__control
    position relative
    margin-top pxToRem(32px)

    &:firat-child
    &--mt0
      margin-top 0

  &__control-area
    position relative

    &--cols2
      display grid
      grid-gap pxToRem(10px)
      grid-template-columns repeat(2, 1fr)

  &__label
    display block

    &--inline
      display inline-flex
      align-items center

      input
        margin-bottom pxToRem(4px)
        margin-right pxToRem(5px)

    &--lm10
      margin-left pxToRem(10px)

    &--lm-minus3
      margin-left pxToRem(-3px)

  &__label-txt
    margin-bottom pxToRem(8px)
    color #000

  &__label-txt-sub
    color #757575

  &__label-txt-sub2
    color #3d89f9

  &__body
    position relative
    min-height pxToRem(40px)
    border-bottom 1px solid $gray-10

    &--error
      border-color #f66565

    &--radio
    &--check
      min-height auto
      margin-top pxToRem(12px)
      border 0 none
      border-radius 0

    &--is_public
      min-height pxToRem(20px)

    &--textarea
      padding ptr(9) ptr(16) ptr(9) 0

    .flatpickr-wrapper
      position absolute
      top 0
      left 0
      right 0
      bottom 0

    &--terms
      padding pxToRem(10px)
      height pxToRem(160px)
      overflow auto

    &--file
      width 100%
      position relative

      &[can_cancel="false"]
        .add-question-item-editor__body-file-del
          display none

  &__body-area
    position relative

    &--cols2
      display grid
      grid-gap pxToRem(10px)
      grid-template-columns repeat(2, 1fr)

  &__body-input
  &__body-textarea
    border 0 none !important
    background-color transparent !important
    outline none

    &::placeholder
      color #b2b2b2

  &__body-input
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    padding pxToRem(6px) pxToRem(24px) pxToRem(5px)

  &__body-textarea
    padding 0
    width 100%
    height pxToRem(72px)
    resize none

  &__body-textarea-length
    margin-top pxToRem(15px)
    text-align right
    color #828282

  &__body-textarea-length-txt
    color #3c89f9

  &__txt1
    margin pxToRem(6px) 0 0
    color #828282

    &--icon1
      display flex
      justify-content flex-start

  &__txt2
    margin pxToRem(6px) 0 0
    padding 0
    color #828282

  &__icon-important
    margin-right pxToRem(5px)
    padding-top pxToRem(4px)

    img
      width pxToRem(15px)

  &__body-right
    position absolute
    top 0
    right 0
    bottom 0
    padding 0 pxToRem(11px)
    display flex
    align-items center

  &__control--file
    border 1px solid transparent

  &__control-wrap
    display flex
    align-items center
    justify-content space-between

  &__input-file
    position absolute
    top 0
    left 0
    width 0
    height 0
    opacity 0

  &__body-file-text
    position absolute
    top 50%
    left 0
    right pxToRem(60px)
    transform translateY(-50%)
    limitLine(1)

  &__body-file-del
    position absolute
    top 50%
    right pxToRem(20px)
    transform translateY(-50%)
    cursor pointer

    img
      width pxToRem(20px)

  &__btn-file-area
    height pxToRem(40px)
    margin-left pxToRem(10px)
    display flex
    align-items center
    justify-content center

    label
      position relative
      display block
      width 100%
      height 100%
      overflow hidden

  &__btn-file
    min-width ptr(120)
    width 100%
    height 100%
    box-sizing border-box
    border-radius pxToRem(8px)
    text-align center
    color $blue-100 !important
    background-color $blue-10 !important
    border none

  &__buttons
    margin-top pxToRem(32px)

  &__btn-submit
    width 100%
    height pxToRem(52px)
    padding 0
    color $gray-50 !important
    background-color $gray-5 !important
    text-align center
    border 0 none
    border-radius pxToRem(8px)
    pointer-events none

    &[data-is-active="true"]
      pointer-events auto
      color $white !important
      background-color $blue-100 !important
      transition background-color 200ms ease-out

      &:hover:not(&:disabled,&.disabled)
        background-color $blue-10 !important
        color $blue !important
