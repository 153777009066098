.new-maker-atom
  &__checkbox
    cursor pointer
    position relative
    display flex
    align-items center
    width fit-content
    height fit-content
    gap 8px
    &>input[type="checkbox"]
      display none
      &:checked
        ~.omp-atom__checkbox-box
          background-image url(//s3.marpple.co/files/u_14355/2018/10/original/f_12370_1540968742928_EEZcT5AfHu3P9SBPR2w.png);
  &__checkbox-box
    position relative
    display block
    width 16px
    height 16px
    background url(//s3.marpple.co/files/u_14355/2018/10/original/f_12369_1540968528808_wa9m8Im4uHIc9u0ORxMn.png) no-repeat
    background-size cover
  &__checkbox-title
    {new-maker_font_14_r_140}


.new-maker-atom
  &__empty-checkbox
    cursor pointer
    position relative
    display flex
    align-items flex-start
    width fit-content
    height fit-content
    gap 8px
    &> input[type="checkbox"]
      display none
      ~.new-maker-atom__empty-checkbox-box-wrapper .new-maker-atom__empty-checkbox-box-check
        stroke transparent
      &:checked
        ~.new-maker-atom__empty-checkbox-box-wrapper .new-maker-atom__empty-checkbox-box-check
          stroke black

  &__empty-checkbox-box-wrapper
    width 28px
    height 28px

    &[data-style_size="small"]
      width 20px
      height 20px
      flex-shrink 0

  &__empty-checkbox-title
    {new-maker_font_14_r_140}
