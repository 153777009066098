.new_maker_guide_layer
  .condition_popup_body
    .img
      text-align center
      margin-bottom 20px
      img
        max-width 100%
        vertical-align top

    .checkboxs
      display flex
      margin 0 auto
      justify-content center
      gap 5px
      margin-bottom 20px

    .btns
      width 90%
      display flex
      margin 0 auto
      justify-content space-between
      position absolute
      left 50%
      transform translateX(-50%)
      bottom 21px

      button
        width 49%
        height 38px
        color #000
        font-size 12px
        font-weight 500
        vertical-align top
        background-color #fff
        canvas-editor-border-radius-l()
        &.btn_edit
          color #fff
          border-color #000
          background-color #000
        &.btn_confirm
          width 100%
          color #fff
          background-color #000
