/* 레거시 frame에 Rune페이지를 띄운 곳, 크리에이터 리뷰는 subHeader가 존재하고, Brandcrumbs는 없다.  */
.don_frame[frame_name="/modules/MShop/App/Store/Main/S/Mui/frame.js"] {
  .PhotoReviewPage {
    margin-top: $default-header-mo_height + $default-sub-header-mo_height;
  }
}

.store-community
  min-height 220px
  padding-bottom 80px
  &__seller
    margin 0 24px 8px
  &__sub_title
    margin 20px 24px 16px
    font-size 20px
    font-weight 600
    line-height 1.3
    letter-spacing -0.3px
    color #111
  &__write
    margin-bottom 30px

.store-articles-empty
  margin 32px 0 48px
  text-align center
  .m_br
    display block
  &__text
    font-size 16px
    line-height 1.63
    letter-spacing -0.2px
    text-align center
    color #a2a2a2
  &__write
    margin-top 40px

.store-articles-write
  margin 0 24px
  &__btn
    width 100%
    background none
    font-size 16px
    line-height 1.25
    letter-spacing -0.2px
    color #4e4e4e
    padding 9px 0 7px
    border-radius 20px
    border 1px solid #ddd
    span
      display inline-block
      padding-right 17px
      background url("//s3.marpple.co/files/u_218933/2021/5/original/f0e72f7b53b19500f3c83cf8776aa5fa86763c681.svg") no-repeat 100% 30%
      background-size 13px

.store-recent-articles
  position relative
  opacity 0
  &__container
    padding 0 24px
  .community-item
    width 80%
    margin-bottom 0
    margin-left 4px
    margin-right 4px
    &__photo_img
      position relative
      width 100%
      padding-top 100%
      overflow hidden
      background-size cover
      background-repeat no-repeat
      background-position center
      img
        position absolute
        top 0
        left 0
        right 0
        bottom 0
        width 100%
        height 100%
        max-width 100%
        max-height 100%
        vertical-align top
        object-fit cover
    &__body
      height pxToRem(44px)
      overflow hidden
      limitLine(2)

.seller-profile
  display flex
  align-items center
  &__img
    width 34px
    height 34px
    margin-right 12px
    img
      width 100%
      height 100%
      border-radius 14px
  &__name
    margin-right 4px
    font-size 14px
    font-weight 600
    line-height 1.29
    letter-spacing -0.2px
    color #111
  &__mps
    img
      width 14px
      vertical-align top


.review-recent-list
  margin 0
  min-height 300px
  padding pxToRem(16) pxToRem(24) pxToRem(60)


.empty-community-articles
.empty-reviews
  padding ptr(160) 0
  margin-bottom ptr(60)
  flexCenter(column)
  &__icon
    margin-bottom ptr(24)
    img
      width ptr(120)
      height ptr(120)
  &__title
    margin-bottom ptr(8)
    color $gray-80
  &__desc
    margin-bottom ptr(24)
    color $gray-50
    text-align center
  &__to-main
    min-width pxToRem(196px)

.store-community2
  min-height 220px
  padding-bottom 80px
  &__seller
    margin 0 24px 8px
  &__sub_title
    margin 30px 24px 16px
    font-size 20px
    font-weight 600
    line-height 1.3
    letter-spacing -0.3px
    color #111
  &__write
    margin-bottom 30px
  &__seller-btns
    margin pxToRem(24px) pxToRem(24px) 0
  &__seller-btn-write
    width 100%
    padding 11px 11px 9px
    font-size 16px
    line-height 1.25
    color #4e4e4e
    background #fff
    border-radius 20px
    border solid 1px #dddddd
    span
      display inline-block
      padding-left 5px
      padding-right 22px
      background url("//s3.marpple.co/files/u_218933/2021/5/original/f0e72f7b53b19500f3c83cf8776aa5fa86763c681.svg") no-repeat 100% 40%
      background-size 15px
  &__articles
    margin 0
    margin-top pxToRem(40px)
    min-height 300px
    .community-article-list
      margin-top: ptr(16);
      padding: 0;
  &__articles-header
    position relative
    margin-top pxToRem(30px)
    padding: 0;
  &__articles-buttons
    position absolute
    bottom 0
    right 0
  &__btn-write
    padding 7px 20px 6px
    font-size 14px
    line-height 1.25
    color #4e4e4e
    background #fff
    border-radius 16px
    border solid 1px #dddddd
    span
      display inline-block
      padding-left 5px
      padding-right 22px
      background url("//s3.marpple.co/files/u_218933/2021/5/original/f0e72f7b53b19500f3c83cf8776aa5fa86763c681.svg") no-repeat 100% 40%
      background-size 15px

button.mps2_action_button,
a.mps2_action_button
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 200ms ease-out, box-shadow 200ms ease-out;
  width: 100%;
  flex: 1 1 0;
  border 0 none

  &[data-fit="true"]
    width: fit-content;
    flex: unset;

  &.size_regular
    height: ptr(40);
    padding: ptr(9) ptr(16);
    gap: ptr(4);
    border-radius: ptr(8);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);

  &.size_medium
    height: ptr(40);
    padding: ptr(9) ptr(16);
    gap: ptr(4);
    border-radius: ptr(8);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);

  &.size_large
    height: ptr(40);
    padding: ptr(16) ptr(24);
    gap: ptr(8);
    border-radius: ptr(12);

    html[is_mobile="true"] &
      height: ptr(52);
      gap: ptr(4);
      border-radius: ptr(8);
      backdrop-filter: blur(20px);
      -webkit-backdrop-filter: blur(20px);

  &.type_white
    background-color: $blue-10;
    color: $blue;

    &:hover
      background-color: $blue;
      color: $white;

  &.type_blue
    background-color: $blue;
    color: $white;

    &:hover
      background-color: $blue-10;
      color: $blue;

  &.type_line
    box-shadow: 0 0 0 1px $gray-20 inset;
    background-color: $white;
    color: $gray-80;

    &:hover
      box-shadow: 0 0 0 1px $blue inset;
      color: $blue;

  &.type_disabled
    cursor: default;
    background-color: $gray-5;
    color: $gray-50;

  .mps2_action_button
    width: ptr(40) !important;
    height: ptr(40) !important;
  .mps2_action_button__wrap
    display flex
    align-items center
    justify-content center
    gap ptr(4)
    box-sizing border-box
  .mps2_action_button__txt
    display none
  .mps2_action_button__icon
    width ptr(20)
    height ptr(20)
    color $white
