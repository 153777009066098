.don_frame[frame_name="/modules/MShop/App/Keyword/Drawer/S/Mui/frame.js"].is_mobile
  position fixed
  @import '../../../../F/Style/Mo/keyword-container.styl'

  .creator-layout
    padding 10px 24px
    &__body
      position relative

  .search-history
    margin-top 42px
    &.is_app
      margin-top 24px
    &.hidden
      visibility hidden
    &__header
      display flex
      justify-content space-between
      margin-bottom 16px
    &__title
      font-size 16px
      font-weight bold
      letter-spacing -0.2px
      color #111
    &__delete-all-btn
      background-color #fff
      border none
      font-size 13px
      letter-spacing -0.2px
      color #828282
      padding 0
    &__items
      display flex
      flex-wrap wrap
      gap 8px
    &__item
      background-color #f5f9fe
      border-radius 20px
      color #111
      font-size 14px
      letter-spacing -0.2px
      padding 0 16px
      height 34px
      display flex
      align-items center
    &__link
      max-width 250px
      text-overflow ellipsis
      white-space nowrap
      overflow hidden
    &__delete-btn
      border none
      width 8.5px
      height 8.5px
      background url(https://s3.marpple.co/files/u_2232571/2023/5/original/fef6d96c1f5bb7bd1cbda1040526c7400204fdae1.png) no-repeat center
      background-size 14px
      margin-left 8px
    &__no-result
      color #828282
      font-size 14px

  .search-popular
    margin-top 36px
    &.hidden
      visibility hidden
    &__title
      font-size 16px
      font-weight bold
      letter-spacing -0.2px
      color #111
      margin-bottom 16px
    &__items
      display flex
      flex-wrap wrap
      gap 8px
    &__item
      background-color #fff
      box-shadow 0 0 0 1px #ededed inset
      border-radius 20px
      color #111
      font-size 14px
      letter-spacing -0.2px
      height 34px
      padding 0 18px
      display flex
      align-items center

.auto-complete
  &.hidden
    display none
  &__list
    opacity 1
    margin-top 24px
    padding 0
    position absolute
    width 100%
    height 100vh
    z-index 1
    background-color #fff
    &.hide
      opacity 0
      visibility hidden
      height 0
  &__item
    padding 18px 0
    color #111111
    list-style none
    font-size 15px
    font-weight 500
    letter-spacing -0.2px
    &--matched
      color #1C75FF


.keyword-container.is_app
  padding 0
  .keyword-container__form
    display none
  .auto-complete__list
    color red
    top 0 !important
    margin-top 0 !important
