// modules/MShop/App/PopUpStore/QrPopUp/F/Style/Mo/index.styl

.don_page[page_name="/modules/MShop/App/PopUpStore/QrPopUp/S/Mui/page.js"]
  background-image: linear-gradient(180deg, rgba(31, 94, 255, 0.00) 14.58%, #1F5EFF 86.98%), url("//s3.marpple.co/files/u_2658905/2023/11/original/aee8f7bc57a8b2fb0ac62ec826d626857ae17ebf1.png");
  background-color #1F5EFF
  background-size cover
  background-attachment fixed
  height 100vh
  height -webkit-fill-available
  position fixed
  z-index 1
.don_tab[tab_name="/modules/MShop/App/PopUpStore/QrPopUp/S/Mui/tab.js"]
  background-color transparent
  main
    color white
    height 100%
  .main-header
    padding 19px 0 19px 0
    position relative
    text-align center
  .main-content-wrap
    display flex
    justify-content center
    //height calc(calc(var(--vh, 1vh) * 100) - 78px)
    //height -webkit-calc(100vh - 78px)
    align-items center
    flex-direction column
  .main-content
    display flex
    flex-direction column
    align-items center
    gap 12px
    &__header
      display flex
      gap 28px
      flex-direction column
      align-items center
    &__order-number
      font-size pxToRem(14px)
      font-weight 600
      a
        color white
        font-weight 400
    &__message
      text-align center
      font-size 24px
      font-weight 700
      line-height 34px
      text-shadow: 0 0 20px rgba(0, 0, 0, 0.40)
      letter-spacing -0.2px
      u
        text-underline-offset 2px
    &__qr-wrap
      width pxToRem(200px)
      height pxToRem(200px)
      padding pxToRem(20px)
      border-radius pxToRem(20px)
      background #FFF
      box-shadow pxToRem(10px) pxToRem(10px) pxToRem(60px) 0 rgba(0, 0, 0, 0.15)
      display flex
      align-items center
      justify-content center
      box-sizing border-box
      &__img
        max-width 100%
        max-height 100%
  .notice
    display flex
    flex-direction column
    align-items center
    gap pxToRem(12px)
    &__head
      font-size pxToRem(21px)
    &__content
      font-size pxToRem(16px)
      line-height pxToRem(22px)
      text-align center
    &__last
      font-size pxToRem(14px)
      color #FFEF9A
  .go-other
    display flex
    justify-content center
    padding 48px 0
    > a
      color white
      font-weight 600
      line-height pxToRem(28px)
      padding: pxToRem(14px) pxToRem(40px) pxToRem(13px) pxToRem(40px)
      border-radius pxToRem(404px)
      background rgba(0, 0, 0, 0.50)
      backdrop-filter blur pxToRem(20px)
      -webkit-backdrop-filter blur(20px)
      font-size pxToRem(20px)
      border none
  footer
    text-align center
    opacity 0.6
  .close_popup
    position absolute
    right pxToRem(24px)

/*
@media screen and (max-height 600px)
  html
    font-size 11px
*/
