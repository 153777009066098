// modules/BpOption/UploadType/F/Style/Mo/index.styl

.maker-product-info__upload-type
.maker-upload-type-board
.board__upload-type-direct-result
.maker-upload-type-selector
  display none
.bp_option_group[data-dev_name="UPLOAD_TYPE"]
  display none
.maker-upload-type-mobile
  background #fff
  .option_btn
    width 168px
    height 28px
    gap 4px;
    border-radius 4px
    border 1px solid GY
    display flex
    justify-content center
    align-items center
    font-size 14px
    color GY
    margin-bottom 12px
    background #fff
    svg
      width 20px
      height 20px
      path
        stroke GY
  .desc
    font-size 12px
    color GY
    //*
    //  display flex
    //  justify-content center
    //  align-items center

//  button
//    background none
//    outline none
//    border none
//    padding 0
//    font-size 14px
//    font-weight 500
.maker-upload-type-mobile-popup-head
  position relative
  width 100%
  &__title
    font-size 18px
    font-weight 400
    text-align center
  &__close
    position absolute
    right 16px
    outline none
    border none
    background none
    top 50%
    transform translateY(-50%)
    img
      width 16px
      height 16px
.maker-upload-type-mobile-popup
  width 100%
  height 100%
  box-sizing border-box
  padding 0 16px
  &__image
    height 100%
    width 100%
    object-fit contain
    object-position top
