html body.mall_help
  letter-spacing -0.4px
html body.mall_help
html body.crew-mall-solution
  .crew-mall-header
    border-bottom 1px solid #dcdcdc
  .crew-mall-header-body
    max-width 1120px
  .store-help
    &[type="creator_mall_customer_faq_kr"]
    &[type="creator_mall_customer_faq_jp"]
    &[type="creator_mall_customer_faq"]
      .help_tab li:nth-child(1)
        border-width 1px
        border-bottom-color $black
        a
          color $black
    &[type="creator_mall_faq_kr"]
    &[type="creator_mall_faq_jp"]
    &[type="creator_mall_faq"]
      .help_tab li:nth-child(2)
        border-width 1px
        border-bottom-color $black
        a
          color $black

html.creator-settings
  .store-help-body
    padding 0

html.mall_help
html.creator_store.help
  .store-help
    .store-help-header
      margin-top $default-header-mo_height
      padding-left 0
      padding-right 0
      height auto
      &[help-group-selected="true"]
        h2
        .help_tab
          display none
        h3
          position relative
          justify-content center
          img
            position absolute
            top 4px
            left 15px
          span
            {$kr-16-b}
      h2
        text-align center
    .help_tab
      ul
        position relative
        margin 0
        padding 0
        list-style none
        display flex
        height 40px
        li
          display inline-block
          width 100%
          border-bottom 1px solid $gray-50
          height 40px
          a
            display inline-block
            padding 8px 0
            width 100%
            text-align center
            color $gray-50
            span
              display none

html.creator_store.help
  .store-body > h2
    display none

.store-help
  width 100%
  background-color #ffffff
  display flex
  flex-direction column
  align-items center
  html.creator-settings &
    padding-top 0
    padding-bottom 40px
    min-height 400px
  &-header
    width 100%
    height 110px
    padding 0 20px 0
    box-sizing border-box
    display flex
    flex-direction column
    justify-content space-between
    font-weight 600
    html.creator-settings &
      display none
    *
      margin 0
    h2
      font-size 20px
    h3
      font-size 14px
      padding 24px
      display flex
      align-items center
      &[data-help-group-selected="false"]
        display none
      img
        display none
        margin-right 10px
        max-width 14px
        max-height 14px
  &-body
    padding 0 24px
    width 100%
    box-sizing border-box
    &-left
      margin 0
      padding 1px 0 0
    &-groups
      list-style none
      margin 20px 0 60px
      padding 0
      li
        height 48px
        width 100%
        margin 0
        box-sizing border-box
        background-color #ffffff
        a
          width 100%
          height 100%
          box-sizing border-box
          display flex
          align-items center
          span
            flex-grow 1
            white-space nowrap
            overflow hidden
            text-overflow ellipsis
            color $black
          img
            max-width 40px
            max-height 40px
            margin-left 10px
    &-groups[data-help-group-selected="true"]
      display none
    &-pages
      margin 0
      margin-bottom 60px
      padding 0
      list-style none
      .help_item
        margin 0 0 1px 0
        padding 0
        background-color #ffffff
        border-bottom 1px solid $gray-10
        .question
          height 54px
          width 100%
          font-weight normal
          box-sizing border-box
          margin 0
          display flex
          align-items center
          span
            flex-grow 1
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color $black
          img
            max-width 40px
            max-height 40px
            margin-left 10px
            transform rotate(180deg)
        .answer
          padding-bottom 16px
          overflow-wrap break-word
          background-color $white
          display flex
          flex-direction column
          row-gap 4px
          p
            margin 0
            color $black
            span
              display inline-block
              margin-bottom 12px
          table
            margin 20px 0
            width 100%
            table-layout fixed
            border-collapse collapse
            text-align center
            th
            td
              padding 11px 0
              color #333
              font-size 12px
              border 1px solid #e9ecef
              font-weight 200
            th
              font-weight 600
              text-align center
              color color_black
              background-color #fafafa
            td
              color color_black
          img
            margin-top 10px
            max-width 100%
          ul, ol
            padding 0 0 0 16px
            margin 6px 0
      .help_item[data-selected="false"]
        .answer
          display none
        img
          transform rotate(0)
      .help_item[data-selected="true"]
        & > .question
          & > span
            font-weight 700 !important
    &-pages[data-help-group-selected="false"]
      display none
  .cs_menu_mobile
    margin-top 40px
    width 100%
    > div
      height 66px
      width 100%
      margin-bottom 10px
      box-sizing border-box
      background-color #fafafa
      font-size 14px
      font-weight 600
      line-height 1.29
      color #000
      a
        display flex
        align-items center
        height 100%
        color #000
        background #fafafa no-repeat 20px 50%
        background-size 18px auto
    .solution
      a
        padding-left 48px
        padding-right 20px
        background-image url("//s3.marpple.co/files/u_218933/2020/7/original/31568008a737ff3455a5839f426bd2fa3dd66feeb4183c0.png")

