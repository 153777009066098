.app-home-limit-product
  width 100%
  &__wrapper
    width 100vw
    background-color $dim-90
    padding pxToRem(60) 0
    display flex
    justify-content center
    flex-direction column
  &__list
    padding 0 pxToRem(24)
    box-sizing border-box
    width 100%
    display flex
    flex-direction column
    gap pxToRem(36)

  .app-product-item--home-limit
    width 100%
    margin 0
    .app-product-item
      &__bottom-info
        height unset
      &__store-name
        display none
      &__p-name
        color $white
      &__category-name
        color $white
      &__price
        color $white
      &__review-info
        color $white
