.keyword-container.is_mobile
  .keyword-container
    position fixed
    top 10px
    left 24px
    right 24px
    padding 10px
    background-color #fff
    &__form
      position relative
      width 100%
      border-radius 4px
      box-sizing border-box
      display flex
      align-items center
      &.is_app
        display none
    &__input_el
      border 0 none
      width 100%
      height 40px
      font-size 16px
      line-height 1.25
      letter-spacing -0.2px
      outline none
      background-color #f8f8f8
      border-radius 8px
      padding-left 14px
      padding-right 35px
      caret-color #1C75FF
      margin-left 8px
      &::placeholder
        color #a2a2a2
    &__btn_search
      display none
      border none
      background url(https://s3.marpple.co/files/u_2232571/2023/5/original/cc845a4a9df84956c6ee4bc159aca4bd7de82d6f1.png) no-repeat center
      background-size 22px
      width 30px
      height 22px
      padding 0
    &__btn_backward
      display none
      border none
      background url(//s3.marpple.co/files/u_2232571/2023/5/original/fa09dba80fcfb3c9cca276225db05978bf63dfaf1.png) no-repeat center
      background-size 22px
      width 30px
      height 22px
      padding 0
    &__btn_reset
      display none
      position absolute
      top 0
      bottom 0
      right 10px
      border none
      width 20px
      background url(//s3.marpple.co/files/u_2232571/2023/5/original/79c98f92973c618d3e13b11de0c1fc1504eafe451.png) no-repeat center
      background-size 20px
    &__btn--show
      display unset
