@import "../share.styl"
body.setting-pb-item-orders
  @import "../Basic.styl"
  @import "../Table.styl"
  .date_picker_view_wrap
    margin-bottom 32px
    & > div
      width 100%
    & button
      width 100%
  .settings-content
    padding-top pxToRem(40px)
  .main-section
    width 100%
    &__table
      flex 1
      margin-top pxToRem(15px)
      overflow-x scroll
    &__buttons
      margin-bottom pxToRem(12px)
      width 100%
      &__status
        display flex
        min-height 40px
        select, input, button
          min-height 40px
      &__status
        &__label
          width pxToRem(110px)
        &__body
          display flex
      button
        padding pxToRem(8px) pxToRem(16px)
      .change-delivering-all
        width 100%
        display flex
        flex-direction column
        row-gap 8px
        & > div:first-child
          display flex
          flex-direction column
          row-gap 8px
        .main-section__buttons__status__body
          height 40px
          width 100%
          select,
          input
            width 100%
          button
            flex-shrink 0

      &_left
        row-gap 16px


    &__buttons__related-excel
      display none !important
    &__w58
      width 58px
    &__prepare_order_icon_wrap
      width 16px
      height 16px
      color $gray-80
      margin-right 8px
  .search
    &__vacant
      display none
    &.long
      .search__name
        display none
    &__name
      width pxToRem(100px)
      text-indent pxToRem(10px)
  .search-date
    .__input
      display none
    &__hidden
      visibility visible
  .flatpickr-mobile
    position absolute
    width 100%
    height 100%
    top 0
    left 0
