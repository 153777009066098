/*
 * -----------------메인-----------------------
 * 이미지, 뱃지, (상품이름 || 제품이름), 가격, 스토어이름
 *  - 홈(인기상품), 검색(추천) 상품
 *  < 가로형
 *    - 리뷰(메인안에서 전부), 검색(상품결과)
*/
.{a-p-i}--v1
  .{a-p-i}
    &__store-name
      display block

.{a-p-i}--v1-first-list
  width 100%
  margin-bottom 24px

.{a-p-i}--v1-rest-list
.{a-p-i}--v1-recent-list
  width 50%
  margin-bottom 24px
  padding 0 3.5px
  box-sizing border-box

.{a-p-i}--v1-limit
  width 104px
  margin-bottom 24px

.{a-p-i}--v1-category
  width calc(33.333333% - 8px)
  padding 0 4px
  margin-bottom 27px
  .{a-p-i}
    &__badge
      display none
