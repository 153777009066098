$color_studio_blue = #3c89f9
$studio_border_radius = pxToRem(4)

body.setting-ecommerce-info
  @import '../Share/index.styl'
  @import './font.styl';
  @import './seller_info_form.styl';

  $tab_height = pxToRem(40)
  .ecommerce-info
    &__tabs
      display flex
      justify-content space-between
      margin 0
      margin-top pxToRem(32)
      padding 0
      height $tab_height
      list-style none
      border 1px solid $color_studio_blue
      border-radius $studio_border_radius
      overflow hidden
    &__tab-menu
      width 100%
      > a
        display flex
        justify-content center
        align-items center
        width 100%
        height 100%
        line-height $tab_height
        color inherit !important
      &[data-is_active="true"]
        background-color $color_studio_blue
        color #fff
      &[data-is_active="false"]
        color $color_studio_blue
    &__form-section
      margin-bottom pxToRem(32)

.studio-info-form
  &__body
    display flex
    align-items center
  &__body-input
    flex 1
  &__flex-column
  &__qna-type
    display flex
    flex-direction column
    align-items start
  &__btn-save
    width 100%
    height pxToRem(48)
    display block
    margin-top pxToRem(12)
    color #fff !important
    font-weight 500
    letter-spacing -0.5px
    text-align center
    border-radius $studio_border_radius
    background-color #3c89f9
    border 1px solid #3c89f9
    box-sizing border-box
    &:disabled
      background-color #F2F2F2
      color #A2A2A2 !important
      border 1px solid #F2F2F2
      cursor not-allowed


body.creator-settings
  .ecommerce-info
    margin-bottom 80px
    &__is_show_pc
      display none !important
    &__btn-cert-area
      margin-top pxToRem(4px)
    &__file-btns
      margin-top pxToRem(4px)
    &__mail-order-business
      margin-top pxToRem(6px)
    &[type="PERSON"]
      .ecommerce-info__tab-person
        color #fff
        background-color #3c89f9
        border 1px solid #3c89f9
      .ecommerce-info__tab-company
        color #3c89f9
        background-color #fff
        border 1px solid #3c89f9
      .studio-info__help-box--company
      .ecommerce-info__form-section--company
        display none
    &[type="COMPANY"]
      .ecommerce-info__tab-person
        color #3c89f9
        background-color #fff
        border 1px solid #3c89f9
      .ecommerce-info__tab-company
        color #fff
        background-color #3c89f9
        border 1px solid #3c89f9
      .studio-info__help-box--person
        display none
    &__file
      display none
    &__business_file
      &[is_file="false"]
        .ecommerce-info__btn-file-del
        .ecommerce-info__btn-file-link
          display none
      &[is_file="true"]
        .ecommerce-info__btn-file
          display none

      input
        margin 0 pxToRem(8px) pxToRem(2px) 0
        vertical-align middle
      span
        margin-left pxToRem(5px)
        font-weight bold
        text-decoration underline
    &__alert
      h3
        margin 0
        padding 0
        color #111
        font-size 16px !important
        font-weight 800
        margin-bottom 12px
      p
        margin 0
        padding 0
        text-align center !important
        color #111 !important
        font-size 13px !important
        line-height 1.69 !important


  .studio-qna-type-control
  .studio-stores-delivery-companies
    &[qna_type="BOARD"]
      width 100% !important
      .studio-qna-type-control__sns
        display none
    &[qna_type="SNS"]
      width 100% !important
      .studio-qna-type-control__board
        display none
    &__board
      margin-top pxToRem(18px)
      p
        margin 0 0 pxToRem(4px)
        padding 0
        font-size pxToRem(13px)
        line-height 1.67
        letter-spacing -0.2px
        color #828282
      ul
        margin 0
        padding 0
        list-style none
      li
        font-size pxToRem(12px)
        line-height 1.67
        letter-spacing -0.2px
        color #828282
    &__sns
      .studio-info-form__body
        padding 0 !important
    &__sns-control
      margin pxToRem(10px) 0
    &__sns-txt1
      margin-top pxToRem(6px)
      font-size pxToRem(13px)
      font-weight 500
      line-height 1.38
      letter-spacing -0.5px
      color #828282
  .studio-stores-delivery-companies
    &__item
      margin-top 20px
      display none
      &--show
        display block
    &__name
      font-weight 500
      line-height 1.29
      letter-spacing -0.5px
      text-align left
      color #4e4e4e
      margin-bottom 10px
    &__btn
      border-radius 4px
      font-size 13px
      border none
      font-weight bold
      width auto
      height 24px
      &--modify
        background-color #ebf3fe
        color #3c89f9 !important
      &--remove
        background-color #fee8e8
        color #f66565 !important

.seller-info__forms
  > form
    padding pxToRem(32) 0
    border-bottom 1px solid #f2f2f2
    &:first-child
      padding-top 0
