.stores-delivery-company-frame
  >.don_wrapper
    >.header
      border-bottom 1px solid $gray-5
      display flex
      align-items center
      justify-content center
      background-color #fff
      >.title
        font-size 16px
        font-weight 600
        line-height 1.38
        letter-spacing -0.5px
    .studio-info-form__body
      display block
    .stores-delivery-company-tab
      padding 0 20px 96px
      .studio-info-form__label-txt
        width 110px !important
      &__header
        font-size 20px
        font-weight 800
        line-height 1.3
        letter-spacing -0.3px
        text-align left
        color $gray-1
        margin 0
      &__section
        margin-top pxToRem(24px)
      &__submit
        display flex
        align-items center
        justify-content center
      &__countries-selector
        display block
        margin-top 8px
        &--hide
          display none
    .en-jp-shipping-price
      color #1C75FF
      font-size 14px
      font-weight 500
      line-height 18px
      letter-spacing -0.5px
      display block
      margin 10px 0 0
    .stores-delivery-companies-countries
      display flex
      gap 8px
      flex-wrap wrap
      &[data-is_len="true"]
        margin 8px 0 0
      &__item
        border-radius 4px
        background #EBF3FE
        border 0
        padding 6px 8px
        display flex
        align-items center
        gap 4px
