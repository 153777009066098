.mshop-error
  display flex
  flex-direction column
  text-align center
  height 100%
  width 100%
  position absolute
  justify-content space-between
  .mshop-error
    &__content
      justify-content center
      display flex
      flex-direction column
      align-items center
      flex 1
    &__img
      width 197px
      margin-bottom 41px
      img
        width 100%
    &__title
      font-size 18px
      font-weight 600
      line-height 1.56
      letter-spacing -0.2px
      color #4e4e4e
      margin-bottom 8px
    &__description
      font-size 15px
      line-height 1.27
      letter-spacing -0.2px
      color #828282
      padding 0 16px
      word-break keep-all
    &__buttons
      display flex
      width 100%
    &__button
      reset-button()
      font-size 16px
      font-weight 600
      line-height 1.25
      letter-spacing -0.2px
      text-align center
      color #ffffff
      background-color #111
      width 100%
      height 56px
      display flex
      align-items center
      justify-content center
      box-sizing unset
      padding-bottom env(safe-area-inset-bottom)
      padding-bottom constant(safe-area-inset-bottom)
      html[not-safe-area="true"] &
        padding-bottom 0
      html.safe_area_inset_bottom &
        padding-bottom 34px
      &--app-tab
        padding-bottom 0
        html.safe_area_inset_bottom &
          padding-bottom 0
      &--main
        background-color #1C75FF
    &__app-tab
      padding-bottom 64px
      html.safe_area_inset_bottom &
        padding-bottom 96px
