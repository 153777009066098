.creator-settings
  .pb-product-tip-frame
    > .don_wrapper > .header
      z-index 1
  .pb-product-tip
    padding-bottom 50px
    &__title
      margin 19px 0 0
      padding 0
      font-size 16px
      font-weight 600
      font-stretch normal
      font-style normal
      line-height 1.2
      letter-spacing -0.5px
      text-align center
      color #000
    &__ul
      margin 0 8%
      padding 0
      list-style none
    &__li
      width 100%
      margin-top 50px
    &__img
      img
        width 100%
    &__h3
      margin 39px 0 0
      padding 0
      font-size 18px
      font-weight bold
      line-height 1.33
      letter-spacing -0.2px
      color #000
    &__p
      margin 10px 0 0
      padding 0
      font-size 14px
      line-height 1.43
      color #4e4e4e

    .swiper-wrapper
      display block
    .swiper-button-prev
    .swiper-button-next
    .swiper-pagination
      display none