body.creator-settings
  #creator_settings
    #creator_settings_body
      &.setting-pb-orders
      &.setting-digital-item-orders
      &.setting-list-template
      &.setting-pb-item-orders
      &.setting-pb-inventory-management
      &.setting-question-list
      &.setting-pb-refund-orders
        padding 0
        .top-section
          margin 0 20px
        .search__name
          display none
