.ad_res
  .don_dialog_msg .content >.body .modal-content
    text-align left
    &__title
      h4
        margin 0 0 16px
        font-size 18px
        font-weight normal
        font-style normal
        line-height 1.56
      p
        margin 0 0 6px
        font-size 14px
        font-weight 600
        line-height 1.4
        color #111
    &__items
      margin 6px 0 0
      padding 0
      li
        list-style-type none
        font-size 13px
        line-height 1.38
      li + li
        margin-top 3px
    &__tip
      margin 8px 0 0
      font-size 13px
      line-height 1.38
      color #a2a2a2
    &__ok
      display none
