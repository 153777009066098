@import "../share.styl"

.pb-order
  position relative
  &__paid-at
    margin-bottom 12px

.pb-order-count
  display flex
  overflow-x auto
  scrollbar-width none
  margin 0 -20px 30px
  &::-webkit-scrollbar {
    display: none;
  }
  &__item
    border-radius 8px
    border solid 1px $gray-10
    height 64px
    flex 0 0 90px
    display flex
    align-items center
    justify-content center
    margin-right 8px
    text-align center
    min-width 100px
    &:first-child
      margin-left 20px
    &:last-child
      margin-right 20px
    &[data-order_process_step_id="ALL"]
      background-color $gray-5
    &[data-selected="true"]
      .pb-order-count__name
      .pb-order-count__count-kr
      .pb-order-count__count
        color #3c89f9
  &__name
    color $gray-80
  &__count
    color $black
  &__count-kr
    margin-left 2px
.pb-order-form
  width 100%
  select
    width 100px
    margin-right 6px
  button
    width 58px
  input
    margin-right 6px
    max-width 100% !important
    width calc(100% - 170px)
