.mshop-pop-up-style-1
  &[id="don_confirm"]
    >.content
      padding 36px 24px 24px 24px !important
      width 343px !important
      border-radius 16px !important
    .title
      color $black
      {$kr-20-b}
      margin-bottom 12px
    .popup-body
      {$font-body-b2-r}
      color $gray-700
      word-break keep-all
      word-wrap break-word
    .msg
      padding 0
    .popup-body
      color $gray-80 !important
      {$kr-14-m}
    .buttons
      display grid !important
      margin 0 !important
      grid-gap 12px
      grid-template-columns 1fr 1fr
      button
        margin 0 !important
        height 52px !important
        border-radius 8px !important
        &.ok
          order 2
          background $blue-100 !important
          color $white !important
          {$kr-16-b}
        &.cancel
          order 1
          background $white !important
          color $gray-80 !important
          border 1px solid $gray-20
          {$kr-16-b}

  &[id="don_alert"]
    >.content
      padding 36px 24px 24px 24px !important
      width 343px !important
      border-radius 16px !important
      .body
        margin-bottom 32px !important
      .title
        color $black
        {$kr-20-b}
        margin-bottom 12px
      .msg
        padding 0
      .popup-body
        color $gray-80 !important
        {$kr-14-m}
      .buttons
        button
          margin 0 !important
          height 52px !important
          width 100% !important
          border-radius 8px !important
          &.ok
            background $blue-100 !important
            color $white !important
            {$kr-16-b}
