.pb-editor-frame
  .w_br
  .m_br
    display block
  &__btn-close-frame
    position absolute
    top 9px
    right 9px
    width 36px
    height 36px
    border 0 none
    padding 0
    margin 0
    background-color transparent
    background-repeat no-repeat
    background-position center center
    background-size 18px
    background-image url("//s3.marpple.co/files/u_14345/2021/5/original/e6fc7c6c93a1091aa268db9f6fa0730c8da9793d1.svg")

.pb-editor
  font-size pxToRem(13px)
  &__left
    padding pxToRem(50px) pxToRem(20px)
    box-sizing border-box
    background-color #f8f9fa
  &__tip
    cursor pointer
    display flex
    align-items center
    justify-content center
    position absolute
    top pxToRem(44px)
    left 0
    width pxToRem(70px)
    height pxToRem(50px)
    overflow hidden
    border-top-right-radius pxToRem(100px)
    border-bottom-right-radius pxToRem(100px)
    box-shadow 0 2px 10px 0 rgba(0, 0, 0, 0.05)
    background-color #fff
    box-sizing border-box
    transition width .4s
  &__tip-img
    img
      width pxToRem(25px)
  &__head
    text-align center
  &__title
    margin 0
    padding 0
    color #000
    font-size pxToRem(22px)
    font-weight bold
    letter-spacing -1px
    text-align center
  &__desc
    color #4c4c4c
    font-size pxToRem(14px)
    letter-spacing -1px
    text-align center
  &__right
    padding pxToRem(40px) pxToRem(20px) pxToRem(40px)
    box-sizing border-box
  &__thumbnails-area
    margin-top pxToRem(76px)
    padding 0
    display flex
    align-items center
    flex-direction column
  &__form-buttons
    position fixed
    bottom 0
    left 0
    right 0
    padding pxToRem(8px)
    display grid
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    grid-gap pxToRem(7px)
    align-items center
    justify-content center
    background-color #fff
    border-top 1px solid #f2f2f2
    &--top
      display none
  &__form-area
    margin-top pxToRem(30px)
    margin-bottom pxToRem(30px)

  .product-story-wrap
    .title
      display none !important

.pb-thumbnail-editor
  position relative
  width 100%
  max-width pxToRem(500px)
  &__list
    margin 0
    padding 0
    list-style none
    display grid
    grid-gap pxToRem(10px)
    grid-template-columns repeat(6, 1fr)
  &__item
    position relative
    border 1px dashed #ccc
    padding-top calc(100% - 2px)
    background url("//s3.marpple.co/files/u_218933/2022/8/original/2b31b03ac278bce848da14702fbc4b25fe7769061.png") no-repeat center
    background-size pxToRem(15px) pxToRem(15px)
    cursor pointer
    &--on
      border-style solid
      background none
      &:hover
        border solid 1px #3c89f9
        .pb-thumbnail-editor__item-thumb-del
          display none
      img
        position absolute
        top pxToRem(2px)
        left pxToRem(2px)
        right pxToRem(2px)
        bottom pxToRem(2px)
        max-width calc(100% - 4px)
        max-height calc(100% - 4px)
        margin auto
        vertical-align top
    &--selected
      border solid 1px #3c89f9
      &:hover
        border-width 1px
  &__item-thumb-del
    display none
    position absolute
    top pxToRem(-10px)
    right pxToRem(-10px)
    width pxToRem(20px)
    height pxToRem(20px)
    img
      width 100%
      height 100%
  &__view-area
    position relative
    margin-top pxToRem(10px)
    width 100%
    max-width 100%
    border 1px dashed transparent
    padding-top 100%
    &[data-length="0"]
      border-color #ccc
    &[data-length="1"]
      .pb-thumbnail-editor__arrow
        display none
    &[is-show-upload="true"]
      .pb-thumbnail-editor__upload
        display block
      .pb-thumbnail-editor__photos
        display none
    &[is-show-upload="false"]
      .pb-thumbnail-editor__upload
        display none
      .pb-thumbnail-editor__photos
        display block
  &__upload
    position absolute
    top 50%
    left 0
    right 0
    transform translateY(-50%)
    margin auto
    width pxToRem(280px)
    text-align center
    &--hide
      display none
    &--show
      display block
  &__upload-icon
    img
      width pxToRem(36px)
      vertical-align top
  &__update-text1
    margin-top pxToRem(26px)
    font-size pxToRem(15px)
    font-weight 800
    line-height 1.27
    letter-spacing -0.2px
    color #aeaeae
  &__update-text2
    margin-top pxToRem(10px)
    font-size pxToRem(13px)
    font-weight 500
    line-height 1.46
    letter-spacing -0.2px
    color #aeaeae
  &__upload-btn-area
    margin-top pxToRem(36px)
    label
      position relative
      display inline-block
  &__input-file
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    opacity 0
    display none
    width 100%
    height 100%
  &__upload-btn
    padding pxToRem(10px) pxToRem(25px) pxToRem(9px) pxToRem(25px)
    border 0 none
    background-color #3c89f9
    color #fff !important
    font-size pxToRem(14px)
    font-weight 600
    letter-spacing -0.5px
    border-radius pxToRem(2px)
    span
      display block
      padding pxToRem(2px) 0 pxToRem(2px) pxToRem(27px)
      background url("//s3.marpple.co/files/u_218933/2022/8/original/5730e4209e5552643156f0fa44916a5eec8010671.png") no-repeat left center
      background-size pxToRem(20px) pxToRem(20px)
  &__photos
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    &--show
      display block
    &--hide
      display none
  &__photo
    display none
    position relative
    width 100%
    height 100%
    &--show
      display block
    img
      position absolute
      top 0
      left 0
      right 0
      bottom 0
      margin auto
      max-width 100%
      max-height 100%
    &:hover
    &:active
      .pb-thumbnail-editor__photo-delete
        display block
  &__photo-delete
    position absolute
    top 12px
    right 12px
    background-color rgba(17, 17, 17, 0.45)
    width 36px
    height 36px
    border-radius 50%
    cursor pointer
    & img
      width 18px
      height 18px

  &__photo-set-first
    position absolute
    left 0
    right 0
    bottom pxToRem(-40px)
    text-align center
    font-size pxToRem(12px)
    line-height 1.57
    letter-spacing -0.5px
    color #000
    input
      vertical-align middle
      margin-bottom pxToRem(2px)
  &__thumb-story-image
    margin pxToRem(60px) 0 0
  &__btn-thumb-story-image
    display: flex;
    width: pxToRem(240);
    height: pxToRem(40);
    min-width: pxToRem(96);
    padding: 0 pxToRem(24);
    justify-content: center;
    align-items: center;
    gap: pxToRem(4);
    border-radius: pxToRem(4);
    border: 1px solid $blue-100;
    background: $white;
    color: $blue-100 !important;
    text-align: center;
    font-family: Pretendard;
    font-size: pxToRem(14);
    font-weight: 500;
    line-height: pxToRem(22);
    letter-spacing: -0.2px;
  &__text
    margin pxToRem(30px) 0 0
    padding pxToRem(7px) pxToRem(20px) pxToRem(5px)
    border-radius pxToRem(4px)
    background-color rgba(76, 76, 76, 0.05)
    font-size pxToRem(13px)
    line-height 1.46
    letter-spacing -0.2px
    text-align center
    color #4c4c4c
  &__arrow-prev
  &__arrow-next
    display none
  &__radio-label
    >input[type='radio']
      margin-top -1px
      margin-left 0
      vertical-align middle
      appearance none
      border 1px solid $gray-s1
      border-radius 50%
      width 18px
      height 18px
    >input[type="radio"]:checked
      border 6px solid #3c89f9
      &:disabled
        border-color $gray-s1
.pb-editor-form
  > *
    margin-top pxToRem(24px)
    &:first-child
      margin-top 0
  hr
    border 0
    border-top 1px solid #f2f2f2
  &[data-stock_type="TOTAL_STOCK"]
    .pb-editor-form__total-stock
    .pb-editor-form__real-total-stock
      display block
  &__total-stock
  &__real-total-stock
    display none
  &__icon-help
    margin-top pxToRem(-3px)
    margin-left pxToRem(2px)
    display inline-block
    vertical-align top
    width pxToRem(16px)
    height pxToRem(16px)
    cursor pointer
    background url(//s3.marpple.co/files/u_218933/2022/10/original/0d3dcb646f626ff920da6a45d773112b275a1adf1.png) no-repeat
    background-size pxToRem(16px) pxToRem(16px)
    img
      display none
    &--on
      background-image url(//s3.marpple.co/files/u_218933/2022/10/original/aebf590743191741c5fad24a4e592d5a9be690721.png)
    &--ml0
      margin-left 0
      margin-right pxToRem(2px)
  &__icon-important
    margin-left pxToRem(2px)
    display inline-block
    vertical-align top
    width pxToRem(15px)
    height pxToRem(15px)
    background url(//s3.marpple.co/files/u_218933/2022/8/original/06b2fc36647431e3e4b333a11156a1eaa285ab351.png) no-repeat
    background-size pxToRem(15px) pxToRem(15px)
    &--ml0
      margin-left 0
      margin-right pxToRem(2px)
  &__btn-temp-save
  &__btn-cancel
    border 1px solid #ddd
    padding pxToRem(12px) pxToRem(15px) pxToRem(9px)
    border-radius pxToRem(2px)
    background-color #fff
    box-sizing border-box
    font-size pxToRem(14px)
    font-weight 500
    letter-spacing -0.5px
    text-align center
    color #4c4c4c !important
  &__btn-submit
    padding pxToRem(12px) pxToRem(15px) pxToRem(9px)
    border-radius pxToRem(2px)
    background-color #3c89f9
    box-sizing border-box
    border 1px solid #3c89f9
    font-size pxToRem(14px)
    font-weight 600
    letter-spacing -0.5px
    text-align center
    color #fff !important
    &--disabled
      background-color #828282
      border-color #828282
  &__error-msg
    display none
    margin pxToRem(7px) 0 0
    padding 0
    font-size pxToRem(13px)
    font-weight 500
    line-height 1.38
    letter-spacing -0.5px
    color #f66565
    &--show
      display block
  &__control
    position relative
    &--mt0
      margin-top 0
    &--refund-info
      &[refund_info_type="NORMAL"]
        .pb-editor-form__refund-info-normal
          display block
      &[refund_info_type="PRINT_ON_DEMAND"]
        .pb-editor-form__refund-info-print-on-demand
          display block
  &__refund-info-normal
  &__refund-info-print-on-demand
    display none
    margin-bottom pxToRem(15px)
  &__control-area
    position relative
    &--cols2
      display grid
      grid-gap pxToRem(6px)
      grid-template-columns repeat(2, 1fr)
  &__label
    display block
    &--inline
      display inline-flex
      align-items center
      input
        margin-right pxToRem(8px)
    &--lm10
      margin-left pxToRem(10px)
    &--lm-minus3
      margin-left pxToRem(-3px)
  &__radio-label
    >input[type='radio']
      margin-top -1px
      margin-left 0
      vertical-align middle
      appearance none
      border 1px solid $gray-s1
      border-radius 50%
      width 18px
      height 18px
    >input[type="radio"]:checked
      border 6px solid #3c89f9
      &:disabled
        border-color $gray-s1

  &__label-txt
    margin-bottom pxToRem(8px)
    font-size pxToRem(13px)
    font-weight 600
    line-height 0.93
    letter-spacing -0.5px
    color #000
  &__label-txt-sub
    font-weight 400
    color #757575
  &__label-txt-sub2
    font-weight 400
    color #3d89f9
  &__body
    position relative
    min-height pxToRem(34px)
    border-radius pxToRem(3px)
    border 1px solid #ededed
    &--error
      border-color #f66565
    &--radio
    &--check
      margin-top pxToRem(12px)
      border 0 none
      border-radius 0
      display flex
    &--min-height-none
      min-height 0
    &--textarea
      min-height pxToRem(120px)
    .flatpickr-wrapper
      position absolute
      top 0
      left 0
      right 0
      bottom 0
    &--terms
      padding pxToRem(10px)
      height pxToRem(160px)
      overflow auto
  &__body-area
    position relative
    &--cols2
      display grid
      grid-gap pxToRem(6px)
      grid-template-columns repeat(2, 1fr)
    &--op-add
      display flex
      > *
        margin-left pxToRem(10px)
        &:first-child
          flex 1 0 0
          margin-left 0
    &--op-add-del
      display grid
      grid-gap pxToRem(6px)
      grid-template-columns 1fr pxToRem(34px) pxToRem(34px)
  &__producing-period
    &--show
      display block
    &--hide
      display none
  &__btn-op-add
    width pxToRem(34px)
    height pxToRem(34px)
    border-radius pxToRem(3px)
    box-shadow 0 2px 10px 0 rgba(0, 0, 0, 0.05)
    color transparent !important
    border 1px solid #3c89f9
    background #3c89f9 url("//s3.marpple.co/files/u_218933/2023/1/original/a5da871a63c44179a0b2a958532e504f41968afd1.svg") no-repeat 50% 50%
    background-size pxToRem(18px)
  &__btn-op-del
    width pxToRem(34px)
    height pxToRem(34px)
    border-radius pxToRem(3px)
    border solid 1px #ddd
    color transparent !important
    background #fff url("//s3.marpple.co/files/u_218933/2023/1/original/b41ad772540ec666e8fc19c4e7c0d7324b2666e41.svg") no-repeat 50% 50%
    background-size pxToRem(18px)
  &__body-input
  &__body-textarea
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    padding pxToRem(2px) pxToRem(11px) 0
    color #000
    font-size pxToRem(12px)
    font-weight 500
    border 0 none !important
    background-color transparent !important
    outline none
    &::placeholder
      color #b2b2b2
      font-weight 500
    &[disabled]
      background-color #f8f8f8 !important
    &--text-length
      right pxToRem(70px)
    &--won
      right pxToRem(20px)
    &-tpl-fee
      padding-right 36px
  &__body-textarea
    top pxToRem(11px)
    left pxToRem(11px)
    right pxToRem(11px)
    bottom pxToRem(11px)
    padding 0
    resize none
  &__body-length
    position absolute
    top 0
    right 0
    bottom 0
    display flex
    padding 0 pxToRem(6px)
    justify-content center
    align-items center
    min-width pxToRem(66px)
    font-size pxToRem(12px)
    font-weight 500
    letter-spacing -0.5px
    color #828282
    border-top-right-radius pxToRem(3px)
    border-bottom-right-radius pxToRem(3px)
    background-color #ededed
  &__txt1
    margin pxToRem(6px) 0 0
    padding 0
    font-size pxToRem(12px)
    line-height 1.5
    letter-spacing -0.5px
    color #757575
  &__body-right
    position absolute
    top 0
    right 0
    bottom 0
    padding 0 pxToRem(11px)
    display flex
    align-items center
  &__select
    width 100%
    height 100%
    select
      position absolute
      top 0
      left 0
      right 0
      bottom 0
      width 100%
      height 100%
      -webkit-appearance none
      -moz-appearance none
      border 0 none
      border-radius 2px
      background $image-down-arrow no-repeat 95% center
      background-size 18px 18px
      text-indent 10px
      &--error
        border-color #f66565 !important
      &:disabled
        background-color #EDEDED
        color black
  &__direct-option
    margin-top pxToRem(24px)
    .pb-editor-form__body-area-btn-add
      display none
    .pb-editor-form__body-area-btn-del
      display block
    &:last-child
      .pb-editor-form__body-area-btn-add
        display block
  &__option-control
    margin-top pxToRem(24px)
    grid-template-columns 34% 1fr
    .pb-editor-form__body-area-btn-op-add
      display none
    .pb-editor-form__body-area-btn-del
      display block
    &:last-child
      .pb-editor-form__body-area-btn-op-add
        display block
  &__set-option
    width auto
  &__btn-set-op-list,
  &__btn-set-op-list-tpl
    display inline-flex
    padding pxToRem(14px) pxToRem(17px) pxToRem(10px) pxToRem(18px)
    font-size pxToRem(12px)
    font-weight 600
    color #fff !important
    line-height 1.2
    letter-spacing -0.5px
    border 0 none
    border-radius 2px
    background-color #3c89f9
    width 100%
    justify-content center
    span
      display block
      padding-left pxToRem(24px)
      background url("//s3.marpple.co/files/u_218933/2022/8/original/68ff5cb2d7bf84f22d8f787eac338f936c7b6c663.png") no-repeat 0 30%
      background-size auto pxToRem(13px)
  &__op-select-delete
    margin-top pxToRem(24px)
  &__btn-select-delete
    display none
  &__sku
    .pb-editor-form__label-txt
      line-height 16.8px
      &.flex-gap-2
        display flex
        align-items end
        gap pxToRem(2px)
  &__toggle-container
    margin-left pxToRem(10px)
    cursor pointer
    position relative
    display inline-block
    width pxToRem(48px)
    height pxToRem(20px)
    pointer-events none
    user-select none
    > input
      cursor pointer
      opacity 0
      width 100%
      height 100%
      pointer-events all
    > input:checked + .slider
      background-color #1C75FF
    > input:checked:disabled + .slider
      background-color #111111
    > input:checked + .slider:before
      transform translate(pxToRem(27px), -50%)
    > input:checked + .slider:after
      content 'ON'
      left pxToRem(10px)
    .slider
      position absolute
      top 0
      left 0
      right 0
      bottom 0
      background-color  #A2A2A2
      transition 0.4s
      pointer-events none
      &:before
        position absolute
        content ''
        height pxToRem(15px)
        width pxToRem(15px)
        left pxToRem(3px)
        top 50%
        transform translateY(-50%)
        background-color #FFFFFF
        transition 0.5s
        pointer-events none
      &:after
        position absolute
        content 'OFF'
        right pxToRem(6px)
        top 55%
        transform translateY(-50%)
        transition 0.2s
        color #FFFFFF
        pointer-events none
        font-weight 700
        font-size pxToRem(12px)
    .slider.round
      border-radius 34px
    .slider.round:before
      border-radius 50%
  &__sku
    ul
      margin 0
      padding 0
      list-style none
      font-size pxToRem(13px)
      line-height pxToRem(20px)
      letter-spacing pxToRem(-0.5px)
      li.bold
        font-weight 700
  &__inquiry-precautions
    margin-top pxToRem(10px)
    padding pxToRem(20px)
    color #757575
    border-radius pxToRem(3px)
    background-color #f8f8f8
    &__txt1
      margin 0 0 pxToRem(6px)
      padding 0
      font-weight 600
      line-height 1.29
      letter-spacing -0.2px
      color #111
    &__txt2
      margin 0 0 pxToRem(6px)
      padding 0
      font-size pxToRem(13px)
      line-height 1.69
      letter-spacing -0.2px
    &__txt3
      margin 0
      padding 0
      font-size pxToRem(13px)
      line-height 1.69
      letter-spacing -0.2px
    ul
      margin 0
      padding 0
      list-style none
      font-size pxToRem(13px)
      line-height 1.69
  &__options-area
    margin-top pxToRem(24px)
  &__options-table, &__options-table-no-options
    margin-top pxToRem(10px)
    overflow-x scroll
    table
      width 100%
      table-layout fixed
      border-collapse collapse
    th
      text-align center !important
    th
    td
      padding pxToRem(5px) pxToRem(5px)
      line-height 1.71
      letter-spacing -0.5px
      color #000
      font-size pxToRem(11px)
      text-align center
      vertical-align middle
    th
      font-weight 600
      border-bottom 1px solid #000
    td
      border-bottom 1px solid #ededed
    input
      font-size pxToRem(12px)
    input
    img
      max-width 100%
      vertical-align middle
  &__options-check
    display none
  &__option-name-input
  &__option-price-input
  &__option-sku-nickname-input
  &__option-quantity-input
  &__option-real-quantity-input
    width pxToRem(120px)
    padding pxToRem(5px)
    border 0 none !important
    text-align center
  &__option-name-input
    width pxToRem(180px)
  &__options-price
  &__options-quantity
  &__options-real-quantity
    text-align center !important
  &__options-check
  &__options-del
    width pxToRem(60px)
  &__option-btn-del
    border 0 none
    outline none
    background-color transparent
    img
      width pxToRem(18px)
  &__options-sku-nickname
    display none
    &.show-sku
      display table-cell
      width 10rem
  &__options-size:not(.show-sku)
    display none
  &__options-size.show-sku
    display table-cell
  &__control-sales-period
  &__control-quantity
  &__control-max_purchase_per_user
    display none
    &--show
      display block
  &__qna-type-mp
    &--hide
      display none
  &__qna-type-sns
    &--hide
      display none
  &__kc-body
    display none
    margin-top 15px
    &--show
      display block
  &__select--only-mps
    color #000
    &[disabled]
      font-weight 600
      background-color $gray-6
  &__selected-stores-delivery-company
    display flex
    flex-wrap wrap
    gap pxToRem(2px)
    & > div
      white-space nowrap
    .studio-stores-delivery-companies__goto-ecommerce-info
      color #1C75FF
      text-decoration underline
      cursor pointer
      margin-left 8px
  &__title-wrap
    display flex
    flex-direction column
    row-gap ptr(12)
    margin-bottom ptr(32)
  &__title
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.2px;
  &__control-wrap
    display flex
    flex-direction column
    row-gap ptr(32)

.pb-editor-form
  #product-story-editor
    padding 0 !important

.editor-tooltip
  position absolute
  top 0
  left 0
  right 0
  bottom 0
  z-index 1
  &__bg
    display none
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    border 1px solid transparent
  &__wrap
    position absolute
    top pxToRem(50px)
    left pxToRem(20px)
    box-sizing border-box
    padding pxToRem(12px) pxToRem(16px)
    border-radius pxToRem(2px)
    box-shadow 0 pxToRem(2px) pxToRem(8px) 0 rgba(0, 0, 0, 0.2)
    border solid 1px #3c89f9
    background-color #fff
    z-index 1
  &__arrow
    position absolute
    top pxToRem(-7px)
    left pxToRem(40px)
    width pxToRem(10px)
    height pxToRem(8px)
    background url(//s3.marpple.co/files/u_218933/2022/10/original/d82c32facebdf179486ef9848b2071dc24dea58f1.png) no-repeat
    background-size pxToRem(10px) pxToRem(8px)
  &--hide
    display none
  h3
    margin pxToRem(14px) 0 0
    padding 0
    font-size pxToRem(14px)
    font-weight 600
    font-style normal
    line-height 1.29
    letter-spacing -0.5px
    color #3c89f9
    &:first-child
      margin-top 0
  p
    margin pxToRem(2px) 0 0
    padding 0
    font-size pxToRem(14px)
    font-weight 500
    line-height 1.43
    letter-spacing -0.5px
    color #000
  &__p--mt
    margin-top pxToRem(14px) !important
  a
    color #3c89f9 !important
    text-decoration underline


.editor-tooltip-box
  &--hide
    display none
  h3
    margin pxToRem(14px) 0 0
    padding 0
    font-size pxToRem(14px)
    font-weight 600
    font-style normal
    line-height 1.29
    letter-spacing -0.5px
    color #3c89f9
    &:first-child
      margin-top 0
  p
    margin pxToRem(2px) 0 0
    padding 0
    font-size pxToRem(14px)
    font-weight 500
    line-height 1.43
    letter-spacing -0.5px
    color #000
  &__p--mt
    margin-top pxToRem(14px) !important
  a
    color #3c89f9 !important
    text-decoration underline


@media screen and (max-width 1550px)
  .pb-thumbnail-editor__arrow-prev
    left pxToRem(10px)
  .pb-thumbnail-editor__arrow-next
    right pxToRem(10px)

.product-gray-info
  margin pxToRem(6px) 0 0
  font-size pxToRem(12px)
  line-height 1.38
  letter-spacing -0.5px
  color #828282

.to-tpl
  &__description
    margin-bottom 20px
  &__input
    width 120px
    height 52px
    margin 0 auto
  &__input-value
    padding 16px 36px 16px 16px
    font-size 16px
.mshop-pop-up-style-1 .buttons button.ok.to-tpl__not-ok
  background-color $gray-3 !important

/* tpl-global */
.pb-editor
  &[data-is_global="true"]
    & .pb-editor-form__body-name-length
      display none
    & .pb-editor-form__body-area-op-direct
      grid-template-columns 24px 1fr 32px 32px
    & .pb-global-wrap--option-table
        & input[name="option_price"]
          border solid 1px #ededed !important
          border-radius 3px
          text-align center
          height 40px
        & input[name="option_sku_nickname"]
          border solid 1px #ededed !important
          border-radius 3px
          text-align left
          height 40px
          text-indent 10px
          &.invalid
            border-color #D92A0F !important
        & button[name="option_size"]
          font-size pxToRem(14px)
          font-weight 500
          line-height pxToRem(16.8px)
          letter-spacing pxToRem(-0.5px)
          width 100%
          max-width pxToRem(67px)
          height pxToRem(40px)
          border-radius 4px
          background-color transparent
          border 1px solid #1C75FF
          color #1C75FF
          &.invalid
            border-color #F0415A !important
            color #F0415A !important
        tr.has-sku
          & button[name="option_size"]
            border 1px solid #CCCCCC
            color #4C4C4C
        .spo-item-text
          word-break break-word

    & .pb-global-wrap--option-direct
      & .pb-editor-form__body-op-direct-length
        display none

    & .pb-editor-form__product-name
      display none
    & [data-is_certification_info="false"]
      & .pb-editor-form__global-txt
        display none
    & .pb-editor-form__body-input--text-length
      right 0

  &[data-is_global="false"]
    & .pb-global
      display none
  & .pb-tpl-invisible
    display none
  & [data-is_certification_info="false"]
    & img
      display none

.pb-global-wrap
  & .mb-18
    margin-bottom 18px

.pb-global-wrap--name
  & .pb-editor-form__body
    margin-top 12px
  & .pb-editor-form__global-txt
    display inline-block
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px; /* 150% */
    letter-spacing: -0.2px;
  & .pb-editor-form__global-txt:not(:first-child)
    margin-top 24px

.pb-global-wrap--option-direct
  & .mt-12
    margin-top 12px
  & .pb-editor-form__global-txt
    align-self center
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px; /* 150% */
    letter-spacing: -0.2px;

.pb-global-wrap--product-info
  display flex
  gap 10px

  & .pb-editor-form__global-txt
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px; /* 150% */
    letter-spacing: -0.2px;

  & .pb-editor-form__body--textarea
    flex auto

.pb-global-wrap--certification_info
  display flex
  flex-direction column
  row-gap 8px
  margin-top 16px
  & > div
    display flex
    align-items center
    width 100%
    column-gap 8px
  & img
    width 24px
    height 24px
    margin-top 12px
    margin-right 8px
    &.none
      display none
  & .pb-editor-form__kc-body--show
    flex auto
    margin-top 0

.pb-global-wrap--refund-info
  display flex
  & .pb-editor-form__global-txt
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px; /* 150% */
    letter-spacing: -0.2px;
    margin-right 8px
  & .pb-editor-form__body--textarea
    flex auto

.pb-global-wrap--option-control
  & .mt-12
    margin-top 12px
  & img
    width 24px
    height 24px
  & .pb-global-wrap__option-name
    display flex
    gap 10px
    align-items center
    & > .pb-editor-form__body
      flex 1


.pb-editor-frame:has(+ .pb-product-size-frame)
  overflow hidden

.pb-global-wrap--store-delivery-company
  margin-bottom ptr(40)

