.new_maker_guide_layer.don_frame[is_modal="false"]
  >.don_wrapper
    >.header
      z-index 10
      >.title
        position absolute
        top 18px
        left 50%
        transform translateX(-50%)
        font-size pxToRem(16px) !important
        font-weight 700 !important
        line-height pxToRem(24px) !important


.dtg-download-guide
  padding ptr(24) ptr(16)
  padding-bottom calc(env(safe-area-inset-bottom) + 116px)
  padding-bottom calc(constant(safe-area-inset-bottom) + 116px)
  & > .title
    display none
  &__content-container
    display flex
    flex-direction column
    row-gap ptr(16px)
  &__content-wrap
    display flex
    flex-direction column
    row-gap ptr(8px)
    padding-left ptr(9px)
  &__content
    display flex
    column-gap ptr(10px)
    &:before
      content '·'
      display block
  &__buttons
    position fixed
    bottom 0
    left 0
    right 0
    background-color $white
    padding ptr(16px)
    border-top 1px solid $gray-5
    z-index 10
    display flex
    &:after
      display block
      content ''
      padding-bottom env(safe-area-inset-bottom)
      padding-bottom constant(safe-area-inset-bottom)
    html[safe-area="true"] &
      padding-bottom 34px
    & > button
      width 100%
      height ptr(56px)
      border-radius ptr(8px)
  &__btn_down_agree
    color $white
    border 0 none
    background-color $blue