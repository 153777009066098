.creator-settings
  .product-guide-popup-frame
    > .don_wrapper > .header
      z-index 1
    .don_hide_frame
      display none !important
  .product-guide-popup
    padding-bottom 50px
    &__title
      position relative
      margin 0
      padding ptr(18)
      text-align center
      border-bottom 1px solid #F8F8F8
    &__close
      position absolute
      background-image url(//s3.marpple.co/files/u_2283830/2024/10/original/e65cc766cb28a8f2365cce65952083b7414141081.webp)
      background-size ptr(24)
      width ptr(24)
      height ptr(24)
      right ptr(18)
      top ptr(18)
    &__body
      padding ptr(16) ptr(32)
    &__ul
      padding 0
      margin 0
      list-style none
      display flex
      flex-direction column
      align-items center
      gap ptr(32)
    &__li
      flex 1 1 0
      display flex
      flex-direction column
      gap ptr(16)
    &__img
      img
        width 100%
    &__text
      display flex
      flex-direction column
      gap ptr(8)
      h3, p
        margin 0
        padding 0
    &__guide
      display flex
      flex-direction column
      gap ptr(4)
