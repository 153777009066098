.store-about
  padding 0 20px
  margin-bottom 50px
  &__right
    margin-top pxToRem(64px)

.about
  margin-bottom pxToRem(20px)
  padding 0 pxToRem(24px)
  &__profile
    display flex
    justify-content flex-start
    align-items flex-start
  &__thumb
    position relative
    width pxToRem(96px)
    margin-right 16px
  &__thumb-img
    position relative
    width 100%
    padding-top 100%
    background-size cover
    background-repeat no-repeat
    background-position center
    border-radius pxToRem(34px)
  &__sns
    position absolute
    bottom -3px
    right -3px
    width pxToRem(26px)
    height pxToRem(26px)
    padding pxToRem(3px)
    background-color #fff
    border-radius 50%
    overflow hidden
    img
      width 100%
      border-radius 50%
      vertical-align top
  &__info
    position relative
    width 100%
    overflow hidden
  &__name
    margin 0 0 pxToRem(3px)
    font-size pxToRem(15px)
    font-weight 600
    line-height 1.27
    letter-spacing -0.2px
  &__link
    width 100%
    margin 0 0 pxToRem(11px)
    font-size pxToRem(15px)
    line-height 1.07
    letter-spacing -0.2px
    color #4e4e4e
    white-space nowrap
    overflow hidden
    text-overflow ellipsis
  &__meta
    display flex
    align-items center
    > button
      display flex
      align-items center
      margin-right 8px
      padding 5px 14px 4px
      border-radius 14px
      background-color #f8f8f8
      border none
      outline none
      &.active
        svg g g
          fill #ec1131
          stroke #ec1131
    .icon
      width 18px
      font-size 0
      line-height 1
      margin-right 5px
    .count, .text
      font-size 14px
      line-height 1.29
      letter-spacing -0.3px
      color #a2a2a2
  &__edit
    position absolute
    top 0
    right 0
  &__edit-btn
    padding 5px 10px 4px
    font-size 11px
    line-height 1.25
    color #4e4e4e
    background #fff
    border-radius 12px
    border solid 1px #dddddd
  &__description
    margin-top pxToRem(16px)
    font-size 16px
    line-height 1.63
    letter-spacing -0.2px
    color #4e4e4e
  &__represent
    margin-top pxToRem(32px)
    img
      width 100%
  &__video
    width 100%
    min-height calc((100vw - 48px) * 0.5625)


.about-gallery
  &__title
    margin 0 0 pxToRem(16px)
    padding 0
    font-size 20px
    font-weight 600
    line-height 1.3
    letter-spacing -0.2px
    color #111111
  &__btn_add
    margin-bottom 16px
    width 100%
    box-sizing border-box
    font-size 16px
    line-height 1.25
    letter-spacing -0.2px
    color #4e4e4e
    padding 11px 32px 9px
    border-radius 20px
    border solid 1px #dddddd
    background none


.gallery-item
  margin-bottom pxToRem(32px)
  width 100%
  cursor pointer
  &__created_at
    margin-bottom pxToRem(10px)
    font-size 16px
    font-weight 600
    line-height 1.25
    letter-spacing -0.3px
    color #4e4e4e
  &__imgs
    text-align center
  &__img
    margin-bottom pxToRem(8px)
    img
      vertical-align top
      max-width 100%

.empty-gallery
  margin-top 50px
  text-align center
  &__icon
    margin-bottom 14px
  &__text
    font-size 15px
    line-height 1.27
    letter-spacing -0.2px
    text-align center
    color #a2a2a2
    b
      display block
      margin-bottom 6px
      font-size 18px
      font-weight 600
      line-height 1.56
      letter-spacing -0.2px
      text-align center
      color #4e4e4e
